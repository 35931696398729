import {Dispatch} from "redux";

import {catch400, patchRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {addFailureNotification} from "../../notifications/utils/add_failure_notification";

interface IUnsubscribeParams {
    emailHash: string;
    verificationHash: string;
}

export const unsubscribeUserFromMailing = (params: IUnsubscribeParams, onSuccess: () => void) => async (dispatch: Dispatch) => {
    const unsubscribeApiLink = apiLink.newsletter.unsubscribe.mailing({})(params);

    return patchRequest(clientRpRequestMeta, unsubscribeApiLink, {})
        .then(onSuccess)
        .catch(
            catch400(async () => {
                dispatch(addFailureNotification("Wystąpił błąd podczas wypisu z newslettera"));
            })
        );
};
import {Dispatch} from "redux";

import {catch400, patchRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {addFailureNotification} from "../../notifications/utils/add_failure_notification";

interface IUnsubscribeParams {
    emailHash: string;
    verificationHash: string;
}

export const unsubscribeUserFromMailing = (params: IUnsubscribeParams, onSuccess: () => void) => async (dispatch: Dispatch) => {
    const unsubscribeApiLink = apiLink.newsletter.unsubscribe.mailing({})(params);

    return patchRequest(clientRpRequestMeta, unsubscribeApiLink, {})
        .then(onSuccess)
        .catch(
            catch400(async () => {
                dispatch(addFailureNotification("Wystąpił błąd podczas wypisu z newslettera"));
            })
        );
};
