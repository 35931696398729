import {useMemo} from "react";
import {useSelector} from "react-redux";

import {upperFirst} from "@pg-mono/nodash";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {buildFriendlyPromotionSlug} from "../helpers/build_friendly_promotion_slug";
import {getPromotionMetaDataTypeName} from "../helpers/PromotionType";

export const usePromotionTypeListByCurrentRegion = (currentPromotionType: number) => {
    const promotionsStatistic = useSelector((state: IRPStore) => state.promotionList.statistic);
    const region = useSelector((state: IRPStore) => state.promotionList.region);

    const availablePromotions = useMemo(() => {
        if (!region?.slug) {
            return [];
        }

        return (
            promotionsStatistic
                ?.map((promotionStatistic) => ({
                    type: promotionStatistic.type,
                    label: getPromotionMetaDataTypeName(promotionStatistic.type),
                    count: promotionStatistic.count
                }))
                .filter((promotion) => !!promotion.label && promotion.count > 0 && promotion.type !== currentPromotionType) || []
        );
    }, [region?.slug, promotionsStatistic]);

    const promotionLinks = useMemo(() => {
        if (!region?.slug) {
            return [];
        }

        return (
            availablePromotions?.map((promotion) => ({
                href: rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({region: region?.slug, type: promotion.type})}),
                text: upperFirst(`${promotion.label} ${region?.name_declension_where}`)
            })) || []
        );
    }, [region?.slug, promotionsStatistic]);

    return {
        availablePromotions,
        promotionLinks
    };
};
import {useMemo} from "react";
import {useSelector} from "react-redux";

import {upperFirst} from "@pg-mono/nodash";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {buildFriendlyPromotionSlug} from "../helpers/build_friendly_promotion_slug";
import {getPromotionMetaDataTypeName} from "../helpers/PromotionType";

export const usePromotionTypeListByCurrentRegion = (currentPromotionType: number) => {
    const promotionsStatistic = useSelector((state: IRPStore) => state.promotionList.statistic);
    const region = useSelector((state: IRPStore) => state.promotionList.region);

    const availablePromotions = useMemo(() => {
        if (!region?.slug) {
            return [];
        }

        return (
            promotionsStatistic
                ?.map((promotionStatistic) => ({
                    type: promotionStatistic.type,
                    label: getPromotionMetaDataTypeName(promotionStatistic.type),
                    count: promotionStatistic.count
                }))
                .filter((promotion) => !!promotion.label && promotion.count > 0 && promotion.type !== currentPromotionType) || []
        );
    }, [region?.slug, promotionsStatistic]);

    const promotionLinks = useMemo(() => {
        if (!region?.slug) {
            return [];
        }

        return (
            availablePromotions?.map((promotion) => ({
                href: rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({region: region?.slug, type: promotion.type})}),
                text: upperFirst(`${promotion.label} ${region?.name_declension_where}`)
            })) || []
        );
    }, [region?.slug, promotionsStatistic]);

    return {
        availablePromotions,
        promotionLinks
    };
};
