import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {backgroundColor, display, flex, flexDirection, mb, onDesktop, pt} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {ApplicationModalButton} from "../../application/components/ApplicationModalButton";
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import ResponsiveCard from "../../atoms/responsive_card/ResponsiveCard";
import {useResponsiveLinkTarget} from "../../hooks/useResponsiveLinkTarget";
import {useOfferLink} from "../../offer/detail/hooks/use_offer_link";
import {promotionTypeNameStyle} from "../../vendor/components/detail/boxes/VendorDetailPromotionBox";
import {getPromotionTypeName} from "../helpers/PromotionType";
import {IPromotionListPromotion} from "../interfaces/IPromotionListResponse";
import {usePromotionStyle} from "./Promotion";

interface IPromotionBox {
    promotion: IPromotionListPromotion;
}

export const PromotionBox = (props: IPromotionBox) => {
    const {promotion} = props;
    const promotionStyle = usePromotionStyle(props.promotion.type);
    const offerUrl = useOfferLink(promotion.offer);
    const target = useResponsiveLinkTarget();

    const renderBoxContent = () => {
        return (
            <ResponsiveCard
                cover={
                    <div>
                        <Image
                            src={promotion.offer.main_image.m_img_375x211}
                            alt={promotion.offer.name}
                            width="auto"
                            height="auto"
                            css={[promotionBoxImageHolder]}
                        />
                        <Text as="h3" css={[flex(), flexDirection("column")]}>
                            <Text variant="headline_6" as="span" css={[pt(2)]}>
                                {promotion.offer.name}{" "}
                            </Text>
                            <Text variant="info_txt_1" as="span" css={[pt(2)]}>
                                {promotion.offer.address}
                            </Text>
                        </Text>
                    </div>
                }
                coverWidthOnDesktop="336px"
                coverPadding={2}
                coverDesktopPosition="left"
                badges={
                    <>
                        <Text as="h2" css={[flex(), flexDirection("column")]}>
                            <Text variant="mini_header" as="span" css={[promotionTypeNameStyle, backgroundColor(promotionStyle.color), mb(2)]}>
                                {getPromotionTypeName(promotion.type)}{" "}
                            </Text>
                            <Text variant="headline_4" as="span">
                                {promotion.name}
                            </Text>
                        </Text>
                    </>
                }
            >
                <ResponsiveCard.Body text={<span css={[display("none"), onDesktop(display("inline"))]}>{promotion.description}</span>}>
                    <Button onClick={(e) => e.stopPropagation()} href={offerUrl} target={target} size="medium" type="button" variant="filled_primary">
                        ZOBACZ SZCZEGÓŁY
                    </Button>
                </ResponsiveCard.Body>
            </ResponsiveCard>
        );
    };

    return (
        <ApplicationModalButton
            source={ApplicationSource.Promotion}
            sourceSection={ApplicationSourceSection.MODAL}
            vendorId={promotion.offer.vendor.id}
            offerId={promotion.offer.id}
            propertyId={null}
            render={({openModal}) => (
                <li css={[promotionBoxHolder, mb(3)]} onClick={openModal}>
                    {renderBoxContent()}
                </li>
            )}
        />
    );
};

const promotionBoxHolder = css`
    display: block;
    cursor: pointer;
`;

const promotionBoxImageHolder = (theme: Theme) => css`
    img {
        height: auto;
        width: 100%;

        @media (min-width: ${theme.breakpoints.sm}) {
            max-width: 336px;
        }

        @media (min-width: ${theme.breakpoints.md}) {
            width: 336px;
        }
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {backgroundColor, display, flex, flexDirection, mb, onDesktop, pt} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {ApplicationModalButton} from "../../application/components/ApplicationModalButton";
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import ResponsiveCard from "../../atoms/responsive_card/ResponsiveCard";
import {useResponsiveLinkTarget} from "../../hooks/useResponsiveLinkTarget";
import {useOfferLink} from "../../offer/detail/hooks/use_offer_link";
import {promotionTypeNameStyle} from "../../vendor/components/detail/boxes/VendorDetailPromotionBox";
import {getPromotionTypeName} from "../helpers/PromotionType";
import {IPromotionListPromotion} from "../interfaces/IPromotionListResponse";
import {usePromotionStyle} from "./Promotion";

interface IPromotionBox {
    promotion: IPromotionListPromotion;
}

export const PromotionBox = (props: IPromotionBox) => {
    const {promotion} = props;
    const promotionStyle = usePromotionStyle(props.promotion.type);
    const offerUrl = useOfferLink(promotion.offer);
    const target = useResponsiveLinkTarget();

    const renderBoxContent = () => {
        return (
            <ResponsiveCard
                cover={
                    <div>
                        <Image
                            src={promotion.offer.main_image.m_img_375x211}
                            alt={promotion.offer.name}
                            width="auto"
                            height="auto"
                            css={[promotionBoxImageHolder]}
                        />
                        <Text as="h3" css={[flex(), flexDirection("column")]}>
                            <Text variant="headline_6" as="span" css={[pt(2)]}>
                                {promotion.offer.name}{" "}
                            </Text>
                            <Text variant="info_txt_1" as="span" css={[pt(2)]}>
                                {promotion.offer.address}
                            </Text>
                        </Text>
                    </div>
                }
                coverWidthOnDesktop="336px"
                coverPadding={2}
                coverDesktopPosition="left"
                badges={
                    <>
                        <Text as="h2" css={[flex(), flexDirection("column")]}>
                            <Text variant="mini_header" as="span" css={[promotionTypeNameStyle, backgroundColor(promotionStyle.color), mb(2)]}>
                                {getPromotionTypeName(promotion.type)}{" "}
                            </Text>
                            <Text variant="headline_4" as="span">
                                {promotion.name}
                            </Text>
                        </Text>
                    </>
                }
            >
                <ResponsiveCard.Body text={<span css={[display("none"), onDesktop(display("inline"))]}>{promotion.description}</span>}>
                    <Button onClick={(e) => e.stopPropagation()} href={offerUrl} target={target} size="medium" type="button" variant="filled_primary">
                        ZOBACZ SZCZEGÓŁY
                    </Button>
                </ResponsiveCard.Body>
            </ResponsiveCard>
        );
    };

    return (
        <ApplicationModalButton
            source={ApplicationSource.Promotion}
            sourceSection={ApplicationSourceSection.MODAL}
            vendorId={promotion.offer.vendor.id}
            offerId={promotion.offer.id}
            propertyId={null}
            render={({openModal}) => (
                <li css={[promotionBoxHolder, mb(3)]} onClick={openModal}>
                    {renderBoxContent()}
                </li>
            )}
        />
    );
};

const promotionBoxHolder = css`
    display: block;
    cursor: pointer;
`;

const promotionBoxImageHolder = (theme: Theme) => css`
    img {
        height: auto;
        width: 100%;

        @media (min-width: ${theme.breakpoints.sm}) {
            max-width: 336px;
        }

        @media (min-width: ${theme.breakpoints.md}) {
            width: 336px;
        }
    }
`;
