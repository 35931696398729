import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Col, Container, Row} from "@pg-design/grid-module";
import {flexAlignCenter, mh, onDesktop, p, pb} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {bigDataLink} from "../../navigation/constants/links";
const dataVis = require("../assets/data_vis.png");

export const AnalyticsTools = () => {
    return (
        <section css={section}>
            <div css={uxContainer}>
                <Container>
                    <Row css={flexAlignCenter}>
                        <Col sm={4} md={6} css={[pb(8), onDesktop(pb(0))]}>
                            <Text as="h2" mb="3.2rem" variant="headline_1">
                                Dajemy Ci narzędzie do analizy rynku
                            </Text>
                            <Text variant="body_copy_0" mb="3.2rem" strong>
                                Platforma BigData to pierwsze, ogólnopolskie narzędzie monitoringu i analizy rynku dla profesjonalistów.
                            </Text>
                            <Text variant="body_copy_0" mb="3.2rem">
                                Prezentujemy aktualne, zagregowane dane na podstawie 4000 monitorowanych inwestycji mieszkaniowych rocznie, zamieszczanych na{" "}
                                {concatWithBrandName("portalu")}, realizowanych przez ponad 1000 deweloperów w całej Polsce.
                            </Text>

                            <Button as="a" href={bigDataLink} target="_blank" size="small" variant="filled_primary">
                                przejdź do bigdata
                            </Button>
                        </Col>
                        <Col sm={4} md={6}>
                            <Image css={chartImage} ratio={{xs: "1539/1296"}} height="1296" width="1539" src={dataVis} alt="wykres" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const section = css`
    background-color: #fff;
    ${p(10, 1)};
    ${onDesktop(p(10, 4))}
`;

const chartImage = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 51rem;
        margin-left: auto;
    }
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Col, Container, Row} from "@pg-design/grid-module";
import {flexAlignCenter, mh, onDesktop, p, pb} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {bigDataLink} from "../../navigation/constants/links";
const dataVis = require("../assets/data_vis.png");

export const AnalyticsTools = () => {
    return (
        <section css={section}>
            <div css={uxContainer}>
                <Container>
                    <Row css={flexAlignCenter}>
                        <Col sm={4} md={6} css={[pb(8), onDesktop(pb(0))]}>
                            <Text as="h2" mb="3.2rem" variant="headline_1">
                                Dajemy Ci narzędzie do analizy rynku
                            </Text>
                            <Text variant="body_copy_0" mb="3.2rem" strong>
                                Platforma BigData to pierwsze, ogólnopolskie narzędzie monitoringu i analizy rynku dla profesjonalistów.
                            </Text>
                            <Text variant="body_copy_0" mb="3.2rem">
                                Prezentujemy aktualne, zagregowane dane na podstawie 4000 monitorowanych inwestycji mieszkaniowych rocznie, zamieszczanych na{" "}
                                {concatWithBrandName("portalu")}, realizowanych przez ponad 1000 deweloperów w całej Polsce.
                            </Text>

                            <Button as="a" href={bigDataLink} target="_blank" size="small" variant="filled_primary">
                                przejdź do bigdata
                            </Button>
                        </Col>
                        <Col sm={4} md={6}>
                            <Image css={chartImage} ratio={{xs: "1539/1296"}} height="1296" width="1539" src={dataVis} alt="wykres" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const section = css`
    background-color: #fff;
    ${p(10, 1)};
    ${onDesktop(p(10, 4))}
`;

const chartImage = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 51rem;
        margin-left: auto;
    }
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;
