import {useLocation} from "react-router";

import {IFetchContextRoute} from "@pg-mono/data-fetcher";

import {getRouteFromLocation} from "../utils/get_route_from_location";

export function useCurrentRoute(): IFetchContextRoute {
    const location = useLocation();

    return getRouteFromLocation(location);
}
import {useLocation} from "react-router";

import {IFetchContextRoute} from "@pg-mono/data-fetcher";

import {getRouteFromLocation} from "../utils/get_route_from_location";

export function useCurrentRoute(): IFetchContextRoute {
    const location = useLocation();

    return getRouteFromLocation(location);
}
