import React, {forwardRef} from "react";
import {css} from "@emotion/react";

import {IFit} from "./Picture";

interface IImage {
    alt: string;
    height: number;
    width: number;
    src?: string;
    srcSet?: string;
    loading?: "lazy";
    fit?: IFit;
    hasError?: boolean;
    fetchPriority?: "high" | "low" | "auto";
    onLoad?: (event: React.SyntheticEvent<HTMLImageElement>) => void;
}

export const imageStyle = ({hasError, fit}: {hasError?: boolean; fit?: IFit}) => css`
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;

    ${hasError ? `display: none;` : ""}

    ${fit &&
    css`
        object-fit: cover;
        object-position: ${fit};
        height: 100%;
        width: 100%;
    `}
`;
export const Image = forwardRef<HTMLImageElement, IImage>((props, ref) => {
    const {fit, hasError, alt, fetchPriority, onLoad, ...rest} = props;

    return <img ref={ref} alt={alt} {...rest} fetchpriority={fetchPriority} onLoad={onLoad} css={imageStyle({hasError, fit})} />;
});
import React, {forwardRef} from "react";
import {css} from "@emotion/react";

import {IFit} from "./Picture";

interface IImage {
    alt: string;
    height: number;
    width: number;
    src?: string;
    srcSet?: string;
    loading?: "lazy";
    fit?: IFit;
    hasError?: boolean;
    fetchPriority?: "high" | "low" | "auto";
    onLoad?: (event: React.SyntheticEvent<HTMLImageElement>) => void;
}

export const imageStyle = ({hasError, fit}: {hasError?: boolean; fit?: IFit}) => css`
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;

    ${hasError ? `display: none;` : ""}

    ${fit &&
    css`
        object-fit: cover;
        object-position: ${fit};
        height: 100%;
        width: 100%;
    `}
`;
export const Image = forwardRef<HTMLImageElement, IImage>((props, ref) => {
    const {fit, hasError, alt, fetchPriority, onLoad, ...rest} = props;

    return <img ref={ref} alt={alt} {...rest} fetchpriority={fetchPriority} onLoad={onLoad} css={imageStyle({hasError, fit})} />;
});
