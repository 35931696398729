import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import type {Dispatch} from "redux";

import {flex, flexDirection} from "@pg-design/helpers-css";
import type {IFormValuesAction} from "@pg-mono/form";

import {IRPStore} from "../../../app/rp_reducer";
import {SearchTab} from "../../../search/actions/fetch_search_all_action";
import {ISearchInputValue} from "../../../search/components/ISearchInputValue";
import {promotionListFilterFormActions} from "../../actions/promotion_list_filter_fields_actions";
import {PromotionType} from "../../helpers/PromotionType";
import {usePromotionListFilterChange} from "../hooks/usePromotionListFilterChange";
import {PromotionListFilters} from "./filters/PromotionListFilters";
import {PromotionListHeaderResults} from "./PromotionListHeaderResults";

interface IProps {
    className?: string;
}
export const PromotionListHeader = (props: IProps) => {
    const dispatch: Dispatch<IFormValuesAction<unknown>> = useDispatch();

    const formValues = useSelector((state: IRPStore) => state.promotionList.form.formValues);
    const searchValue: ISearchInputValue = useSelector((state: IRPStore) => state.search.formValues.search);

    const onPromotionListFilterChange = usePromotionListFilterChange();

    const onChange = (newFormValues: {type?: PromotionType}) => {
        dispatch(promotionListFilterFormActions.update(newFormValues));

        if (searchValue.tabType === SearchTab.Regions) {
            onPromotionListFilterChange(newFormValues.type || undefined, searchValue?.regions?.[0]?.slug);
        } else {
            onPromotionListFilterChange(newFormValues.type || undefined);
        }
    };

    return (
        <div css={[promotionListHeader]} className={props.className}>
            <PromotionListHeaderResults type={formValues.type} />

            <PromotionListFilters onChange={onChange} values={formValues} />
        </div>
    );
};

const promotionListHeader = (theme: Theme) => css`
    ${flex("center", "space-between")};
    ${flexDirection("column-reverse")};
    row-gap: 2.4rem;

    @media (min-width: ${theme.breakpoints.md}) {
        ${flexDirection("row")};
        column-gap: 1.6rem;
        row-gap: 0;
    }
`;
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import type {Dispatch} from "redux";

import {flex, flexDirection} from "@pg-design/helpers-css";
import type {IFormValuesAction} from "@pg-mono/form";

import {IRPStore} from "../../../app/rp_reducer";
import {SearchTab} from "../../../search/actions/fetch_search_all_action";
import {ISearchInputValue} from "../../../search/components/ISearchInputValue";
import {promotionListFilterFormActions} from "../../actions/promotion_list_filter_fields_actions";
import {PromotionType} from "../../helpers/PromotionType";
import {usePromotionListFilterChange} from "../hooks/usePromotionListFilterChange";
import {PromotionListFilters} from "./filters/PromotionListFilters";
import {PromotionListHeaderResults} from "./PromotionListHeaderResults";

interface IProps {
    className?: string;
}
export const PromotionListHeader = (props: IProps) => {
    const dispatch: Dispatch<IFormValuesAction<unknown>> = useDispatch();

    const formValues = useSelector((state: IRPStore) => state.promotionList.form.formValues);
    const searchValue: ISearchInputValue = useSelector((state: IRPStore) => state.search.formValues.search);

    const onPromotionListFilterChange = usePromotionListFilterChange();

    const onChange = (newFormValues: {type?: PromotionType}) => {
        dispatch(promotionListFilterFormActions.update(newFormValues));

        if (searchValue.tabType === SearchTab.Regions) {
            onPromotionListFilterChange(newFormValues.type || undefined, searchValue?.regions?.[0]?.slug);
        } else {
            onPromotionListFilterChange(newFormValues.type || undefined);
        }
    };

    return (
        <div css={[promotionListHeader]} className={props.className}>
            <PromotionListHeaderResults type={formValues.type} />

            <PromotionListFilters onChange={onChange} values={formValues} />
        </div>
    );
};

const promotionListHeader = (theme: Theme) => css`
    ${flex("center", "space-between")};
    ${flexDirection("column-reverse")};
    row-gap: 2.4rem;

    @media (min-width: ${theme.breakpoints.md}) {
        ${flexDirection("row")};
        column-gap: 1.6rem;
        row-gap: 0;
    }
`;
