import {css, Theme, useTheme} from "@emotion/react";

import {m} from "@pg-design/helpers-css";
import {RoomsIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

export interface IFavouritesListItemRoomsProps {
    propertiesForSale?: number;
    roomsRange?: number[];
    className?: string;
}

const roomsCountPluralize = pluralize(["dostępne mieszkanie", "dostępne mieszkania", "dostępnych mieszkań"]);
const roomsRangePluralize = pluralize(["pokojowe", "pokojowych", "pokojowych"]);
const roomsRangePluralizeBasedOnPropertiesCount = pluralize(["pokojowe", "pokojowe", "pokojowych"]);

export const FavouritesListItemRooms = (props: IFavouritesListItemRoomsProps) => {
    const {propertiesForSale, roomsRange, className} = props;
    const theme = useTheme();

    const roomsRangesText = (roomsRange: number[], propertiesForSale: number): React.ReactNode => {
        const roomsRangeMin: number = Math.min(...roomsRange) ?? 0;
        const roomsRangeMax: number = Math.max(...roomsRange) ?? 0;

        if (roomsRangeMin === roomsRangeMax) {
            return <span>{roomsRangePluralize(roomsRangeMax)}</span>;
        } else {
            return (
                <span>
                    {roomsRangeMin} - {roomsRangeMax} {roomsRangePluralizeBasedOnPropertiesCount(propertiesForSale)}
                </span>
            );
        }
    };

    return (
        <div className={className} css={roomsStyle}>
            <div css={iconWrapStyle}>
                <RoomsIcon size="2" fill={theme.colors.secondary} />
            </div>
            <Text variant="body_copy_2">
                {!!propertiesForSale && roomsRange && roomsRange.length > 0 ? (
                    <>
                        {propertiesForSale} {roomsCountPluralize(propertiesForSale)} <span>{roomsRangesText(roomsRange, propertiesForSale)}</span>
                    </>
                ) : (
                    <span>Aktualnie brak dostępnych mieszkań</span>
                )}
            </Text>
        </div>
    );
};

const roomsStyle = (theme: Theme) => css`
    color: ${theme.colors.secondary};
    display: flex;
`;

const iconWrapStyle = css`
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    ${m(0, 1, 0, 0)}
`;
import {css, Theme, useTheme} from "@emotion/react";

import {m} from "@pg-design/helpers-css";
import {RoomsIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

export interface IFavouritesListItemRoomsProps {
    propertiesForSale?: number;
    roomsRange?: number[];
    className?: string;
}

const roomsCountPluralize = pluralize(["dostępne mieszkanie", "dostępne mieszkania", "dostępnych mieszkań"]);
const roomsRangePluralize = pluralize(["pokojowe", "pokojowych", "pokojowych"]);
const roomsRangePluralizeBasedOnPropertiesCount = pluralize(["pokojowe", "pokojowe", "pokojowych"]);

export const FavouritesListItemRooms = (props: IFavouritesListItemRoomsProps) => {
    const {propertiesForSale, roomsRange, className} = props;
    const theme = useTheme();

    const roomsRangesText = (roomsRange: number[], propertiesForSale: number): React.ReactNode => {
        const roomsRangeMin: number = Math.min(...roomsRange) ?? 0;
        const roomsRangeMax: number = Math.max(...roomsRange) ?? 0;

        if (roomsRangeMin === roomsRangeMax) {
            return <span>{roomsRangePluralize(roomsRangeMax)}</span>;
        } else {
            return (
                <span>
                    {roomsRangeMin} - {roomsRangeMax} {roomsRangePluralizeBasedOnPropertiesCount(propertiesForSale)}
                </span>
            );
        }
    };

    return (
        <div className={className} css={roomsStyle}>
            <div css={iconWrapStyle}>
                <RoomsIcon size="2" fill={theme.colors.secondary} />
            </div>
            <Text variant="body_copy_2">
                {!!propertiesForSale && roomsRange && roomsRange.length > 0 ? (
                    <>
                        {propertiesForSale} {roomsCountPluralize(propertiesForSale)} <span>{roomsRangesText(roomsRange, propertiesForSale)}</span>
                    </>
                ) : (
                    <span>Aktualnie brak dostępnych mieszkań</span>
                )}
            </Text>
        </div>
    );
};

const roomsStyle = (theme: Theme) => css`
    color: ${theme.colors.secondary};
    display: flex;
`;

const iconWrapStyle = css`
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    ${m(0, 1, 0, 0)}
`;
