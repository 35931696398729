import React, {useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {CenterBox, Container} from "@pg-design/grid-module";
import {calculateRemSize, flex, flexDirection, flexJustifyCenter, mh, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {UnsubscribeConfirmationModal} from "../components/UnsubscribeConfirmationModal";

const newsletterImg = require("../assets/newsletter.png");
const newsletterBigImg = require("../assets/newsletter_big.png");

enum FeedbackType {
    NO_LOOKING_ANYMORE,
    TOO_MANY_EMAILS
}

export const NewsletterUnsubscribeView = () => {
    const email = useSelector((state: IRPStore) => state.newsletter.unsubscribe.email);
    const theme = useTheme();

    const [showModal, setShowModal] = useState<boolean>(!!email);

    const pushToHomePage = () => {
        window.location.href = rpAppPath.base;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onFeedback = (type: FeedbackType) => {
        pushToHomePage();
    };

    return (
        <div>
            <Container>
                {showModal ? (
                    <CenterBox height="500px">
                        <Loader size="lg" />
                    </CenterBox>
                ) : (
                    <div css={[flexJustifyCenter, flexDirection("column"), p(2, 0, 5, 0), onDesktop(p(2, 0, 10, 0))]}>
                        <Picture
                            alt=""
                            sources={[
                                {minWidthPX: 0, width: 336, height: 288, src: newsletterImg},
                                {minWidthPX: theme.breakpoints.numeric.md, width: 498, height: 425, src: newsletterBigImg}
                            ]}
                            css={[mh("auto")]}
                        />
                        <Text as="div" variant="headline_0" align="center">
                            Dziękujemy, że byłeś(aś) z nami.
                        </Text>

                        <Text as="div" variant="body_copy_0" align="center" css={[mt(2)]} color={theme.colors.gray[800]}>
                            Od tej chwili nie będziemy już wysyłać naszego newslettera na adres <strong>{email ?? ""}</strong>
                        </Text>

                        <Text as="div" variant="headline_6" align="center" css={[mt(2)]}>
                            Czy możemy poznać powód Twojej rezygnacji?
                        </Text>

                        <div css={feedbackOptions}>
                            <Button onClick={() => onFeedback(FeedbackType.NO_LOOKING_ANYMORE)} variant="filled_primary" css={[buttonStyle]}>
                                Nie szukam już mieszkania
                            </Button>
                            <Button onClick={() => onFeedback(FeedbackType.TOO_MANY_EMAILS)} variant="filled_primary" css={[buttonStyle]}>
                                Za dużo wiadomości
                            </Button>
                        </div>
                    </div>
                )}
            </Container>

            <UnsubscribeConfirmationModal modalState={showModal} onModalClose={() => setShowModal(false)} />
        </div>
    );
};

const feedbackOptions = css`
    ${mt(4)};
    ${flex("center", "center")};
    ${flexDirection("column")};
    gap: ${calculateRemSize(2)};

    ${onDesktop(css`
        ${flexDirection("row")};
        gap: ${calculateRemSize(3)};
        ${mt(6)};
    `)};
`;

const buttonStyle = (theme: Theme) => css`
    ${w100};

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 30rem;
    }
`;
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {CenterBox, Container} from "@pg-design/grid-module";
import {calculateRemSize, flex, flexDirection, flexJustifyCenter, mh, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {UnsubscribeConfirmationModal} from "../components/UnsubscribeConfirmationModal";

const newsletterImg = require("../assets/newsletter.png");
const newsletterBigImg = require("../assets/newsletter_big.png");

enum FeedbackType {
    NO_LOOKING_ANYMORE,
    TOO_MANY_EMAILS
}

export const NewsletterUnsubscribeView = () => {
    const email = useSelector((state: IRPStore) => state.newsletter.unsubscribe.email);
    const theme = useTheme();

    const [showModal, setShowModal] = useState<boolean>(!!email);

    const pushToHomePage = () => {
        window.location.href = rpAppPath.base;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onFeedback = (type: FeedbackType) => {
        pushToHomePage();
    };

    return (
        <div>
            <Container>
                {showModal ? (
                    <CenterBox height="500px">
                        <Loader size="lg" />
                    </CenterBox>
                ) : (
                    <div css={[flexJustifyCenter, flexDirection("column"), p(2, 0, 5, 0), onDesktop(p(2, 0, 10, 0))]}>
                        <Picture
                            alt=""
                            sources={[
                                {minWidthPX: 0, width: 336, height: 288, src: newsletterImg},
                                {minWidthPX: theme.breakpoints.numeric.md, width: 498, height: 425, src: newsletterBigImg}
                            ]}
                            css={[mh("auto")]}
                        />
                        <Text as="div" variant="headline_0" align="center">
                            Dziękujemy, że byłeś(aś) z nami.
                        </Text>

                        <Text as="div" variant="body_copy_0" align="center" css={[mt(2)]} color={theme.colors.gray[800]}>
                            Od tej chwili nie będziemy już wysyłać naszego newslettera na adres <strong>{email ?? ""}</strong>
                        </Text>

                        <Text as="div" variant="headline_6" align="center" css={[mt(2)]}>
                            Czy możemy poznać powód Twojej rezygnacji?
                        </Text>

                        <div css={feedbackOptions}>
                            <Button onClick={() => onFeedback(FeedbackType.NO_LOOKING_ANYMORE)} variant="filled_primary" css={[buttonStyle]}>
                                Nie szukam już mieszkania
                            </Button>
                            <Button onClick={() => onFeedback(FeedbackType.TOO_MANY_EMAILS)} variant="filled_primary" css={[buttonStyle]}>
                                Za dużo wiadomości
                            </Button>
                        </div>
                    </div>
                )}
            </Container>

            <UnsubscribeConfirmationModal modalState={showModal} onModalClose={() => setShowModal(false)} />
        </div>
    );
};

const feedbackOptions = css`
    ${mt(4)};
    ${flex("center", "center")};
    ${flexDirection("column")};
    gap: ${calculateRemSize(2)};

    ${onDesktop(css`
        ${flexDirection("row")};
        gap: ${calculateRemSize(3)};
        ${mt(6)};
    `)};
`;

const buttonStyle = (theme: Theme) => css`
    ${w100};

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 30rem;
    }
`;
