import * as React from "react";
import {FunctionComponent} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {CartTimerIcon, HotIcon, MegaphoneIcon, PercentIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {ApplicationModalButton} from "../../application/components/ApplicationModalButton";
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {InquiryButtonTypeName} from "../../offer/detail/constants/inquiry_button_type";
import {getPromotionTypeName, PromotionType} from "../helpers/PromotionType";

interface IProps {
    promotion: {
        description: string | null;
        id: number;
        name: string;
        type: PromotionType;
    };
    vendorId: number;
    isAutoresponderEnabled: boolean;
    offerId?: number;
    propertyId?: number;
    isSaleOverLimit?: boolean;
}

export const Promotion: FunctionComponent<IProps> = (props) => {
    const promotionStyle = usePromotionStyle(props.promotion.type);
    const applicationSource = props.propertyId ? ApplicationSource.PromotionProperty : ApplicationSource.Promotion;

    return (
        <li css={promotionLi} key={props.promotion.id}>
            <div css={promotionIconColumnStyle}>{promotionStyle.icon}</div>

            <div>
                <Text css={promotionTypeNameStyle(promotionStyle.color)} as="p" variant="mini_header">
                    {getPromotionTypeName(props.promotion.type)}
                </Text>

                <Text as="p" variant="headline_6" css={promotionNameStyle}>
                    {props.promotion.name}
                </Text>

                <Text as="p" variant="body_copy_2" css={promotionDescription}>
                    {props.promotion.description && props.promotion.description}
                </Text>

                <ApplicationModalButton
                    source={applicationSource}
                    sourceSection={ApplicationSourceSection.MODAL}
                    vendorId={props.vendorId}
                    offerId={props.offerId || null}
                    propertyId={props.propertyId || null}
                    render={({openModal}) => (
                        <Button type="button" onClick={openModal} variant="filled_primary" size="x-small" css={promotionButtonStyle}>
                            {InquiryButtonTypeName.PROMOTIONS}
                        </Button>
                    )}
                />
            </div>
        </li>
    );
};

const promotionLi = (theme: Theme) => css`
    display: flex;

    &:not(:last-of-type) {
        margin: 0 0 5.8rem 0;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        &:not(:last-of-type) {
            margin: 0 0 5.6rem 0;
        }
    }
`;

const promotionIconColumnStyle = (theme: Theme) => css`
    flex: 0 0 75px;

    @media (min-width: ${theme.breakpoints.md}) {
        flex: 0 0 95px;
    }
`;

const promotionNameStyle = css`
    margin: 0 0 1.6rem 0;
`;

export const promotionTypeNameStyle = (color: string) => css`
    background-color: ${color};
    text-transform: uppercase;
    display: inline-flex;
    margin: 0 0 1.6rem 0;
    padding: 0 0.1rem;
`;

const promotionDescription = css`
    margin-bottom: 1.6rem;
`;

const promotionButtonStyle = css`
    padding: 1rem 1.4rem;
`;

//  usePromotionStyle - Style configuration hook
interface IGetPromotionStyleOutput {
    icon: JSX.Element | null;
    color: string;
}

export function usePromotionStyle(type: PromotionType): IGetPromotionStyleOutput {
    const theme = useTheme();

    switch (type) {
        case PromotionType.OPENDAYS:
            return {
                icon: <MegaphoneIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.background["200"]} />,
                color: theme.colors.background["200"]
            };
        case PromotionType.DISCOUNT:
            return {
                icon: <PercentIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.info} />,
                color: theme.colors.info
            };
        case PromotionType.LASTMINUTE:
            return {icon: <CartTimerIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.warning} />, color: theme.colors.warning};
        case PromotionType.PRESALE:
            return {icon: <MegaphoneIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.success} />, color: theme.colors.success};
        case PromotionType.EXHIBITION: //TODO: Not used anymore, remove implementation and usages in the app
            return {icon: null, color: "gray"};
        default:
            //  OFFER
            return {
                icon: <HotIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.highlight} />,
                color: theme.colors.highlight
            };
    }
}
import * as React from "react";
import {FunctionComponent} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {CartTimerIcon, HotIcon, MegaphoneIcon, PercentIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {ApplicationModalButton} from "../../application/components/ApplicationModalButton";
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {InquiryButtonTypeName} from "../../offer/detail/constants/inquiry_button_type";
import {getPromotionTypeName, PromotionType} from "../helpers/PromotionType";

interface IProps {
    promotion: {
        description: string | null;
        id: number;
        name: string;
        type: PromotionType;
    };
    vendorId: number;
    isAutoresponderEnabled: boolean;
    offerId?: number;
    propertyId?: number;
    isSaleOverLimit?: boolean;
}

export const Promotion: FunctionComponent<IProps> = (props) => {
    const promotionStyle = usePromotionStyle(props.promotion.type);
    const applicationSource = props.propertyId ? ApplicationSource.PromotionProperty : ApplicationSource.Promotion;

    return (
        <li css={promotionLi} key={props.promotion.id}>
            <div css={promotionIconColumnStyle}>{promotionStyle.icon}</div>

            <div>
                <Text css={promotionTypeNameStyle(promotionStyle.color)} as="p" variant="mini_header">
                    {getPromotionTypeName(props.promotion.type)}
                </Text>

                <Text as="p" variant="headline_6" css={promotionNameStyle}>
                    {props.promotion.name}
                </Text>

                <Text as="p" variant="body_copy_2" css={promotionDescription}>
                    {props.promotion.description && props.promotion.description}
                </Text>

                <ApplicationModalButton
                    source={applicationSource}
                    sourceSection={ApplicationSourceSection.MODAL}
                    vendorId={props.vendorId}
                    offerId={props.offerId || null}
                    propertyId={props.propertyId || null}
                    render={({openModal}) => (
                        <Button type="button" onClick={openModal} variant="filled_primary" size="x-small" css={promotionButtonStyle}>
                            {InquiryButtonTypeName.PROMOTIONS}
                        </Button>
                    )}
                />
            </div>
        </li>
    );
};

const promotionLi = (theme: Theme) => css`
    display: flex;

    &:not(:last-of-type) {
        margin: 0 0 5.8rem 0;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        &:not(:last-of-type) {
            margin: 0 0 5.6rem 0;
        }
    }
`;

const promotionIconColumnStyle = (theme: Theme) => css`
    flex: 0 0 75px;

    @media (min-width: ${theme.breakpoints.md}) {
        flex: 0 0 95px;
    }
`;

const promotionNameStyle = css`
    margin: 0 0 1.6rem 0;
`;

export const promotionTypeNameStyle = (color: string) => css`
    background-color: ${color};
    text-transform: uppercase;
    display: inline-flex;
    margin: 0 0 1.6rem 0;
    padding: 0 0.1rem;
`;

const promotionDescription = css`
    margin-bottom: 1.6rem;
`;

const promotionButtonStyle = css`
    padding: 1rem 1.4rem;
`;

//  usePromotionStyle - Style configuration hook
interface IGetPromotionStyleOutput {
    icon: JSX.Element | null;
    color: string;
}

export function usePromotionStyle(type: PromotionType): IGetPromotionStyleOutput {
    const theme = useTheme();

    switch (type) {
        case PromotionType.OPENDAYS:
            return {
                icon: <MegaphoneIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.background["200"]} />,
                color: theme.colors.background["200"]
            };
        case PromotionType.DISCOUNT:
            return {
                icon: <PercentIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.info} />,
                color: theme.colors.info
            };
        case PromotionType.LASTMINUTE:
            return {icon: <CartTimerIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.warning} />, color: theme.colors.warning};
        case PromotionType.PRESALE:
            return {icon: <MegaphoneIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.success} />, color: theme.colors.success};
        case PromotionType.EXHIBITION: //TODO: Not used anymore, remove implementation and usages in the app
            return {icon: null, color: "gray"};
        default:
            //  OFFER
            return {
                icon: <HotIcon size="2.56" wrapperSize="5" wrapperColor={theme.colors.highlight} />,
                color: theme.colors.highlight
            };
    }
}
