import React from "react";
import {css, Theme} from "@emotion/react";

import {mb, p, pb, ph} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

export type IBrandIcon = React.ComponentType<{
    size: string;
    className?: string;
    wrapperColor?: string;
    wrapperSize?: string;
    wrapperType?: "circle" | "square";
}>;

interface IProps {
    children: React.ReactNode;
    icon: IBrandIcon;
    iconText?: React.ReactNode;
    header?: React.ReactNode;
    headerTag?: Parameters<typeof Text>[0]["as"];
    text?: React.ReactNode;
}

export const ResponsiveCardBodyWithIcon: React.FC<IProps> = ({icon: Icon, iconText, header, headerTag, text, children}) => (
    <div css={wrapperStyle}>
        {(Icon || iconText) && (
            <div css={[ph(2)]}>
                {Icon && (
                    <div css={iconWrapperStyle}>
                        <Icon size="6.4" wrapperColor="#ebff00" wrapperSize="6.4" wrapperType="circle" css={iconStyle} />
                    </div>
                )}
                <Text as="p" variant="info_txt_1" align="center">
                    {iconText}
                </Text>
            </div>
        )}
        <div css={contentStyle}>
            {header && (
                <Text as={headerTag || "h3"} variant="headline_3" css={pb(1)}>
                    {header}
                </Text>
            )}
            {text && (
                <Text variant="body_copy_1" css={pb(2)}>
                    {text}
                </Text>
            )}
            {children}
        </div>
    </div>
);

const wrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ${p(2)};

    @media (min-width: ${theme.breakpoints.md}) {
        align-items: center;
    }
`;

const iconWrapperStyle = css`
    position: relative;
    height: 6.4rem;
    width: 6.4rem;
    ${mb(1)};
`;

const iconStyle = css`
    left: 0;
    top: 0;
    position: absolute;
`;

const contentStyle = css`
    flex: 1 1;
    ${ph(2)};
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {mb, p, pb, ph} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

export type IBrandIcon = React.ComponentType<{
    size: string;
    className?: string;
    wrapperColor?: string;
    wrapperSize?: string;
    wrapperType?: "circle" | "square";
}>;

interface IProps {
    children: React.ReactNode;
    icon: IBrandIcon;
    iconText?: React.ReactNode;
    header?: React.ReactNode;
    headerTag?: Parameters<typeof Text>[0]["as"];
    text?: React.ReactNode;
}

export const ResponsiveCardBodyWithIcon: React.FC<IProps> = ({icon: Icon, iconText, header, headerTag, text, children}) => (
    <div css={wrapperStyle}>
        {(Icon || iconText) && (
            <div css={[ph(2)]}>
                {Icon && (
                    <div css={iconWrapperStyle}>
                        <Icon size="6.4" wrapperColor="#ebff00" wrapperSize="6.4" wrapperType="circle" css={iconStyle} />
                    </div>
                )}
                <Text as="p" variant="info_txt_1" align="center">
                    {iconText}
                </Text>
            </div>
        )}
        <div css={contentStyle}>
            {header && (
                <Text as={headerTag || "h3"} variant="headline_3" css={pb(1)}>
                    {header}
                </Text>
            )}
            {text && (
                <Text variant="body_copy_1" css={pb(2)}>
                    {text}
                </Text>
            )}
            {children}
        </div>
    </div>
);

const wrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ${p(2)};

    @media (min-width: ${theme.breakpoints.md}) {
        align-items: center;
    }
`;

const iconWrapperStyle = css`
    position: relative;
    height: 6.4rem;
    width: 6.4rem;
    ${mb(1)};
`;

const iconStyle = css`
    left: 0;
    top: 0;
    position: absolute;
`;

const contentStyle = css`
    flex: 1 1;
    ${ph(2)};
`;
