import {userComUserengage} from "@pg-mono/user-com";
import {updateUserAttributes} from "@pg-mono/user-com/src/events/update_user_attributes";

export enum B2BUsercomSources {
    OUR_OFFER_FORM_TOP = "for_developer_form_top",
    OUR_OFFER_FORM = "for_developer_form"
}

interface IB2BEventValues {
    mktOptIn: boolean;
    mktOptInPhone: boolean;
    mktOptInSms: boolean;
    name?: string;
    email?: string;
    company?: string;
    phone?: string;
    text?: string;
    source?: B2BUsercomSources;
}

export const sendB2BUsercomEvent = (values: IB2BEventValues) => {
    const userPayload = {
        "[BD]_marketing_consent": values.mktOptIn,
        "[BD]_marketing_consent_phone": values.mktOptInPhone,
        "[BD]_marketing_consent_sms": values.mktOptInSms,
        ...((values.name && {name: values.name}) || {}),
        ...((values.email && {email: values.email}) || {}),
        ...((values.phone && {phone_number: values.phone}) || {}),
        ...((values.company && {company: values.company}) || {}),
        ...((values.text && {message: values.text}) || {}),
        ...((values.source && {source: values.source}) || {})
    };

    updateUserAttributes({...userPayload, event_name: "event.form_conversion"});
    userComUserengage("event.form_conversion", userPayload);
};
import {userComUserengage} from "@pg-mono/user-com";
import {updateUserAttributes} from "@pg-mono/user-com/src/events/update_user_attributes";

export enum B2BUsercomSources {
    OUR_OFFER_FORM_TOP = "for_developer_form_top",
    OUR_OFFER_FORM = "for_developer_form"
}

interface IB2BEventValues {
    mktOptIn: boolean;
    mktOptInPhone: boolean;
    mktOptInSms: boolean;
    name?: string;
    email?: string;
    company?: string;
    phone?: string;
    text?: string;
    source?: B2BUsercomSources;
}

export const sendB2BUsercomEvent = (values: IB2BEventValues) => {
    const userPayload = {
        "[BD]_marketing_consent": values.mktOptIn,
        "[BD]_marketing_consent_phone": values.mktOptInPhone,
        "[BD]_marketing_consent_sms": values.mktOptInSms,
        ...((values.name && {name: values.name}) || {}),
        ...((values.email && {email: values.email}) || {}),
        ...((values.phone && {phone_number: values.phone}) || {}),
        ...((values.company && {company: values.company}) || {}),
        ...((values.text && {message: values.text}) || {}),
        ...((values.source && {source: values.source}) || {})
    };

    updateUserAttributes({...userPayload, event_name: "event.form_conversion"});
    userComUserengage("event.form_conversion", userPayload);
};
