import React, {RefObject} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {display, flexAbsoluteCenter, mb, mr, onDesktop, pv} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {OurOfferContactForm} from "./OurOfferContactForm";

interface IProps {
    scrollToRef: RefObject<HTMLDivElement>;
}

export const ContactSection = (props: IProps) => {
    const theme = useTheme();
    const brandColor = theme.colors.primary;

    return (
        <section css={section} ref={props.scrollToRef}>
            <div css={onDesktop(display("none"))}>
                <Text as="h2" mb="3.2rem" variant="headline_1" align="center">
                    Porozmawiajmy
                    <br /> o Twojej inwestycji
                </Text>

                <Text as="p" mb="3.2rem" variant="body_copy_1" align="center">
                    Skontaktuj się z nami. Nasz konsultant przygotuje dla Ciebie ofertę dopasowaną do skali Twojej <br /> firmy i jej celów marketingowych.
                </Text>

                <div css={[flexAbsoluteCenter, mb(6)]}>
                    <PhoneIcon css={mr(2)} wrapperColor={brandColor} wrapperSize="4.8" size="3" />
                    <Text variant="headline_3" as="span">
                        <ContactLink href="tel:228256071">+48 (22) 825 60 71</ContactLink>
                    </Text>
                </div>
            </div>

            <OurOfferContactForm />
        </section>
    );
};

const section = (theme: Theme) => css`
    background-color: ${theme.colors.gray[100]};
    text-align: center;
    ${pv(4)}

    ${onDesktop(pv(11))}
`;
const ContactLink = styled("a")`
    &:hover,
    &:focus {
        text-decoration: none;
    }
`;
import React, {RefObject} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {display, flexAbsoluteCenter, mb, mr, onDesktop, pv} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {OurOfferContactForm} from "./OurOfferContactForm";

interface IProps {
    scrollToRef: RefObject<HTMLDivElement>;
}

export const ContactSection = (props: IProps) => {
    const theme = useTheme();
    const brandColor = theme.colors.primary;

    return (
        <section css={section} ref={props.scrollToRef}>
            <div css={onDesktop(display("none"))}>
                <Text as="h2" mb="3.2rem" variant="headline_1" align="center">
                    Porozmawiajmy
                    <br /> o Twojej inwestycji
                </Text>

                <Text as="p" mb="3.2rem" variant="body_copy_1" align="center">
                    Skontaktuj się z nami. Nasz konsultant przygotuje dla Ciebie ofertę dopasowaną do skali Twojej <br /> firmy i jej celów marketingowych.
                </Text>

                <div css={[flexAbsoluteCenter, mb(6)]}>
                    <PhoneIcon css={mr(2)} wrapperColor={brandColor} wrapperSize="4.8" size="3" />
                    <Text variant="headline_3" as="span">
                        <ContactLink href="tel:228256071">+48 (22) 825 60 71</ContactLink>
                    </Text>
                </div>
            </div>

            <OurOfferContactForm />
        </section>
    );
};

const section = (theme: Theme) => css`
    background-color: ${theme.colors.gray[100]};
    text-align: center;
    ${pv(4)}

    ${onDesktop(pv(11))}
`;
const ContactLink = styled("a")`
    &:hover,
    &:focus {
        text-decoration: none;
    }
`;
