import {useFormContext} from "react-hook-form";

import {ITextareaFieldProps, TextareaField as TextareaFieldDs} from "@pg-design/inputs-module";

type IProps = Omit<ITextareaFieldProps, "name" | "error"> & Required<Pick<ITextareaFieldProps, "name">>;

export function TextareaField(props: IProps) {
    const {name, ...fieldProps} = props;
    const {register, getFieldState, formState} = useFormContext<{[key: string]: unknown}>();
    const fs = getFieldState(name, formState);

    return <TextareaFieldDs {...register(name)} error={fs.error?.message} name={name} {...fieldProps} disabled={formState.disabled || fieldProps.disabled} />;
}
import {useFormContext} from "react-hook-form";

import {ITextareaFieldProps, TextareaField as TextareaFieldDs} from "@pg-design/inputs-module";

type IProps = Omit<ITextareaFieldProps, "name" | "error"> & Required<Pick<ITextareaFieldProps, "name">>;

export function TextareaField(props: IProps) {
    const {name, ...fieldProps} = props;
    const {register, getFieldState, formState} = useFormContext<{[key: string]: unknown}>();
    const fs = getFieldState(name, formState);

    return <TextareaFieldDs {...register(name)} error={fs.error?.message} name={name} {...fieldProps} disabled={formState.disabled || fieldProps.disabled} />;
}
