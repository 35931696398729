import React from "react";
import {css, Theme} from "@emotion/react";

import {p, pb, ph} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    children: React.ReactNode;
    header?: React.ReactNode;
    headerTag?: Parameters<typeof Text>[0]["as"];
    text?: React.ReactNode;
}

export const ResponsiveCardBody: React.FC<IProps> = ({header, headerTag, text, children}) => (
    <div css={wrapperStyle}>
        <div css={contentStyle}>
            {typeof header === "string" ? (
                <Text as={headerTag || "h4"} variant="headline_4" css={pb(2)}>
                    {header}
                </Text>
            ) : (
                header
            )}
            {text && (
                <Text variant="body_copy_2" css={pb(2)}>
                    {text}
                </Text>
            )}
            {children}
        </div>
    </div>
);

const wrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ${p(2)};

    @media (min-width: ${theme.breakpoints.md}) {
        align-items: center;
    }
`;

const contentStyle = (theme: Theme) => css`
    flex: 1 1;

    @media (min-width: ${theme.breakpoints.md}) {
        ${ph(2)};
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {p, pb, ph} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    children: React.ReactNode;
    header?: React.ReactNode;
    headerTag?: Parameters<typeof Text>[0]["as"];
    text?: React.ReactNode;
}

export const ResponsiveCardBody: React.FC<IProps> = ({header, headerTag, text, children}) => (
    <div css={wrapperStyle}>
        <div css={contentStyle}>
            {typeof header === "string" ? (
                <Text as={headerTag || "h4"} variant="headline_4" css={pb(2)}>
                    {header}
                </Text>
            ) : (
                header
            )}
            {text && (
                <Text variant="body_copy_2" css={pb(2)}>
                    {text}
                </Text>
            )}
            {children}
        </div>
    </div>
);

const wrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ${p(2)};

    @media (min-width: ${theme.breakpoints.md}) {
        align-items: center;
    }
`;

const contentStyle = (theme: Theme) => css`
    flex: 1 1;

    @media (min-width: ${theme.breakpoints.md}) {
        ${ph(2)};
    }
`;
