import {FieldValues, Path, UseFormSetError} from "react-hook-form";

import {castToValidationError} from "./cast_to_validation_error";
import {isFetchBaseQueryError} from "./is_fetch_base_query_error";

interface IParams<FormValues extends FieldValues> {
    error: unknown;
    setError: UseFormSetError<FormValues>;
    handle400ValidationError?: boolean;
    onUnknownError?: () => void;
}

export function handleRtkqApiError<FormValues extends FieldValues>(params: IParams<FormValues>) {
    const {error, setError, handle400ValidationError, onUnknownError} = params;

    if (handle400ValidationError && isFetchBaseQueryError(error) && error.status === 400) {
        const validationErrors = castToValidationError(error);

        for (const fieldName of Object.keys(validationErrors)) {
            const fieldError = validationErrors[fieldName];
            const errorMessage = Array.isArray(fieldError) ? fieldError.join(", ") : fieldError;

            setError(fieldName as Path<FormValues>, {
                message: errorMessage
            });
        }
        return;
    }

    if (onUnknownError) {
        onUnknownError();
    }
}
import {FieldValues, Path, UseFormSetError} from "react-hook-form";

import {castToValidationError} from "./cast_to_validation_error";
import {isFetchBaseQueryError} from "./is_fetch_base_query_error";

interface IParams<FormValues extends FieldValues> {
    error: unknown;
    setError: UseFormSetError<FormValues>;
    handle400ValidationError?: boolean;
    onUnknownError?: () => void;
}

export function handleRtkqApiError<FormValues extends FieldValues>(params: IParams<FormValues>) {
    const {error, setError, handle400ValidationError, onUnknownError} = params;

    if (handle400ValidationError && isFetchBaseQueryError(error) && error.status === 400) {
        const validationErrors = castToValidationError(error);

        for (const fieldName of Object.keys(validationErrors)) {
            const fieldError = validationErrors[fieldName];
            const errorMessage = Array.isArray(fieldError) ? fieldError.join(", ") : fieldError;

            setError(fieldName as Path<FormValues>, {
                message: errorMessage
            });
        }
        return;
    }

    if (onUnknownError) {
        onUnknownError();
    }
}
