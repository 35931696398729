import * as React from "react";

import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";

export const PartnersListHeader = () => {
    return (
        <header>
            <Text variant="headline_3" as="h1" css={mb(2)}>
                Partnerzy portalu rynekpierwotny.pl
            </Text>

            <Text variant="headline_6" as="p" css={mb(3)}>
                Duża rozpoznawalność i silna pozycja {concatWithBrandName("portalu")} na rynku nowych nieruchomości z pewnością nie byłaby możliwa, bez pomocy
                wielu partnerów biznesowych, z którymi codziennie utrzymujemy dobre relacje.
            </Text>

            <Text variant="headline_6" as="p" css={mb(3)}>
                Każdego dnia staramy się sprostać wspólnym wyzwaniom. Wśród nich znalazły się największe i najbardziej opiniotwórcze media oraz szanowane,
                poważane firmy oraz portale internetowe z branży finansowej i nieruchomościowej. Należą do nich między innymi:
            </Text>
        </header>
    );
};
import * as React from "react";

import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";

export const PartnersListHeader = () => {
    return (
        <header>
            <Text variant="headline_3" as="h1" css={mb(2)}>
                Partnerzy portalu rynekpierwotny.pl
            </Text>

            <Text variant="headline_6" as="p" css={mb(3)}>
                Duża rozpoznawalność i silna pozycja {concatWithBrandName("portalu")} na rynku nowych nieruchomości z pewnością nie byłaby możliwa, bez pomocy
                wielu partnerów biznesowych, z którymi codziennie utrzymujemy dobre relacje.
            </Text>

            <Text variant="headline_6" as="p" css={mb(3)}>
                Każdego dnia staramy się sprostać wspólnym wyzwaniom. Wśród nich znalazły się największe i najbardziej opiniotwórcze media oraz szanowane,
                poważane firmy oraz portale internetowe z branży finansowej i nieruchomościowej. Należą do nich między innymi:
            </Text>
        </header>
    );
};
