import React from "react";
import {css} from "@emotion/react";

import {p} from "@pg-design/helpers-css";

interface IProps {
    children: React.ReactNode;
    className?: string;
}

export const ResponsiveCardBodySimple: React.FC<IProps> = ({className, children}) => (
    <div css={wrapperStyle} className={className}>
        {children}
    </div>
);

const wrapperStyle = css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ${p(2)};
`;
import React from "react";
import {css} from "@emotion/react";

import {p} from "@pg-design/helpers-css";

interface IProps {
    children: React.ReactNode;
    className?: string;
}

export const ResponsiveCardBodySimple: React.FC<IProps> = ({className, children}) => (
    <div css={wrapperStyle} className={className}>
        {children}
    </div>
);

const wrapperStyle = css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ${p(2)};
`;
