import {ICampaignDetails} from "../types/ICampaignDetails";

export function getDateFromCampaignDetails(campaign: Pick<ICampaignDetails, "opendays_date_range" | "opendays_time_range">) {
    const fromDate = getDateFromString(campaign.opendays_date_range.lower, campaign.opendays_time_range.lower);
    const toDate = getDateFromString(campaign.opendays_date_range.upper, campaign.opendays_time_range.upper);

    return {
        fromDate,
        toDate
    };
}

function getDateFromString(dayString: string, timeString: string) {
    const dateParts = getDateParts(dayString, timeString);
    return getDateFromParts(dateParts);
}

function getDateFromParts(dateParts: number[]) {
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5]);
}

function getDateParts(dayString: string, timeString: string) {
    const dayParts = dayString.split("-");
    const timeParts = timeString.split(":");
    return [...dayParts.map((part) => parseInt(part)), ...timeParts.map((part) => parseInt(part))];
}
import {ICampaignDetails} from "../types/ICampaignDetails";

export function getDateFromCampaignDetails(campaign: Pick<ICampaignDetails, "opendays_date_range" | "opendays_time_range">) {
    const fromDate = getDateFromString(campaign.opendays_date_range.lower, campaign.opendays_time_range.lower);
    const toDate = getDateFromString(campaign.opendays_date_range.upper, campaign.opendays_time_range.upper);

    return {
        fromDate,
        toDate
    };
}

function getDateFromString(dayString: string, timeString: string) {
    const dateParts = getDateParts(dayString, timeString);
    return getDateFromParts(dateParts);
}

function getDateFromParts(dateParts: number[]) {
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5]);
}

function getDateParts(dayString: string, timeString: string) {
    const dayParts = dayString.split("-");
    const timeParts = timeString.split(":");
    return [...dayParts.map((part) => parseInt(part)), ...timeParts.map((part) => parseInt(part))];
}
