import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

interface IProps extends IIcon {
    secondaryFill?: string;
}

export const ListAddIcon = (props: IProps) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.893 4.734H5v.533h1.893v-.533ZM6.887 5.8H3.134v.533h3.753V5.8ZM6.887 6.867H3.134V7.4h3.753v-.533Z" />
            <path d="M7.965 2.67 6.288 1H5v.535h.82v2.133h2.145v4.8H2.072V5h-.535v3.999H8.5v-5.8l-.535-.53Zm-1.607.465V1.824l1.315 1.31H6.358Z" />
            <circle cx={3} cy={3} r={2} fill={props.secondaryFill || "var(--colors-primary)"} />
            <path d="M3.258 4v-.745H4v-.511h-.742V2h-.513v.744H2v.51h.745V4h.513Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

interface IProps extends IIcon {
    secondaryFill?: string;
}

export const ListAddIcon = (props: IProps) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.893 4.734H5v.533h1.893v-.533ZM6.887 5.8H3.134v.533h3.753V5.8ZM6.887 6.867H3.134V7.4h3.753v-.533Z" />
            <path d="M7.965 2.67 6.288 1H5v.535h.82v2.133h2.145v4.8H2.072V5h-.535v3.999H8.5v-5.8l-.535-.53Zm-1.607.465V1.824l1.315 1.31H6.358Z" />
            <circle cx={3} cy={3} r={2} fill={props.secondaryFill || "var(--colors-primary)"} />
            <path d="M3.258 4v-.745H4v-.511h-.742V2h-.513v.744H2v.51h.745V4h.513Z" />
        </SvgIcon>
    );
};
