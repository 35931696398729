import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAlignCenter, mt} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {appendQueryString} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

interface IProps {
    extraContent?: React.ReactNode;
    header: string;
    subHeader?: React.ReactNode;
    showLoginButton?: boolean;
}

export const FavouritesEmptyData = (props: IProps) => {
    const {extraContent, header, subHeader, showLoginButton} = props;

    const onLogin = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        // Redirect user to Meta Account login page
        window.location.href = appendQueryString(apiUserLink.oauth.authorize, {
            next: location.pathname,
            redirect_uri: `${location.origin}${rpAppPath.oauth.redirect}`
        });
    };

    return (
        <div css={emptyDataContainer}>
            <div css={favouritesEmptyDataHeader}>
                <Text align="center" variant="headline_3" mb="1.6rem">
                    {header}
                </Text>

                {subHeader && (
                    <Text as="div" align="center" css={subHeaderStyles}>
                        {subHeader}
                    </Text>
                )}
            </div>

            {extraContent}

            {showLoginButton && (
                <Button onClick={onLogin} css={mt(4)} variant="filled_primary">
                    Zaloguj się
                </Button>
            )}
        </div>
    );
};

const favouritesEmptyDataHeader = css`
    ${flexAlignCenter};
    flex-direction: column;
`;

const emptyDataContainer = (theme: Theme) => css`
    ${mt(3)};
    ${flexAlignCenter};
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mt(6)};
    }
`;

const subHeaderStyles = (theme: Theme) => css`
    color: ${theme.colors.gray[600]};
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAlignCenter, mt} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {appendQueryString} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

interface IProps {
    extraContent?: React.ReactNode;
    header: string;
    subHeader?: React.ReactNode;
    showLoginButton?: boolean;
}

export const FavouritesEmptyData = (props: IProps) => {
    const {extraContent, header, subHeader, showLoginButton} = props;

    const onLogin = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        // Redirect user to Meta Account login page
        window.location.href = appendQueryString(apiUserLink.oauth.authorize, {
            next: location.pathname,
            redirect_uri: `${location.origin}${rpAppPath.oauth.redirect}`
        });
    };

    return (
        <div css={emptyDataContainer}>
            <div css={favouritesEmptyDataHeader}>
                <Text align="center" variant="headline_3" mb="1.6rem">
                    {header}
                </Text>

                {subHeader && (
                    <Text as="div" align="center" css={subHeaderStyles}>
                        {subHeader}
                    </Text>
                )}
            </div>

            {extraContent}

            {showLoginButton && (
                <Button onClick={onLogin} css={mt(4)} variant="filled_primary">
                    Zaloguj się
                </Button>
            )}
        </div>
    );
};

const favouritesEmptyDataHeader = css`
    ${flexAlignCenter};
    flex-direction: column;
`;

const emptyDataContainer = (theme: Theme) => css`
    ${mt(3)};
    ${flexAlignCenter};
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mt(6)};
    }
`;

const subHeaderStyles = (theme: Theme) => css`
    color: ${theme.colors.gray[600]};
`;
