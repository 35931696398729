import React, {FC, PropsWithChildren, ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {Col, Container, Row} from "@pg-design/grid-module";
import {ml, onDesktop} from "@pg-design/helpers-css";

interface IProps {
    children: React.ReactNode;
    className?: string;
    as?: React.ElementType;
}

// TODO: remove dummy component after implementing article detail view
export const TwoColumnGrid = () => (
    <div
        css={css`
            background-color: hotpink;
            padding: 1rem;
        `}
    >
        This section not work until you update usage of <code>`TwoColumnGrid`</code> 🥁
    </div>
);

export const TwoColumnGridHolder: FC<IProps> = (props) => {
    return (
        <Container className={props.className} as={props.as}>
            <Row>{props.children}</Row>
        </Container>
    );
};

export const TwoColumnGridMain: FC<IProps> = (props) => (
    <Col sm={4} md={8} lgOffset={2} lg={8} className={props.className}>
        {props.children}
    </Col>
);

export const TwoColumnGridAside: FC<IProps> = (props) => (
    <Col md={4} lg={4} as="aside" css={asideStyle} className={props.className}>
        {props.children}
    </Col>
);

const asideStyle = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
    }
`;

//  TwoColumnGridWithStickyAside
type IOneColumnGridProps = PropsWithChildren<{
    stickyAside: ReactNode | null;
    className?: string;
    as?: React.ElementType;
}>;

export const TwoColumnGridWithStickyAside: FC<IOneColumnGridProps> = (props) => {
    const {className, stickyAside, children, as} = props;

    return (
        <Container as={as} className={className}>
            <div css={withStickyWrap}>
                <Col noGutters sm={4} md={stickyAside ? 8 : 12} lg={stickyAside ? 12 : 16}>
                    <Container as={as} className={className}>
                        <Row>
                            <Col noGutters>{children}</Col>
                        </Row>
                    </Container>
                </Col>
                {stickyAside && (
                    <Col md={4} lg={4} as="aside" noGutters className={props.className}>
                        <div css={stickyAsideStyle}>{stickyAside}</div>
                    </Col>
                )}
            </div>
        </Container>
    );
};

const withStickyWrap = css`
    display: flex;
    flex-wrap: wrap;
`;

const stickyAsideStyle = css`
    ${onDesktop(css`
        position: sticky;
        top: 0;
        ${ml(3)};
    `)}
`;
import React, {FC, PropsWithChildren, ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {Col, Container, Row} from "@pg-design/grid-module";
import {ml, onDesktop} from "@pg-design/helpers-css";

interface IProps {
    children: React.ReactNode;
    className?: string;
    as?: React.ElementType;
}

// TODO: remove dummy component after implementing article detail view
export const TwoColumnGrid = () => (
    <div
        css={css`
            background-color: hotpink;
            padding: 1rem;
        `}
    >
        This section not work until you update usage of <code>`TwoColumnGrid`</code> 🥁
    </div>
);

export const TwoColumnGridHolder: FC<IProps> = (props) => {
    return (
        <Container className={props.className} as={props.as}>
            <Row>{props.children}</Row>
        </Container>
    );
};

export const TwoColumnGridMain: FC<IProps> = (props) => (
    <Col sm={4} md={8} lgOffset={2} lg={8} className={props.className}>
        {props.children}
    </Col>
);

export const TwoColumnGridAside: FC<IProps> = (props) => (
    <Col md={4} lg={4} as="aside" css={asideStyle} className={props.className}>
        {props.children}
    </Col>
);

const asideStyle = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
    }
`;

//  TwoColumnGridWithStickyAside
type IOneColumnGridProps = PropsWithChildren<{
    stickyAside: ReactNode | null;
    className?: string;
    as?: React.ElementType;
}>;

export const TwoColumnGridWithStickyAside: FC<IOneColumnGridProps> = (props) => {
    const {className, stickyAside, children, as} = props;

    return (
        <Container as={as} className={className}>
            <div css={withStickyWrap}>
                <Col noGutters sm={4} md={stickyAside ? 8 : 12} lg={stickyAside ? 12 : 16}>
                    <Container as={as} className={className}>
                        <Row>
                            <Col noGutters>{children}</Col>
                        </Row>
                    </Container>
                </Col>
                {stickyAside && (
                    <Col md={4} lg={4} as="aside" noGutters className={props.className}>
                        <div css={stickyAsideStyle}>{stickyAside}</div>
                    </Col>
                )}
            </div>
        </Container>
    );
};

const withStickyWrap = css`
    display: flex;
    flex-wrap: wrap;
`;

const stickyAsideStyle = css`
    ${onDesktop(css`
        position: sticky;
        top: 0;
        ${ml(3)};
    `)}
`;
