import * as React from "react";
import {useSelector} from "react-redux";
import {css} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, listUnStyled} from "@pg-design/helpers-css";
import {rpAppLink} from "@pg-mono/rp-routes";

import {Breadcrumbs} from "../../app/components/breadcrumbs/Breadcrumbs";
import {BreadcrumbsItem} from "../../app/components/breadcrumbs/BreadcrumbsItem";
import {IRPStore} from "../../app/rp_reducer";
import {PartnersListHeader} from "../components/PartnersListHeader";
import {PartnersListItem} from "../components/PartnersListItem";

export function PartnersListView() {
    const partnersList = useSelector((store: IRPStore) => store.partnersList.list);

    return (
        <article>
            <WideContent>
                <Breadcrumbs homePageUrl={rpAppLink.base()}>
                    <BreadcrumbsItem name="Partnerzy" position="2" url={rpAppLink.partners.list()} isLinkingToCurrentLocation />
                </Breadcrumbs>
                <PartnersListHeader />

                <ul css={[listUnStyled, list]}>
                    {partnersList.map((partner) => (
                        <PartnersListItem partner={partner} key={partner.name} />
                    ))}
                </ul>
            </WideContent>
        </article>
    );
}

const list = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
`;
import * as React from "react";
import {useSelector} from "react-redux";
import {css} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, listUnStyled} from "@pg-design/helpers-css";
import {rpAppLink} from "@pg-mono/rp-routes";

import {Breadcrumbs} from "../../app/components/breadcrumbs/Breadcrumbs";
import {BreadcrumbsItem} from "../../app/components/breadcrumbs/BreadcrumbsItem";
import {IRPStore} from "../../app/rp_reducer";
import {PartnersListHeader} from "../components/PartnersListHeader";
import {PartnersListItem} from "../components/PartnersListItem";

export function PartnersListView() {
    const partnersList = useSelector((store: IRPStore) => store.partnersList.list);

    return (
        <article>
            <WideContent>
                <Breadcrumbs homePageUrl={rpAppLink.base()}>
                    <BreadcrumbsItem name="Partnerzy" position="2" url={rpAppLink.partners.list()} isLinkingToCurrentLocation />
                </Breadcrumbs>
                <PartnersListHeader />

                <ul css={[listUnStyled, list]}>
                    {partnersList.map((partner) => (
                        <PartnersListItem partner={partner} key={partner.name} />
                    ))}
                </ul>
            </WideContent>
        </article>
    );
}

const list = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
`;
