import type {ElementType, ReactNode} from "react";
import React, {Fragment} from "react";

import {Hidden} from "./Hidden";

interface IProps {
    as?: ElementType;
    desktop: ReactNode;
    mobile: ReactNode;
}

export const ResponsiveRender = (props: IProps) => {
    return (
        <Fragment>
            <Hidden as={props.as} on="mobile">
                {props.desktop}
            </Hidden>
            <Hidden as={props.as} on="desktop">
                {props.mobile}
            </Hidden>
        </Fragment>
    );
};
import type {ElementType, ReactNode} from "react";
import React, {Fragment} from "react";

import {Hidden} from "./Hidden";

interface IProps {
    as?: ElementType;
    desktop: ReactNode;
    mobile: ReactNode;
}

export const ResponsiveRender = (props: IProps) => {
    return (
        <Fragment>
            <Hidden as={props.as} on="mobile">
                {props.desktop}
            </Hidden>
            <Hidden as={props.as} on="desktop">
                {props.mobile}
            </Hidden>
        </Fragment>
    );
};
