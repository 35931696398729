import {Property} from "csstype";

export const position = (value: Property.Position) => `
    position: ${value};
`;

export const positionRelative = `
    ${position("relative")};
`;
import {Property} from "csstype";

export const position = (value: Property.Position) => `
    position: ${value};
`;

export const positionRelative = `
    ${position("relative")};
`;
