import React from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flex, flexDirection, m, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {BrandEmailIcon} from "@pg-design/icons-module";
import {SystemModal} from "@pg-design/modal-module";
import {Text} from "@pg-design/text-module";
import {rpAppPath} from "@pg-mono/rp-routes";

import {unsubscribeUserFromMailing} from "../actions/unsubscribe_user_from_mailing";

interface IProps {
    modalState: boolean;
    onModalClose: () => void;
}

export const UnsubscribeConfirmationModal = (props: IProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const {emailHash, verificationHash} = useParams<{emailHash: string; verificationHash: string}>();

    const onConfirmation = () => {
        if (emailHash && verificationHash) {
            dispatch(unsubscribeUserFromMailing({emailHash, verificationHash}, props.onModalClose));
        }
    };

    const redirectToHomePage = () => {
        window.location.href = rpAppPath.base;
    };

    return (
        <SystemModal variant="spacious" showCloseButton isOpen={props.modalState} onModalClose={redirectToHomePage} shouldCloseOnOverlayClick={false}>
            <SystemModal.Content css={[p(5, 4, 6)]}>
                <div css={[flex("center", "center"), flexDirection("column")]}>
                    <BrandEmailIcon size="9.6" wrapperSize="9.6" wrapperColor={theme.colors.primary} />

                    <Text variant="headline_3" align="center" css={[mt(8), onDesktop(mt(3))]}>
                        Czy potwierdzasz wypisanie z newslettera?
                    </Text>

                    <div css={decisionOptions}>
                        <Button variant="filled_primary" onClick={onConfirmation} css={[commonButtonStyle]}>
                            Tak, potwierdzam
                        </Button>
                        <Button variant="filled_primary" onClick={redirectToHomePage} css={[commonButtonStyle]}>
                            Nie, przejdź do portalu
                        </Button>
                    </div>
                </div>
            </SystemModal.Content>
        </SystemModal>
    );
};

const decisionOptions = css`
    ${mt(4)};

    ${onDesktop(css`
        ${mt(6)};
        ${flex("center")};
    `)};
`;

const commonButtonStyle = css`
    ${w100};
    ${m(2, 0)};

    ${onDesktop(css`
        width: 30rem;
        ${m(1)};
    `)};
`;
import React from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flex, flexDirection, m, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {BrandEmailIcon} from "@pg-design/icons-module";
import {SystemModal} from "@pg-design/modal-module";
import {Text} from "@pg-design/text-module";
import {rpAppPath} from "@pg-mono/rp-routes";

import {unsubscribeUserFromMailing} from "../actions/unsubscribe_user_from_mailing";

interface IProps {
    modalState: boolean;
    onModalClose: () => void;
}

export const UnsubscribeConfirmationModal = (props: IProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const {emailHash, verificationHash} = useParams<{emailHash: string; verificationHash: string}>();

    const onConfirmation = () => {
        if (emailHash && verificationHash) {
            dispatch(unsubscribeUserFromMailing({emailHash, verificationHash}, props.onModalClose));
        }
    };

    const redirectToHomePage = () => {
        window.location.href = rpAppPath.base;
    };

    return (
        <SystemModal variant="spacious" showCloseButton isOpen={props.modalState} onModalClose={redirectToHomePage} shouldCloseOnOverlayClick={false}>
            <SystemModal.Content css={[p(5, 4, 6)]}>
                <div css={[flex("center", "center"), flexDirection("column")]}>
                    <BrandEmailIcon size="9.6" wrapperSize="9.6" wrapperColor={theme.colors.primary} />

                    <Text variant="headline_3" align="center" css={[mt(8), onDesktop(mt(3))]}>
                        Czy potwierdzasz wypisanie z newslettera?
                    </Text>

                    <div css={decisionOptions}>
                        <Button variant="filled_primary" onClick={onConfirmation} css={[commonButtonStyle]}>
                            Tak, potwierdzam
                        </Button>
                        <Button variant="filled_primary" onClick={redirectToHomePage} css={[commonButtonStyle]}>
                            Nie, przejdź do portalu
                        </Button>
                    </div>
                </div>
            </SystemModal.Content>
        </SystemModal>
    );
};

const decisionOptions = css`
    ${mt(4)};

    ${onDesktop(css`
        ${mt(6)};
        ${flex("center")};
    `)};
`;

const commonButtonStyle = css`
    ${w100};
    ${m(2, 0)};

    ${onDesktop(css`
        width: 30rem;
        ${m(1)};
    `)};
`;
