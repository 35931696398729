import React from "react";
import {css} from "@emotion/react";

import {CenteredImage} from "@pg-design/centered-image";
import {calculateRemSize, onDesktop} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";

const mImg = require("../assets/c_360_410.png");
const midImg = require("../assets/c_1366_520.png");
const fhdImg = require("../assets/c_1920_520.png");
const bigImg = require("../assets/c_2560_520.png");

export function BuylandoBanner() {
    return (
        <div css={coverWrap}>
            <CenteredImage
                width={1366}
                height={520}
                breakpoints={[
                    {mediaWidth: "0", height: "410px", width: "auto"},
                    {mediaWidth: "1024px", height: "520px", width: "auto"}
                ]}
                maxSourceWidth={2560}
                sources={[
                    {
                        minWidthPX: 0,
                        src: mImg,
                        width: 360,
                        height: 410
                    },
                    {
                        minWidthPX: 360,
                        src: midImg,
                        width: 1366,
                        height: 520
                    },
                    {
                        minWidthPX: 1366,
                        src: fhdImg,
                        width: 1920,
                        height: 520
                    },
                    {
                        minWidthPX: 1920,
                        src: bigImg,
                        width: 2560,
                        height: 520
                    }
                ]}
                alt="Buylando"
            />
            <div css={bannerWrap}>
                <Text variant="headline_1" fontFamily="secondary" css={bannerTitle}>
                    Działki na sprzedaż - Sprzedaj grunt <br />
                    {concatWithBrandName("z")} i buylando
                </Text>
                <Text variant="headline_6" as="p" css={bannerText}>
                    Chcesz sprzedać grunt inwestycyjny z przeznaczeniem pod zabudowę jednorodzinną lub wielorodzinną? Posiadasz działkę o dużym potencjalne?
                    Dobrze trafiłeś.
                </Text>
            </div>
        </div>
    );
}

/*
    Styles
 */
const coverWrap = css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const bannerWrap = css`
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    row-gap: ${calculateRemSize(5)};

    ${onDesktop(css`
        row-gap: ${calculateRemSize(3)};
    `)}
`;

const bannerTitle = css`
    text-align: center;

    ${onDesktop(css`
        font-size: ${calculateRemSize(8)};
        line-height: 76.8px;
    `)}
`;

const bannerText = css`
    text-align: center;
    max-width: 266px;

    ${onDesktop(css`
        max-width: 677px;
    `)}
`;
import React from "react";
import {css} from "@emotion/react";

import {CenteredImage} from "@pg-design/centered-image";
import {calculateRemSize, onDesktop} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";

const mImg = require("../assets/c_360_410.png");
const midImg = require("../assets/c_1366_520.png");
const fhdImg = require("../assets/c_1920_520.png");
const bigImg = require("../assets/c_2560_520.png");

export function BuylandoBanner() {
    return (
        <div css={coverWrap}>
            <CenteredImage
                width={1366}
                height={520}
                breakpoints={[
                    {mediaWidth: "0", height: "410px", width: "auto"},
                    {mediaWidth: "1024px", height: "520px", width: "auto"}
                ]}
                maxSourceWidth={2560}
                sources={[
                    {
                        minWidthPX: 0,
                        src: mImg,
                        width: 360,
                        height: 410
                    },
                    {
                        minWidthPX: 360,
                        src: midImg,
                        width: 1366,
                        height: 520
                    },
                    {
                        minWidthPX: 1366,
                        src: fhdImg,
                        width: 1920,
                        height: 520
                    },
                    {
                        minWidthPX: 1920,
                        src: bigImg,
                        width: 2560,
                        height: 520
                    }
                ]}
                alt="Buylando"
            />
            <div css={bannerWrap}>
                <Text variant="headline_1" fontFamily="secondary" css={bannerTitle}>
                    Działki na sprzedaż - Sprzedaj grunt <br />
                    {concatWithBrandName("z")} i buylando
                </Text>
                <Text variant="headline_6" as="p" css={bannerText}>
                    Chcesz sprzedać grunt inwestycyjny z przeznaczeniem pod zabudowę jednorodzinną lub wielorodzinną? Posiadasz działkę o dużym potencjalne?
                    Dobrze trafiłeś.
                </Text>
            </div>
        </div>
    );
}

/*
    Styles
 */
const coverWrap = css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const bannerWrap = css`
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    row-gap: ${calculateRemSize(5)};

    ${onDesktop(css`
        row-gap: ${calculateRemSize(3)};
    `)}
`;

const bannerTitle = css`
    text-align: center;

    ${onDesktop(css`
        font-size: ${calculateRemSize(8)};
        line-height: 76.8px;
    `)}
`;

const bannerText = css`
    text-align: center;
    max-width: 266px;

    ${onDesktop(css`
        max-width: 677px;
    `)}
`;
