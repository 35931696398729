import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, m, mb, onDesktop, p} from "@pg-design/helpers-css";
import {BrandClockIcon, BrandHandclapIcon, BrandListIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {BuylandoLogo} from "./BuylandoLogo";

export function BuylandoSteps() {
    return (
        <div css={mainWrap}>
            <WideContent css={content}>
                <Text variant="headline_2" css={title}>
                    Jak sprzedać działkę z {concatWithBrandName("portalem")}
                </Text>
                <ul css={stepsList}>
                    <li css={listItem}>
                        <BrandListIcon wrapperSize="13.5" wrapperColor="transparent" size="13.5" css={mb(4)} />
                        <Text variant="body_copy_0" as="h3" css={[itemText, mb(3)]}>
                            Wypełnij prosty formularz <br />
                            na stronie naszego partnera
                        </Text>
                        <BuylandoLogo css={logo} />
                    </li>
                    <ListArrow />
                    <li css={listItem}>
                        <BrandClockIcon wrapperSize="13.5" wrapperColor="transparent" size="13.5" css={mb(4)} />
                        <Text variant="body_copy_0" as="h3" css={itemText}>
                            Poczekaj na weryfikację
                        </Text>
                    </li>
                    <ListArrow />
                    <li css={listItem}>
                        <BrandHandclapIcon wrapperSize="13.5" wrapperColor="transparent" size="13.5" css={mb(4)} />
                        <Text variant="body_copy_0" as="h3" css={itemText}>
                            Przekażemy Twoją ofertę zainteresowanym deweloperom
                        </Text>
                    </li>
                </ul>
                <Button variant="filled_primary" href="https://buylando.pl/panel/rynek-pierwotny/" target="_blank">
                    Sprzedaj działkę już teraz
                </Button>
            </WideContent>
        </div>
    );
}

const ListArrow = () => (
    <li css={listArrow}>
        <ChevronRightIcon size="5.3" wrapperSize="5.3" wrapperColor="transparent" css={listArrowIcon} />
    </li>
);

/*
    Styles
 */
const listArrow = css`
    display: flex;
    justify-content: center;

    ${mb(5)}

    ${onDesktop(css`
        align-items: center;
        ${mb(0)}
    `)}
`;

const listArrowIcon = css`
    transform: rotate(90deg);

    ${onDesktop(css`
        transform: unset;
        position: relative;
        top: -60px;
    `)}
`;

const mainWrap = (theme: Theme) => css`
    background: ${theme.colors.background[100]};
`;

const content = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${p(4, 0, 5, 0)}

    ${onDesktop(css`
        ${p(12, 0, 10, 0)}
    `)}
`;

const title = css`
    text-align: center;
    ${mb(4)}

    ${onDesktop(css`
        ${mb(8)}
    `)}
`;

const stepsList = css`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    ${m(0, 0, 5, 0)}

    ${onDesktop(css`
        flex-direction: row;
        column-gap: ${calculateRemSize(6)};
    `)}
`;

const listItem = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mb(5)}
`;

const itemText = css`
    text-align: center;
    max-width: 300px;
`;

const logo = css`
    width: 118px;
    height: auto;
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, m, mb, onDesktop, p} from "@pg-design/helpers-css";
import {BrandClockIcon, BrandHandclapIcon, BrandListIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {BuylandoLogo} from "./BuylandoLogo";

export function BuylandoSteps() {
    return (
        <div css={mainWrap}>
            <WideContent css={content}>
                <Text variant="headline_2" css={title}>
                    Jak sprzedać działkę z {concatWithBrandName("portalem")}
                </Text>
                <ul css={stepsList}>
                    <li css={listItem}>
                        <BrandListIcon wrapperSize="13.5" wrapperColor="transparent" size="13.5" css={mb(4)} />
                        <Text variant="body_copy_0" as="h3" css={[itemText, mb(3)]}>
                            Wypełnij prosty formularz <br />
                            na stronie naszego partnera
                        </Text>
                        <BuylandoLogo css={logo} />
                    </li>
                    <ListArrow />
                    <li css={listItem}>
                        <BrandClockIcon wrapperSize="13.5" wrapperColor="transparent" size="13.5" css={mb(4)} />
                        <Text variant="body_copy_0" as="h3" css={itemText}>
                            Poczekaj na weryfikację
                        </Text>
                    </li>
                    <ListArrow />
                    <li css={listItem}>
                        <BrandHandclapIcon wrapperSize="13.5" wrapperColor="transparent" size="13.5" css={mb(4)} />
                        <Text variant="body_copy_0" as="h3" css={itemText}>
                            Przekażemy Twoją ofertę zainteresowanym deweloperom
                        </Text>
                    </li>
                </ul>
                <Button variant="filled_primary" href="https://buylando.pl/panel/rynek-pierwotny/" target="_blank">
                    Sprzedaj działkę już teraz
                </Button>
            </WideContent>
        </div>
    );
}

const ListArrow = () => (
    <li css={listArrow}>
        <ChevronRightIcon size="5.3" wrapperSize="5.3" wrapperColor="transparent" css={listArrowIcon} />
    </li>
);

/*
    Styles
 */
const listArrow = css`
    display: flex;
    justify-content: center;

    ${mb(5)}

    ${onDesktop(css`
        align-items: center;
        ${mb(0)}
    `)}
`;

const listArrowIcon = css`
    transform: rotate(90deg);

    ${onDesktop(css`
        transform: unset;
        position: relative;
        top: -60px;
    `)}
`;

const mainWrap = (theme: Theme) => css`
    background: ${theme.colors.background[100]};
`;

const content = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${p(4, 0, 5, 0)}

    ${onDesktop(css`
        ${p(12, 0, 10, 0)}
    `)}
`;

const title = css`
    text-align: center;
    ${mb(4)}

    ${onDesktop(css`
        ${mb(8)}
    `)}
`;

const stepsList = css`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    ${m(0, 0, 5, 0)}

    ${onDesktop(css`
        flex-direction: row;
        column-gap: ${calculateRemSize(6)};
    `)}
`;

const listItem = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mb(5)}
`;

const itemText = css`
    text-align: center;
    max-width: 300px;
`;

const logo = css`
    width: 118px;
    height: auto;
`;
