/*
 * This file is generated by generate_webpack_entries.ts
 */
import {rpAppPath} from "@pg-mono/rp-routes";

import {rpClient} from "../../../client/rp_client";
import {BuylandoLandingView} from "../../buylando/views/BuylandoLandingView";
import {MyOffersView as MyFavouritesListView} from "../../my_offers/views/MyOffersView";
import {NewsletterUnsubscribeView} from "../../newsletter/views/NewsletterUnsubscribeView";
import {SmsUnsubscribeView} from "../../newsletter/views/SmsUnsubscribeView";
import {OurOfferView} from "../../our_offer/views/OurOfferView";
import {PartnersListView} from "../../partners/views/PartnersListView";
import {PromotionListView} from "../../promotion/views/PromotionListView";
import {SmsCampaign} from "../../sms_campaign/components/sms_campaign/SmsCampaign";
const route = [
    {path: [rpAppPath.openDays.base, rpAppPath.openDays.withSubscriberId], component: SmsCampaign},
    {path: rpAppPath.ourOffer, component: OurOfferView},
    {path: rpAppPath.partners.list, component: PartnersListView},
    {path: rpAppPath.favouriteList, component: MyFavouritesListView},
    {path: rpAppPath.buylando, component: BuylandoLandingView},
    {path: rpAppPath.newsletter.mailing.unsubscribe, component: NewsletterUnsubscribeView},
    {path: rpAppPath.newsletter.sms.unsubscribe, component: SmsUnsubscribeView},
    {path: [rpAppPath.promotions.list, rpAppPath.promotions.friendlyPromotionList], component: PromotionListView}
];

(function () {
    rpClient(route);
})();
/*
 * This file is generated by generate_webpack_entries.ts
 */
import {rpAppPath} from "@pg-mono/rp-routes";

import {rpClient} from "../../../client/rp_client";
import {BuylandoLandingView} from "../../buylando/views/BuylandoLandingView";
import {MyOffersView as MyFavouritesListView} from "../../my_offers/views/MyOffersView";
import {NewsletterUnsubscribeView} from "../../newsletter/views/NewsletterUnsubscribeView";
import {SmsUnsubscribeView} from "../../newsletter/views/SmsUnsubscribeView";
import {OurOfferView} from "../../our_offer/views/OurOfferView";
import {PartnersListView} from "../../partners/views/PartnersListView";
import {PromotionListView} from "../../promotion/views/PromotionListView";
import {SmsCampaign} from "../../sms_campaign/components/sms_campaign/SmsCampaign";
const route = [
    {path: [rpAppPath.openDays.base, rpAppPath.openDays.withSubscriberId], component: SmsCampaign},
    {path: rpAppPath.ourOffer, component: OurOfferView},
    {path: rpAppPath.partners.list, component: PartnersListView},
    {path: rpAppPath.favouriteList, component: MyFavouritesListView},
    {path: rpAppPath.buylando, component: BuylandoLandingView},
    {path: rpAppPath.newsletter.mailing.unsubscribe, component: NewsletterUnsubscribeView},
    {path: rpAppPath.newsletter.sms.unsubscribe, component: SmsUnsubscribeView},
    {path: [rpAppPath.promotions.list, rpAppPath.promotions.friendlyPromotionList], component: PromotionListView}
];

(function () {
    rpClient(route);
})();
