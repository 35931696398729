import React, {useEffect} from "react";
import {useHistory} from "react-router";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAbsoluteCenter, mb, mt, onDesktop, p} from "@pg-design/helpers-css";
import {BrandWeightIcon} from "@pg-design/icons-module";
import {SystemModal} from "@pg-design/modal-module";
import {Text} from "@pg-design/text-module";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IFavouritesConfirmationList} from "../../favourite/components/FavouritesConfirmationList/favourites_confirmation_list.types";
import {FavouritesConfirmationList} from "../../favourite/components/FavouritesConfirmationList/FavouritesConfirmationList";
import {
    handleComparisonModalButtonClickHit,
    handleComparisonModalRenderHit,
    handleGoToAppliedModalButtonClickHit
} from "../../tracking/algolytics_hits/favourites_list_hit";
import {gtmClickGoToComparisonTool, gtmClickGoToMyApplications} from "../../tracking/google_tag_manager/gtm_favourites_list";

interface IProps {
    isConfirmationModalOpen: boolean;
    closeModal: () => void;
    setSelectedNavigationItemValue: (value: number) => void;
    applicationsList: IFavouritesConfirmationList;
}

export const FavouritesConfirmationModal = (props: IProps) => {
    const {isConfirmationModalOpen, closeModal, setSelectedNavigationItemValue, applicationsList} = props;
    const {rejectedOffers, sentProperties, rejectedProperties} = applicationsList;

    const theme = useTheme();
    const history = useHistory();

    const showComparisonToolInfo = sentProperties.length > 1;

    useEffect(() => {
        if (sentProperties.length > 1) {
            handleComparisonModalRenderHit();
        }
    }, []);

    const handleButtonClick = () => {
        if (sentProperties.length > 1) {
            handleComparisonModalButtonClickHit();
            gtmClickGoToComparisonTool();
            const propertyIds = sentProperties.map((property) => property.id);
            history.push(rpAppPath.comparisonTool, {propertyIds});
        } else {
            handleGoToAppliedModalButtonClickHit();
            gtmClickGoToMyApplications();
            closeModal();
            setSelectedNavigationItemValue(1);
        }
    };

    return (
        <SystemModal zIndex={10001} isOpen={isConfirmationModalOpen} onModalClose={closeModal} css={confirmationModal}>
            <div css={confirmationModalWrapper}>
                {rejectedOffers.length === 0 && rejectedProperties.length === 0 ? (
                    showComparisonToolInfo ? null : (
                        <Text align="center" css={mb(5)} variant="headline_3">
                            Twoje zapytania zostały <br /> wysłane
                        </Text>
                    )
                ) : (
                    <FavouritesConfirmationList confirmationList={applicationsList} />
                )}
                {showComparisonToolInfo ? (
                    <>
                        <BrandWeightIcon css={mb(3)} size="12.4" wrapperColor={theme.colors.primary} wrapperSize="12.4" />
                        <Text align="center" css={mb(3)} variant="headline_3">
                            Czy wiesz, że możesz teraz porównywać oferty na które wysłałeś(aś) zapytanie?
                        </Text>
                        <Text align="center">Porównaj dane dotyczące czasu dojazdu oraz 11 innych cech kluczowych w podjęciu decyzji o zakupie.</Text>
                    </>
                ) : (
                    <Text align="center">Możesz ponownie uzupełnić listę i wysłać nowe zapytania</Text>
                )}

                <Button css={[mt(7), onDesktop(mt(5))]} variant="filled_primary" onClick={handleButtonClick}>
                    {showComparisonToolInfo ? "Porównaj oferty" : "Przejdź do moich zapytań"}
                </Button>
            </div>
        </SystemModal>
    );
};

const confirmationModal = (theme: Theme) => css`
    overflow-y: auto;

    @media (min-width: ${theme.breakpoints.md}) {
        max-height: 100%;
    }
`;

const confirmationModalWrapper = (theme: Theme) => css`
    ${flexAbsoluteCenter};
    flex-direction: column;
    ${p(2, 1.5)};

    @media (min-width: ${theme.breakpoints.md}) {
        width: 64.8rem;
        min-height: 63rem;
        ${p(10, 3)};
    }

    > a {
        width: 30rem;
    }
`;
import React, {useEffect} from "react";
import {useHistory} from "react-router";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAbsoluteCenter, mb, mt, onDesktop, p} from "@pg-design/helpers-css";
import {BrandWeightIcon} from "@pg-design/icons-module";
import {SystemModal} from "@pg-design/modal-module";
import {Text} from "@pg-design/text-module";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IFavouritesConfirmationList} from "../../favourite/components/FavouritesConfirmationList/favourites_confirmation_list.types";
import {FavouritesConfirmationList} from "../../favourite/components/FavouritesConfirmationList/FavouritesConfirmationList";
import {
    handleComparisonModalButtonClickHit,
    handleComparisonModalRenderHit,
    handleGoToAppliedModalButtonClickHit
} from "../../tracking/algolytics_hits/favourites_list_hit";
import {gtmClickGoToComparisonTool, gtmClickGoToMyApplications} from "../../tracking/google_tag_manager/gtm_favourites_list";

interface IProps {
    isConfirmationModalOpen: boolean;
    closeModal: () => void;
    setSelectedNavigationItemValue: (value: number) => void;
    applicationsList: IFavouritesConfirmationList;
}

export const FavouritesConfirmationModal = (props: IProps) => {
    const {isConfirmationModalOpen, closeModal, setSelectedNavigationItemValue, applicationsList} = props;
    const {rejectedOffers, sentProperties, rejectedProperties} = applicationsList;

    const theme = useTheme();
    const history = useHistory();

    const showComparisonToolInfo = sentProperties.length > 1;

    useEffect(() => {
        if (sentProperties.length > 1) {
            handleComparisonModalRenderHit();
        }
    }, []);

    const handleButtonClick = () => {
        if (sentProperties.length > 1) {
            handleComparisonModalButtonClickHit();
            gtmClickGoToComparisonTool();
            const propertyIds = sentProperties.map((property) => property.id);
            history.push(rpAppPath.comparisonTool, {propertyIds});
        } else {
            handleGoToAppliedModalButtonClickHit();
            gtmClickGoToMyApplications();
            closeModal();
            setSelectedNavigationItemValue(1);
        }
    };

    return (
        <SystemModal zIndex={10001} isOpen={isConfirmationModalOpen} onModalClose={closeModal} css={confirmationModal}>
            <div css={confirmationModalWrapper}>
                {rejectedOffers.length === 0 && rejectedProperties.length === 0 ? (
                    showComparisonToolInfo ? null : (
                        <Text align="center" css={mb(5)} variant="headline_3">
                            Twoje zapytania zostały <br /> wysłane
                        </Text>
                    )
                ) : (
                    <FavouritesConfirmationList confirmationList={applicationsList} />
                )}
                {showComparisonToolInfo ? (
                    <>
                        <BrandWeightIcon css={mb(3)} size="12.4" wrapperColor={theme.colors.primary} wrapperSize="12.4" />
                        <Text align="center" css={mb(3)} variant="headline_3">
                            Czy wiesz, że możesz teraz porównywać oferty na które wysłałeś(aś) zapytanie?
                        </Text>
                        <Text align="center">Porównaj dane dotyczące czasu dojazdu oraz 11 innych cech kluczowych w podjęciu decyzji o zakupie.</Text>
                    </>
                ) : (
                    <Text align="center">Możesz ponownie uzupełnić listę i wysłać nowe zapytania</Text>
                )}

                <Button css={[mt(7), onDesktop(mt(5))]} variant="filled_primary" onClick={handleButtonClick}>
                    {showComparisonToolInfo ? "Porównaj oferty" : "Przejdź do moich zapytań"}
                </Button>
            </div>
        </SystemModal>
    );
};

const confirmationModal = (theme: Theme) => css`
    overflow-y: auto;

    @media (min-width: ${theme.breakpoints.md}) {
        max-height: 100%;
    }
`;

const confirmationModalWrapper = (theme: Theme) => css`
    ${flexAbsoluteCenter};
    flex-direction: column;
    ${p(2, 1.5)};

    @media (min-width: ${theme.breakpoints.md}) {
        width: 64.8rem;
        min-height: 63rem;
        ${p(10, 3)};
    }

    > a {
        width: 30rem;
    }
`;
