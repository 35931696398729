import React, {RefObject} from "react";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Col, Container, Row} from "@pg-design/grid-module";
import {flexAlignCenter, mb, mh, onDesktop, p, pb, pl} from "@pg-design/helpers-css";
import {ExpandIcon, ProgressIcon} from "@pg-design/icons-module";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

const gainsLG = require("../assets/gains/zysk_lg_669x532.png");
const gainsMD = require("../assets/gains/zysk_md_571x454.png");
const gainsXS = require("../assets/gains/zysk_sm_359x286.png");

interface IProps {
    scrollToForm: () => void;
    scrollToRef: RefObject<HTMLDivElement>;
}

export const SellMoreSection = (props: IProps) => {
    const brandColor = useTheme().colors.primary;

    return (
        <section css={section} ref={props.scrollToRef}>
            <div css={uxContainer}>
                <Container>
                    <Row>
                        <Col sm={4} md={6} css={[onDesktop(pb(8))]}>
                            <Text as="h2" mb="3.2rem" variant="headline_1">
                                Pomagamy sprzedawać więcej
                            </Text>
                            <Text css={[pb(4), onDesktop(pb(6))]} variant="body_copy_0">
                                Sukces sprzedaży mieszkań na rynku pierwotnym to wypadkowa wielu czynników — rozumienia potrzeb klienta, analizy danych,
                                skutecznego marketingu i podnoszenia własnych kompetencji sprzedażowych. Jesteśmy partnerem zapewniającym kompleksowe wsparcie
                                we wszystkich tych obszarach.
                            </Text>
                            <div css={[flexAlignCenter, pb(2)]}>
                                <ProgressIcon size="3.8" wrapperSize="5.6" wrapperColor={brandColor} />
                                <Text css={[pl(3)]} as="span" variant="headline_6">
                                    Rozliczamy się wyłącznie z realnych efektów
                                </Text>
                            </div>
                            <div css={[flexAlignCenter, pb(8)]}>
                                <ExpandIcon size="3.8" wrapperSize="5.6" wrapperColor={brandColor} />
                                <Text css={[pl(3)]} as="span" variant="headline_6">
                                    Oferta dopasowana do skali Twojego biznesu
                                </Text>
                            </div>
                            <Button onClick={props.scrollToForm} css={[mb(8), onDesktop(mb(0))]} size="small" variant="filled_primary">
                                sprzedawaj więcej
                            </Button>
                        </Col>
                        <Col sm={4} md={6}>
                            <Picture
                                sources={[
                                    {minWidthPX: 0, src: gainsXS, height: 286, width: 359},
                                    {minWidthPX: 600, src: gainsMD, height: 454, width: 571},
                                    {minWidthPX: 1366, src: gainsLG, height: 535, width: 669}
                                ]}
                                alt="zyski"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const section = css`
    ${p(4, 1)};

    ${onDesktop(p(5, 4))}
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;
import React, {RefObject} from "react";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Col, Container, Row} from "@pg-design/grid-module";
import {flexAlignCenter, mb, mh, onDesktop, p, pb, pl} from "@pg-design/helpers-css";
import {ExpandIcon, ProgressIcon} from "@pg-design/icons-module";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

const gainsLG = require("../assets/gains/zysk_lg_669x532.png");
const gainsMD = require("../assets/gains/zysk_md_571x454.png");
const gainsXS = require("../assets/gains/zysk_sm_359x286.png");

interface IProps {
    scrollToForm: () => void;
    scrollToRef: RefObject<HTMLDivElement>;
}

export const SellMoreSection = (props: IProps) => {
    const brandColor = useTheme().colors.primary;

    return (
        <section css={section} ref={props.scrollToRef}>
            <div css={uxContainer}>
                <Container>
                    <Row>
                        <Col sm={4} md={6} css={[onDesktop(pb(8))]}>
                            <Text as="h2" mb="3.2rem" variant="headline_1">
                                Pomagamy sprzedawać więcej
                            </Text>
                            <Text css={[pb(4), onDesktop(pb(6))]} variant="body_copy_0">
                                Sukces sprzedaży mieszkań na rynku pierwotnym to wypadkowa wielu czynników — rozumienia potrzeb klienta, analizy danych,
                                skutecznego marketingu i podnoszenia własnych kompetencji sprzedażowych. Jesteśmy partnerem zapewniającym kompleksowe wsparcie
                                we wszystkich tych obszarach.
                            </Text>
                            <div css={[flexAlignCenter, pb(2)]}>
                                <ProgressIcon size="3.8" wrapperSize="5.6" wrapperColor={brandColor} />
                                <Text css={[pl(3)]} as="span" variant="headline_6">
                                    Rozliczamy się wyłącznie z realnych efektów
                                </Text>
                            </div>
                            <div css={[flexAlignCenter, pb(8)]}>
                                <ExpandIcon size="3.8" wrapperSize="5.6" wrapperColor={brandColor} />
                                <Text css={[pl(3)]} as="span" variant="headline_6">
                                    Oferta dopasowana do skali Twojego biznesu
                                </Text>
                            </div>
                            <Button onClick={props.scrollToForm} css={[mb(8), onDesktop(mb(0))]} size="small" variant="filled_primary">
                                sprzedawaj więcej
                            </Button>
                        </Col>
                        <Col sm={4} md={6}>
                            <Picture
                                sources={[
                                    {minWidthPX: 0, src: gainsXS, height: 286, width: 359},
                                    {minWidthPX: 600, src: gainsMD, height: 454, width: 571},
                                    {minWidthPX: 1366, src: gainsLG, height: 535, width: 669}
                                ]}
                                alt="zyski"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const section = css`
    ${p(4, 1)};

    ${onDesktop(p(5, 4))}
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;
