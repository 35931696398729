interface ISearchPayloadAccumulator {
    offer_id: number[];
    offer_name: string[];
    vendor_id: number[];
    vendor_name: string[];
}

interface IOfferData {
    id: number;
    name: string;
    vendor: {
        id: number;
        name: string;
    };
}

export const normalizeOfferListIntoSearchPayload = (offers: IOfferData[]) =>
    offers.reduce<ISearchPayloadAccumulator>(
        ({offer_id, offer_name, vendor_id, vendor_name}, offer) => {
            return {
                offer_id: [...offer_id, offer.id],
                offer_name: [...offer_name, offer.name],
                vendor_id: [...vendor_id, offer.vendor.id],
                vendor_name: [...vendor_name, offer.vendor.name]
            };
        },
        {
            offer_id: [],
            offer_name: [],
            vendor_id: [],
            vendor_name: []
        }
    );
interface ISearchPayloadAccumulator {
    offer_id: number[];
    offer_name: string[];
    vendor_id: number[];
    vendor_name: string[];
}

interface IOfferData {
    id: number;
    name: string;
    vendor: {
        id: number;
        name: string;
    };
}

export const normalizeOfferListIntoSearchPayload = (offers: IOfferData[]) =>
    offers.reduce<ISearchPayloadAccumulator>(
        ({offer_id, offer_name, vendor_id, vendor_name}, offer) => {
            return {
                offer_id: [...offer_id, offer.id],
                offer_name: [...offer_name, offer.name],
                vendor_id: [...vendor_id, offer.vendor.id],
                vendor_name: [...vendor_name, offer.vendor.name]
            };
        },
        {
            offer_id: [],
            offer_name: [],
            vendor_id: [],
            vendor_name: []
        }
    );
