import React from "react";
import {useSelector} from "react-redux";

import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {rpAppLink} from "@pg-mono/rp-routes";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {IRPStore} from "../../../app/rp_reducer";
import {getOfferTypeNameCapital} from "../../../offer/helpers/OfferType";
import {OfferType} from "../../../offer/types/OfferType";
import {useMyOffersContext} from "../../contexts/MyOffersContext";
import {FavouriteListWrapper} from "../FavouriteListWrapper";
import {FavouritesListItem} from "../FavouritesList/FavouritesListItem";

const propertyRoomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

const getPropertyHeaderData = (offerType: OfferType, roomsCount: number, area: number | [number, number]) => {
    const offerTypeName = getOfferTypeNameCapital(offerType);
    const roomsInfo = `${roomsCount} ${propertyRoomsPluralize(roomsCount)}`;
    const formattedArea = areaFormat(area);

    return `${offerTypeName}, ${roomsInfo}, ${formattedArea}`;
};

interface IProps {
    className?: string;
}

export const FavouritePropertiesSection = (props: IProps) => {
    const favouriteProperties = useSelector((store: IRPStore) => store.myOffers.favourites.properties.list);
    const {removeButtonsDisabled} = useMyOffersContext();

    return (
        <div css={props.className}>
            <Text as="h3" variant="mini_header" css={mb(2)}>
                Nieruchomości
            </Text>

            <div>
                <FavouriteListWrapper>
                    {favouriteProperties.map((property) => (
                        <FavouritesListItem
                            key={property.id}
                            id={property.id}
                            itemUrl={rpAppLink.property.detail.base({
                                vendorSlug: property.offer.vendor.slug,
                                offerSlug: property.offer.slug,
                                offerId: property.offer.id,
                                propertyId: property.id
                            })}
                            disableRemoveButton={removeButtonsDisabled}
                            propertyOfferId={property.offer.id}
                            type="property"
                            imageSrc={property.offer.main_image?.m_img_375x211}
                            header={getPropertyHeaderData(property.offer.type, property.rooms, property.area)}
                            address={`${property.offer.name}, ${property.offer.address}`}
                            rodoData={{
                                vendorName: property.offer.vendor.name,
                                personalDataProcessorName: property.offer.configuration.personal_data_processor_name ?? "",
                                personalDataProcessorUrl: property.offer.configuration.personal_data_processor_url ?? "",
                                privacyPolicyUrl: property.offer.configuration.privacy_policy_url ?? ""
                            }}
                        />
                    ))}
                </FavouriteListWrapper>
            </div>
        </div>
    );
};
import React from "react";
import {useSelector} from "react-redux";

import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {rpAppLink} from "@pg-mono/rp-routes";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {IRPStore} from "../../../app/rp_reducer";
import {getOfferTypeNameCapital} from "../../../offer/helpers/OfferType";
import {OfferType} from "../../../offer/types/OfferType";
import {useMyOffersContext} from "../../contexts/MyOffersContext";
import {FavouriteListWrapper} from "../FavouriteListWrapper";
import {FavouritesListItem} from "../FavouritesList/FavouritesListItem";

const propertyRoomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

const getPropertyHeaderData = (offerType: OfferType, roomsCount: number, area: number | [number, number]) => {
    const offerTypeName = getOfferTypeNameCapital(offerType);
    const roomsInfo = `${roomsCount} ${propertyRoomsPluralize(roomsCount)}`;
    const formattedArea = areaFormat(area);

    return `${offerTypeName}, ${roomsInfo}, ${formattedArea}`;
};

interface IProps {
    className?: string;
}

export const FavouritePropertiesSection = (props: IProps) => {
    const favouriteProperties = useSelector((store: IRPStore) => store.myOffers.favourites.properties.list);
    const {removeButtonsDisabled} = useMyOffersContext();

    return (
        <div css={props.className}>
            <Text as="h3" variant="mini_header" css={mb(2)}>
                Nieruchomości
            </Text>

            <div>
                <FavouriteListWrapper>
                    {favouriteProperties.map((property) => (
                        <FavouritesListItem
                            key={property.id}
                            id={property.id}
                            itemUrl={rpAppLink.property.detail.base({
                                vendorSlug: property.offer.vendor.slug,
                                offerSlug: property.offer.slug,
                                offerId: property.offer.id,
                                propertyId: property.id
                            })}
                            disableRemoveButton={removeButtonsDisabled}
                            propertyOfferId={property.offer.id}
                            type="property"
                            imageSrc={property.offer.main_image?.m_img_375x211}
                            header={getPropertyHeaderData(property.offer.type, property.rooms, property.area)}
                            address={`${property.offer.name}, ${property.offer.address}`}
                            rodoData={{
                                vendorName: property.offer.vendor.name,
                                personalDataProcessorName: property.offer.configuration.personal_data_processor_name ?? "",
                                personalDataProcessorUrl: property.offer.configuration.personal_data_processor_url ?? "",
                                privacyPolicyUrl: property.offer.configuration.privacy_policy_url ?? ""
                            }}
                        />
                    ))}
                </FavouriteListWrapper>
            </div>
        </div>
    );
};
