import React from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";

import {parseSearch} from "@pg-mono/request";
import {safelyParsePage} from "@pg-mono/string-utils";

import {IRPStore} from "../../../app/rp_reducer";
import {SeoMainHeadline} from "../../../atoms/SeoMainHeadline";
import {getPromotionTypePlural, PromotionType} from "../../helpers/PromotionType";

interface IPromotionListHeaderResults {
    type: PromotionType;
}

export const PromotionListHeaderResults = (props: IPromotionListHeaderResults) => {
    const location = useLocation();

    const promotionsCount = useSelector((state: IRPStore) => state.promotionList.count);
    const region = useSelector((state: IRPStore) => state.promotionList.region);

    const regionLabel = region?.name_declension_where || "z całej Polski";
    const promotionLabel = getPromotionTypePlural(props.type, promotionsCount);

    const query = parseSearch(location.search);
    const page = safelyParsePage(query.page);

    return (
        <SeoMainHeadline
            pageString={page > 1 ? `- strona ${page}` : undefined}
            highlight
            variant="headline_2"
            as="h1"
            prefixContent="Znaleźliśmy"
            suffixContent={page > 1 ? `- strona ${page}` : undefined}
        >
            {promotionsCount} {promotionLabel} {regionLabel}
        </SeoMainHeadline>
    );
};
import React from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";

import {parseSearch} from "@pg-mono/request";
import {safelyParsePage} from "@pg-mono/string-utils";

import {IRPStore} from "../../../app/rp_reducer";
import {SeoMainHeadline} from "../../../atoms/SeoMainHeadline";
import {getPromotionTypePlural, PromotionType} from "../../helpers/PromotionType";

interface IPromotionListHeaderResults {
    type: PromotionType;
}

export const PromotionListHeaderResults = (props: IPromotionListHeaderResults) => {
    const location = useLocation();

    const promotionsCount = useSelector((state: IRPStore) => state.promotionList.count);
    const region = useSelector((state: IRPStore) => state.promotionList.region);

    const regionLabel = region?.name_declension_where || "z całej Polski";
    const promotionLabel = getPromotionTypePlural(props.type, promotionsCount);

    const query = parseSearch(location.search);
    const page = safelyParsePage(query.page);

    return (
        <SeoMainHeadline
            pageString={page > 1 ? `- strona ${page}` : undefined}
            highlight
            variant="headline_2"
            as="h1"
            prefixContent="Znaleźliśmy"
            suffixContent={page > 1 ? `- strona ${page}` : undefined}
        >
            {promotionsCount} {promotionLabel} {regionLabel}
        </SeoMainHeadline>
    );
};
