import React from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {flex, flexDirection, mb, p, textAlign} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {isEmpty} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {PromotionBox} from "../../components/PromotionBox";

export const PromotionListListSection = () => {
    const promotions = useSelector((state: IRPStore) => state.promotionList.promotions);

    return (
        <div css={[flex("center", "center"), flexDirection("column")]}>
            {!isEmpty(promotions) ? (
                promotions.map((promotion) => {
                    return (
                        <div key={promotion.id} css={[promotionBoxWrapperStyle]}>
                            <PromotionBox promotion={promotion} />
                        </div>
                    );
                })
            ) : (
                <Text as="div" css={[p(2), textAlign("center")]}>
                    <div css={[mb(1)]}>Nie znaleziono promocji o określonych kryteriach.</div>
                    <div>Rozszerz lub zmień parametry szukania i spróbuj ponownie.</div>
                </Text>
            )}
        </div>
    );
};

const promotionBoxWrapperStyle = (theme: Theme) => css`
    width: 100%;
    max-width: 360px;
    align-self: center;

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 100%;
    }
`;
import React from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {flex, flexDirection, mb, p, textAlign} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {isEmpty} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {PromotionBox} from "../../components/PromotionBox";

export const PromotionListListSection = () => {
    const promotions = useSelector((state: IRPStore) => state.promotionList.promotions);

    return (
        <div css={[flex("center", "center"), flexDirection("column")]}>
            {!isEmpty(promotions) ? (
                promotions.map((promotion) => {
                    return (
                        <div key={promotion.id} css={[promotionBoxWrapperStyle]}>
                            <PromotionBox promotion={promotion} />
                        </div>
                    );
                })
            ) : (
                <Text as="div" css={[p(2), textAlign("center")]}>
                    <div css={[mb(1)]}>Nie znaleziono promocji o określonych kryteriach.</div>
                    <div>Rozszerz lub zmień parametry szukania i spróbuj ponownie.</div>
                </Text>
            )}
        </div>
    );
};

const promotionBoxWrapperStyle = (theme: Theme) => css`
    width: 100%;
    max-width: 360px;
    align-self: center;

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 100%;
    }
`;
