import React from "react";
import {css, Theme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, flex, textAlign} from "@pg-design/helpers-css";
import {ChevronRightIcon} from "@pg-design/icons-module";
import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";
import {useIsMobile} from "@pg-mono/hooks";
import {rpAppLink} from "@pg-mono/rp-routes";

export function BuylandoKnowledgeCTA() {
    const isMobile = useIsMobile();

    const iconSize = isMobile ? "5.4" : "8";

    return (
        <div css={mainWrap}>
            <WideContent css={content}>
                <div css={flex("center", "flex-end")}>
                    <ChevronRightIcon wrapperSize={iconSize} wrapperColor="transparent" size={iconSize} css={topIcon} />
                </div>
                <div css={textWrap}>
                    <Text variant="headline_1" fontFamily="secondary" as="h2" css={textAlign("center")}>
                        Zastanawiasz się, jakie działki są atrakcyjne dla deweloperów?
                    </Text>
                    <Text variant="body_copy_0" css={longText}>
                        Ciekawi Cię, ile procent transakcji zakupu gruntów przez deweloperów dotyczy lokali położonych w centrach miast, ile na peryferiach?
                        <br />
                        <br />
                        Analizy ekspertów branży i serię przydatnych artykułów, związanych z szeroko rozumianą tematyką gruntów, znajdziesz na naszym portalu w
                        zakładce{" "}
                        <Link href={rpAppLink.article.list()} size="inherit" underline target="_blank">
                            strefa wiedzy
                        </Link>
                        .
                    </Text>
                </div>
                <div css={flex("center", "flex-start")}>
                    <ChevronRightIcon wrapperSize={iconSize} wrapperColor="transparent" size={iconSize} css={bottomIcon} />
                </div>
            </WideContent>
        </div>
    );
}

/*
    Styles
 */
const mainWrap = (theme: Theme) => css`
    background: ${theme.colors.info};
`;

const content = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(1)};
`;

const textWrap = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${calculateRemSize(4)};
`;

const longText = css`
    max-width: 780px;
    text-align: center;
`;

const topIcon = css`
    transform: translateX(10px) rotate(-45deg);
`;

const bottomIcon = css`
    transform: translateX(-10px) rotate(135deg);
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, flex, textAlign} from "@pg-design/helpers-css";
import {ChevronRightIcon} from "@pg-design/icons-module";
import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";
import {useIsMobile} from "@pg-mono/hooks";
import {rpAppLink} from "@pg-mono/rp-routes";

export function BuylandoKnowledgeCTA() {
    const isMobile = useIsMobile();

    const iconSize = isMobile ? "5.4" : "8";

    return (
        <div css={mainWrap}>
            <WideContent css={content}>
                <div css={flex("center", "flex-end")}>
                    <ChevronRightIcon wrapperSize={iconSize} wrapperColor="transparent" size={iconSize} css={topIcon} />
                </div>
                <div css={textWrap}>
                    <Text variant="headline_1" fontFamily="secondary" as="h2" css={textAlign("center")}>
                        Zastanawiasz się, jakie działki są atrakcyjne dla deweloperów?
                    </Text>
                    <Text variant="body_copy_0" css={longText}>
                        Ciekawi Cię, ile procent transakcji zakupu gruntów przez deweloperów dotyczy lokali położonych w centrach miast, ile na peryferiach?
                        <br />
                        <br />
                        Analizy ekspertów branży i serię przydatnych artykułów, związanych z szeroko rozumianą tematyką gruntów, znajdziesz na naszym portalu w
                        zakładce{" "}
                        <Link href={rpAppLink.article.list()} size="inherit" underline target="_blank">
                            strefa wiedzy
                        </Link>
                        .
                    </Text>
                </div>
                <div css={flex("center", "flex-start")}>
                    <ChevronRightIcon wrapperSize={iconSize} wrapperColor="transparent" size={iconSize} css={bottomIcon} />
                </div>
            </WideContent>
        </div>
    );
}

/*
    Styles
 */
const mainWrap = (theme: Theme) => css`
    background: ${theme.colors.info};
`;

const content = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(1)};
`;

const textWrap = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${calculateRemSize(4)};
`;

const longText = css`
    max-width: 780px;
    text-align: center;
`;

const topIcon = css`
    transform: translateX(10px) rotate(-45deg);
`;

const bottomIcon = css`
    transform: translateX(-10px) rotate(135deg);
`;
