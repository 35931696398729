import {useSelector} from "react-redux";

import {IRPStore} from "../../../app/rp_reducer";
import {PROMOTION_LIST_PAGE_SIZE} from "../../actions/fetch_promotion_list";

export const usePromotionListMaxPage = (): number => {
    const count = useSelector((state: IRPStore) => state.promotionList.count);

    return count ? Math.ceil(count / PROMOTION_LIST_PAGE_SIZE) : 1;
};
import {useSelector} from "react-redux";

import {IRPStore} from "../../../app/rp_reducer";
import {PROMOTION_LIST_PAGE_SIZE} from "../../actions/fetch_promotion_list";

export const usePromotionListMaxPage = (): number => {
    const count = useSelector((state: IRPStore) => state.promotionList.count);

    return count ? Math.ceil(count / PROMOTION_LIST_PAGE_SIZE) : 1;
};
