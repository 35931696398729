import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {WideContent} from "@pg-design/grid-module";
import {borderRadius, calculateRemSize, flex, mb, onDesktop, p} from "@pg-design/helpers-css";
import {BrandQuoteIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
const expertImg = require("../assets/m_wielgo.png");

export function BuylandoExpert() {
    const theme = useTheme();

    return (
        <WideContent css={content}>
            <div css={expertBox}>
                <BrandQuoteIcon wrapperColor={theme.colors.primary} wrapperSize="6.4" size="6.4" css={quoteIconBox} />
                <div css={flex("center", "center")}>
                    <div css={expertAvatar}>
                        <BrandQuoteIcon wrapperColor={theme.colors.primary} wrapperSize="6.4" size="6.4" css={quoteIconExpert} />
                        <Image src={expertImg} alt="Marek Wielgo" width="200px" height="200px" />
                    </div>
                </div>
                <div>
                    <Text variant="headline_3" as="p" css={mb(0.5)}>
                        Marek Wielgo
                    </Text>
                    <Text variant="body_copy_2" css={mb(4)}>
                        {concatWithBrandName("Ekspert portalu")}
                    </Text>
                    <Text variant="body_copy_1" css={expertText}>
                        {concatWithBrandName("Portal")}, z którym współpracuje ponad tysiąc firm deweloperskich jest idealnym partnerem transakcji sprzedaży
                        gruntu. Sprzedający mogą mieć pewność, że ich oferty nie pozostaną niezauważone. Deweloperzy uzyskają zaś informację o sprawdzonych
                        działkach w interesujących ich lokalizacjach.
                    </Text>
                </div>
            </div>
        </WideContent>
    );
}

/*
    Styles
 */
const content = css`
    display: flex;
    justify-content: center;
    ${p(8, 0, 8, 0)}

    ${onDesktop(css`
        ${p(4, 0, 8, 0)}
    `)}
`;

const expertBox = (theme: Theme) => css`
    background: ${theme.colors.background[100]};
    max-width: 936px;
    position: relative;
    ${p(5, 2, 5, 2)}
    ${elevation(4)}
    ${borderRadius(2)}
    
    ${onDesktop(css`
        display: flex;
        column-gap: ${calculateRemSize(7)};
        ${p(5, 6, 5, 6)}
    `)}
`;

const expertAvatar = css`
    position: relative;
    ${mb(3)}

    ${onDesktop(css`
        ${mb(0)}
    `)}
`;

const quoteIconExpert = css`
    position: absolute;
    z-index: 2;
    top: -11px;
    right: -25px;

    ${onDesktop(css`
        display: none;
    `)}
`;

const quoteIconBox = css`
    display: none;

    ${onDesktop(css`
        display: flex;
        position: absolute;
        z-index: 2;
        top: 22px;
        right: 29px;
    `)}
`;
const expertText = (theme: Theme) => css`
    font-style: italic;
    color: ${theme.colors.gray[800]};
`;
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {WideContent} from "@pg-design/grid-module";
import {borderRadius, calculateRemSize, flex, mb, onDesktop, p} from "@pg-design/helpers-css";
import {BrandQuoteIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
const expertImg = require("../assets/m_wielgo.png");

export function BuylandoExpert() {
    const theme = useTheme();

    return (
        <WideContent css={content}>
            <div css={expertBox}>
                <BrandQuoteIcon wrapperColor={theme.colors.primary} wrapperSize="6.4" size="6.4" css={quoteIconBox} />
                <div css={flex("center", "center")}>
                    <div css={expertAvatar}>
                        <BrandQuoteIcon wrapperColor={theme.colors.primary} wrapperSize="6.4" size="6.4" css={quoteIconExpert} />
                        <Image src={expertImg} alt="Marek Wielgo" width="200px" height="200px" />
                    </div>
                </div>
                <div>
                    <Text variant="headline_3" as="p" css={mb(0.5)}>
                        Marek Wielgo
                    </Text>
                    <Text variant="body_copy_2" css={mb(4)}>
                        {concatWithBrandName("Ekspert portalu")}
                    </Text>
                    <Text variant="body_copy_1" css={expertText}>
                        {concatWithBrandName("Portal")}, z którym współpracuje ponad tysiąc firm deweloperskich jest idealnym partnerem transakcji sprzedaży
                        gruntu. Sprzedający mogą mieć pewność, że ich oferty nie pozostaną niezauważone. Deweloperzy uzyskają zaś informację o sprawdzonych
                        działkach w interesujących ich lokalizacjach.
                    </Text>
                </div>
            </div>
        </WideContent>
    );
}

/*
    Styles
 */
const content = css`
    display: flex;
    justify-content: center;
    ${p(8, 0, 8, 0)}

    ${onDesktop(css`
        ${p(4, 0, 8, 0)}
    `)}
`;

const expertBox = (theme: Theme) => css`
    background: ${theme.colors.background[100]};
    max-width: 936px;
    position: relative;
    ${p(5, 2, 5, 2)}
    ${elevation(4)}
    ${borderRadius(2)}
    
    ${onDesktop(css`
        display: flex;
        column-gap: ${calculateRemSize(7)};
        ${p(5, 6, 5, 6)}
    `)}
`;

const expertAvatar = css`
    position: relative;
    ${mb(3)}

    ${onDesktop(css`
        ${mb(0)}
    `)}
`;

const quoteIconExpert = css`
    position: absolute;
    z-index: 2;
    top: -11px;
    right: -25px;

    ${onDesktop(css`
        display: none;
    `)}
`;

const quoteIconBox = css`
    display: none;

    ${onDesktop(css`
        display: flex;
        position: absolute;
        z-index: 2;
        top: 22px;
        right: 29px;
    `)}
`;
const expertText = (theme: Theme) => css`
    font-style: italic;
    color: ${theme.colors.gray[800]};
`;
