import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {Button} from "@pg-design/button-module";
import {elevation} from "@pg-design/elevation";
import {borderRadius, calculateRemSize, display, flexAbsoluteCenter, mb, mr, onDesktop, p, underline, w100} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {CheckboxControlled, InputField, PhoneFieldControlled, TextareaField} from "@pg-design/rhf-components-module";
import {getThemeBreakpoint} from "@pg-design/styles-strings";
import {Highlight, Text} from "@pg-design/text-module";
import {handleRtkqApiError, unknownError} from "@pg-mono/react-hook-forms-utils";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {TermsAcceptation} from "../../application/components/TermsAcceptation";
import {getPhoneString} from "../../form/utils/get_phone_string";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {addWarningNotification} from "../../notifications/utils/add_warning_notification";
import {useAppDispatch} from "../../store/hooks";
import {ourOfferSuccessHit} from "../../tracking/algolytics_hits/our_offer_hit";
import {gtmGaEventOurOfferFormSuccess} from "../../tracking/google_tag_manager/gtm_our_offer_form_success";
import {postOurOffer, usePostOurOfferMutation} from "../api/post_our_offer_api";
import {IOurOfferFormValues} from "../types/IOurOfferFormValues";
import {IPostOurOfferPayload} from "../types/IPostOurOfferPayload";
import {B2BUsercomSources, sendB2BUsercomEvent} from "../utils/send_b2b_usercom_event";

interface IProps {
    smallVersion?: boolean;
}

export const OurOfferContactForm = (props: IProps) => {
    const {smallVersion} = props;
    const theme = useTheme();
    const brandColor = theme.colors.primary;

    const codes = useSelector((store: IRPStore) => store.variables.data?.country_codes);
    const dispatch = useAppDispatch();
    const [_, postOurOfferMutation] = usePostOurOfferMutation();

    const form = useForm<IOurOfferFormValues>({
        defaultValues: {
            name: "",
            email: "",
            company: "",
            phone: {
                code: 48,
                number: ""
            },
            text: "Potrzebuję wsparcia przy promocji moich inwestycji. Proszę o kontakt."
        },
        resolver: yupResolver(
            Yup.object().shape({
                name: Yup.string().required(),
                email: Yup.string().email().required(),
                phone: Yup.object()
                    .shape({
                        code: Yup.number().required(),
                        number: Yup.string().required()
                    })
                    .required(),
                company: Yup.string().required(),
                text: Yup.string().required()
            })
        )
    });

    const handleSubmit = async (values: IOurOfferFormValues) => {
        const postPayload: IPostOurOfferPayload = {
            ...values,
            phone: getPhoneString(values.phone)
        };

        const ourOffer = await dispatch(postOurOffer.initiate(postPayload));

        if (ourOffer.data && !ourOffer.error) {
            // hit success - algolytics
            ourOfferSuccessHit(ourOffer.data);

            // hit success - gtm
            gtmGaEventOurOfferFormSuccess();
            sendB2BUsercomEvent({
                ...ourOffer.data,
                source: smallVersion ? B2BUsercomSources.OUR_OFFER_FORM_TOP : B2BUsercomSources.OUR_OFFER_FORM,
                mktOptIn: values.mktOptIn,
                mktOptInPhone: values.mktOptInPhone,
                mktOptInSms: values.mktOptInSms
            });

            dispatch(addSuccessNotification("Twoje zgłoszenie zostało wysłane."));
            form.reset();
        }

        if (ourOffer.error) {
            handleRtkqApiError({
                error: ourOffer.error,
                setError: form.setError,
                handle400ValidationError: true,
                onUnknownError: () => {
                    dispatch(addWarningNotification(unknownError));
                }
            });
        }
    };

    return (
        <Card smallVersion={smallVersion}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <FormWrap smallVersion={smallVersion}>
                        <FormHeadline variant={smallVersion ? "headline_2" : "headline_1"} as="h2" align="center">
                            {smallVersion ? (
                                <>
                                    Współpracuj z nami już od <Highlight> 299 zł</Highlight> miesięcznie
                                </>
                            ) : (
                                <>
                                    Porozmawiajmy
                                    <br /> o Twojej inwestycji
                                </>
                            )}
                        </FormHeadline>

                        <Text as="p" mb="3.2rem" variant="body_copy_1" align="center">
                            Skontaktuj się z nami. Nasz konsultant przygotuje dla Ciebie ofertę dopasowaną do skali Twojej
                            <br css={props.smallVersion && display("none")} /> firmy i jej celów marketingowych.
                        </Text>

                        {!smallVersion && (
                            <div css={[flexAbsoluteCenter, mb(6)]}>
                                <PhoneIcon css={mr(2)} wrapperColor={brandColor} wrapperSize="4.8" size="3" />
                                <Text variant="headline_3" as="span">
                                    +48 22 825 60 71
                                </Text>
                            </div>
                        )}
                    </FormWrap>

                    <div css={fieldsWrap}>
                        <InputField name="name" placeholder="Imię i nazwisko" disabled={postOurOfferMutation.isLoading} />
                        <InputField name="email" type="email" placeholder="Email" disabled={postOurOfferMutation.isLoading} />
                        <PhoneFieldControlled name="phone" codes={codes ?? []} placeholder="Numer telefonu" className="bt" />
                        <InputField name="company" placeholder="Firma" disabled={postOurOfferMutation.isLoading} />
                        <TextareaField name="text" placeholder="Wiadomość" disabled={postOurOfferMutation.isLoading} />
                        <div css={checkboxWrap}>
                            <CheckboxControlled
                                name="mktOptIn"
                                id={smallVersion ? "mktOptInSmall" : "mktOptInBig"}
                                labelContent={
                                    <Text variant="info_txt_3">
                                        Chcę otrzymywać od Property Group sp. z o.o. za pośrednictwem e-maila informacje marketingowe, w tym o jej usługach,
                                        promocjach, akcjach edukacyjnych, wydarzeniach z rynku nieruchomości. Zgodę mogę zawsze odwołać.
                                    </Text>
                                }
                                disabled={postOurOfferMutation.isLoading}
                            />
                            <CheckboxControlled
                                name="mktOptInPhone"
                                id={smallVersion ? "mktOptInPhoneSmall" : "mktOptInPhoneBig"}
                                labelContent={
                                    <Text variant="info_txt_3">
                                        Chcę otrzymywać od Property Group sp. z o.o. informacje marketingowe w trakcie połączeń głosowych wykonywanych na mój
                                        numer telefonu. Zgodę mogę w każdej chwili wycofać.
                                    </Text>
                                }
                                disabled={postOurOfferMutation.isLoading}
                            />
                            <CheckboxControlled
                                name="mktOptInSms"
                                id={smallVersion ? "mktOptInSmsSmall" : "mktOptInSmsBig"}
                                labelContent={
                                    <Text variant="info_txt_3">
                                        Chcę otrzymywać od Property Group sp. z o.o. informacje marketingowe za pomocą wiadomości SMS. Zgodę mogę zawsze
                                        odwołać.
                                    </Text>
                                }
                                disabled={postOurOfferMutation.isLoading}
                            />
                        </div>
                    </div>

                    <Text css={rodoText} variant="info_txt_1">
                        Administratorem danych osobowych jest Property Group sp. z o.o.
                        <br />
                        <a css={link} href={rpAppLink.rodoTerms.base()} target="_blank">
                            Kliknij, aby dowiedzieć się m.in., w jakim celu przetwarzamy Twoje dane oraz jakie przysługują Ci prawa.
                        </a>
                    </Text>

                    <TermsAcceptation css={mb(3)} isPartnerTerms />

                    <Button variant="filled_primary" css={w100} disabled={postOurOfferMutation.isLoading}>
                        Wyślij
                    </Button>
                </form>
            </FormProvider>
        </Card>
    );
};

const FormHeadline = styled(Text)<Pick<IProps, "smallVersion">>`
    ${({smallVersion}) => css`
        margin-bottom: ${smallVersion ? calculateRemSize(3) : calculateRemSize(4)};
    `};
`;

const link = css`
    color: inherit;
    ${underline};
`;

const Card = styled.div<Pick<IProps, "smallVersion">>`
    background-color: #fff;
    ${borderRadius(2)};

    ${({smallVersion}) =>
        smallVersion
            ? css`
                  max-width: 552px;
                  ${elevation(4)};
                  ${p(2)};

                  @media (min-width: ${getThemeBreakpoint().md}) {
                      ${p(3)};
                  }
              `
            : css`
                  display: inline-block;
                  ${elevation(2)};
                  ${p(4, 2, 5, 2)};

                  @media (min-width: ${getThemeBreakpoint().md}) {
                      ${p(6, 12, 7, 12)};
                  }
              `};
`;

const FormWrap = styled.div<Pick<IProps, "smallVersion">>`
    ${({smallVersion}) => css`
        display: ${smallVersion ? "block" : "none"};

        ${onDesktop(css`
            display: block;
        `)}
    `}
`;

const fieldsWrap = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(2)};
    margin-bottom: ${calculateRemSize(2)};
`;

const checkboxWrap = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(2)};
    align-items: flex-start;
`;

const rodoText = (theme: Theme) => css`
    color: ${theme.colors.gray[700]};
    margin-bottom: ${calculateRemSize(2)};
    text-align: center;
`;
import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {Button} from "@pg-design/button-module";
import {elevation} from "@pg-design/elevation";
import {borderRadius, calculateRemSize, display, flexAbsoluteCenter, mb, mr, onDesktop, p, underline, w100} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {CheckboxControlled, InputField, PhoneFieldControlled, TextareaField} from "@pg-design/rhf-components-module";
import {getThemeBreakpoint} from "@pg-design/styles-strings";
import {Highlight, Text} from "@pg-design/text-module";
import {handleRtkqApiError, unknownError} from "@pg-mono/react-hook-forms-utils";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {TermsAcceptation} from "../../application/components/TermsAcceptation";
import {getPhoneString} from "../../form/utils/get_phone_string";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {addWarningNotification} from "../../notifications/utils/add_warning_notification";
import {useAppDispatch} from "../../store/hooks";
import {ourOfferSuccessHit} from "../../tracking/algolytics_hits/our_offer_hit";
import {gtmGaEventOurOfferFormSuccess} from "../../tracking/google_tag_manager/gtm_our_offer_form_success";
import {postOurOffer, usePostOurOfferMutation} from "../api/post_our_offer_api";
import {IOurOfferFormValues} from "../types/IOurOfferFormValues";
import {IPostOurOfferPayload} from "../types/IPostOurOfferPayload";
import {B2BUsercomSources, sendB2BUsercomEvent} from "../utils/send_b2b_usercom_event";

interface IProps {
    smallVersion?: boolean;
}

export const OurOfferContactForm = (props: IProps) => {
    const {smallVersion} = props;
    const theme = useTheme();
    const brandColor = theme.colors.primary;

    const codes = useSelector((store: IRPStore) => store.variables.data?.country_codes);
    const dispatch = useAppDispatch();
    const [_, postOurOfferMutation] = usePostOurOfferMutation();

    const form = useForm<IOurOfferFormValues>({
        defaultValues: {
            name: "",
            email: "",
            company: "",
            phone: {
                code: 48,
                number: ""
            },
            text: "Potrzebuję wsparcia przy promocji moich inwestycji. Proszę o kontakt."
        },
        resolver: yupResolver(
            Yup.object().shape({
                name: Yup.string().required(),
                email: Yup.string().email().required(),
                phone: Yup.object()
                    .shape({
                        code: Yup.number().required(),
                        number: Yup.string().required()
                    })
                    .required(),
                company: Yup.string().required(),
                text: Yup.string().required()
            })
        )
    });

    const handleSubmit = async (values: IOurOfferFormValues) => {
        const postPayload: IPostOurOfferPayload = {
            ...values,
            phone: getPhoneString(values.phone)
        };

        const ourOffer = await dispatch(postOurOffer.initiate(postPayload));

        if (ourOffer.data && !ourOffer.error) {
            // hit success - algolytics
            ourOfferSuccessHit(ourOffer.data);

            // hit success - gtm
            gtmGaEventOurOfferFormSuccess();
            sendB2BUsercomEvent({
                ...ourOffer.data,
                source: smallVersion ? B2BUsercomSources.OUR_OFFER_FORM_TOP : B2BUsercomSources.OUR_OFFER_FORM,
                mktOptIn: values.mktOptIn,
                mktOptInPhone: values.mktOptInPhone,
                mktOptInSms: values.mktOptInSms
            });

            dispatch(addSuccessNotification("Twoje zgłoszenie zostało wysłane."));
            form.reset();
        }

        if (ourOffer.error) {
            handleRtkqApiError({
                error: ourOffer.error,
                setError: form.setError,
                handle400ValidationError: true,
                onUnknownError: () => {
                    dispatch(addWarningNotification(unknownError));
                }
            });
        }
    };

    return (
        <Card smallVersion={smallVersion}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <FormWrap smallVersion={smallVersion}>
                        <FormHeadline variant={smallVersion ? "headline_2" : "headline_1"} as="h2" align="center">
                            {smallVersion ? (
                                <>
                                    Współpracuj z nami już od <Highlight> 299 zł</Highlight> miesięcznie
                                </>
                            ) : (
                                <>
                                    Porozmawiajmy
                                    <br /> o Twojej inwestycji
                                </>
                            )}
                        </FormHeadline>

                        <Text as="p" mb="3.2rem" variant="body_copy_1" align="center">
                            Skontaktuj się z nami. Nasz konsultant przygotuje dla Ciebie ofertę dopasowaną do skali Twojej
                            <br css={props.smallVersion && display("none")} /> firmy i jej celów marketingowych.
                        </Text>

                        {!smallVersion && (
                            <div css={[flexAbsoluteCenter, mb(6)]}>
                                <PhoneIcon css={mr(2)} wrapperColor={brandColor} wrapperSize="4.8" size="3" />
                                <Text variant="headline_3" as="span">
                                    +48 22 825 60 71
                                </Text>
                            </div>
                        )}
                    </FormWrap>

                    <div css={fieldsWrap}>
                        <InputField name="name" placeholder="Imię i nazwisko" disabled={postOurOfferMutation.isLoading} />
                        <InputField name="email" type="email" placeholder="Email" disabled={postOurOfferMutation.isLoading} />
                        <PhoneFieldControlled name="phone" codes={codes ?? []} placeholder="Numer telefonu" className="bt" />
                        <InputField name="company" placeholder="Firma" disabled={postOurOfferMutation.isLoading} />
                        <TextareaField name="text" placeholder="Wiadomość" disabled={postOurOfferMutation.isLoading} />
                        <div css={checkboxWrap}>
                            <CheckboxControlled
                                name="mktOptIn"
                                id={smallVersion ? "mktOptInSmall" : "mktOptInBig"}
                                labelContent={
                                    <Text variant="info_txt_3">
                                        Chcę otrzymywać od Property Group sp. z o.o. za pośrednictwem e-maila informacje marketingowe, w tym o jej usługach,
                                        promocjach, akcjach edukacyjnych, wydarzeniach z rynku nieruchomości. Zgodę mogę zawsze odwołać.
                                    </Text>
                                }
                                disabled={postOurOfferMutation.isLoading}
                            />
                            <CheckboxControlled
                                name="mktOptInPhone"
                                id={smallVersion ? "mktOptInPhoneSmall" : "mktOptInPhoneBig"}
                                labelContent={
                                    <Text variant="info_txt_3">
                                        Chcę otrzymywać od Property Group sp. z o.o. informacje marketingowe w trakcie połączeń głosowych wykonywanych na mój
                                        numer telefonu. Zgodę mogę w każdej chwili wycofać.
                                    </Text>
                                }
                                disabled={postOurOfferMutation.isLoading}
                            />
                            <CheckboxControlled
                                name="mktOptInSms"
                                id={smallVersion ? "mktOptInSmsSmall" : "mktOptInSmsBig"}
                                labelContent={
                                    <Text variant="info_txt_3">
                                        Chcę otrzymywać od Property Group sp. z o.o. informacje marketingowe za pomocą wiadomości SMS. Zgodę mogę zawsze
                                        odwołać.
                                    </Text>
                                }
                                disabled={postOurOfferMutation.isLoading}
                            />
                        </div>
                    </div>

                    <Text css={rodoText} variant="info_txt_1">
                        Administratorem danych osobowych jest Property Group sp. z o.o.
                        <br />
                        <a css={link} href={rpAppLink.rodoTerms.base()} target="_blank">
                            Kliknij, aby dowiedzieć się m.in., w jakim celu przetwarzamy Twoje dane oraz jakie przysługują Ci prawa.
                        </a>
                    </Text>

                    <TermsAcceptation css={mb(3)} isPartnerTerms />

                    <Button variant="filled_primary" css={w100} disabled={postOurOfferMutation.isLoading}>
                        Wyślij
                    </Button>
                </form>
            </FormProvider>
        </Card>
    );
};

const FormHeadline = styled(Text)<Pick<IProps, "smallVersion">>`
    ${({smallVersion}) => css`
        margin-bottom: ${smallVersion ? calculateRemSize(3) : calculateRemSize(4)};
    `};
`;

const link = css`
    color: inherit;
    ${underline};
`;

const Card = styled.div<Pick<IProps, "smallVersion">>`
    background-color: #fff;
    ${borderRadius(2)};

    ${({smallVersion}) =>
        smallVersion
            ? css`
                  max-width: 552px;
                  ${elevation(4)};
                  ${p(2)};

                  @media (min-width: ${getThemeBreakpoint().md}) {
                      ${p(3)};
                  }
              `
            : css`
                  display: inline-block;
                  ${elevation(2)};
                  ${p(4, 2, 5, 2)};

                  @media (min-width: ${getThemeBreakpoint().md}) {
                      ${p(6, 12, 7, 12)};
                  }
              `};
`;

const FormWrap = styled.div<Pick<IProps, "smallVersion">>`
    ${({smallVersion}) => css`
        display: ${smallVersion ? "block" : "none"};

        ${onDesktop(css`
            display: block;
        `)}
    `}
`;

const fieldsWrap = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(2)};
    margin-bottom: ${calculateRemSize(2)};
`;

const checkboxWrap = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(2)};
    align-items: flex-start;
`;

const rodoText = (theme: Theme) => css`
    color: ${theme.colors.gray[700]};
    margin-bottom: ${calculateRemSize(2)};
    text-align: center;
`;
