import React from "react";
import {useRef} from "react";

import {AnalyticsTools} from "../components/AnalyticsTools";
import {ArmedWithTech} from "../components/ArmedWithTech";
import {ContactSection} from "../components/ContactSection";
import {HeaderSection} from "../components/HeaderSection";
import {KnowledgeSection} from "../components/KnowledgeSection";
import {OurTeamSection} from "../components/OurTeamSection";
import {SearchDataSection} from "../components/SearchDataSection";
import {SellMoreSection} from "../components/SellMoreSection";
import {TestimonialSection} from "../components/TestimonialSection";

export const OurOfferView = () => {
    const sellMoreSectionRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLDivElement>(null);

    const scrollToForm = () => {
        formRef.current && formRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
    };

    const scrollToSellMoreSection = () => {
        sellMoreSectionRef.current && sellMoreSectionRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
    };

    return (
        <article>
            <HeaderSection scrollTo={scrollToSellMoreSection} />
            <SellMoreSection scrollToRef={sellMoreSectionRef} scrollToForm={scrollToForm} />
            <SearchDataSection />
            <ArmedWithTech scrollToForm={scrollToForm} />
            <AnalyticsTools />
            <KnowledgeSection />
            <TestimonialSection />
            <OurTeamSection />
            <ContactSection scrollToRef={formRef} />
        </article>
    );
};
import React from "react";
import {useRef} from "react";

import {AnalyticsTools} from "../components/AnalyticsTools";
import {ArmedWithTech} from "../components/ArmedWithTech";
import {ContactSection} from "../components/ContactSection";
import {HeaderSection} from "../components/HeaderSection";
import {KnowledgeSection} from "../components/KnowledgeSection";
import {OurTeamSection} from "../components/OurTeamSection";
import {SearchDataSection} from "../components/SearchDataSection";
import {SellMoreSection} from "../components/SellMoreSection";
import {TestimonialSection} from "../components/TestimonialSection";

export const OurOfferView = () => {
    const sellMoreSectionRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLDivElement>(null);

    const scrollToForm = () => {
        formRef.current && formRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
    };

    const scrollToSellMoreSection = () => {
        sellMoreSectionRef.current && sellMoreSectionRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
    };

    return (
        <article>
            <HeaderSection scrollTo={scrollToSellMoreSection} />
            <SellMoreSection scrollToRef={sellMoreSectionRef} scrollToForm={scrollToForm} />
            <SearchDataSection />
            <ArmedWithTech scrollToForm={scrollToForm} />
            <AnalyticsTools />
            <KnowledgeSection />
            <TestimonialSection />
            <OurTeamSection />
            <ContactSection scrollToRef={formRef} />
        </article>
    );
};
