import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ProgressIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M6 5.334h-.666V10H6V5.334ZM4 8h-.666v2H4V8ZM8 2.666h-.666V10H8V2.666ZM10 0h-.666v10H10V0ZM3.334 0v.666h1.528L0 5.53.471 6l4.863-4.862v1.528H6V0H3.334Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ProgressIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M6 5.334h-.666V10H6V5.334ZM4 8h-.666v2H4V8ZM8 2.666h-.666V10H8V2.666ZM10 0h-.666v10H10V0ZM3.334 0v.666h1.528L0 5.53.471 6l4.863-4.862v1.528H6V0H3.334Z" />
        </SvgIcon>
    );
};
