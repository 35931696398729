import {css} from "@emotion/react";

type IAxis = "horizontal-and-vertical" | "vertical" | "horizontal";
export const centerAbsolute = (axis: IAxis = "horizontal-and-vertical") => {
    switch (axis) {
        case "horizontal": {
            return css`
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            `;
        }
        case "vertical": {
            return css`
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            `;
        }
        default: {
            return css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            `;
        }
    }
};

export const centerText = css`
    text-align: center;
`;

export const resetTextAlign = css`
    text-align: unset;
`;
import {css} from "@emotion/react";

type IAxis = "horizontal-and-vertical" | "vertical" | "horizontal";
export const centerAbsolute = (axis: IAxis = "horizontal-and-vertical") => {
    switch (axis) {
        case "horizontal": {
            return css`
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            `;
        }
        case "vertical": {
            return css`
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            `;
        }
        default: {
            return css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            `;
        }
    }
};

export const centerText = css`
    text-align: center;
`;

export const resetTextAlign = css`
    text-align: unset;
`;
