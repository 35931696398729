import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {mb, onDesktop} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {RequestState} from "@pg-mono/request-state";
import {rpAppLink} from "@pg-mono/rp-routes";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {IRPStore} from "../../app/rp_reducer";
import {createOfferLink} from "../../offer/helpers/create_offer_link";
import {getOfferTypeNameCapital} from "../../offer/helpers/OfferType";
import {OfferType} from "../../offer/types/OfferType";
import {fetchMyOffersAppliedList} from "../actions/fetch_my_offers_applied_list";
import {FavouritesListItem} from "./FavouritesList/FavouritesListItem";
import {EmptyAppliedList} from "./EmptyAppliedList";
import {FavouriteListWrapper} from "./FavouriteListWrapper";
import {FavouritesEmptyData} from "./FavouritesEmptyData";

const propertyRoomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const AppliedList = () => {
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((store: IRPStore) => store.isAuthenticated);
    const {list: appliedOffersList, fetchRequest: appliedOffersListRequestState} = useSelector((store: IRPStore) => store.myOffers.applied.offers);
    const {list: appliedPropertiesList, fetchRequest: appliedPropertiesListRequestState} = useSelector((store: IRPStore) => store.myOffers.applied.properties);

    const hasAppliedOffersList = appliedOffersList.length > 0;
    const hasAppliedPropertiesList = appliedPropertiesList.length > 0;

    useEffect(() => {
        const shouldFetch = appliedOffersListRequestState !== RequestState.Success || appliedPropertiesListRequestState !== RequestState.Success;

        if (isAuthenticated && shouldFetch) {
            dispatch(fetchMyOffersAppliedList());
        }
    }, [isAuthenticated]);

    const getPropertyHeaderData = (offerType: OfferType, roomsCount: number, area: number | [number, number]) => {
        const offerTypeName = getOfferTypeNameCapital(offerType);
        const roomsInfo = `${roomsCount} ${propertyRoomsPluralize(roomsCount)}`;
        const formattedArea = areaFormat(area);

        return `${offerTypeName}, ${roomsInfo}, ${formattedArea}`;
    };

    if (!isAuthenticated) {
        return <FavouritesEmptyData header="Aby zobaczyć Twoją listę, zaloguj się." showLoginButton />;
    }

    if (appliedOffersListRequestState !== RequestState.Success && appliedPropertiesListRequestState !== RequestState.Success) {
        return (
            <CenterBox height="500px">
                <Loader size="lg" />
            </CenterBox>
        );
    }

    return (
        <div css={appliedContainer}>
            <Text as="h1" css={[mb(3), onDesktop(mb(4))]} variant="headline_2">
                Twoje zapytania
            </Text>

            {!hasAppliedPropertiesList && !hasAppliedOffersList && <EmptyAppliedList />}

            {hasAppliedPropertiesList && (
                <div css={mb(7)}>
                    <Text as="h3" variant="mini_header" css={mb(2)}>
                        Nieruchomości
                    </Text>
                    <div>
                        <FavouriteListWrapper>
                            {appliedPropertiesList.map((property) => (
                                <FavouritesListItem
                                    key={property.id}
                                    id={property.id}
                                    itemUrl={rpAppLink.property.detail.base({
                                        vendorSlug: property.offer.vendor.slug,
                                        offerSlug: property.offer.slug,
                                        offerId: property.offer.id,
                                        propertyId: property.id
                                    })}
                                    type="property"
                                    imageSrc={property.offer.main_image?.m_img_155x87}
                                    header={getPropertyHeaderData(property.offer.type, property.rooms, property.area)}
                                    address={`${property.offer.name}, ${property.offer.address}`}
                                    rodoData={{
                                        vendorName: property.offer.vendor.name,
                                        personalDataProcessorName: property.offer.configuration.personal_data_processor_name ?? "",
                                        personalDataProcessorUrl: property.offer.configuration.personal_data_processor_url ?? "",
                                        privacyPolicyUrl: property.offer.configuration.privacy_policy_url ?? ""
                                    }}
                                    hideRemoveOption
                                />
                            ))}
                        </FavouriteListWrapper>
                    </div>
                </div>
            )}

            {hasAppliedOffersList && (
                <>
                    <Text as="h3" variant="mini_header" css={mb(2)}>
                        Inwestycje
                    </Text>
                    <div>
                        <FavouriteListWrapper>
                            {appliedOffersList.map((offer) => (
                                <FavouritesListItem
                                    key={offer.id}
                                    id={offer.id}
                                    itemUrl={createOfferLink(offer)}
                                    type="offer"
                                    imageSrc={offer.main_image?.m_img_155x87}
                                    header={offer.name}
                                    address={`${offer.address}`}
                                    rodoData={{
                                        vendorName: offer.vendor.name,
                                        personalDataProcessorName: offer.configuration.personal_data_processor_name ?? "",
                                        personalDataProcessorUrl: offer.configuration.personal_data_processor_url ?? "",
                                        privacyPolicyUrl: offer.configuration.privacy_policy_url ?? ""
                                    }}
                                    roomsData={{
                                        propertiesForSale: offer.stats?.properties_count_for_sale,
                                        roomsRange: offer.stats?.rooms
                                    }}
                                    hideRemoveOption
                                />
                            ))}
                        </FavouriteListWrapper>
                    </div>
                </>
            )}
        </div>
    );
};

const appliedContainer = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.md}) {
        overflow-y: auto;
    }
`;
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {mb, onDesktop} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {RequestState} from "@pg-mono/request-state";
import {rpAppLink} from "@pg-mono/rp-routes";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {IRPStore} from "../../app/rp_reducer";
import {createOfferLink} from "../../offer/helpers/create_offer_link";
import {getOfferTypeNameCapital} from "../../offer/helpers/OfferType";
import {OfferType} from "../../offer/types/OfferType";
import {fetchMyOffersAppliedList} from "../actions/fetch_my_offers_applied_list";
import {FavouritesListItem} from "./FavouritesList/FavouritesListItem";
import {EmptyAppliedList} from "./EmptyAppliedList";
import {FavouriteListWrapper} from "./FavouriteListWrapper";
import {FavouritesEmptyData} from "./FavouritesEmptyData";

const propertyRoomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const AppliedList = () => {
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((store: IRPStore) => store.isAuthenticated);
    const {list: appliedOffersList, fetchRequest: appliedOffersListRequestState} = useSelector((store: IRPStore) => store.myOffers.applied.offers);
    const {list: appliedPropertiesList, fetchRequest: appliedPropertiesListRequestState} = useSelector((store: IRPStore) => store.myOffers.applied.properties);

    const hasAppliedOffersList = appliedOffersList.length > 0;
    const hasAppliedPropertiesList = appliedPropertiesList.length > 0;

    useEffect(() => {
        const shouldFetch = appliedOffersListRequestState !== RequestState.Success || appliedPropertiesListRequestState !== RequestState.Success;

        if (isAuthenticated && shouldFetch) {
            dispatch(fetchMyOffersAppliedList());
        }
    }, [isAuthenticated]);

    const getPropertyHeaderData = (offerType: OfferType, roomsCount: number, area: number | [number, number]) => {
        const offerTypeName = getOfferTypeNameCapital(offerType);
        const roomsInfo = `${roomsCount} ${propertyRoomsPluralize(roomsCount)}`;
        const formattedArea = areaFormat(area);

        return `${offerTypeName}, ${roomsInfo}, ${formattedArea}`;
    };

    if (!isAuthenticated) {
        return <FavouritesEmptyData header="Aby zobaczyć Twoją listę, zaloguj się." showLoginButton />;
    }

    if (appliedOffersListRequestState !== RequestState.Success && appliedPropertiesListRequestState !== RequestState.Success) {
        return (
            <CenterBox height="500px">
                <Loader size="lg" />
            </CenterBox>
        );
    }

    return (
        <div css={appliedContainer}>
            <Text as="h1" css={[mb(3), onDesktop(mb(4))]} variant="headline_2">
                Twoje zapytania
            </Text>

            {!hasAppliedPropertiesList && !hasAppliedOffersList && <EmptyAppliedList />}

            {hasAppliedPropertiesList && (
                <div css={mb(7)}>
                    <Text as="h3" variant="mini_header" css={mb(2)}>
                        Nieruchomości
                    </Text>
                    <div>
                        <FavouriteListWrapper>
                            {appliedPropertiesList.map((property) => (
                                <FavouritesListItem
                                    key={property.id}
                                    id={property.id}
                                    itemUrl={rpAppLink.property.detail.base({
                                        vendorSlug: property.offer.vendor.slug,
                                        offerSlug: property.offer.slug,
                                        offerId: property.offer.id,
                                        propertyId: property.id
                                    })}
                                    type="property"
                                    imageSrc={property.offer.main_image?.m_img_155x87}
                                    header={getPropertyHeaderData(property.offer.type, property.rooms, property.area)}
                                    address={`${property.offer.name}, ${property.offer.address}`}
                                    rodoData={{
                                        vendorName: property.offer.vendor.name,
                                        personalDataProcessorName: property.offer.configuration.personal_data_processor_name ?? "",
                                        personalDataProcessorUrl: property.offer.configuration.personal_data_processor_url ?? "",
                                        privacyPolicyUrl: property.offer.configuration.privacy_policy_url ?? ""
                                    }}
                                    hideRemoveOption
                                />
                            ))}
                        </FavouriteListWrapper>
                    </div>
                </div>
            )}

            {hasAppliedOffersList && (
                <>
                    <Text as="h3" variant="mini_header" css={mb(2)}>
                        Inwestycje
                    </Text>
                    <div>
                        <FavouriteListWrapper>
                            {appliedOffersList.map((offer) => (
                                <FavouritesListItem
                                    key={offer.id}
                                    id={offer.id}
                                    itemUrl={createOfferLink(offer)}
                                    type="offer"
                                    imageSrc={offer.main_image?.m_img_155x87}
                                    header={offer.name}
                                    address={`${offer.address}`}
                                    rodoData={{
                                        vendorName: offer.vendor.name,
                                        personalDataProcessorName: offer.configuration.personal_data_processor_name ?? "",
                                        personalDataProcessorUrl: offer.configuration.personal_data_processor_url ?? "",
                                        privacyPolicyUrl: offer.configuration.privacy_policy_url ?? ""
                                    }}
                                    roomsData={{
                                        propertiesForSale: offer.stats?.properties_count_for_sale,
                                        roomsRange: offer.stats?.rooms
                                    }}
                                    hideRemoveOption
                                />
                            ))}
                        </FavouriteListWrapper>
                    </div>
                </>
            )}
        </div>
    );
};

const appliedContainer = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.md}) {
        overflow-y: auto;
    }
`;
