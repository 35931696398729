import React from "react";
import {css, Theme} from "@emotion/react";

import {listUnStyled, mb} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";

interface Link {
    href: string;
    text: string;
}

interface IProps {
    className?: string;
    links: Link[];
}

export const PromotionInternalLinks = (props: IProps) => {
    return (
        <div className={props.className}>
            <Text variant="body_copy_0" css={[mb(2)]}>
                Sprawdź także
            </Text>
            <ul css={[listUnStyled]}>
                {props.links?.map((link) => (
                    <Text variant="info_txt_1" as="li" key={`${link.href}${link.text}`} css={[locationLinkLi]}>
                        <Link href={link.href} css={linkStyle}>
                            {link.text}
                        </Link>
                    </Text>
                ))}
            </ul>
        </div>
    );
};

const locationLinkLi = css`
    list-style-type: none;
`;

const linkStyle = (theme: Theme) => css`
    color: ${theme.colors.secondary};

    &:hover,
    &:focus {
        color: ${theme.colors.secondary};
        text-decoration: underline;
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {listUnStyled, mb} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";

interface Link {
    href: string;
    text: string;
}

interface IProps {
    className?: string;
    links: Link[];
}

export const PromotionInternalLinks = (props: IProps) => {
    return (
        <div className={props.className}>
            <Text variant="body_copy_0" css={[mb(2)]}>
                Sprawdź także
            </Text>
            <ul css={[listUnStyled]}>
                {props.links?.map((link) => (
                    <Text variant="info_txt_1" as="li" key={`${link.href}${link.text}`} css={[locationLinkLi]}>
                        <Link href={link.href} css={linkStyle}>
                            {link.text}
                        </Link>
                    </Text>
                ))}
            </ul>
        </div>
    );
};

const locationLinkLi = css`
    list-style-type: none;
`;

const linkStyle = (theme: Theme) => css`
    color: ${theme.colors.secondary};

    &:hover,
    &:focus {
        color: ${theme.colors.secondary};
        text-decoration: underline;
    }
`;
