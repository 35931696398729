import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import {compact} from "@pg-mono/nodash";
import {rpAppLink} from "@pg-mono/rp-routes";

import {Breadcrumbs} from "../../../app/components/breadcrumbs/Breadcrumbs";
import {BreadcrumbsItem} from "../../../app/components/breadcrumbs/BreadcrumbsItem";
import {IRPStore} from "../../../app/rp_reducer";
import {buildFriendlyPromotionSlug} from "../../helpers/build_friendly_promotion_slug";
import {getPromotionTypeName} from "../../helpers/PromotionType";

const DYNAMIC_START_POSITION = 3;

export const PromotionListBreadcrumbs = () => {
    const promotionType = useSelector((state: IRPStore) => state.promotionList.form.formValues.type);
    const region = useSelector((state: IRPStore) => state.promotionList.region);

    const breadcrumbs = useMemo(() => {
        return compact([
            {
                name: "Promocje",
                position: "2",
                url: rpAppLink.promotions.list()
            },
            !!promotionType && {
                name: getPromotionTypeName(promotionType, true),
                url: rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({type: promotionType})})
            },
            !!region?.parent &&
                !!region?.parent?.slug && {
                    name: region.parent.name,
                    url: rpAppLink.promotions.listFriendly({
                        friendlySlug: buildFriendlyPromotionSlug({type: promotionType, region: region.parent.slug})
                    })
                },
            !!region && {
                name: region.name,
                url: rpAppLink.promotions.listFriendly({
                    friendlySlug: buildFriendlyPromotionSlug({
                        type: promotionType,
                        region: region.slug
                    })
                })
            }
        ]);
    }, [promotionType, region]);

    return (
        <Breadcrumbs homePageUrl={rpAppLink.base()}>
            {breadcrumbs.map((breadcrumb, index) => {
                return (
                    <BreadcrumbsItem
                        key={index}
                        name={breadcrumb.name}
                        position={`${DYNAMIC_START_POSITION + index}`}
                        url={breadcrumb.url}
                        isLinkingToCurrentLocation={index === breadcrumbs.length - 1}
                    />
                );
            })}
        </Breadcrumbs>
    );
};
import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import {compact} from "@pg-mono/nodash";
import {rpAppLink} from "@pg-mono/rp-routes";

import {Breadcrumbs} from "../../../app/components/breadcrumbs/Breadcrumbs";
import {BreadcrumbsItem} from "../../../app/components/breadcrumbs/BreadcrumbsItem";
import {IRPStore} from "../../../app/rp_reducer";
import {buildFriendlyPromotionSlug} from "../../helpers/build_friendly_promotion_slug";
import {getPromotionTypeName} from "../../helpers/PromotionType";

const DYNAMIC_START_POSITION = 3;

export const PromotionListBreadcrumbs = () => {
    const promotionType = useSelector((state: IRPStore) => state.promotionList.form.formValues.type);
    const region = useSelector((state: IRPStore) => state.promotionList.region);

    const breadcrumbs = useMemo(() => {
        return compact([
            {
                name: "Promocje",
                position: "2",
                url: rpAppLink.promotions.list()
            },
            !!promotionType && {
                name: getPromotionTypeName(promotionType, true),
                url: rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({type: promotionType})})
            },
            !!region?.parent &&
                !!region?.parent?.slug && {
                    name: region.parent.name,
                    url: rpAppLink.promotions.listFriendly({
                        friendlySlug: buildFriendlyPromotionSlug({type: promotionType, region: region.parent.slug})
                    })
                },
            !!region && {
                name: region.name,
                url: rpAppLink.promotions.listFriendly({
                    friendlySlug: buildFriendlyPromotionSlug({
                        type: promotionType,
                        region: region.slug
                    })
                })
            }
        ]);
    }, [promotionType, region]);

    return (
        <Breadcrumbs homePageUrl={rpAppLink.base()}>
            {breadcrumbs.map((breadcrumb, index) => {
                return (
                    <BreadcrumbsItem
                        key={index}
                        name={breadcrumb.name}
                        position={`${DYNAMIC_START_POSITION + index}`}
                        url={breadcrumb.url}
                        isLinkingToCurrentLocation={index === breadcrumbs.length - 1}
                    />
                );
            })}
        </Breadcrumbs>
    );
};
