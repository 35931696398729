import {ICampaignDetails} from "../types/ICampaignDetails";
import {getDateFromCampaignDetails} from "./get_date_from_campaign_details";

export function getAddToCalendarLink(
    campaign: Pick<ICampaignDetails, "opendays_date_range" | "opendays_time_range" | "slogan" | "offer" | "highlight_description">,
    isIphone: boolean
) {
    const {fromDate, toDate} = getDateFromCampaignDetails(campaign);
    const fromDateFormatted = getFormattedDate(fromDate);
    const toDateFormatted = getFormattedDate(toDate);

    const location = campaign.offer.sales_offices[0].address || campaign.offer.address;
    const title = campaign.slogan;
    const description = campaign.highlight_description;

    const timezone = "UTC";

    if (isIphone) {
        const appleCalendarData = [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            "DTSTART;TZID=" + timezone + ":" + fromDateFormatted,
            "DTEND;TZID=" + timezone + ":" + toDateFormatted,
            "SUMMARY:" + title,
            "DESCRIPTION:" + description.replace(/\n\n/g, "\\n"),
            "LOCATION:" + location.replace(/\r\n/g, "\\n"),
            "END:VEVENT",
            "END:VCALENDAR"
        ].join("\n");

        return `data:text/calendar;charset=utf8,${encodeURI(appleCalendarData)}`;
    }

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${fromDateFormatted}/${toDateFormatted}&location=${encodeURIComponent(
        location
    )}&text=${encodeURIComponent(title)}&details=${encodeURIComponent(description)}`;
}

function getFormattedDate(date: Date) {
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneOffsetInMs = timezoneOffset * 60 * 1000;
    const utcDate = new Date(date.getTime() + timezoneOffsetInMs);

    return utcDate.toISOString().slice(0, 10).replace(/-/g, "") + "T" + utcDate.toTimeString().slice(0, 8).replace(/:/g, "") + "Z";
}
import {ICampaignDetails} from "../types/ICampaignDetails";
import {getDateFromCampaignDetails} from "./get_date_from_campaign_details";

export function getAddToCalendarLink(
    campaign: Pick<ICampaignDetails, "opendays_date_range" | "opendays_time_range" | "slogan" | "offer" | "highlight_description">,
    isIphone: boolean
) {
    const {fromDate, toDate} = getDateFromCampaignDetails(campaign);
    const fromDateFormatted = getFormattedDate(fromDate);
    const toDateFormatted = getFormattedDate(toDate);

    const location = campaign.offer.sales_offices[0].address || campaign.offer.address;
    const title = campaign.slogan;
    const description = campaign.highlight_description;

    const timezone = "UTC";

    if (isIphone) {
        const appleCalendarData = [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            "DTSTART;TZID=" + timezone + ":" + fromDateFormatted,
            "DTEND;TZID=" + timezone + ":" + toDateFormatted,
            "SUMMARY:" + title,
            "DESCRIPTION:" + description.replace(/\n\n/g, "\\n"),
            "LOCATION:" + location.replace(/\r\n/g, "\\n"),
            "END:VEVENT",
            "END:VCALENDAR"
        ].join("\n");

        return `data:text/calendar;charset=utf8,${encodeURI(appleCalendarData)}`;
    }

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${fromDateFormatted}/${toDateFormatted}&location=${encodeURIComponent(
        location
    )}&text=${encodeURIComponent(title)}&details=${encodeURIComponent(description)}`;
}

function getFormattedDate(date: Date) {
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneOffsetInMs = timezoneOffset * 60 * 1000;
    const utcDate = new Date(date.getTime() + timezoneOffsetInMs);

    return utcDate.toISOString().slice(0, 10).replace(/-/g, "") + "T" + utcDate.toTimeString().slice(0, 8).replace(/:/g, "") + "Z";
}
