import React from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, display, m, onDesktop, p} from "@pg-design/helpers-css";
import {TrashIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";

import {handleFavouritesListCheckHit} from "../../../tracking/algolytics_hits/favourites_list_hit";
import {gtmClickDeleteFromList} from "../../../tracking/google_tag_manager/gtm_favourites_list";
import {useMyOffersFavourites} from "../../hooks/use_my_offers_favourites";
import {FavouritesListItemRodo, IFavouritesListItemRodoProps} from "./FavouritesListItemRodo";
import {FavouritesListItemRooms, IFavouritesListItemRoomsProps} from "./FavouritesListItemRooms";

type ListType = "offer" | "property";

interface IProps {
    id: number;
    imageSrc: string;
    itemUrl: string;
    header: string;
    address: string;
    rodoData: IFavouritesListItemRodoProps;
    type: ListType;
    roomsData?: IFavouritesListItemRoomsProps;
    hideRemoveOption?: boolean;
    propertyOfferId?: number;
    disableRemoveButton?: boolean;
    className?: string;
}

export const FavouritesListItem = (props: IProps) => {
    const {imageSrc, address, header, id, itemUrl, propertyOfferId, type, rodoData, roomsData, hideRemoveOption, disableRemoveButton, className} = props;
    const {removeFavouriteProperty, removeFavouriteOffer} = useMyOffersFavourites();

    const handleFavouriteRemove = async () => {
        if (disableRemoveButton) {
            return;
        }

        if (type === "property" && propertyOfferId) {
            await removeFavouriteProperty(id);

            handleFavouritesListCheckHit(false, propertyOfferId, id);
            gtmClickDeleteFromList();
        }

        if (type === "offer") {
            await removeFavouriteOffer(id);

            handleFavouritesListCheckHit(false, id);
            gtmClickDeleteFromList();
        }
    };

    return (
        <li css={listItemContainerStyle} className={className}>
            <div css={display("flex")}>
                <Link href={itemUrl} css={listImageWrapStyle}>
                    <Image alt={address} src={imageSrc} loading="lazy" width="375" height="211" ratio={{xs: "406/248"}} />
                </Link>
                <div css={dataContainerStyle}>
                    <Link href={itemUrl} css={titleLinkStyle}>
                        <div css={titleLinkContentStyle}>
                            <Text as="span" variant="headline_4">
                                {header}
                            </Text>
                            <Text as="span" variant="body_copy_2">
                                {address}
                            </Text>
                        </div>
                    </Link>
                    {roomsData && (
                        <div css={showOnDesktopStyle}>
                            <FavouritesListItemRooms {...roomsData} css={desktopRoomsStyle} />
                        </div>
                    )}
                    <Text as="span" variant="info_txt_2" css={showOnDesktopStyle}>
                        <FavouritesListItemRodo {...rodoData} />
                    </Text>
                </div>
                {!hideRemoveOption && (
                    <div css={[showOnDesktopStyle, desktopTrashButtonWrap]}>
                        <TrashButton onClick={handleFavouriteRemove} />
                    </div>
                )}
            </div>
            <div css={mobileFooterStyle}>
                {roomsData && <FavouritesListItemRooms {...roomsData} css={mobileRoomsStyle} />}
                <div css={display("flex")}>
                    <FavouritesListItemRodo size="mid" {...rodoData} css={mobileRodoStyle} />
                    {!hideRemoveOption && (
                        <div css={mobileTrashButtonWrapStyle}>
                            <TrashButton onClick={handleFavouriteRemove} />
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
};

//  Components
interface ITrashButtonProps {
    onClick: () => void;
}

const TrashButton = (props: ITrashButtonProps) => (
    <button css={trashButtonStyle} onClick={props.onClick}>
        <TrashIcon size="2.4" />
    </button>
);

const trashButtonStyle = (theme: Theme) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;

    &:hover svg {
        transition: fill ${theme.transition.timingFunction} ${theme.transition.duration};
        fill: ${theme.colors.highlight};
    }
`;

//  Styles
const showOnDesktopStyle = css`
    display: none;
    gap: 1.6rem;

    ${onDesktop(css`
        display: flex;
    `)}
`;

const listItemContainerStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 12.4rem;
    }
`;

const listImageWrapStyle = (theme: Theme) => css`
    ${borderRadius(2, 0, 0, 0)}
    flex: 0 0 12.3rem;
    height: 7.6rem;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.md}) {
        ${borderRadius(2, 0, 0, 2)}
        flex: 0 0 20.3rem;
        height: 12.4rem;
    }

    //TODO: Request for proper aspect ratio image and remove those liness
    & img {
        height: 100%;
    }
`;

const dataContainerStyle = (theme: Theme) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    ${p(0.5, 0, 0, 1)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(1, 0, 0, 2)}
    }
`;

const titleLinkContentStyle = css`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

const titleLinkStyle = css`
    flex: 1 1 auto;
`;

const desktopRoomsStyle = css`
    ${m(0, 0, 0.5, 0)}
`;

const desktopTrashButtonWrap = css`
    display: none;

    ${onDesktop(css`
        display: flex;
        justify-content: center;
        align-items: center;
        ${p(0, 2, 0, 2)}
    `)}
`;

const mobileFooterStyle = css`
    display: flex;
    flex-direction: column;
    ${p(2)}

    ${onDesktop(css`
        display: none;
    `)}
`;

const mobileRoomsStyle = css`
    ${m(0, 0, 2, 0)}
`;

const mobileRodoStyle = css`
    display: inline-block;
    flex: 1 1 auto;
`;

const mobileTrashButtonWrapStyle = css`
    ${p(0, 0, 0, 2)}
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, display, m, onDesktop, p} from "@pg-design/helpers-css";
import {TrashIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";

import {handleFavouritesListCheckHit} from "../../../tracking/algolytics_hits/favourites_list_hit";
import {gtmClickDeleteFromList} from "../../../tracking/google_tag_manager/gtm_favourites_list";
import {useMyOffersFavourites} from "../../hooks/use_my_offers_favourites";
import {FavouritesListItemRodo, IFavouritesListItemRodoProps} from "./FavouritesListItemRodo";
import {FavouritesListItemRooms, IFavouritesListItemRoomsProps} from "./FavouritesListItemRooms";

type ListType = "offer" | "property";

interface IProps {
    id: number;
    imageSrc: string;
    itemUrl: string;
    header: string;
    address: string;
    rodoData: IFavouritesListItemRodoProps;
    type: ListType;
    roomsData?: IFavouritesListItemRoomsProps;
    hideRemoveOption?: boolean;
    propertyOfferId?: number;
    disableRemoveButton?: boolean;
    className?: string;
}

export const FavouritesListItem = (props: IProps) => {
    const {imageSrc, address, header, id, itemUrl, propertyOfferId, type, rodoData, roomsData, hideRemoveOption, disableRemoveButton, className} = props;
    const {removeFavouriteProperty, removeFavouriteOffer} = useMyOffersFavourites();

    const handleFavouriteRemove = async () => {
        if (disableRemoveButton) {
            return;
        }

        if (type === "property" && propertyOfferId) {
            await removeFavouriteProperty(id);

            handleFavouritesListCheckHit(false, propertyOfferId, id);
            gtmClickDeleteFromList();
        }

        if (type === "offer") {
            await removeFavouriteOffer(id);

            handleFavouritesListCheckHit(false, id);
            gtmClickDeleteFromList();
        }
    };

    return (
        <li css={listItemContainerStyle} className={className}>
            <div css={display("flex")}>
                <Link href={itemUrl} css={listImageWrapStyle}>
                    <Image alt={address} src={imageSrc} loading="lazy" width="375" height="211" ratio={{xs: "406/248"}} />
                </Link>
                <div css={dataContainerStyle}>
                    <Link href={itemUrl} css={titleLinkStyle}>
                        <div css={titleLinkContentStyle}>
                            <Text as="span" variant="headline_4">
                                {header}
                            </Text>
                            <Text as="span" variant="body_copy_2">
                                {address}
                            </Text>
                        </div>
                    </Link>
                    {roomsData && (
                        <div css={showOnDesktopStyle}>
                            <FavouritesListItemRooms {...roomsData} css={desktopRoomsStyle} />
                        </div>
                    )}
                    <Text as="span" variant="info_txt_2" css={showOnDesktopStyle}>
                        <FavouritesListItemRodo {...rodoData} />
                    </Text>
                </div>
                {!hideRemoveOption && (
                    <div css={[showOnDesktopStyle, desktopTrashButtonWrap]}>
                        <TrashButton onClick={handleFavouriteRemove} />
                    </div>
                )}
            </div>
            <div css={mobileFooterStyle}>
                {roomsData && <FavouritesListItemRooms {...roomsData} css={mobileRoomsStyle} />}
                <div css={display("flex")}>
                    <FavouritesListItemRodo size="mid" {...rodoData} css={mobileRodoStyle} />
                    {!hideRemoveOption && (
                        <div css={mobileTrashButtonWrapStyle}>
                            <TrashButton onClick={handleFavouriteRemove} />
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
};

//  Components
interface ITrashButtonProps {
    onClick: () => void;
}

const TrashButton = (props: ITrashButtonProps) => (
    <button css={trashButtonStyle} onClick={props.onClick}>
        <TrashIcon size="2.4" />
    </button>
);

const trashButtonStyle = (theme: Theme) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;

    &:hover svg {
        transition: fill ${theme.transition.timingFunction} ${theme.transition.duration};
        fill: ${theme.colors.highlight};
    }
`;

//  Styles
const showOnDesktopStyle = css`
    display: none;
    gap: 1.6rem;

    ${onDesktop(css`
        display: flex;
    `)}
`;

const listItemContainerStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 12.4rem;
    }
`;

const listImageWrapStyle = (theme: Theme) => css`
    ${borderRadius(2, 0, 0, 0)}
    flex: 0 0 12.3rem;
    height: 7.6rem;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.md}) {
        ${borderRadius(2, 0, 0, 2)}
        flex: 0 0 20.3rem;
        height: 12.4rem;
    }

    //TODO: Request for proper aspect ratio image and remove those liness
    & img {
        height: 100%;
    }
`;

const dataContainerStyle = (theme: Theme) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    ${p(0.5, 0, 0, 1)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(1, 0, 0, 2)}
    }
`;

const titleLinkContentStyle = css`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

const titleLinkStyle = css`
    flex: 1 1 auto;
`;

const desktopRoomsStyle = css`
    ${m(0, 0, 0.5, 0)}
`;

const desktopTrashButtonWrap = css`
    display: none;

    ${onDesktop(css`
        display: flex;
        justify-content: center;
        align-items: center;
        ${p(0, 2, 0, 2)}
    `)}
`;

const mobileFooterStyle = css`
    display: flex;
    flex-direction: column;
    ${p(2)}

    ${onDesktop(css`
        display: none;
    `)}
`;

const mobileRoomsStyle = css`
    ${m(0, 0, 2, 0)}
`;

const mobileRodoStyle = css`
    display: inline-block;
    flex: 1 1 auto;
`;

const mobileTrashButtonWrapStyle = css`
    ${p(0, 0, 0, 2)}
`;
