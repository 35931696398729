import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Container} from "@pg-design/grid-module";
import {flexAbsoluteCenter, flexDirection, mh, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";
import {rpAppPath} from "@pg-mono/rp-routes";

const newsletterImg = require("../assets/newsletter.png");
const newsletterBigImg = require("../assets/newsletter_big.png");

export const SmsUnsubscribeView = () => {
    const theme = useTheme();

    const pushToHomePage = () => {
        window.location.href = rpAppPath.base;
    };

    return (
        <Container>
            <div css={[flexAbsoluteCenter, flexDirection("column"), p(2, 0, 5, 0), onDesktop(p(2, 0, 10, 0))]}>
                <Picture
                    alt=""
                    sources={[
                        {minWidthPX: 0, width: 336, height: 288, src: newsletterImg},
                        {minWidthPX: theme.breakpoints.numeric.md, width: 498, height: 425, src: newsletterBigImg}
                    ]}
                    css={[mh("auto")]}
                />
                <Text as="div" variant="headline_0" align="center">
                    Dziękujemy, że byłeś(aś) z nami.
                </Text>

                <Text as="div" variant="body_copy_0" align="center" css={[mt(2)]} color={theme.colors.gray[800]}>
                    Od tej chwili nie będziemy już wysyłać naszych SMS-ów
                </Text>

                <Button css={button} variant="filled_primary" onClick={pushToHomePage}>
                    Strona główna
                </Button>
            </div>
        </Container>
    );
};

const button = (theme: Theme) => css`
    ${mt(4)};
    ${w100};

    @media (min-width: ${theme.breakpoints.md}) {
        ${mt(6)};
        width: 20rem;
        max-width: 20rem;
    }
`;
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Container} from "@pg-design/grid-module";
import {flexAbsoluteCenter, flexDirection, mh, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";
import {rpAppPath} from "@pg-mono/rp-routes";

const newsletterImg = require("../assets/newsletter.png");
const newsletterBigImg = require("../assets/newsletter_big.png");

export const SmsUnsubscribeView = () => {
    const theme = useTheme();

    const pushToHomePage = () => {
        window.location.href = rpAppPath.base;
    };

    return (
        <Container>
            <div css={[flexAbsoluteCenter, flexDirection("column"), p(2, 0, 5, 0), onDesktop(p(2, 0, 10, 0))]}>
                <Picture
                    alt=""
                    sources={[
                        {minWidthPX: 0, width: 336, height: 288, src: newsletterImg},
                        {minWidthPX: theme.breakpoints.numeric.md, width: 498, height: 425, src: newsletterBigImg}
                    ]}
                    css={[mh("auto")]}
                />
                <Text as="div" variant="headline_0" align="center">
                    Dziękujemy, że byłeś(aś) z nami.
                </Text>

                <Text as="div" variant="body_copy_0" align="center" css={[mt(2)]} color={theme.colors.gray[800]}>
                    Od tej chwili nie będziemy już wysyłać naszych SMS-ów
                </Text>

                <Button css={button} variant="filled_primary" onClick={pushToHomePage}>
                    Strona główna
                </Button>
            </div>
        </Container>
    );
};

const button = (theme: Theme) => css`
    ${mt(4)};
    ${w100};

    @media (min-width: ${theme.breakpoints.md}) {
        ${mt(6)};
        width: 20rem;
        max-width: 20rem;
    }
`;
