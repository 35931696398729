import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";
import {css, Theme} from "@emotion/react";

import {Container} from "@pg-design/grid-module";
import {backgroundColor, mb, mt, onDesktop, pb, pt} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {PaginationSize, PaginationWithList} from "@pg-design/pagination";
import {FaqSection} from "@pg-mono/faq";
import {useIsMobile} from "@pg-mono/hooks";
import {appendQueryString, parseSearch, validateQuery} from "@pg-mono/request";

import {IRPStore} from "../../app/rp_reducer";
import {LeadModal} from "../../lead/components/lead_modal/LeadModal";
import {normalizeRegionDataToPayload, promotionListSearchHit} from "../../tracking/algolytics_hits/promotion_listing_search_hit";
import {normalizeOfferListIntoSearchPayload} from "../../tracking/algolytics_hits/utils/normalize_offer_data_to_payload";
import {PromotionInternalLinks} from "../components/PromotionInternalLinks";
import {usePromotionTypeListByCurrentRegion} from "../hooks/use_promotion_type_list_by_region";
import {PromotionListBreadcrumbs} from "../list/components/PromotionListBreadcrumbs";
import {PromotionListHeader} from "../list/components/PromotionListHeader";
import {PromotionListListSection} from "../list/components/PromotionListListSection";
import {usePromotionListMaxPage} from "../list/hooks/usePromotionListMaxPage";

export const PromotionListView = () => {
    const promotions = useSelector((state: IRPStore) => state.promotionList.promotions);
    const searchOfferType = useSelector((state: IRPStore) => state.promotionList.form.formValues.type);
    const searchRegions = useSelector((state: IRPStore) => state.search.formValues.search.regions);
    const faq = useSelector((state: IRPStore) => state.faq.data);
    const location = useLocation();
    const query = parseSearch(location.search);
    const currentPage = typeof query.page === "string" ? parseInt(query.page, 10) : 1;
    const isMobile = useIsMobile();
    const {promotionLinks} = usePromotionTypeListByCurrentRegion(searchOfferType);

    const maxPage = usePromotionListMaxPage();

    useEffect(() => {
        const searchOptions = normalizeRegionDataToPayload(searchRegions?.[0]);
        const payload = normalizeOfferListIntoSearchPayload(promotions.map((promotion) => promotion.offer));

        promotionListSearchHit({...searchOptions, search_type: searchOfferType}, payload);
    }, [searchRegions, searchOfferType]);

    const buildPageLink = (page: number) => {
        const query = validateQuery(["page"], parseSearch(location.search), page >= 2 ? {page: page.toString()} : {});

        return appendQueryString(location.pathname, query);
    };

    return (
        <article css={promotionListView}>
            <Container>
                <PromotionListBreadcrumbs />
                <PromotionListHeader css={[pt(4), pb(3), onDesktop(pb(8))]} />
                <PromotionListListSection />
                <PaginationWithList
                    hrefBuilder={buildPageLink}
                    iconPrev={<ChevronLeftIcon />}
                    iconNext={<ChevronRightIcon />}
                    pageCount={maxPage}
                    currentPage={currentPage}
                    multiNumbersInside
                    size={isMobile ? PaginationSize.SM : PaginationSize.LG}
                    css={[mt(3), mb(6), onDesktop(mt(4), mb(10))]}
                />
                {/* waiting for task: CU-2268pv8*/}
                {/*<PromotionSeoDescription header={"TBD"} css={[mb(4)]}>*/}
                {/*    TBD*/}
                {/*</PromotionSeoDescription>*/}
                {promotionLinks.length > 0 && <PromotionInternalLinks css={[mb(8)]} links={promotionLinks} />}
            </Container>
            {currentPage === 1 && <FaqSection faqContent={faq} />}
            <LeadModal />
        </article>
    );
};

const promotionListView = (theme: Theme) => css`
    ${backgroundColor(theme.colors.gray["100"])};
`;
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";
import {css, Theme} from "@emotion/react";

import {Container} from "@pg-design/grid-module";
import {backgroundColor, mb, mt, onDesktop, pb, pt} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {PaginationSize, PaginationWithList} from "@pg-design/pagination";
import {FaqSection} from "@pg-mono/faq";
import {useIsMobile} from "@pg-mono/hooks";
import {appendQueryString, parseSearch, validateQuery} from "@pg-mono/request";

import {IRPStore} from "../../app/rp_reducer";
import {LeadModal} from "../../lead/components/lead_modal/LeadModal";
import {normalizeRegionDataToPayload, promotionListSearchHit} from "../../tracking/algolytics_hits/promotion_listing_search_hit";
import {normalizeOfferListIntoSearchPayload} from "../../tracking/algolytics_hits/utils/normalize_offer_data_to_payload";
import {PromotionInternalLinks} from "../components/PromotionInternalLinks";
import {usePromotionTypeListByCurrentRegion} from "../hooks/use_promotion_type_list_by_region";
import {PromotionListBreadcrumbs} from "../list/components/PromotionListBreadcrumbs";
import {PromotionListHeader} from "../list/components/PromotionListHeader";
import {PromotionListListSection} from "../list/components/PromotionListListSection";
import {usePromotionListMaxPage} from "../list/hooks/usePromotionListMaxPage";

export const PromotionListView = () => {
    const promotions = useSelector((state: IRPStore) => state.promotionList.promotions);
    const searchOfferType = useSelector((state: IRPStore) => state.promotionList.form.formValues.type);
    const searchRegions = useSelector((state: IRPStore) => state.search.formValues.search.regions);
    const faq = useSelector((state: IRPStore) => state.faq.data);
    const location = useLocation();
    const query = parseSearch(location.search);
    const currentPage = typeof query.page === "string" ? parseInt(query.page, 10) : 1;
    const isMobile = useIsMobile();
    const {promotionLinks} = usePromotionTypeListByCurrentRegion(searchOfferType);

    const maxPage = usePromotionListMaxPage();

    useEffect(() => {
        const searchOptions = normalizeRegionDataToPayload(searchRegions?.[0]);
        const payload = normalizeOfferListIntoSearchPayload(promotions.map((promotion) => promotion.offer));

        promotionListSearchHit({...searchOptions, search_type: searchOfferType}, payload);
    }, [searchRegions, searchOfferType]);

    const buildPageLink = (page: number) => {
        const query = validateQuery(["page"], parseSearch(location.search), page >= 2 ? {page: page.toString()} : {});

        return appendQueryString(location.pathname, query);
    };

    return (
        <article css={promotionListView}>
            <Container>
                <PromotionListBreadcrumbs />
                <PromotionListHeader css={[pt(4), pb(3), onDesktop(pb(8))]} />
                <PromotionListListSection />
                <PaginationWithList
                    hrefBuilder={buildPageLink}
                    iconPrev={<ChevronLeftIcon />}
                    iconNext={<ChevronRightIcon />}
                    pageCount={maxPage}
                    currentPage={currentPage}
                    multiNumbersInside
                    size={isMobile ? PaginationSize.SM : PaginationSize.LG}
                    css={[mt(3), mb(6), onDesktop(mt(4), mb(10))]}
                />
                {/* waiting for task: CU-2268pv8*/}
                {/*<PromotionSeoDescription header={"TBD"} css={[mb(4)]}>*/}
                {/*    TBD*/}
                {/*</PromotionSeoDescription>*/}
                {promotionLinks.length > 0 && <PromotionInternalLinks css={[mb(8)]} links={promotionLinks} />}
            </Container>
            {currentPage === 1 && <FaqSection faqContent={faq} />}
            <LeadModal />
        </article>
    );
};

const promotionListView = (theme: Theme) => css`
    ${backgroundColor(theme.colors.gray["100"])};
`;
