import React from "react";
import {css, Theme} from "@emotion/react";

import {mb, mr} from "@pg-design/helpers-css";
import {Select} from "@pg-design/select-module";
import {form, FormFieldType, IFormProps} from "@pg-mono/form";

import {PromotionType, promotionTypes} from "../../../helpers/PromotionType";
import {PromotionListSearchFilter} from "./PromotionListSearchFilter";

export const promotionListFilterFields = {
    type: FormFieldType.Select
};

export interface IPromotionListFilterFields {
    type: PromotionType;
}

interface IPromotionListFilters extends IFormProps<IPromotionListFilterFields> {}

const PromotionListFiltersC = (props: IPromotionListFilters) => {
    const typeSelectProps = props.getFieldProps("type");
    const currentTypeOption = promotionTypes.filter((option) => option.value === props.values.type);

    const onChange = (option: unknown | null) => {
        const optionValue = (option as {value: PromotionType})?.value;
        typeSelectProps.onChange("type", optionValue);
    };

    return (
        <div css={filters}>
            <div css={cityFilter}>
                <PromotionListSearchFilter />
            </div>

            <div css={offerTypeFilter}>
                <Select isClearable value={currentTypeOption} options={promotionTypes} name="type" onChange={onChange} placeholder="Typ oferty" />
            </div>
        </div>
    );
};

export const PromotionListFilters = form(PromotionListFiltersC);

const filters = (theme: Theme) => css`
    display: flex;
    align-items: center;

    @media (max-width: ${theme.breakpoints.sm}) {
        flex-direction: column;
    }

    @media (max-width: ${theme.breakpoints.md}) {
        width: 100%;
    }
`;

const cityFilter = (theme: Theme) => css`
    width: 100%;
    ${mb(2)};

    @media (min-width: ${theme.breakpoints.sm}) {
        width: 50%;
        ${mb(0)};
        ${mr(3)};
    }

    @media (min-width: ${theme.breakpoints.md}) {
        width: 36rem;
    }
`;

const offerTypeFilter = (theme: Theme) => css`
    width: 100%;

    @media (min-width: ${theme.breakpoints.sm}) {
        width: 50%;
    }
    @media (min-width: ${theme.breakpoints.md}) {
        width: 22rem;
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {mb, mr} from "@pg-design/helpers-css";
import {Select} from "@pg-design/select-module";
import {form, FormFieldType, IFormProps} from "@pg-mono/form";

import {PromotionType, promotionTypes} from "../../../helpers/PromotionType";
import {PromotionListSearchFilter} from "./PromotionListSearchFilter";

export const promotionListFilterFields = {
    type: FormFieldType.Select
};

export interface IPromotionListFilterFields {
    type: PromotionType;
}

interface IPromotionListFilters extends IFormProps<IPromotionListFilterFields> {}

const PromotionListFiltersC = (props: IPromotionListFilters) => {
    const typeSelectProps = props.getFieldProps("type");
    const currentTypeOption = promotionTypes.filter((option) => option.value === props.values.type);

    const onChange = (option: unknown | null) => {
        const optionValue = (option as {value: PromotionType})?.value;
        typeSelectProps.onChange("type", optionValue);
    };

    return (
        <div css={filters}>
            <div css={cityFilter}>
                <PromotionListSearchFilter />
            </div>

            <div css={offerTypeFilter}>
                <Select isClearable value={currentTypeOption} options={promotionTypes} name="type" onChange={onChange} placeholder="Typ oferty" />
            </div>
        </div>
    );
};

export const PromotionListFilters = form(PromotionListFiltersC);

const filters = (theme: Theme) => css`
    display: flex;
    align-items: center;

    @media (max-width: ${theme.breakpoints.sm}) {
        flex-direction: column;
    }

    @media (max-width: ${theme.breakpoints.md}) {
        width: 100%;
    }
`;

const cityFilter = (theme: Theme) => css`
    width: 100%;
    ${mb(2)};

    @media (min-width: ${theme.breakpoints.sm}) {
        width: 50%;
        ${mb(0)};
        ${mr(3)};
    }

    @media (min-width: ${theme.breakpoints.md}) {
        width: 36rem;
    }
`;

const offerTypeFilter = (theme: Theme) => css`
    width: 100%;

    @media (min-width: ${theme.breakpoints.sm}) {
        width: 50%;
    }
    @media (min-width: ${theme.breakpoints.md}) {
        width: 22rem;
    }
`;
