import React from "react";
import {css, Theme} from "@emotion/react";

import {pb, ph, pv} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    children: React.ReactNode;
    image: React.ReactNode;
    header?: React.ReactNode;
    headerTag?: Parameters<typeof Text>[0]["as"];
    text?: React.ReactNode;
}

export const ResponsiveCardBodyWithImage: React.FC<IProps> = ({image, header, headerTag, text, children}) => (
    <div css={wrapperStyle}>
        {image && <div css={imageCol}>{image}</div>}
        <div css={contentStyle}>
            {header && (
                <Text as={headerTag || "h4"} variant="headline_4" css={pb(1)}>
                    {header}
                </Text>
            )}
            {text && <Text variant="body_copy_1">{text}</Text>}
        </div>
        <div css={childrenColStyle}>{children}</div>
    </div>
);

const wrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (min-width: ${theme.breakpoints.md}) {
        align-items: center;
    }
`;

const imageCol = css`
    flex: 0 0 auto;

    /* fix inline-block display's gap */
    font-size: 0;
    line-height: 0;
`;

const contentStyle = css`
    flex: 1 1;
    ${ph(2)};
    ${pv(1)};

    &:after {
        display: block;
        width: 15rem;
        height: 0;
        content: "";
    }
`;

const childrenColStyle = css`
    flex: 0 0 auto;
    align-self: center;
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {pb, ph, pv} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    children: React.ReactNode;
    image: React.ReactNode;
    header?: React.ReactNode;
    headerTag?: Parameters<typeof Text>[0]["as"];
    text?: React.ReactNode;
}

export const ResponsiveCardBodyWithImage: React.FC<IProps> = ({image, header, headerTag, text, children}) => (
    <div css={wrapperStyle}>
        {image && <div css={imageCol}>{image}</div>}
        <div css={contentStyle}>
            {header && (
                <Text as={headerTag || "h4"} variant="headline_4" css={pb(1)}>
                    {header}
                </Text>
            )}
            {text && <Text variant="body_copy_1">{text}</Text>}
        </div>
        <div css={childrenColStyle}>{children}</div>
    </div>
);

const wrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (min-width: ${theme.breakpoints.md}) {
        align-items: center;
    }
`;

const imageCol = css`
    flex: 0 0 auto;

    /* fix inline-block display's gap */
    font-size: 0;
    line-height: 0;
`;

const contentStyle = css`
    flex: 1 1;
    ${ph(2)};
    ${pv(1)};

    &:after {
        display: block;
        width: 15rem;
        height: 0;
        content: "";
    }
`;

const childrenColStyle = css`
    flex: 0 0 auto;
    align-self: center;
`;
