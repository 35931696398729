import React from "react";
import {useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {color, flexAlignCenter, mb, mr} from "@pg-design/helpers-css";
import {WeightIcon} from "@pg-design/icons-module";
import {Highlight, Text} from "@pg-design/text-module";

import {SeeListingButton} from "../SeeListingButton";

export const EmptyPropertyList = () => {
    const lightFontColor = color(useTheme().colors.gray[700]);

    return (
        <div>
            <Text as="h3" variant="mini_header" css={mb(2)}>
                Nieruchomości
            </Text>
            <Text css={mb(4)} variant="headline_4" as="div">
                Nie masz jeszcze żadnych nieruchomości na liście
            </Text>
            <Text variant="headline_6" as="p" css={[lightFontColor, mb(4)]}>
                Porównaj <Highlight strong>12 cech nieruchomości</Highlight> w jednym miejscu. W tym czasy dojazdu i odległość od spersonalizowanych punktów.
                Korzystając z listy i porównywarki możesz zapytać o wiele ofert jednocześnie
            </Text>
            <div css={[flexAlignCenter, mb(4)]}>
                <WeightIcon css={[elevation(4), mr(2)]} size="6" wrapperColor="#fff" wrapperSize="6.4" />
                <Text variant="body_copy_2">
                    Przeglądając oferty użyj <Highlight>tej ikony</Highlight>, aby dodać oferty do listy i zacząć porównywać.
                </Text>
            </div>

            <SeeListingButton>wybierz mieszkania do porównania</SeeListingButton>
        </div>
    );
};
import React from "react";
import {useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {color, flexAlignCenter, mb, mr} from "@pg-design/helpers-css";
import {WeightIcon} from "@pg-design/icons-module";
import {Highlight, Text} from "@pg-design/text-module";

import {SeeListingButton} from "../SeeListingButton";

export const EmptyPropertyList = () => {
    const lightFontColor = color(useTheme().colors.gray[700]);

    return (
        <div>
            <Text as="h3" variant="mini_header" css={mb(2)}>
                Nieruchomości
            </Text>
            <Text css={mb(4)} variant="headline_4" as="div">
                Nie masz jeszcze żadnych nieruchomości na liście
            </Text>
            <Text variant="headline_6" as="p" css={[lightFontColor, mb(4)]}>
                Porównaj <Highlight strong>12 cech nieruchomości</Highlight> w jednym miejscu. W tym czasy dojazdu i odległość od spersonalizowanych punktów.
                Korzystając z listy i porównywarki możesz zapytać o wiele ofert jednocześnie
            </Text>
            <div css={[flexAlignCenter, mb(4)]}>
                <WeightIcon css={[elevation(4), mr(2)]} size="6" wrapperColor="#fff" wrapperSize="6.4" />
                <Text variant="body_copy_2">
                    Przeglądając oferty użyj <Highlight>tej ikony</Highlight>, aby dodać oferty do listy i zacząć porównywać.
                </Text>
            </div>

            <SeeListingButton>wybierz mieszkania do porównania</SeeListingButton>
        </div>
    );
};
