import React from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {Col, Container, Row} from "@pg-design/grid-module";
import {centerText, mb, mh, onDesktop, p, pb, resetTextAlign} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../../common/app/rp_reducer";
import {TestimonialSlider} from "./TestimonialGallery/TestimonialGallery";

// Assets
const domDevelopment = require("../assets/developer_logo/large/domdevelopment-logo 1.png");
const atal = require("../assets/developer_logo/large/ATAL logo 2021 RGB inwersja 1.png");
const homeInvest = require("../assets/developer_logo/large/HOME_INVEST_LOGO 1.png");
const lokumDeveloper = require("../assets/developer_logo/large/Lokum_Deweloper 2.png");
const nexity = require("../assets/developer_logo/large/Nexity 2.png");
const skanska = require("../assets/developer_logo/large/Skanska 2.png");
const spravia = require("../assets/developer_logo/large/Spravia 2.png");
const vinci = require("../assets/developer_logo/large/Vinci_logo_white 1.png");

const domDevelopmentSM = require("../assets/developer_logo/large/domdevelopment-logo 1.png");
const atalSM = require("../assets/developer_logo/large/ATAL logo 2021 RGB inwersja 1.png");
const homeInvestSM = require("../assets/developer_logo/large/HOME_INVEST_LOGO 1.png");
const lokumDeveloperSM = require("../assets/developer_logo/large/Lokum_Deweloper 2.png");
const nexitySM = require("../assets/developer_logo/large/Nexity 2.png");
const skanskaSM = require("../assets/developer_logo/large/Skanska 2.png");
const spraviaSM = require("../assets/developer_logo/large/Spravia 2.png");
const vinciSM = require("../assets/developer_logo/large/Vinci_logo_white 1.png");

export const TestimonialSection = () => {
    const list = useSelector((store: IRPStore) => store.ourOffer.testimonials.list);

    return (
        <section css={section}>
            <div css={uxContainer}>
                <Container>
                    <Row>
                        <Col sm={4} md={6} css={[pb(8), onDesktop(pb(0))]}>
                            <Text as="h2" variant="headline_1" css={[mb(5), centerText, onDesktop(mb(7), resetTextAlign)]}>
                                Zaufało nam ponad 1000 firm deweloperskich
                            </Text>

                            <TestimonialSlider testimonials={list} />
                        </Col>
                        <Col sm={4} md={6}>
                            <Container>
                                <Row css={logoRow}>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: domDevelopmentSM, width: 70, height: 61},
                                                {minWidthPX: 768, src: domDevelopment, width: 95, height: 83}
                                            ]}
                                            alt="dom dewelopment logo"
                                        />
                                    </Col>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: vinciSM, width: 129, height: 47},
                                                {minWidthPX: 768, src: vinci, width: 175, height: 63}
                                            ]}
                                            alt="vinci logo"
                                        />
                                    </Col>
                                </Row>
                                <Row css={logoRow}>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: homeInvestSM, width: 129, height: 49},
                                                {minWidthPX: 768, src: homeInvest, width: 175, height: 67}
                                            ]}
                                            alt="home invest logo"
                                        />
                                    </Col>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: atalSM, width: 70, height: 70},
                                                {minWidthPX: 768, src: atal, width: 95, height: 95}
                                            ]}
                                            alt="atal logo"
                                        />
                                    </Col>
                                </Row>
                                <Row css={logoRow}>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: lokumDeveloperSM, width: 116, height: 33},
                                                {minWidthPX: 768, src: lokumDeveloper, width: 157, height: 45}
                                            ]}
                                            alt="lokum deweloper logo"
                                        />
                                    </Col>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: nexitySM, width: 70, height: 70},
                                                {minWidthPX: 768, src: nexity, width: 95, height: 95}
                                            ]}
                                            alt="nexity logo"
                                        />
                                    </Col>
                                </Row>
                                <Row css={logoRow}>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: skanskaSM, width: 129, height: 20},
                                                {minWidthPX: 768, src: skanska, width: 175, height: 26}
                                            ]}
                                            alt="skanska logo"
                                        />
                                    </Col>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: spraviaSM, width: 129, height: 20},
                                                {minWidthPX: 768, src: spravia, width: 175, height: 26}
                                            ]}
                                            alt="spravia logo"
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const logoRow = css`
    flex-wrap: nowrap;

    & img {
        max-width: 100%;
    }
`;

const section = (theme: Theme) => css`
    background-color: ${theme.colors.secondary};
    color: #fff;
    ${p(4, 1)};

    ${onDesktop(p(8, 4, 0, 4))}
`;

const devLogo = (theme: Theme) => css`
    text-align: center;
    ${mb(4)};

    & img {
        margin: 0 auto;
    }

    @media (max-width: ${theme.breakpoints.md}) {
        max-width: 130px;
        ${mh("auto")}
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        ${mb(8)}
    }

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(13)}
    }
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")};
`;
import React from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {Col, Container, Row} from "@pg-design/grid-module";
import {centerText, mb, mh, onDesktop, p, pb, resetTextAlign} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../../common/app/rp_reducer";
import {TestimonialSlider} from "./TestimonialGallery/TestimonialGallery";

// Assets
const domDevelopment = require("../assets/developer_logo/large/domdevelopment-logo 1.png");
const atal = require("../assets/developer_logo/large/ATAL logo 2021 RGB inwersja 1.png");
const homeInvest = require("../assets/developer_logo/large/HOME_INVEST_LOGO 1.png");
const lokumDeveloper = require("../assets/developer_logo/large/Lokum_Deweloper 2.png");
const nexity = require("../assets/developer_logo/large/Nexity 2.png");
const skanska = require("../assets/developer_logo/large/Skanska 2.png");
const spravia = require("../assets/developer_logo/large/Spravia 2.png");
const vinci = require("../assets/developer_logo/large/Vinci_logo_white 1.png");

const domDevelopmentSM = require("../assets/developer_logo/large/domdevelopment-logo 1.png");
const atalSM = require("../assets/developer_logo/large/ATAL logo 2021 RGB inwersja 1.png");
const homeInvestSM = require("../assets/developer_logo/large/HOME_INVEST_LOGO 1.png");
const lokumDeveloperSM = require("../assets/developer_logo/large/Lokum_Deweloper 2.png");
const nexitySM = require("../assets/developer_logo/large/Nexity 2.png");
const skanskaSM = require("../assets/developer_logo/large/Skanska 2.png");
const spraviaSM = require("../assets/developer_logo/large/Spravia 2.png");
const vinciSM = require("../assets/developer_logo/large/Vinci_logo_white 1.png");

export const TestimonialSection = () => {
    const list = useSelector((store: IRPStore) => store.ourOffer.testimonials.list);

    return (
        <section css={section}>
            <div css={uxContainer}>
                <Container>
                    <Row>
                        <Col sm={4} md={6} css={[pb(8), onDesktop(pb(0))]}>
                            <Text as="h2" variant="headline_1" css={[mb(5), centerText, onDesktop(mb(7), resetTextAlign)]}>
                                Zaufało nam ponad 1000 firm deweloperskich
                            </Text>

                            <TestimonialSlider testimonials={list} />
                        </Col>
                        <Col sm={4} md={6}>
                            <Container>
                                <Row css={logoRow}>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: domDevelopmentSM, width: 70, height: 61},
                                                {minWidthPX: 768, src: domDevelopment, width: 95, height: 83}
                                            ]}
                                            alt="dom dewelopment logo"
                                        />
                                    </Col>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: vinciSM, width: 129, height: 47},
                                                {minWidthPX: 768, src: vinci, width: 175, height: 63}
                                            ]}
                                            alt="vinci logo"
                                        />
                                    </Col>
                                </Row>
                                <Row css={logoRow}>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: homeInvestSM, width: 129, height: 49},
                                                {minWidthPX: 768, src: homeInvest, width: 175, height: 67}
                                            ]}
                                            alt="home invest logo"
                                        />
                                    </Col>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: atalSM, width: 70, height: 70},
                                                {minWidthPX: 768, src: atal, width: 95, height: 95}
                                            ]}
                                            alt="atal logo"
                                        />
                                    </Col>
                                </Row>
                                <Row css={logoRow}>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: lokumDeveloperSM, width: 116, height: 33},
                                                {minWidthPX: 768, src: lokumDeveloper, width: 157, height: 45}
                                            ]}
                                            alt="lokum deweloper logo"
                                        />
                                    </Col>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: nexitySM, width: 70, height: 70},
                                                {minWidthPX: 768, src: nexity, width: 95, height: 95}
                                            ]}
                                            alt="nexity logo"
                                        />
                                    </Col>
                                </Row>
                                <Row css={logoRow}>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: skanskaSM, width: 129, height: 20},
                                                {minWidthPX: 768, src: skanska, width: 175, height: 26}
                                            ]}
                                            alt="skanska logo"
                                        />
                                    </Col>
                                    <Col sm={2} css={devLogo}>
                                        <Picture
                                            sources={[
                                                {minWidthPX: 0, src: spraviaSM, width: 129, height: 20},
                                                {minWidthPX: 768, src: spravia, width: 175, height: 26}
                                            ]}
                                            alt="spravia logo"
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const logoRow = css`
    flex-wrap: nowrap;

    & img {
        max-width: 100%;
    }
`;

const section = (theme: Theme) => css`
    background-color: ${theme.colors.secondary};
    color: #fff;
    ${p(4, 1)};

    ${onDesktop(p(8, 4, 0, 4))}
`;

const devLogo = (theme: Theme) => css`
    text-align: center;
    ${mb(4)};

    & img {
        margin: 0 auto;
    }

    @media (max-width: ${theme.breakpoints.md}) {
        max-width: 130px;
        ${mh("auto")}
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        ${mb(8)}
    }

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(13)}
    }
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")};
`;
