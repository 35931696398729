import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button-module";
import {elevation} from "@pg-design/elevation";
import {Col, Container, Row} from "@pg-design/grid-module";
import {borderRadius, mb, mh, onDesktop, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {academyRealEstateLink, developerAndMarketingLink} from "../../navigation/constants/links";

export const KnowledgeSection = () => {
    return (
        <section css={section}>
            <div css={uxContainer}>
                <Text as="h2" mb="3.2rem" variant="headline_1" align="center">
                    Wiedza, która polepszy Twoje wyniki
                </Text>
                <Container>
                    <Row css={cardsRow}>
                        <Col sm={4} md={6} css={[mb(2), onDesktop(mb(0))]}>
                            <DataCard color="info">
                                <Text mb="3.2rem" as="h4" variant="headline_2" align="center">
                                    Akademia <br /> Real Estate
                                </Text>
                                <Text css={[mb(3), onDesktop(mb(5))]} variant="body_copy_1" align="center">
                                    Platforma edukacyjna oferująca webinary, szkolenia, raporty <br /> i porady dla deweloperów.
                                </Text>
                                <Button as="a" href={academyRealEstateLink} target="_blank" size="small" variant="filled_primary">
                                    zobacz więcej
                                </Button>
                            </DataCard>
                        </Col>
                        <Col sm={4} md={6}>
                            <DataCard color="warning">
                                <Text mb="3.2rem" as="h4" variant="headline_2" align="center">
                                    Deweloper <br />& Marketing
                                </Text>
                                <Text css={[mb(3), onDesktop(mb(5))]} variant="body_copy_1" align="center">
                                    Kwartalnik branżowy pełen wywiadów z ekspertami, analiz i insightów z rynku pierwotnego.
                                </Text>
                                <Button as="a" href={developerAndMarketingLink} target="_blank" size="small" variant="filled_primary">
                                    zobacz więcej
                                </Button>
                            </DataCard>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const section = css`
    background-color: #fff;
    ${p(4, 1)};

    ${onDesktop(p(5, 4))};
`;

const cardsRow = css`
    align-items: stretch;
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;

const DataCard = styled.div<{color: "info" | "warning"}>`
    text-align: center;
    background-color: ${(props) => props.theme.colors[props.color]};
    height: 100%;
    ${elevation(2)};
    ${borderRadius(2)};
    ${p(3, 3, 4, 3)};
`;
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button-module";
import {elevation} from "@pg-design/elevation";
import {Col, Container, Row} from "@pg-design/grid-module";
import {borderRadius, mb, mh, onDesktop, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {academyRealEstateLink, developerAndMarketingLink} from "../../navigation/constants/links";

export const KnowledgeSection = () => {
    return (
        <section css={section}>
            <div css={uxContainer}>
                <Text as="h2" mb="3.2rem" variant="headline_1" align="center">
                    Wiedza, która polepszy Twoje wyniki
                </Text>
                <Container>
                    <Row css={cardsRow}>
                        <Col sm={4} md={6} css={[mb(2), onDesktop(mb(0))]}>
                            <DataCard color="info">
                                <Text mb="3.2rem" as="h4" variant="headline_2" align="center">
                                    Akademia <br /> Real Estate
                                </Text>
                                <Text css={[mb(3), onDesktop(mb(5))]} variant="body_copy_1" align="center">
                                    Platforma edukacyjna oferująca webinary, szkolenia, raporty <br /> i porady dla deweloperów.
                                </Text>
                                <Button as="a" href={academyRealEstateLink} target="_blank" size="small" variant="filled_primary">
                                    zobacz więcej
                                </Button>
                            </DataCard>
                        </Col>
                        <Col sm={4} md={6}>
                            <DataCard color="warning">
                                <Text mb="3.2rem" as="h4" variant="headline_2" align="center">
                                    Deweloper <br />& Marketing
                                </Text>
                                <Text css={[mb(3), onDesktop(mb(5))]} variant="body_copy_1" align="center">
                                    Kwartalnik branżowy pełen wywiadów z ekspertami, analiz i insightów z rynku pierwotnego.
                                </Text>
                                <Button as="a" href={developerAndMarketingLink} target="_blank" size="small" variant="filled_primary">
                                    zobacz więcej
                                </Button>
                            </DataCard>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const section = css`
    background-color: #fff;
    ${p(4, 1)};

    ${onDesktop(p(5, 4))};
`;

const cardsRow = css`
    align-items: stretch;
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;

const DataCard = styled.div<{color: "info" | "warning"}>`
    text-align: center;
    background-color: ${(props) => props.theme.colors[props.color]};
    height: 100%;
    ${elevation(2)};
    ${borderRadius(2)};
    ${p(3, 3, 4, 3)};
`;
