import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {css, Theme} from "@emotion/react";

import {elevation} from "@pg-design/elevation/";
import {borderRadius, m, onDesktop, p} from "@pg-design/helpers-css/";
import {Text} from "@pg-design/text-module";

import {FavouritesApplication} from "../../../favourite/components/FavouritesApplication/FavouritesApplication";
import {IFavouritesConfirmationList} from "../../../favourite/components/FavouritesConfirmationList/favourites_confirmation_list.types";
import {gtmFavouritesMultileadSendLead} from "../../../favourite/tracking/gtm_event_favourites_multilead";
import {handleFavouritesMultiApplicationHit} from "../../../tracking/algolytics_hits/favourites_list_hit";
import {fetchMyOffersAppliedList} from "../../actions/fetch_my_offers_applied_list";
import {useMyOffersContext} from "../../contexts/MyOffersContext";
import {FavouritesConfirmationModal} from "../FavouritesConfirmationModal";

export const FavouritesListApplication = () => {
    const dispatch = useDispatch();
    const {setRemoveButtonsDisabled, setSelectedNavigationItemValue} = useMyOffersContext();
    const [confirmationModalData, setConfirmationModalData] = useState<null | IFavouritesConfirmationList>(null);

    return (
        <>
            <div css={applicationWrap}>
                <Text variant="headline_5" as="h5" css={[m(0, 0, 3, 0)]}>
                    Zapytaj o oferty
                </Text>
                <FavouritesApplication
                    storeHash="my_list_application"
                    onSubmitStart={() => {
                        setRemoveButtonsDisabled(true);
                    }}
                    onSubmitSuccess={(applicationData, sentOffers, rejectedOffers, sentProperties, rejectedProperties) => {
                        handleFavouritesMultiApplicationHit(applicationData);
                        gtmFavouritesMultileadSendLead();

                        dispatch(fetchMyOffersAppliedList());

                        setConfirmationModalData({
                            sentOffers,
                            rejectedOffers,
                            sentProperties,
                            rejectedProperties
                        });
                    }}
                    onSubmitFinish={() => {
                        setRemoveButtonsDisabled(false);
                    }}
                />
            </div>
            {confirmationModalData && (
                <FavouritesConfirmationModal
                    applicationsList={confirmationModalData}
                    isConfirmationModalOpen={Boolean(confirmationModalData)}
                    closeModal={() => {
                        setConfirmationModalData(null);
                    }}
                    setSelectedNavigationItemValue={setSelectedNavigationItemValue}
                />
            )}
        </>
    );
};

const applicationWrap = (theme: Theme) => css`
    background: ${theme.colors.background["100"]};
    ${p(2, 1.5, 4, 1.5)};

    ${onDesktop(css`
        overflow: hidden;
        ${p(3, 2, 4, 2)}
        ${m(12, 0, 0, 0)}
        ${borderRadius(1)}
        ${elevation(4)}
    `)}
`;
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {css, Theme} from "@emotion/react";

import {elevation} from "@pg-design/elevation/";
import {borderRadius, m, onDesktop, p} from "@pg-design/helpers-css/";
import {Text} from "@pg-design/text-module";

import {FavouritesApplication} from "../../../favourite/components/FavouritesApplication/FavouritesApplication";
import {IFavouritesConfirmationList} from "../../../favourite/components/FavouritesConfirmationList/favourites_confirmation_list.types";
import {gtmFavouritesMultileadSendLead} from "../../../favourite/tracking/gtm_event_favourites_multilead";
import {handleFavouritesMultiApplicationHit} from "../../../tracking/algolytics_hits/favourites_list_hit";
import {fetchMyOffersAppliedList} from "../../actions/fetch_my_offers_applied_list";
import {useMyOffersContext} from "../../contexts/MyOffersContext";
import {FavouritesConfirmationModal} from "../FavouritesConfirmationModal";

export const FavouritesListApplication = () => {
    const dispatch = useDispatch();
    const {setRemoveButtonsDisabled, setSelectedNavigationItemValue} = useMyOffersContext();
    const [confirmationModalData, setConfirmationModalData] = useState<null | IFavouritesConfirmationList>(null);

    return (
        <>
            <div css={applicationWrap}>
                <Text variant="headline_5" as="h5" css={[m(0, 0, 3, 0)]}>
                    Zapytaj o oferty
                </Text>
                <FavouritesApplication
                    storeHash="my_list_application"
                    onSubmitStart={() => {
                        setRemoveButtonsDisabled(true);
                    }}
                    onSubmitSuccess={(applicationData, sentOffers, rejectedOffers, sentProperties, rejectedProperties) => {
                        handleFavouritesMultiApplicationHit(applicationData);
                        gtmFavouritesMultileadSendLead();

                        dispatch(fetchMyOffersAppliedList());

                        setConfirmationModalData({
                            sentOffers,
                            rejectedOffers,
                            sentProperties,
                            rejectedProperties
                        });
                    }}
                    onSubmitFinish={() => {
                        setRemoveButtonsDisabled(false);
                    }}
                />
            </div>
            {confirmationModalData && (
                <FavouritesConfirmationModal
                    applicationsList={confirmationModalData}
                    isConfirmationModalOpen={Boolean(confirmationModalData)}
                    closeModal={() => {
                        setConfirmationModalData(null);
                    }}
                    setSelectedNavigationItemValue={setSelectedNavigationItemValue}
                />
            )}
        </>
    );
};

const applicationWrap = (theme: Theme) => css`
    background: ${theme.colors.background["100"]};
    ${p(2, 1.5, 4, 1.5)};

    ${onDesktop(css`
        overflow: hidden;
        ${p(3, 2, 4, 2)}
        ${m(12, 0, 0, 0)}
        ${borderRadius(1)}
        ${elevation(4)}
    `)}
`;
