import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const MegaphoneIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.42 6.043a1.378 1.378 0 0 0-.563-2.623l-.39-1.785L6.331 1l-.463.456L2.71 4.58l-1.175.254L1 4.95l.151.698.216 1.003.72.727L3.309 8.61 3.695 9l.387-.385.39-.386.39-.385-.385-.39-.366-.371 3.242 1.239.616.234-.137-.64-.411-1.873Zm.219-1.42a.83.83 0 0 1-.34.858L6.976 3.98a.831.831 0 0 1 .664.643Zm-3.94 3.602-1.83-1.85-.218-1.006 1.265-.273.065-.015.277-.06.332 1.536-.268-.272-.39.386L4.09 7.84l-.39.385Zm.441-1.717-.346-1.604-.108-.513 2.32-2.3.308 1.428.12.55.336 1.555.12.55.325 1.505-3.075-1.17Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const MegaphoneIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.42 6.043a1.378 1.378 0 0 0-.563-2.623l-.39-1.785L6.331 1l-.463.456L2.71 4.58l-1.175.254L1 4.95l.151.698.216 1.003.72.727L3.309 8.61 3.695 9l.387-.385.39-.386.39-.385-.385-.39-.366-.371 3.242 1.239.616.234-.137-.64-.411-1.873Zm.219-1.42a.83.83 0 0 1-.34.858L6.976 3.98a.831.831 0 0 1 .664.643Zm-3.94 3.602-1.83-1.85-.218-1.006 1.265-.273.065-.015.277-.06.332 1.536-.268-.272-.39.386L4.09 7.84l-.39.385Zm.441-1.717-.346-1.604-.108-.513 2.32-2.3.308 1.428.12.55.336 1.555.12.55.325 1.505-3.075-1.17Z" />
        </SvgIcon>
    );
};
