export enum MyFavouritesListNavigationItemValue {
    LIST,
    APPLICATIONS
}

enum MyFavouritesListNavigationItemLabel {
    LIST = "Twoja lista",
    APPLICATIONS = "Twoje zapytania"
}

export const myFavouritesListNavigationOptions = [
    {
        value: MyFavouritesListNavigationItemValue.LIST,
        label: MyFavouritesListNavigationItemLabel.LIST
    },
    {
        value: MyFavouritesListNavigationItemValue.APPLICATIONS,
        label: MyFavouritesListNavigationItemLabel.APPLICATIONS
    }
] as const;
export enum MyFavouritesListNavigationItemValue {
    LIST,
    APPLICATIONS
}

enum MyFavouritesListNavigationItemLabel {
    LIST = "Twoja lista",
    APPLICATIONS = "Twoje zapytania"
}

export const myFavouritesListNavigationOptions = [
    {
        value: MyFavouritesListNavigationItemValue.LIST,
        label: MyFavouritesListNavigationItemLabel.LIST
    },
    {
        value: MyFavouritesListNavigationItemValue.APPLICATIONS,
        label: MyFavouritesListNavigationItemLabel.APPLICATIONS
    }
] as const;
