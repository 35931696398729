import React from "react";
import {useSelector} from "react-redux";

import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../../app/rp_reducer";
import {createOfferLink} from "../../../offer/helpers/create_offer_link";
import {useMyOffersContext} from "../../contexts/MyOffersContext";
import {FavouriteListWrapper} from "../FavouriteListWrapper";
import {FavouritesListItem} from "../FavouritesList/FavouritesListItem";

interface IProps {
    className?: string;
}

export const FavouriteOfferSection = (props: IProps) => {
    const favouriteOffers = useSelector((store: IRPStore) => store.myOffers.favourites.offers.list);
    const {removeButtonsDisabled} = useMyOffersContext();

    return (
        <div css={props.className}>
            <Text as="h3" variant="mini_header" css={mb(2)}>
                Inwestycje
            </Text>

            <div>
                <FavouriteListWrapper>
                    {favouriteOffers.map((offer) => (
                        <FavouritesListItem
                            key={offer.id}
                            id={offer.id}
                            itemUrl={createOfferLink(offer)}
                            disableRemoveButton={removeButtonsDisabled}
                            type="offer"
                            imageSrc={offer.main_image?.m_img_375x211}
                            header={offer.name}
                            address={`${offer.address}`}
                            rodoData={{
                                vendorName: offer.vendor.name,
                                personalDataProcessorName: offer.configuration.personal_data_processor_name ?? "",
                                personalDataProcessorUrl: offer.configuration.personal_data_processor_url ?? "",
                                privacyPolicyUrl: offer.configuration.privacy_policy_url ?? ""
                            }}
                            roomsData={{
                                propertiesForSale: offer.stats?.properties_count_for_sale,
                                roomsRange: offer.stats?.rooms
                            }}
                        />
                    ))}
                </FavouriteListWrapper>
            </div>
        </div>
    );
};
import React from "react";
import {useSelector} from "react-redux";

import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../../app/rp_reducer";
import {createOfferLink} from "../../../offer/helpers/create_offer_link";
import {useMyOffersContext} from "../../contexts/MyOffersContext";
import {FavouriteListWrapper} from "../FavouriteListWrapper";
import {FavouritesListItem} from "../FavouritesList/FavouritesListItem";

interface IProps {
    className?: string;
}

export const FavouriteOfferSection = (props: IProps) => {
    const favouriteOffers = useSelector((store: IRPStore) => store.myOffers.favourites.offers.list);
    const {removeButtonsDisabled} = useMyOffersContext();

    return (
        <div css={props.className}>
            <Text as="h3" variant="mini_header" css={mb(2)}>
                Inwestycje
            </Text>

            <div>
                <FavouriteListWrapper>
                    {favouriteOffers.map((offer) => (
                        <FavouritesListItem
                            key={offer.id}
                            id={offer.id}
                            itemUrl={createOfferLink(offer)}
                            disableRemoveButton={removeButtonsDisabled}
                            type="offer"
                            imageSrc={offer.main_image?.m_img_375x211}
                            header={offer.name}
                            address={`${offer.address}`}
                            rodoData={{
                                vendorName: offer.vendor.name,
                                personalDataProcessorName: offer.configuration.personal_data_processor_name ?? "",
                                personalDataProcessorUrl: offer.configuration.personal_data_processor_url ?? "",
                                privacyPolicyUrl: offer.configuration.privacy_policy_url ?? ""
                            }}
                            roomsData={{
                                propertiesForSale: offer.stats?.properties_count_for_sale,
                                roomsRange: offer.stats?.rooms
                            }}
                        />
                    ))}
                </FavouriteListWrapper>
            </div>
        </div>
    );
};
