import React from "react";
import {css, Theme} from "@emotion/react";

import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";

export interface IFavouritesListItemRodoProps {
    vendorName: string;
    personalDataProcessorName: string;
    personalDataProcessorUrl: string;
    privacyPolicyUrl: string;
    size?: "small" | "mid";
    className?: string;
}

export const FavouritesListItemRodo = (props: IFavouritesListItemRodoProps) => {
    const {vendorName, personalDataProcessorName, personalDataProcessorUrl, privacyPolicyUrl, size, className} = props;
    const privacyPolicyLink = personalDataProcessorUrl || privacyPolicyUrl;
    const showOfferPersonalDataProcessor = !!personalDataProcessorName && !!personalDataProcessorUrl;
    const responsiveLinkTarget = useResponsiveLinkTarget();

    return (
        <Text as="span" variant={size && size === "small" ? "info_txt_2" : "info_txt_1"} className={className} css={rodoTextStyle}>
            Administratorem danych jest {vendorName}.
            {privacyPolicyUrl && !showOfferPersonalDataProcessor && (
                <>
                    {" "}
                    Dowiedz się więcej o{" "}
                    <span>
                        <Link size={size && size === "small" ? "x-small" : "small"} href={privacyPolicyLink} target={responsiveLinkTarget}>
                            przetwarzaniu Twoich danych osobowych.
                        </Link>
                    </span>
                </>
            )}
        </Text>
    );
};

const rodoTextStyle = (theme: Theme) => css`
    color: ${theme.colors.gray["700"]};
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";

export interface IFavouritesListItemRodoProps {
    vendorName: string;
    personalDataProcessorName: string;
    personalDataProcessorUrl: string;
    privacyPolicyUrl: string;
    size?: "small" | "mid";
    className?: string;
}

export const FavouritesListItemRodo = (props: IFavouritesListItemRodoProps) => {
    const {vendorName, personalDataProcessorName, personalDataProcessorUrl, privacyPolicyUrl, size, className} = props;
    const privacyPolicyLink = personalDataProcessorUrl || privacyPolicyUrl;
    const showOfferPersonalDataProcessor = !!personalDataProcessorName && !!personalDataProcessorUrl;
    const responsiveLinkTarget = useResponsiveLinkTarget();

    return (
        <Text as="span" variant={size && size === "small" ? "info_txt_2" : "info_txt_1"} className={className} css={rodoTextStyle}>
            Administratorem danych jest {vendorName}.
            {privacyPolicyUrl && !showOfferPersonalDataProcessor && (
                <>
                    {" "}
                    Dowiedz się więcej o{" "}
                    <span>
                        <Link size={size && size === "small" ? "x-small" : "small"} href={privacyPolicyLink} target={responsiveLinkTarget}>
                            przetwarzaniu Twoich danych osobowych.
                        </Link>
                    </span>
                </>
            )}
        </Text>
    );
};

const rodoTextStyle = (theme: Theme) => css`
    color: ${theme.colors.gray["700"]};
`;
