import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {backgroundColor, centerText, display, mb, onDesktop, p, pb, pr, resetTextAlign, resetWidth, w100} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

import {WORKERS_COUNT, YEARS_OF_EXPERIENCE} from "../../app/constants/company";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {propertyGroupLink} from "../../navigation/constants/links";

const propertyLogo = require("../assets/property_logo.png");
const forbesLogo = require("../assets/forbes_logo.png");

const ItDepartmentXL = require("../assets/it_department/it_dept_1340x882.png");
const ItDepartmentLG = require("../assets/it_department/it_dept_948x768.png");
const ItDepartmentMD = require("../assets/it_department/it_dept_757x1001.png");
const ItDepartmentXS = require("../assets/it_department/it_dept_362x479.png");

export const OurTeamSection = () => {
    const theme = useTheme();

    const peoplePluralize = pluralize(["osoba", "osoby", "osób"]);
    const yearsPluralize = pluralize(["rok", "lata", "lat"]);

    return (
        <section css={[backgroundColor(theme.colors.primary), onDesktop(display("flex"))]}>
            <div css={textColumn}>
                <Image css={mb(4)} src={propertyLogo} alt="property group logo" width="197px" height="45px" />
                <Text css={[centerText, onDesktop(resetTextAlign)]} as="h2" mb="3.2rem" variant="headline_1">
                    {WORKERS_COUNT} {peoplePluralize(WORKERS_COUNT)} w zespole,
                    <br />
                    {YEARS_OF_EXPERIENCE} {yearsPluralize(YEARS_OF_EXPERIENCE)} doświadczenia
                </Text>
                <Text css={[pb(4), pr(4), centerText, onDesktop(resetTextAlign)]} variant="body_copy_0">
                    {concatWithBrandName("")} to część Property Group. Jesteśmy doświadczonym zespołem ekspertów, którzy od {YEARS_OF_EXPERIENCE} lat łączą
                    pasję do technologii, znajomość rynku nieruchomości i umiejętności marketingowe, by dostarczać naszym Klientom skutecznych rozwiązań
                    biznesowych.
                </Text>

                <Button css={[w100, onDesktop(resetWidth)]} as="a" href={propertyGroupLink} target="_blank" variant="outlined_secondary" size="small">
                    Zobacz więcej
                </Button>
            </div>

            <div css={imageColumn}>
                <Image css={forbesLogoPosition} src={forbesLogo} alt="forbes logo" width="168px" height="168px" />

                <Picture
                    fit="top center"
                    sources={[
                        {minWidthPX: 0, src: ItDepartmentXS, height: 479, width: 362},
                        {minWidthPX: 362, src: ItDepartmentMD, height: 664, width: 763},
                        {minWidthPX: 763, src: ItDepartmentLG, height: 768, width: 948},
                        {minWidthPX: 948, src: ItDepartmentXL, height: 882, width: 1340}
                    ]}
                    alt="zespół IT"
                />
            </div>
        </section>
    );
};

const textColumn = css`
    ${p(8, 2, 13, 2)};
    text-align: center;

    ${onDesktop(css`
        width: 80rem;
        margin-left: auto;
        max-width: calc(50% - 3.2rem);
        text-align: left;
        ${p(8, 2, 0, 2)};
    `)}
`;

const imageColumn = (theme: Theme) => css`
    position: relative;
    height: 47.9rem;

    @media (min-width: ${theme.breakpoints.md}) {
        width: 50%;
        height: 76.8rem;
    }
`;

const forbesLogoPosition = css`
    position: absolute;

    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    ${onDesktop(css`
        top: unset;
        left: 0;
        transform: translateX(-50%);
        bottom: 5.5rem;
    `)}
`;
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {backgroundColor, centerText, display, mb, onDesktop, p, pb, pr, resetTextAlign, resetWidth, w100} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

import {WORKERS_COUNT, YEARS_OF_EXPERIENCE} from "../../app/constants/company";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {propertyGroupLink} from "../../navigation/constants/links";

const propertyLogo = require("../assets/property_logo.png");
const forbesLogo = require("../assets/forbes_logo.png");

const ItDepartmentXL = require("../assets/it_department/it_dept_1340x882.png");
const ItDepartmentLG = require("../assets/it_department/it_dept_948x768.png");
const ItDepartmentMD = require("../assets/it_department/it_dept_757x1001.png");
const ItDepartmentXS = require("../assets/it_department/it_dept_362x479.png");

export const OurTeamSection = () => {
    const theme = useTheme();

    const peoplePluralize = pluralize(["osoba", "osoby", "osób"]);
    const yearsPluralize = pluralize(["rok", "lata", "lat"]);

    return (
        <section css={[backgroundColor(theme.colors.primary), onDesktop(display("flex"))]}>
            <div css={textColumn}>
                <Image css={mb(4)} src={propertyLogo} alt="property group logo" width="197px" height="45px" />
                <Text css={[centerText, onDesktop(resetTextAlign)]} as="h2" mb="3.2rem" variant="headline_1">
                    {WORKERS_COUNT} {peoplePluralize(WORKERS_COUNT)} w zespole,
                    <br />
                    {YEARS_OF_EXPERIENCE} {yearsPluralize(YEARS_OF_EXPERIENCE)} doświadczenia
                </Text>
                <Text css={[pb(4), pr(4), centerText, onDesktop(resetTextAlign)]} variant="body_copy_0">
                    {concatWithBrandName("")} to część Property Group. Jesteśmy doświadczonym zespołem ekspertów, którzy od {YEARS_OF_EXPERIENCE} lat łączą
                    pasję do technologii, znajomość rynku nieruchomości i umiejętności marketingowe, by dostarczać naszym Klientom skutecznych rozwiązań
                    biznesowych.
                </Text>

                <Button css={[w100, onDesktop(resetWidth)]} as="a" href={propertyGroupLink} target="_blank" variant="outlined_secondary" size="small">
                    Zobacz więcej
                </Button>
            </div>

            <div css={imageColumn}>
                <Image css={forbesLogoPosition} src={forbesLogo} alt="forbes logo" width="168px" height="168px" />

                <Picture
                    fit="top center"
                    sources={[
                        {minWidthPX: 0, src: ItDepartmentXS, height: 479, width: 362},
                        {minWidthPX: 362, src: ItDepartmentMD, height: 664, width: 763},
                        {minWidthPX: 763, src: ItDepartmentLG, height: 768, width: 948},
                        {minWidthPX: 948, src: ItDepartmentXL, height: 882, width: 1340}
                    ]}
                    alt="zespół IT"
                />
            </div>
        </section>
    );
};

const textColumn = css`
    ${p(8, 2, 13, 2)};
    text-align: center;

    ${onDesktop(css`
        width: 80rem;
        margin-left: auto;
        max-width: calc(50% - 3.2rem);
        text-align: left;
        ${p(8, 2, 0, 2)};
    `)}
`;

const imageColumn = (theme: Theme) => css`
    position: relative;
    height: 47.9rem;

    @media (min-width: ${theme.breakpoints.md}) {
        width: 50%;
        height: 76.8rem;
    }
`;

const forbesLogoPosition = css`
    position: absolute;

    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    ${onDesktop(css`
        top: unset;
        left: 0;
        transform: translateX(-50%);
        bottom: 5.5rem;
    `)}
`;
