import React from "react";

import {Button} from "@pg-design/button-module";
import {BrandWeightIcon} from "@pg-design/icons-module";
import {rpAppLink} from "@pg-mono/rp-routes";

import {useFavourites} from "../../favourite/hooks/use_favourites";
import {useResponsiveLinkTarget} from "../../hooks/use_responsive_link_target";

interface IProps {
    className?: string;
}

export const GoToComparisonToolButton = (props: IProps) => {
    const {className} = props;
    const {favouritePropertiesIds} = useFavourites();
    const linkTarget = useResponsiveLinkTarget();

    return (
        <Button variant="filled_primary" iconLeft={BrandWeightIcon} size="small" href={rpAppLink.comparisonTool()} target={linkTarget} className={className}>
            Porównaj Oferty ({favouritePropertiesIds.length})
        </Button>
    );
};
import React from "react";

import {Button} from "@pg-design/button-module";
import {BrandWeightIcon} from "@pg-design/icons-module";
import {rpAppLink} from "@pg-mono/rp-routes";

import {useFavourites} from "../../favourite/hooks/use_favourites";
import {useResponsiveLinkTarget} from "../../hooks/use_responsive_link_target";

interface IProps {
    className?: string;
}

export const GoToComparisonToolButton = (props: IProps) => {
    const {className} = props;
    const {favouritePropertiesIds} = useFavourites();
    const linkTarget = useResponsiveLinkTarget();

    return (
        <Button variant="filled_primary" iconLeft={BrandWeightIcon} size="small" href={rpAppLink.comparisonTool()} target={linkTarget} className={className}>
            Porównaj Oferty ({favouritePropertiesIds.length})
        </Button>
    );
};
