import {apiPath} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPostOurOfferPayload} from "../types/IPostOurOfferPayload";

interface IOurOffer {
    name: string;
    email: string;
    company: string;
    phone: string;
    text: string;
    variant: number;
}

export const postOurOfferApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        postOurOffer: build.mutation<IOurOffer, IPostOurOfferPayload>({
            query: (body) => ({
                url: apiPath.our_offer,
                method: "POST",
                body
            })
        })
    })
});

export const {postOurOffer} = postOurOfferApi.endpoints;
export const {usePostOurOfferMutation} = postOurOfferApi;
import {apiPath} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPostOurOfferPayload} from "../types/IPostOurOfferPayload";

interface IOurOffer {
    name: string;
    email: string;
    company: string;
    phone: string;
    text: string;
    variant: number;
}

export const postOurOfferApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        postOurOffer: build.mutation<IOurOffer, IPostOurOfferPayload>({
            query: (body) => ({
                url: apiPath.our_offer,
                method: "POST",
                body
            })
        })
    })
});

export const {postOurOffer} = postOurOfferApi.endpoints;
export const {usePostOurOfferMutation} = postOurOfferApi;
