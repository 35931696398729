import {FieldError, UseControllerProps} from "react-hook-form";
import {Controller, useFormContext} from "react-hook-form";

import {IPhoneFieldProps, IPhoneFieldValue, PhoneField} from "@pg-design/phone-input-module";

interface IProps extends Omit<IPhoneFieldProps, "value" | "onChange" | "onAfterChange"> {
    name: string;
    rules?: UseControllerProps["rules"];
}

// TODO: Add "disabled" functionality
export function PhoneFieldControlled(props: IProps) {
    const {name, rules, ...phoneFieldProps} = props;

    const {control} = useFormContext<{phone?: IPhoneFieldValue}>();

    return (
        <Controller
            name={name}
            rules={rules}
            control={control}
            render={({field, fieldState: {error}, formState: {errors}}) => {
                const fieldError = error && error.message;

                const fullFieldError = errors?.[name] as
                    | {
                          number?: FieldError;
                          code?: FieldError;
                      }
                    | undefined;

                const fieldErrors: string[] = fieldError ? [fieldError] : [];

                if (fullFieldError?.code?.message) {
                    fieldErrors.push(fullFieldError.code.message);
                }

                if (fullFieldError?.number?.message) {
                    fieldErrors.push(fullFieldError.number.message);
                }

                const {ref: _ref, ...fieldProps} = field;

                return (
                    <PhoneField
                        {...fieldProps}
                        {...phoneFieldProps}
                        value={field.value ? field.value : {code: 48, number: ""}}
                        error={fieldErrors.length > 0 ? fieldErrors : undefined}
                        onAfterChange={() => null}
                        onChange={(_, value) => {
                            field.onChange(value);
                        }}
                    />
                );
            }}
        />
    );
}
import {FieldError, UseControllerProps} from "react-hook-form";
import {Controller, useFormContext} from "react-hook-form";

import {IPhoneFieldProps, IPhoneFieldValue, PhoneField} from "@pg-design/phone-input-module";

interface IProps extends Omit<IPhoneFieldProps, "value" | "onChange" | "onAfterChange"> {
    name: string;
    rules?: UseControllerProps["rules"];
}

// TODO: Add "disabled" functionality
export function PhoneFieldControlled(props: IProps) {
    const {name, rules, ...phoneFieldProps} = props;

    const {control} = useFormContext<{phone?: IPhoneFieldValue}>();

    return (
        <Controller
            name={name}
            rules={rules}
            control={control}
            render={({field, fieldState: {error}, formState: {errors}}) => {
                const fieldError = error && error.message;

                const fullFieldError = errors?.[name] as
                    | {
                          number?: FieldError;
                          code?: FieldError;
                      }
                    | undefined;

                const fieldErrors: string[] = fieldError ? [fieldError] : [];

                if (fullFieldError?.code?.message) {
                    fieldErrors.push(fullFieldError.code.message);
                }

                if (fullFieldError?.number?.message) {
                    fieldErrors.push(fullFieldError.number.message);
                }

                const {ref: _ref, ...fieldProps} = field;

                return (
                    <PhoneField
                        {...fieldProps}
                        {...phoneFieldProps}
                        value={field.value ? field.value : {code: 48, number: ""}}
                        error={fieldErrors.length > 0 ? fieldErrors : undefined}
                        onAfterChange={() => null}
                        onChange={(_, value) => {
                            field.onChange(value);
                        }}
                    />
                );
            }}
        />
    );
}
