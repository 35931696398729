import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandListIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.13 4.13c.055 0-.037-.004-.052-.005a2.045 2.045 0 0 0-.124-.003 20.213 20.213 0 0 1-.428-.007c-.462-.012-.923-.016-1.385-.012-.748.006-1.496.012-2.244.014-.154 0-.154.24 0 .24.748.002 1.496.008 2.244.014.455.004.91 0 1.366-.012a26.643 26.643 0 0 1 .57-.01c.016-.002.108-.005.053-.005.138 0 .138-.215 0-.215v.001ZM7.13 5.7a2.045 2.045 0 0 0-.176-.008 20.28 20.28 0 0 1-.428-.007 41.114 41.114 0 0 0-1.385-.012c-.748.006-1.496.012-2.244.014-.154 0-.154.24 0 .24.748.002 1.496.008 2.244.014.455.004.91 0 1.366-.011a26.537 26.537 0 0 1 .57-.011c.016-.001.108-.005.053-.005.138 0 .138-.214 0-.214ZM7.13 7.27c.055 0-.037-.003-.052-.004a2.045 2.045 0 0 0-.124-.003 20.213 20.213 0 0 1-.428-.007c-.462-.012-.923-.016-1.385-.012-.748.006-1.496.012-2.244.014-.154 0-.154.24 0 .24.748.002 1.496.008 2.244.014.455.003.91 0 1.366-.012a26.537 26.537 0 0 1 .57-.01c.016-.002.108-.005.053-.005.138 0 .138-.215 0-.215Z" />
            <path d="m8.428 4.768-.029-1.583c-.004-.19.034-.397-.097-.545-.573-.645-1.23-1.23-1.84-1.839-.154-.153-.24-.177-.455-.176-.422.003-.844.005-1.267.011-.615.01-1.229.034-1.844.047-.391.008-.8-.008-1.188.056-.075.012-.116.091-.122.16-.03.391-.028.788-.012 1.18.026.656.043 1.309.047 1.965a325.309 325.309 0 0 1-.018 4.646c-.002.098-.033.254.016.344.101.192.541.184.716.202 1.156.12 2.333.122 3.494.114.608-.004 1.224-.002 1.83-.058.18-.017.364-.029.537-.082.046-.014.09-.031.135-.052a.052.052 0 0 0 .02-.015.07.07 0 0 0 .02-.017.101.101 0 0 1 .012-.017.084.084 0 0 0 .015-.02.118.118 0 0 0 .015-.056c.01-1.423.039-2.843.015-4.265Zm-.486-1.996c-.114-.006-.233.002-.343.002-.209 0-.418.003-.627.004l-.568.009a33.569 33.569 0 0 1-.129-1.68l1.667 1.665ZM7.563 8.98c-.513.018-1.026.04-1.541.048-1.135.017-2.274.03-3.406-.044a6.681 6.681 0 0 1-.539-.05 2.023 2.023 0 0 1-.204-.041c0-.007.002-.013.003-.02.007-.069.004-.14.004-.208.002-.188 0-.375 0-.563-.007-1.26-.03-2.52-.043-3.78-.011-1.092.012-2.19.073-3.281.225-.026.524-.028.759-.036C3.171.987 3.673.992 4.175.987 4.788.982 5.4.976 6.015.973c.017.66.036 1.323.076 1.981.006.09.071.164.165.166.24.004.478.009.717.01l.594.006c.155 0 .326.016.483-.01.013.651.025 1.303.035 1.955.02 1.29.089 2.58.101 3.87l-.134.005c-.162.007-.325.018-.488.024Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandListIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.13 4.13c.055 0-.037-.004-.052-.005a2.045 2.045 0 0 0-.124-.003 20.213 20.213 0 0 1-.428-.007c-.462-.012-.923-.016-1.385-.012-.748.006-1.496.012-2.244.014-.154 0-.154.24 0 .24.748.002 1.496.008 2.244.014.455.004.91 0 1.366-.012a26.643 26.643 0 0 1 .57-.01c.016-.002.108-.005.053-.005.138 0 .138-.215 0-.215v.001ZM7.13 5.7a2.045 2.045 0 0 0-.176-.008 20.28 20.28 0 0 1-.428-.007 41.114 41.114 0 0 0-1.385-.012c-.748.006-1.496.012-2.244.014-.154 0-.154.24 0 .24.748.002 1.496.008 2.244.014.455.004.91 0 1.366-.011a26.537 26.537 0 0 1 .57-.011c.016-.001.108-.005.053-.005.138 0 .138-.214 0-.214ZM7.13 7.27c.055 0-.037-.003-.052-.004a2.045 2.045 0 0 0-.124-.003 20.213 20.213 0 0 1-.428-.007c-.462-.012-.923-.016-1.385-.012-.748.006-1.496.012-2.244.014-.154 0-.154.24 0 .24.748.002 1.496.008 2.244.014.455.003.91 0 1.366-.012a26.537 26.537 0 0 1 .57-.01c.016-.002.108-.005.053-.005.138 0 .138-.215 0-.215Z" />
            <path d="m8.428 4.768-.029-1.583c-.004-.19.034-.397-.097-.545-.573-.645-1.23-1.23-1.84-1.839-.154-.153-.24-.177-.455-.176-.422.003-.844.005-1.267.011-.615.01-1.229.034-1.844.047-.391.008-.8-.008-1.188.056-.075.012-.116.091-.122.16-.03.391-.028.788-.012 1.18.026.656.043 1.309.047 1.965a325.309 325.309 0 0 1-.018 4.646c-.002.098-.033.254.016.344.101.192.541.184.716.202 1.156.12 2.333.122 3.494.114.608-.004 1.224-.002 1.83-.058.18-.017.364-.029.537-.082.046-.014.09-.031.135-.052a.052.052 0 0 0 .02-.015.07.07 0 0 0 .02-.017.101.101 0 0 1 .012-.017.084.084 0 0 0 .015-.02.118.118 0 0 0 .015-.056c.01-1.423.039-2.843.015-4.265Zm-.486-1.996c-.114-.006-.233.002-.343.002-.209 0-.418.003-.627.004l-.568.009a33.569 33.569 0 0 1-.129-1.68l1.667 1.665ZM7.563 8.98c-.513.018-1.026.04-1.541.048-1.135.017-2.274.03-3.406-.044a6.681 6.681 0 0 1-.539-.05 2.023 2.023 0 0 1-.204-.041c0-.007.002-.013.003-.02.007-.069.004-.14.004-.208.002-.188 0-.375 0-.563-.007-1.26-.03-2.52-.043-3.78-.011-1.092.012-2.19.073-3.281.225-.026.524-.028.759-.036C3.171.987 3.673.992 4.175.987 4.788.982 5.4.976 6.015.973c.017.66.036 1.323.076 1.981.006.09.071.164.165.166.24.004.478.009.717.01l.594.006c.155 0 .326.016.483-.01.013.651.025 1.303.035 1.955.02 1.29.089 2.58.101 3.87l-.134.005c-.162.007-.325.018-.488.024Z" />
        </SvgIcon>
    );
};
