import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {getOfferTypeNamePlural} from "../../offer/helpers/OfferType";
import {getTrackedSiteData} from "../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {ICampaignDetails} from "../types/ICampaignDetails";

export function smsCampaignPhoneNumberClick(campaign: Pick<ICampaignDetails, "offer">) {
    const payload = {
        event_type: "call",
        view_type: "opendays",
        site: getTrackedSiteData(),
        site_id: 33,
        vendor_id: campaign.offer.vendor.id,
        vendor_name: campaign.offer.vendor.name,
        offer_id: campaign.offer.id,
        offer_longitude: campaign.offer.geo_point.coordinates[0],
        offer_latitude: campaign.offer.geo_point.coordinates[1],
        offer_name: campaign.offer.name,
        offer_region: campaign.offer.region.full_name,
        offer_region_id: campaign.offer.region.id,
        offer_type: getOfferTypeNamePlural(campaign.offer.type),
        offer_type_id: campaign.offer.type
    };

    delayHit((payload: Object) => hitAlgolytics("phone_number", payload), 500)(payload);
}
import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {getOfferTypeNamePlural} from "../../offer/helpers/OfferType";
import {getTrackedSiteData} from "../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {ICampaignDetails} from "../types/ICampaignDetails";

export function smsCampaignPhoneNumberClick(campaign: Pick<ICampaignDetails, "offer">) {
    const payload = {
        event_type: "call",
        view_type: "opendays",
        site: getTrackedSiteData(),
        site_id: 33,
        vendor_id: campaign.offer.vendor.id,
        vendor_name: campaign.offer.vendor.name,
        offer_id: campaign.offer.id,
        offer_longitude: campaign.offer.geo_point.coordinates[0],
        offer_latitude: campaign.offer.geo_point.coordinates[1],
        offer_name: campaign.offer.name,
        offer_region: campaign.offer.region.full_name,
        offer_region_id: campaign.offer.region.id,
        offer_type: getOfferTypeNamePlural(campaign.offer.type),
        offer_type_id: campaign.offer.type
    };

    delayHit((payload: Object) => hitAlgolytics("phone_number", payload), 500)(payload);
}
