import React from "react";
import {css} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {m, mb, onDesktop, p, resetWidth, w100} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";

import {useFavourites} from "../../../favourite/hooks/use_favourites";
import {useMyOffersFavourites} from "../../hooks/use_my_offers_favourites";
import {EmptyFavouritesList} from "../EmptyFavouritesList";
import {FavouriteOfferSection} from "../FavouritesOfferSection/FavouriteOfferSection";
import {EmptyPropertyList} from "../FavouritesPropertiesSection/EmptyPropertyList";
import {FavouritePropertiesSection} from "../FavouritesPropertiesSection/FavouritePropertiesSection";
import {GoToComparisonToolButton} from "../GoToComparisonToolButton";

export const FavouritesList = () => {
    // TODO: do not remove this loaded. We need to use two hooks otherwise we wont get all correct data as two hooks wont sync
    const {loaded} = useMyOffersFavourites(true);
    const {favouriteOfferIds, favouritePropertiesIds} = useFavourites();

    const hasOffers = favouriteOfferIds.length > 0 && loaded;
    const hasProperties = favouritePropertiesIds.length > 0 && loaded;
    const hasSomethingToShow = hasOffers || hasProperties;

    if (!loaded) {
        return (
            <CenterBox height="500px">
                <Loader size="lg" />
            </CenterBox>
        );
    }

    return (
        <div css={p(2, 0, 0, 0)}>
            <div css={headerWrapper}>
                <Text as="h1" css={[mb(3), onDesktop(mb(2))]} variant="headline_2">
                    Twoja lista ofert
                </Text>

                <div css={listTitleBarStyle}>
                    <GoToComparisonToolButton css={[w100, onDesktop(resetWidth)]} />
                </div>
            </div>

            <div css={listingsOrderContainer}>
                {!hasSomethingToShow && <EmptyFavouritesList />}

                {hasProperties && <FavouritePropertiesSection css={[!hasOffers && firstItem, mb(3), onDesktop(mb(7))]} />}
                {!hasProperties && hasSomethingToShow && <EmptyPropertyList />}

                {hasOffers && <FavouriteOfferSection css={[!hasProperties && firstItem, mb(3), onDesktop(mb(7))]} />}
            </div>
        </div>
    );
};

const headerWrapper = css`
    ${onDesktop(css`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `)}
`;

const listingsOrderContainer = css`
    display: flex;
    flex-direction: column;
`;

// if other listing is empty, hoist this one to the top
const firstItem = css`
    order: -1;
`;

const listTitleBarStyle = css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    ${m(0, 0, 4, 0)};

    ${onDesktop(css`
        flex-direction: row;
        justify-content: space-between;
    `)}
`;
import React from "react";
import {css} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {m, mb, onDesktop, p, resetWidth, w100} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";

import {useFavourites} from "../../../favourite/hooks/use_favourites";
import {useMyOffersFavourites} from "../../hooks/use_my_offers_favourites";
import {EmptyFavouritesList} from "../EmptyFavouritesList";
import {FavouriteOfferSection} from "../FavouritesOfferSection/FavouriteOfferSection";
import {EmptyPropertyList} from "../FavouritesPropertiesSection/EmptyPropertyList";
import {FavouritePropertiesSection} from "../FavouritesPropertiesSection/FavouritePropertiesSection";
import {GoToComparisonToolButton} from "../GoToComparisonToolButton";

export const FavouritesList = () => {
    // TODO: do not remove this loaded. We need to use two hooks otherwise we wont get all correct data as two hooks wont sync
    const {loaded} = useMyOffersFavourites(true);
    const {favouriteOfferIds, favouritePropertiesIds} = useFavourites();

    const hasOffers = favouriteOfferIds.length > 0 && loaded;
    const hasProperties = favouritePropertiesIds.length > 0 && loaded;
    const hasSomethingToShow = hasOffers || hasProperties;

    if (!loaded) {
        return (
            <CenterBox height="500px">
                <Loader size="lg" />
            </CenterBox>
        );
    }

    return (
        <div css={p(2, 0, 0, 0)}>
            <div css={headerWrapper}>
                <Text as="h1" css={[mb(3), onDesktop(mb(2))]} variant="headline_2">
                    Twoja lista ofert
                </Text>

                <div css={listTitleBarStyle}>
                    <GoToComparisonToolButton css={[w100, onDesktop(resetWidth)]} />
                </div>
            </div>

            <div css={listingsOrderContainer}>
                {!hasSomethingToShow && <EmptyFavouritesList />}

                {hasProperties && <FavouritePropertiesSection css={[!hasOffers && firstItem, mb(3), onDesktop(mb(7))]} />}
                {!hasProperties && hasSomethingToShow && <EmptyPropertyList />}

                {hasOffers && <FavouriteOfferSection css={[!hasProperties && firstItem, mb(3), onDesktop(mb(7))]} />}
            </div>
        </div>
    );
};

const headerWrapper = css`
    ${onDesktop(css`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `)}
`;

const listingsOrderContainer = css`
    display: flex;
    flex-direction: column;
`;

// if other listing is empty, hoist this one to the top
const firstItem = css`
    order: -1;
`;

const listTitleBarStyle = css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    ${m(0, 0, 4, 0)};

    ${onDesktop(css`
        flex-direction: row;
        justify-content: space-between;
    `)}
`;
