import React from "react";
import {css, Theme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {Col, Container, Row} from "@pg-design/grid-module";
import {borderRadius, centerAbsolute, flexAlignCenter, mh, mr, onDesktop, p, pb} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

const tabletMd1x = require("../assets/tablet/tablet_md_1x.png");
const tabletLg1x = require("../assets/tablet/tablet_lg_1x.png");

const tabletSm2x = require("../assets/tablet/tablet_sm_2x.png");
const tabletMd2x = require("../assets/tablet/tablet_md_2x.png");
const tabletLg2x = require("../assets/tablet/tablet_lg_2x.png");

export const SearchDataSection = () => {
    return (
        <section css={[section]}>
            <div css={[uxContainer, p(5, 0, 0, 0), onDesktop(p(10, 0, 8, 0))]}>
                <Container>
                    <Row>
                        <Col sm={4} md={7} lg={8} css={[pb(8), onDesktop(pb(0))]}>
                            <Text as="h2" mb="3.2rem" variant="headline_1">
                                Dbamy, aby Twoją ofertę odnalazł ten, kto jej szuka
                            </Text>

                            <Container>
                                <Row>
                                    <Col sm={4} md={6} css={[pb(2), onDesktop(pb(3))]}>
                                        <div css={dataCard}>
                                            <Text mb="1.2rem" as="h4" variant="headline_2" align="center">
                                                1 mln
                                            </Text>
                                            <Text variant="body_copy_1" align="center">
                                                użytkowników szukających co miesiąc nowych mieszkań
                                            </Text>
                                        </div>
                                    </Col>
                                    <Col sm={4} md={6} css={[pb(2), onDesktop(pb(3))]}>
                                        <div css={dataCard}>
                                            <Text mb="1.2rem" as="h4" variant="headline_2" align="center">
                                                700 tys
                                            </Text>
                                            <Text variant="body_copy_1" align="center">
                                                potencjalnych klientów rocznie
                                            </Text>
                                        </div>
                                    </Col>
                                    <Col sm={4} md={6} css={[pb(2)]}>
                                        <div css={dataCard}>
                                            <Text mb="1.2rem" as="h4" variant="headline_2" align="center">
                                                Top 3
                                            </Text>
                                            <Text variant="body_copy_1" align="center">
                                                w Google - lider rynku pierwotnego
                                            </Text>
                                        </div>
                                    </Col>
                                    <Col sm={4} md={6}>
                                        <div css={dataCard}>
                                            <Text mb="1.2rem" as="h4" variant="headline_2" align="center">
                                                300
                                            </Text>
                                            <Text variant="body_copy_1" align="center">
                                                miast z ofertą mieszkaniową na portalu
                                            </Text>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Picture
                css={tabletPicture}
                sources={[
                    // For smallest breakpoint we will use image 2x because it has better quality
                    {minWidthPX: 0, src: tabletSm2x, height: 320, width: 418},
                    {minWidthPX: 600, srcSet: `${tabletMd1x} 1x, ${tabletMd2x} 2x`, height: 634, width: 648},
                    {minWidthPX: 1366, srcSet: `${tabletLg1x} 1x, ${tabletLg2x} 2x`, height: 766, width: 813}
                ]}
                alt="tablet"
            />
            <div css={whiteStripe}>
                <Container>
                    <Row>
                        <Col sm={4} md={3}>
                            <div css={[flexAlignCenter, pb(4), onDesktop(pb(0))]}>
                                <div css={circle}>
                                    <Text css={centerAbsolute()} noWrap as="span" variant="headline_4" align="center">
                                        150 tys
                                    </Text>
                                </div>
                                <Text as="span" variant="body_copy_0">
                                    ofert nowych nieruchomości rocznie
                                </Text>
                            </div>
                        </Col>
                        <Col sm={4} md={3}>
                            <div css={flexAlignCenter}>
                                <div css={circle}>
                                    <Text css={centerAbsolute()} noWrap as="span" variant="headline_4" align="center">
                                        99%
                                    </Text>
                                </div>
                                <Text as="span" variant="body_copy_0">
                                    zadowolonych deweloperów
                                    <Text variant="body_copy_2">Źródło: Badanie Kantar 2021</Text>
                                </Text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const circle = (theme: Theme) => css`
    background-color: ${theme.colors.primary};
    height: 9.6rem;
    width: 9.6rem;
    border-radius: 50%;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    ${mr(2)}
`;

const section = (theme: Theme) => css`
    position: relative;
    background-color: ${theme.colors.background[200]};
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;

const dataCard = (theme: Theme) => css`
    min-height: 18.4rem;
    background-color: ${theme.colors.primary};
    ${elevation(2)}
    ${borderRadius(2)}
    
    ${p(4, 4, 6, 4)}

    ${onDesktop(p(4, 4, 7, 4))}
`;

const tabletPicture = (theme: Theme) => css`
    margin-left: unset;
    max-width: unset;

    right: 0;

    & img {
        margin-left: auto;
    }

    margin-bottom: -30%;

    @media (min-width: ${theme.breakpoints.sm}) {
        margin-left: auto;
        max-width: 80vw;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 50vw;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        ${centerAbsolute("vertical")};
        max-width: 40vw;
    }
`;

const whiteStripe = css`
    background-color: #fff;

    ${p(20, 0, 6, 0)}

    ${onDesktop(p(9, 0, 6, 0))}
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {Col, Container, Row} from "@pg-design/grid-module";
import {borderRadius, centerAbsolute, flexAlignCenter, mh, mr, onDesktop, p, pb} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

const tabletMd1x = require("../assets/tablet/tablet_md_1x.png");
const tabletLg1x = require("../assets/tablet/tablet_lg_1x.png");

const tabletSm2x = require("../assets/tablet/tablet_sm_2x.png");
const tabletMd2x = require("../assets/tablet/tablet_md_2x.png");
const tabletLg2x = require("../assets/tablet/tablet_lg_2x.png");

export const SearchDataSection = () => {
    return (
        <section css={[section]}>
            <div css={[uxContainer, p(5, 0, 0, 0), onDesktop(p(10, 0, 8, 0))]}>
                <Container>
                    <Row>
                        <Col sm={4} md={7} lg={8} css={[pb(8), onDesktop(pb(0))]}>
                            <Text as="h2" mb="3.2rem" variant="headline_1">
                                Dbamy, aby Twoją ofertę odnalazł ten, kto jej szuka
                            </Text>

                            <Container>
                                <Row>
                                    <Col sm={4} md={6} css={[pb(2), onDesktop(pb(3))]}>
                                        <div css={dataCard}>
                                            <Text mb="1.2rem" as="h4" variant="headline_2" align="center">
                                                1 mln
                                            </Text>
                                            <Text variant="body_copy_1" align="center">
                                                użytkowników szukających co miesiąc nowych mieszkań
                                            </Text>
                                        </div>
                                    </Col>
                                    <Col sm={4} md={6} css={[pb(2), onDesktop(pb(3))]}>
                                        <div css={dataCard}>
                                            <Text mb="1.2rem" as="h4" variant="headline_2" align="center">
                                                700 tys
                                            </Text>
                                            <Text variant="body_copy_1" align="center">
                                                potencjalnych klientów rocznie
                                            </Text>
                                        </div>
                                    </Col>
                                    <Col sm={4} md={6} css={[pb(2)]}>
                                        <div css={dataCard}>
                                            <Text mb="1.2rem" as="h4" variant="headline_2" align="center">
                                                Top 3
                                            </Text>
                                            <Text variant="body_copy_1" align="center">
                                                w Google - lider rynku pierwotnego
                                            </Text>
                                        </div>
                                    </Col>
                                    <Col sm={4} md={6}>
                                        <div css={dataCard}>
                                            <Text mb="1.2rem" as="h4" variant="headline_2" align="center">
                                                300
                                            </Text>
                                            <Text variant="body_copy_1" align="center">
                                                miast z ofertą mieszkaniową na portalu
                                            </Text>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Picture
                css={tabletPicture}
                sources={[
                    // For smallest breakpoint we will use image 2x because it has better quality
                    {minWidthPX: 0, src: tabletSm2x, height: 320, width: 418},
                    {minWidthPX: 600, srcSet: `${tabletMd1x} 1x, ${tabletMd2x} 2x`, height: 634, width: 648},
                    {minWidthPX: 1366, srcSet: `${tabletLg1x} 1x, ${tabletLg2x} 2x`, height: 766, width: 813}
                ]}
                alt="tablet"
            />
            <div css={whiteStripe}>
                <Container>
                    <Row>
                        <Col sm={4} md={3}>
                            <div css={[flexAlignCenter, pb(4), onDesktop(pb(0))]}>
                                <div css={circle}>
                                    <Text css={centerAbsolute()} noWrap as="span" variant="headline_4" align="center">
                                        150 tys
                                    </Text>
                                </div>
                                <Text as="span" variant="body_copy_0">
                                    ofert nowych nieruchomości rocznie
                                </Text>
                            </div>
                        </Col>
                        <Col sm={4} md={3}>
                            <div css={flexAlignCenter}>
                                <div css={circle}>
                                    <Text css={centerAbsolute()} noWrap as="span" variant="headline_4" align="center">
                                        99%
                                    </Text>
                                </div>
                                <Text as="span" variant="body_copy_0">
                                    zadowolonych deweloperów
                                    <Text variant="body_copy_2">Źródło: Badanie Kantar 2021</Text>
                                </Text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const circle = (theme: Theme) => css`
    background-color: ${theme.colors.primary};
    height: 9.6rem;
    width: 9.6rem;
    border-radius: 50%;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    ${mr(2)}
`;

const section = (theme: Theme) => css`
    position: relative;
    background-color: ${theme.colors.background[200]};
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;

const dataCard = (theme: Theme) => css`
    min-height: 18.4rem;
    background-color: ${theme.colors.primary};
    ${elevation(2)}
    ${borderRadius(2)}
    
    ${p(4, 4, 6, 4)}

    ${onDesktop(p(4, 4, 7, 4))}
`;

const tabletPicture = (theme: Theme) => css`
    margin-left: unset;
    max-width: unset;

    right: 0;

    & img {
        margin-left: auto;
    }

    margin-bottom: -30%;

    @media (min-width: ${theme.breakpoints.sm}) {
        margin-left: auto;
        max-width: 80vw;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 50vw;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        ${centerAbsolute("vertical")};
        max-width: 40vw;
    }
`;

const whiteStripe = css`
    background-color: #fff;

    ${p(20, 0, 6, 0)}

    ${onDesktop(p(9, 0, 6, 0))}
`;
