import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandEmailIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9.367 3.867c-.004-.406-.009-.826-.009-1.222a.338.338 0 0 0-.09-.241c-.182-.186-.626-.169-1.19-.147-.289.011-.616.024-.955.011-.142 0-.286 0-.43.002-.314 0-.638.002-.96-.003-1.49-.022-3.03-.045-4.545-.08-.15-.003-.25.028-.315.097-.067.069-.094.17-.092.322.008.52.013 1.049.018 1.561.01.987.02 2.008.044 3.013.017.679.057.72.703.728 2.054.028 4.183.048 6.51.06h.03c.282 0 .568-.02.846-.04.134-.01.274-.02.412-.028l.03-.001V5.004c.002-.325-.003-.72-.007-1.137ZM6.212 5.08c-.32.255-.597.476-.78.63L1.456 2.53l.01-.05c.379.012.759.025 1.138.039 1.003.034 2.04.07 3.059.1h.027c.126.001.422-.002.88-.01l.452-.01c.861-.017 1.846-.043 2.025-.047-.012.07-.152.29-.939.973-.575.5-1.308 1.085-1.896 1.555Zm-2.267-.088c.114.092.23.184.344.273.28.22.568.448.836.69.252.227.458.22.713-.028.187-.182.388-.352.582-.515l.224-.19 2.128 2.36H1.385l2.56-2.59ZM9.047 3.13c0 .61 0 1.424-.002 2.186l-.002 1.702-2.225-2.014c.395-.306.815-.66 1.185-.975.154-.132.3-.255.43-.363.311-.258.522-.45.614-.535V3.13ZM3.668 4.753l-2.52 2.353V2.712l2.52 2.041Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandEmailIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9.367 3.867c-.004-.406-.009-.826-.009-1.222a.338.338 0 0 0-.09-.241c-.182-.186-.626-.169-1.19-.147-.289.011-.616.024-.955.011-.142 0-.286 0-.43.002-.314 0-.638.002-.96-.003-1.49-.022-3.03-.045-4.545-.08-.15-.003-.25.028-.315.097-.067.069-.094.17-.092.322.008.52.013 1.049.018 1.561.01.987.02 2.008.044 3.013.017.679.057.72.703.728 2.054.028 4.183.048 6.51.06h.03c.282 0 .568-.02.846-.04.134-.01.274-.02.412-.028l.03-.001V5.004c.002-.325-.003-.72-.007-1.137ZM6.212 5.08c-.32.255-.597.476-.78.63L1.456 2.53l.01-.05c.379.012.759.025 1.138.039 1.003.034 2.04.07 3.059.1h.027c.126.001.422-.002.88-.01l.452-.01c.861-.017 1.846-.043 2.025-.047-.012.07-.152.29-.939.973-.575.5-1.308 1.085-1.896 1.555Zm-2.267-.088c.114.092.23.184.344.273.28.22.568.448.836.69.252.227.458.22.713-.028.187-.182.388-.352.582-.515l.224-.19 2.128 2.36H1.385l2.56-2.59ZM9.047 3.13c0 .61 0 1.424-.002 2.186l-.002 1.702-2.225-2.014c.395-.306.815-.66 1.185-.975.154-.132.3-.255.43-.363.311-.258.522-.45.614-.535V3.13ZM3.668 4.753l-2.52 2.353V2.712l2.52 2.041Z" />
        </SvgIcon>
    );
};
