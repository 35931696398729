import {SerializedError} from "@reduxjs/toolkit";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

import {isFetchBaseQueryError} from "./is_fetch_base_query_error";

type FieldErrors<ModelValues extends object> = {readonly [Key in keyof ModelValues]: FieldError};
type FieldError = string | string[];
type UnknownModel = {[index: string]: unknown};

export function castToValidationError<ModelValues extends object = UnknownModel>(rtkqError: FetchBaseQueryError | SerializedError | unknown) {
    if (!isFetchBaseQueryError(rtkqError)) {
        throw new Error("Provided error is not a FetchBaseQueryError");
    }

    if (rtkqError.status !== 400) {
        throw new Error("Provided error has a wrong status");
    }

    return rtkqError.data as FieldErrors<ModelValues>;
}
import {SerializedError} from "@reduxjs/toolkit";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

import {isFetchBaseQueryError} from "./is_fetch_base_query_error";

type FieldErrors<ModelValues extends object> = {readonly [Key in keyof ModelValues]: FieldError};
type FieldError = string | string[];
type UnknownModel = {[index: string]: unknown};

export function castToValidationError<ModelValues extends object = UnknownModel>(rtkqError: FetchBaseQueryError | SerializedError | unknown) {
    if (!isFetchBaseQueryError(rtkqError)) {
        throw new Error("Provided error is not a FetchBaseQueryError");
    }

    if (rtkqError.status !== 400) {
        throw new Error("Provided error has a wrong status");
    }

    return rtkqError.data as FieldErrors<ModelValues>;
}
