import React, {ReactNode} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, mb, onDesktop, p} from "@pg-design/helpers-css";
import {BrandDocToHandIcon, BrandDrawerIcon, BrandGuardIcon, BrandOfferIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

import {YEARS_OF_EXPERIENCE} from "../../app/constants/company";

const searchingGuyImg = require("../assets/searching_guy.svg");

export function BuylandoOffer() {
    const theme = useTheme();
    const yearsPluralize = pluralize(["rok", "lata", "lat"]);

    const offerIcons: {
        icon: ReactNode;
        text: string;
    }[] = [
        {
            icon: <BrandDrawerIcon wrapperSize="9.4" size="9.4" wrapperColor={theme.colors.primary} />,
            text: "Największa baza zainteresowanych nabywców"
        },
        {
            icon: <BrandGuardIcon wrapperSize="9.4" size="9.4" wrapperColor={theme.colors.primary} />,
            text: "Bezpieczne informacje o aktywach - nie publikujemy ofert online"
        },
        {
            icon: <BrandDocToHandIcon wrapperSize="9.4" size="9.4" wrapperColor={theme.colors.primary} />,
            text: "Docierasz z ofertą bezpośrednio do odbiorcy"
        },
        {
            icon: <BrandOfferIcon wrapperSize="9.4" size="9.4" wrapperColor={theme.colors.primary} />,
            text: "Rozważymy każdą ofertę sprzedaży gruntu"
        }
    ];

    return (
        <WideContent>
            <div css={mainWrap}>
                <ul css={list}>
                    {offerIcons.map((item, index) => (
                        <li key={`oli${index}`} css={listItem}>
                            {item.icon}
                            <Text variant="body_copy_0" css={itemText}>
                                {item.text}
                            </Text>
                        </li>
                    ))}
                </ul>
                <div css={content}>
                    <Text variant="headline_2" css={mb(4)}>
                        Znajdziemy nabywcę Twojego gruntu
                    </Text>
                    <Text variant="body_copy_0" css={longText}>
                        Wiemy, jak ważne w przypadku sprzedaży działki o dużym potencjale inwestycyjnym, jest dotarcie z ofertą bezpośrednio do nabywców realnie
                        zainteresowanych zakupem. {YEARS_OF_EXPERIENCE} {yearsPluralize(YEARS_OF_EXPERIENCE)} doświadczenia na rynku i pozycja lidera branży
                        nieruchomości pozwoliła na wypracowanie kontaktów z deweloperami. Współpracujemy z ponad 1000 firm deweloperskich, dzięki czemu Twoja
                        oferta o sprzedaży gruntu dotrze do wyselekcjonowanej grupy odbiorczej, realnie zainteresowanej zakupem działki.
                    </Text>
                    <Image alt="Znajdziemy nabywcę Twojego gruntu" width="309px" height="450px" css={searchingGuy} src={searchingGuyImg} />
                </div>
            </div>
        </WideContent>
    );
}

/*
    Styles
 */
const mainWrap = css`
    ${p(8, 0, 15, 0)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: ${calculateRemSize(8)};

    ${onDesktop(css`
        ${p(13, 0, 13, 0)};
        row-gap: ${calculateRemSize(21)};
    `)}
`;

const list = css`
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${calculateRemSize(4)};

    ${onDesktop(css`
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        column-gap: ${calculateRemSize(3)};
    `)}
`;

const listItem = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
    align-items: center;
    max-width: 264px;
`;

const itemText = css`
    text-align: center;
`;

const content = (theme: Theme) => css`
    background: ${theme.colors.success};
    position: relative;

    ${p(2, 2, 34, 2)};
    ${onDesktop(css`
        max-width: 841px;
        ${p(8, 25, 8, 11)}
    `)};
`;

const longText = css`
    max-width: 550px;
`;

const searchingGuy = css`
    position: absolute;
    left: 57px;
    bottom: -60px;
    width: 214px;

    img {
        max-width: 100%;
        height: auto;
    }

    ${onDesktop(css`
        width: 309px;
        left: unset;
        right: -230px;
        bottom: -69px;
    `)}
`;
import React, {ReactNode} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, mb, onDesktop, p} from "@pg-design/helpers-css";
import {BrandDocToHandIcon, BrandDrawerIcon, BrandGuardIcon, BrandOfferIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

import {YEARS_OF_EXPERIENCE} from "../../app/constants/company";

const searchingGuyImg = require("../assets/searching_guy.svg");

export function BuylandoOffer() {
    const theme = useTheme();
    const yearsPluralize = pluralize(["rok", "lata", "lat"]);

    const offerIcons: {
        icon: ReactNode;
        text: string;
    }[] = [
        {
            icon: <BrandDrawerIcon wrapperSize="9.4" size="9.4" wrapperColor={theme.colors.primary} />,
            text: "Największa baza zainteresowanych nabywców"
        },
        {
            icon: <BrandGuardIcon wrapperSize="9.4" size="9.4" wrapperColor={theme.colors.primary} />,
            text: "Bezpieczne informacje o aktywach - nie publikujemy ofert online"
        },
        {
            icon: <BrandDocToHandIcon wrapperSize="9.4" size="9.4" wrapperColor={theme.colors.primary} />,
            text: "Docierasz z ofertą bezpośrednio do odbiorcy"
        },
        {
            icon: <BrandOfferIcon wrapperSize="9.4" size="9.4" wrapperColor={theme.colors.primary} />,
            text: "Rozważymy każdą ofertę sprzedaży gruntu"
        }
    ];

    return (
        <WideContent>
            <div css={mainWrap}>
                <ul css={list}>
                    {offerIcons.map((item, index) => (
                        <li key={`oli${index}`} css={listItem}>
                            {item.icon}
                            <Text variant="body_copy_0" css={itemText}>
                                {item.text}
                            </Text>
                        </li>
                    ))}
                </ul>
                <div css={content}>
                    <Text variant="headline_2" css={mb(4)}>
                        Znajdziemy nabywcę Twojego gruntu
                    </Text>
                    <Text variant="body_copy_0" css={longText}>
                        Wiemy, jak ważne w przypadku sprzedaży działki o dużym potencjale inwestycyjnym, jest dotarcie z ofertą bezpośrednio do nabywców realnie
                        zainteresowanych zakupem. {YEARS_OF_EXPERIENCE} {yearsPluralize(YEARS_OF_EXPERIENCE)} doświadczenia na rynku i pozycja lidera branży
                        nieruchomości pozwoliła na wypracowanie kontaktów z deweloperami. Współpracujemy z ponad 1000 firm deweloperskich, dzięki czemu Twoja
                        oferta o sprzedaży gruntu dotrze do wyselekcjonowanej grupy odbiorczej, realnie zainteresowanej zakupem działki.
                    </Text>
                    <Image alt="Znajdziemy nabywcę Twojego gruntu" width="309px" height="450px" css={searchingGuy} src={searchingGuyImg} />
                </div>
            </div>
        </WideContent>
    );
}

/*
    Styles
 */
const mainWrap = css`
    ${p(8, 0, 15, 0)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: ${calculateRemSize(8)};

    ${onDesktop(css`
        ${p(13, 0, 13, 0)};
        row-gap: ${calculateRemSize(21)};
    `)}
`;

const list = css`
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${calculateRemSize(4)};

    ${onDesktop(css`
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        column-gap: ${calculateRemSize(3)};
    `)}
`;

const listItem = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
    align-items: center;
    max-width: 264px;
`;

const itemText = css`
    text-align: center;
`;

const content = (theme: Theme) => css`
    background: ${theme.colors.success};
    position: relative;

    ${p(2, 2, 34, 2)};
    ${onDesktop(css`
        max-width: 841px;
        ${p(8, 25, 8, 11)}
    `)};
`;

const longText = css`
    max-width: 550px;
`;

const searchingGuy = css`
    position: absolute;
    left: 57px;
    bottom: -60px;
    width: 214px;

    img {
        max-width: 100%;
        height: auto;
    }

    ${onDesktop(css`
        width: 309px;
        left: unset;
        right: -230px;
        bottom: -69px;
    `)}
`;
