import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {IImageProps, Image as DSImage} from "@pg-design/image";
import {IPictureProps, Picture as DSPicture} from "@pg-design/picture";

interface IProps extends IImageProps {
    width: number;
    height: number;
    alt: string;
    breakpoints?: IBreakpoint[];
    className?: string;
    sources?: IPictureProps["sources"];
    maxSourceWidth?: number;
    customComponents?: {
        Image: React.FC<IImageProps>;
    };
}

interface IBreakpoint {
    mediaWidth: string;
    width: string;
    height: string;
}

export function CenteredImage(props: IProps) {
    const {breakpoints, className, sources, customComponents, maxSourceWidth, ...imageProps} = props;

    const renderImage = () => {
        if (customComponents && customComponents.Image) {
            return <customComponents.Image {...imageProps} />;
        }

        return <DSImage {...imageProps} width={`${imageProps.width}px`} height={`${imageProps.height}px`} />;
    };

    return (
        <ImageWrap breakpoints={breakpoints} css={[imageWrapStyle, imageStyle(maxSourceWidth)]} className={className} initialHeight={imageProps.height}>
            {sources ? <DSPicture sources={sources} {...imageProps} /> : renderImage()}
        </ImageWrap>
    );
}

/*
    Styles
 */

const ImageWrap = styled.div<{breakpoints?: IBreakpoint[]; initialHeight: number}>`
    max-height: 100%;
    max-width: 100%;

    img {
        height: 100%;
        width: auto;
        max-width: unset;
    }

    ${({breakpoints, initialHeight}) => css`
        display: flex;
        position: relative;
        overflow: hidden;

        ${!breakpoints &&
        css`
            height: ${initialHeight}px;
        `};

        ${breakpoints &&
        css`
            ${getCssBreakpoints(breakpoints)}
        `};
    `}
`;

const getCssBreakpoints = (breakpoints: IBreakpoint[]) => {
    const bp = breakpoints.map(
        (breakpoint) => css`
            @media (min-width: ${breakpoint.mediaWidth}) {
                width: ${breakpoint.width};
                height: ${breakpoint.height};
            }
        `
    );

    return bp;
};

const imageWrapStyle = css`
    flex: 1;
`;

const imageStyle = (maxSourceWidth?: number) => css`
    & > * {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & > * img {
        height: 100%;
        width: auto;
        position: absolute;
    }

    ${maxSourceWidth &&
    css`
        & > * img {
            @media screen and (min-width: ${maxSourceWidth}px) {
                height: auto !important;
                width: 100% !important;
            }
        }
    `}
`;
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {IImageProps, Image as DSImage} from "@pg-design/image";
import {IPictureProps, Picture as DSPicture} from "@pg-design/picture";

interface IProps extends IImageProps {
    width: number;
    height: number;
    alt: string;
    breakpoints?: IBreakpoint[];
    className?: string;
    sources?: IPictureProps["sources"];
    maxSourceWidth?: number;
    customComponents?: {
        Image: React.FC<IImageProps>;
    };
}

interface IBreakpoint {
    mediaWidth: string;
    width: string;
    height: string;
}

export function CenteredImage(props: IProps) {
    const {breakpoints, className, sources, customComponents, maxSourceWidth, ...imageProps} = props;

    const renderImage = () => {
        if (customComponents && customComponents.Image) {
            return <customComponents.Image {...imageProps} />;
        }

        return <DSImage {...imageProps} width={`${imageProps.width}px`} height={`${imageProps.height}px`} />;
    };

    return (
        <ImageWrap breakpoints={breakpoints} css={[imageWrapStyle, imageStyle(maxSourceWidth)]} className={className} initialHeight={imageProps.height}>
            {sources ? <DSPicture sources={sources} {...imageProps} /> : renderImage()}
        </ImageWrap>
    );
}

/*
    Styles
 */

const ImageWrap = styled.div<{breakpoints?: IBreakpoint[]; initialHeight: number}>`
    max-height: 100%;
    max-width: 100%;

    img {
        height: 100%;
        width: auto;
        max-width: unset;
    }

    ${({breakpoints, initialHeight}) => css`
        display: flex;
        position: relative;
        overflow: hidden;

        ${!breakpoints &&
        css`
            height: ${initialHeight}px;
        `};

        ${breakpoints &&
        css`
            ${getCssBreakpoints(breakpoints)}
        `};
    `}
`;

const getCssBreakpoints = (breakpoints: IBreakpoint[]) => {
    const bp = breakpoints.map(
        (breakpoint) => css`
            @media (min-width: ${breakpoint.mediaWidth}) {
                width: ${breakpoint.width};
                height: ${breakpoint.height};
            }
        `
    );

    return bp;
};

const imageWrapStyle = css`
    flex: 1;
`;

const imageStyle = (maxSourceWidth?: number) => css`
    & > * {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & > * img {
        height: 100%;
        width: auto;
        position: absolute;
    }

    ${maxSourceWidth &&
    css`
        & > * img {
            @media screen and (min-width: ${maxSourceWidth}px) {
                height: auto !important;
                width: 100% !important;
            }
        }
    `}
`;
