import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {elevation} from "@pg-design/elevation";
import {borderRadius, onDesktop, p} from "@pg-design/helpers-css";

import {ResponsiveCardBody} from "./body_layouts/ResponsiveCardBody";
import {ResponsiveCardBodySimple} from "./body_layouts/ResponsiveCardBodySimple";
import {ResponsiveCardBodyWithIcon} from "./body_layouts/ResponsiveCardBodyWithIcon";
import {ResponsiveCardBodyWithImage} from "./body_layouts/ResponsiveCardBodyWithImage";
import {ResponsiveCardLayoutProps} from "./constants";
import {ResponsiveCardImgCover} from "./ResponsiveCardImgCover";

interface IProps extends ResponsiveCardLayoutProps {
    children: React.ReactNode;
}

type ResponsiveCardType = React.FC<IProps> & {
    Body: typeof ResponsiveCardBody;
    BodySimple: typeof ResponsiveCardBodySimple;
    BodyWithIcon: typeof ResponsiveCardBodyWithIcon;
    BodyWithImage: typeof ResponsiveCardBodyWithImage;
    ImgCover: typeof ResponsiveCardImgCover;
};

const ResponsiveCard: ResponsiveCardType = ({badges, cover, coverDesktopPosition, coverPadding, coverWidthOnDesktop, children}) => {
    return (
        <CardBoxWrapper coverDesktopPosition={coverDesktopPosition} hasBadges={Boolean(badges)}>
            {badges && <div css={[badgesWrapperStyle]}>{badges}</div>}

            {cover && (
                <CardBoxCoverWrapper padding={coverPadding} coverDesktopPosition={coverDesktopPosition} coverWidthOnDesktop={coverWidthOnDesktop}>
                    {cover}
                </CardBoxCoverWrapper>
            )}

            <div css={cardBoxBody}>{children}</div>
        </CardBoxWrapper>
    );
};

ResponsiveCard.Body = ResponsiveCardBody;
ResponsiveCard.BodySimple = ResponsiveCardBodySimple;
ResponsiveCard.BodyWithIcon = ResponsiveCardBodyWithIcon;
ResponsiveCard.BodyWithImage = ResponsiveCardBodyWithImage;
ResponsiveCard.ImgCover = ResponsiveCardImgCover;

export default ResponsiveCard;

const CardBoxWrapper = styled.div<{
    coverDesktopPosition: ResponsiveCardLayoutProps["coverDesktopPosition"];
    hasBadges: boolean;
}>`
    display: grid;
    overflow: hidden;
    grid-template-areas:
        "badge"
        "cover"
        "content";
    background: #fff;
    ${borderRadius(2)};
    ${elevation(1)};

    ${({coverDesktopPosition, hasBadges}) =>
        onDesktop(css`
            ${coverDesktopPosition === "right"
                ? css`
                      grid-template-areas:
                          ${hasBadges ? `"badge cover"` : ""}
                          "content cover";
                      grid-template-columns: auto min-content;
                  `
                : css`
                      grid-template-areas:
                          ${hasBadges ? `"cover badge"` : ""}
                          "cover content";
                      grid-template-columns: min-content auto;
                  `};
        `)};
`;

const CardBoxCoverWrapper = styled.div<{
    coverDesktopPosition: ResponsiveCardLayoutProps["coverDesktopPosition"];
    padding: ResponsiveCardLayoutProps["coverPadding"];
    coverWidthOnDesktop: ResponsiveCardLayoutProps["coverWidthOnDesktop"];
}>`
    grid-area: cover;
    ${({padding}) => padding && p(padding, padding, 0, padding)};

    /* fix inline-block display's gap */
    line-height: 0;
    font-size: 0;

    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        width: ${({coverWidthOnDesktop}) => coverWidthOnDesktop || "280px"};
        ${({coverDesktopPosition, padding}) =>
            padding && (coverDesktopPosition === "right" ? p(padding, padding, padding, 0) : p(padding, 0, padding, padding))};
    }
`;

const cardBoxBody = (theme: Theme) => css`
    grid-area: content;

    @media (min-width: ${theme.breakpoints.md}) {
        order: 1;
    }
`;

const badgesWrapperStyle = (theme: Theme) => css`
    ${p(2, 2, 0, 2)};
    grid-area: badge;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
        flex: 1 1 auto;
        order: 1;
        ${p(2, 4, 0, 4)};
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {elevation} from "@pg-design/elevation";
import {borderRadius, onDesktop, p} from "@pg-design/helpers-css";

import {ResponsiveCardBody} from "./body_layouts/ResponsiveCardBody";
import {ResponsiveCardBodySimple} from "./body_layouts/ResponsiveCardBodySimple";
import {ResponsiveCardBodyWithIcon} from "./body_layouts/ResponsiveCardBodyWithIcon";
import {ResponsiveCardBodyWithImage} from "./body_layouts/ResponsiveCardBodyWithImage";
import {ResponsiveCardLayoutProps} from "./constants";
import {ResponsiveCardImgCover} from "./ResponsiveCardImgCover";

interface IProps extends ResponsiveCardLayoutProps {
    children: React.ReactNode;
}

type ResponsiveCardType = React.FC<IProps> & {
    Body: typeof ResponsiveCardBody;
    BodySimple: typeof ResponsiveCardBodySimple;
    BodyWithIcon: typeof ResponsiveCardBodyWithIcon;
    BodyWithImage: typeof ResponsiveCardBodyWithImage;
    ImgCover: typeof ResponsiveCardImgCover;
};

const ResponsiveCard: ResponsiveCardType = ({badges, cover, coverDesktopPosition, coverPadding, coverWidthOnDesktop, children}) => {
    return (
        <CardBoxWrapper coverDesktopPosition={coverDesktopPosition} hasBadges={Boolean(badges)}>
            {badges && <div css={[badgesWrapperStyle]}>{badges}</div>}

            {cover && (
                <CardBoxCoverWrapper padding={coverPadding} coverDesktopPosition={coverDesktopPosition} coverWidthOnDesktop={coverWidthOnDesktop}>
                    {cover}
                </CardBoxCoverWrapper>
            )}

            <div css={cardBoxBody}>{children}</div>
        </CardBoxWrapper>
    );
};

ResponsiveCard.Body = ResponsiveCardBody;
ResponsiveCard.BodySimple = ResponsiveCardBodySimple;
ResponsiveCard.BodyWithIcon = ResponsiveCardBodyWithIcon;
ResponsiveCard.BodyWithImage = ResponsiveCardBodyWithImage;
ResponsiveCard.ImgCover = ResponsiveCardImgCover;

export default ResponsiveCard;

const CardBoxWrapper = styled.div<{
    coverDesktopPosition: ResponsiveCardLayoutProps["coverDesktopPosition"];
    hasBadges: boolean;
}>`
    display: grid;
    overflow: hidden;
    grid-template-areas:
        "badge"
        "cover"
        "content";
    background: #fff;
    ${borderRadius(2)};
    ${elevation(1)};

    ${({coverDesktopPosition, hasBadges}) =>
        onDesktop(css`
            ${coverDesktopPosition === "right"
                ? css`
                      grid-template-areas:
                          ${hasBadges ? `"badge cover"` : ""}
                          "content cover";
                      grid-template-columns: auto min-content;
                  `
                : css`
                      grid-template-areas:
                          ${hasBadges ? `"cover badge"` : ""}
                          "cover content";
                      grid-template-columns: min-content auto;
                  `};
        `)};
`;

const CardBoxCoverWrapper = styled.div<{
    coverDesktopPosition: ResponsiveCardLayoutProps["coverDesktopPosition"];
    padding: ResponsiveCardLayoutProps["coverPadding"];
    coverWidthOnDesktop: ResponsiveCardLayoutProps["coverWidthOnDesktop"];
}>`
    grid-area: cover;
    ${({padding}) => padding && p(padding, padding, 0, padding)};

    /* fix inline-block display's gap */
    line-height: 0;
    font-size: 0;

    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        width: ${({coverWidthOnDesktop}) => coverWidthOnDesktop || "280px"};
        ${({coverDesktopPosition, padding}) =>
            padding && (coverDesktopPosition === "right" ? p(padding, padding, padding, 0) : p(padding, 0, padding, padding))};
    }
`;

const cardBoxBody = (theme: Theme) => css`
    grid-area: content;

    @media (min-width: ${theme.breakpoints.md}) {
        order: 1;
    }
`;

const badgesWrapperStyle = (theme: Theme) => css`
    ${p(2, 2, 0, 2)};
    grid-area: badge;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
        flex: 1 1 auto;
        order: 1;
        ${p(2, 4, 0, 4)};
    }
`;
