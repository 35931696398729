import React, {FC} from "react";

import {Button} from "@pg-design/button-module";
import {onDesktop, resetWidth, w100} from "@pg-design/helpers-css";
import {rpAppLink} from "@pg-mono/rp-routes";

interface IProps {
    children: React.ReactNode;
    className?: string;
}

export const SeeListingButton: FC<IProps> = (props) => {
    const onShowListing = () => {
        window.location.replace(rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy"}));
    };

    return (
        <Button css={[w100, onDesktop(resetWidth)]} className={props.className} onClick={onShowListing} variant="filled_primary">
            {props.children}
        </Button>
    );
};
import React, {FC} from "react";

import {Button} from "@pg-design/button-module";
import {onDesktop, resetWidth, w100} from "@pg-design/helpers-css";
import {rpAppLink} from "@pg-mono/rp-routes";

interface IProps {
    children: React.ReactNode;
    className?: string;
}

export const SeeListingButton: FC<IProps> = (props) => {
    const onShowListing = () => {
        window.location.replace(rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy"}));
    };

    return (
        <Button css={[w100, onDesktop(resetWidth)]} className={props.className} onClick={onShowListing} variant="filled_primary">
            {props.children}
        </Button>
    );
};
