import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, mb, onDesktop, p} from "@pg-design/helpers-css";
import {CheckIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

import {YEARS_OF_EXPERIENCE} from "../../app/constants/company";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
const pointingGuyImg = require("../assets/pointing_guy.svg");

export function BuylandoWhyRP() {
    const theme = useTheme();

    return (
        <div css={mainWrap}>
            <WideContent css={layout}>
                <div css={content}>
                    <Text variant="headline_2" css={listTitle}>
                        {concatWithBrandName("Dlaczego")}?
                    </Text>
                    <ul css={list}>
                        {listItems.map((itemText, i) => (
                            <li key={`itx${i}`} css={listItem}>
                                <CheckIcon css={checkIcon} wrapperSize="3.2" size="3.2" wrapperColor={theme.colors.primary} />
                                <Text variant="body_copy_0">
                                    <strong>{itemText}</strong>
                                </Text>
                            </li>
                        ))}
                    </ul>
                </div>
                <div css={imageWrap}>
                    <Image css={pointingGuy} src={pointingGuyImg} width="552px" height="366px" alt={`${concatWithBrandName("Dlaczego")}?`} />
                </div>
            </WideContent>
        </div>
    );
}

const yearsPluralize = pluralize(["rok", "lata", "lat"]);

const listItems = [
    `${YEARS_OF_EXPERIENCE} ${yearsPluralize(YEARS_OF_EXPERIENCE)} doświadczenia na rynku nieruchomości`,
    "sprawdzone know-how",
    "skuteczność bazująca na autorskich algorytmach",
    "wyselekcjonowana grupa odbiorców"
];

/*
    Styles
 */
const mainWrap = (theme: Theme) => css`
    background: ${theme.colors.background[200]};
`;

const layout = css`
    display: flex;
    flex-direction: column;

    ${onDesktop(css`
        flex-direction: row;
        justify-content: center;
        column-gap: ${calculateRemSize(3)};
    `)}
`;

const content = css`
    ${p(4, 0, 2, 0)}

    ${onDesktop(css`
        order: 2;
        ${p(14, 0, 6, 0)}
    `)}
`;

const listTitle = css`
    ${mb(4)}

    ${onDesktop(css`
        ${mb(7)}
    `)}
`;

const list = css`
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
`;

const listItem = css`
    display: flex;
    column-gap: ${calculateRemSize(2)};
`;

const checkIconSize = calculateRemSize(4);

const checkIcon = css`
    width: ${checkIconSize};
    height: ${checkIconSize};
`;

const imageWrap = css`
    ${p(3, 0, 2, 0)};

    ${onDesktop(css`
        ${p(11, 0, 8, 0)};
        ${onDesktop(css`
            order: 1;
        `)}
    `)}
`;

const pointingGuy = css`
    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
    }
`;
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, mb, onDesktop, p} from "@pg-design/helpers-css";
import {CheckIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

import {YEARS_OF_EXPERIENCE} from "../../app/constants/company";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
const pointingGuyImg = require("../assets/pointing_guy.svg");

export function BuylandoWhyRP() {
    const theme = useTheme();

    return (
        <div css={mainWrap}>
            <WideContent css={layout}>
                <div css={content}>
                    <Text variant="headline_2" css={listTitle}>
                        {concatWithBrandName("Dlaczego")}?
                    </Text>
                    <ul css={list}>
                        {listItems.map((itemText, i) => (
                            <li key={`itx${i}`} css={listItem}>
                                <CheckIcon css={checkIcon} wrapperSize="3.2" size="3.2" wrapperColor={theme.colors.primary} />
                                <Text variant="body_copy_0">
                                    <strong>{itemText}</strong>
                                </Text>
                            </li>
                        ))}
                    </ul>
                </div>
                <div css={imageWrap}>
                    <Image css={pointingGuy} src={pointingGuyImg} width="552px" height="366px" alt={`${concatWithBrandName("Dlaczego")}?`} />
                </div>
            </WideContent>
        </div>
    );
}

const yearsPluralize = pluralize(["rok", "lata", "lat"]);

const listItems = [
    `${YEARS_OF_EXPERIENCE} ${yearsPluralize(YEARS_OF_EXPERIENCE)} doświadczenia na rynku nieruchomości`,
    "sprawdzone know-how",
    "skuteczność bazująca na autorskich algorytmach",
    "wyselekcjonowana grupa odbiorców"
];

/*
    Styles
 */
const mainWrap = (theme: Theme) => css`
    background: ${theme.colors.background[200]};
`;

const layout = css`
    display: flex;
    flex-direction: column;

    ${onDesktop(css`
        flex-direction: row;
        justify-content: center;
        column-gap: ${calculateRemSize(3)};
    `)}
`;

const content = css`
    ${p(4, 0, 2, 0)}

    ${onDesktop(css`
        order: 2;
        ${p(14, 0, 6, 0)}
    `)}
`;

const listTitle = css`
    ${mb(4)}

    ${onDesktop(css`
        ${mb(7)}
    `)}
`;

const list = css`
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
`;

const listItem = css`
    display: flex;
    column-gap: ${calculateRemSize(2)};
`;

const checkIconSize = calculateRemSize(4);

const checkIcon = css`
    width: ${checkIconSize};
    height: ${checkIconSize};
`;

const imageWrap = css`
    ${p(3, 0, 2, 0)};

    ${onDesktop(css`
        ${p(11, 0, 8, 0)};
        ${onDesktop(css`
            order: 1;
        `)}
    `)}
`;

const pointingGuy = css`
    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
    }
`;
