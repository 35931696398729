import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {WideContent} from "@pg-design/grid-module";
import {mb, onDesktop, p} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

const handshakeImg = require("../assets/hand_shake.svg");
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {BuylandoLogo} from "./BuylandoLogo";

export function BuylandoIntro() {
    return (
        <WideContent>
            <div css={contentWrap}>
                <div css={content}>
                    <div css={logoWrap}>
                        <BuylandoLogo />
                    </div>
                    <Text variant="headline_2" css={mb(4)}>
                        Pomożemy sprzedać Twoją ziemię, nawet taką z nieuregulowanym stanem prawnym
                    </Text>
                    <Text variant="body_copy_0" css={mb(4)}>
                        Rozpocznij współpracę z {concatWithBrandName("portalem")} i <strong>serwisem buylando</strong> - Twoja oferta dotrze bezpośrednio do
                        ponad 1000 deweloperów, z którymi od lat współpracujemy.
                        <br />
                        <br />
                        <strong>Dzięki temu realnie zwiększysz szanse na sprzedaż.</strong>
                    </Text>
                    <div css={buttonWrap}>
                        <Button css={button} variant="filled_primary" target="_blank" href="https://buylando.pl/panel/rynek-pierwotny/">
                            Sprzedaj grunt Już teraz
                        </Button>
                    </div>
                    <Image css={handshakeImage} src={handshakeImg} width="534px" height="446px" alt="Pomożemy sprzedać Twoją ziemię" />
                </div>
            </div>
        </WideContent>
    );
}

/*
    Styles
 */
const contentWrap = css`
    ${p(3, 0, 16, 0)};

    ${onDesktop(css`
        ${p(13, 0, 13, 0)}
        padding-right: 383px;
    `)};
`;

const content = (theme: Theme) => css`
    background: ${theme.colors.success};
    position: relative;
    max-width: 742px;
    ${p(2, 2, 18, 2)};

    ${onDesktop(css`
        ${p(8)};
    `)}
`;

const logoWrap = css`
    display: flex;
    justify-content: center;

    ${mb(4)}
`;

const handshakeImage = css`
    position: absolute;
    bottom: -71px;
    left: 55px;
    width: 227px;
    height: 189px;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    ${onDesktop(css`
        width: 534px;
        height: 444px;
        bottom: 96px;
        left: unset;
        right: -383px;
    `)}
`;

const buttonWrap = css`
    display: flex;
`;

const button = css`
    flex: 1;

    ${onDesktop(css`
        flex: unset;
    `)}
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {WideContent} from "@pg-design/grid-module";
import {mb, onDesktop, p} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

const handshakeImg = require("../assets/hand_shake.svg");
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {BuylandoLogo} from "./BuylandoLogo";

export function BuylandoIntro() {
    return (
        <WideContent>
            <div css={contentWrap}>
                <div css={content}>
                    <div css={logoWrap}>
                        <BuylandoLogo />
                    </div>
                    <Text variant="headline_2" css={mb(4)}>
                        Pomożemy sprzedać Twoją ziemię, nawet taką z nieuregulowanym stanem prawnym
                    </Text>
                    <Text variant="body_copy_0" css={mb(4)}>
                        Rozpocznij współpracę z {concatWithBrandName("portalem")} i <strong>serwisem buylando</strong> - Twoja oferta dotrze bezpośrednio do
                        ponad 1000 deweloperów, z którymi od lat współpracujemy.
                        <br />
                        <br />
                        <strong>Dzięki temu realnie zwiększysz szanse na sprzedaż.</strong>
                    </Text>
                    <div css={buttonWrap}>
                        <Button css={button} variant="filled_primary" target="_blank" href="https://buylando.pl/panel/rynek-pierwotny/">
                            Sprzedaj grunt Już teraz
                        </Button>
                    </div>
                    <Image css={handshakeImage} src={handshakeImg} width="534px" height="446px" alt="Pomożemy sprzedać Twoją ziemię" />
                </div>
            </div>
        </WideContent>
    );
}

/*
    Styles
 */
const contentWrap = css`
    ${p(3, 0, 16, 0)};

    ${onDesktop(css`
        ${p(13, 0, 13, 0)}
        padding-right: 383px;
    `)};
`;

const content = (theme: Theme) => css`
    background: ${theme.colors.success};
    position: relative;
    max-width: 742px;
    ${p(2, 2, 18, 2)};

    ${onDesktop(css`
        ${p(8)};
    `)}
`;

const logoWrap = css`
    display: flex;
    justify-content: center;

    ${mb(4)}
`;

const handshakeImage = css`
    position: absolute;
    bottom: -71px;
    left: 55px;
    width: 227px;
    height: 189px;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    ${onDesktop(css`
        width: 534px;
        height: 444px;
        bottom: 96px;
        left: unset;
        right: -383px;
    `)}
`;

const buttonWrap = css`
    display: flex;
`;

const button = css`
    flex: 1;

    ${onDesktop(css`
        flex: unset;
    `)}
`;
