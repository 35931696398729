import {useIsMobile} from "@pg-mono/hooks";

export const useResponsiveLinkTarget = () => {
    const isMobile = useIsMobile();
    return isMobile ? "_self" : "_blank";
};
import {useIsMobile} from "@pg-mono/hooks";

export const useResponsiveLinkTarget = () => {
    const isMobile = useIsMobile();
    return isMobile ? "_self" : "_blank";
};
