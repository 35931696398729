import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Col, Container, Row} from "@pg-design/grid-module";
import {flexAlignCenter, mb, mh, onDesktop, p, pb, resetWidth, w100} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

import {BRAND_NAME} from "../../app/constants/company";

const workStaffLG = require("../assets/work_staff/work_staff_936x593.png");
const workStaffMD = require("../assets/work_staff/work_staff_647x410.png");
const workStaffXS = require("../assets/work_staff/work_staff_336_213.png");

interface IProps {
    scrollToForm: () => void;
}

export const ArmedWithTech = (props: IProps) => {
    return (
        <section css={section}>
            <div css={uxContainer}>
                <Container>
                    <Row css={flexAlignCenter}>
                        <Col sm={4} md={7} css={[mb(4), onDesktop(mb(0))]}>
                            <Picture
                                css={pictureCenter}
                                sources={[
                                    {minWidthPX: 0, src: workStaffXS, height: 286, width: 359},
                                    {minWidthPX: 600, src: workStaffMD, height: 454, width: 571},
                                    {minWidthPX: 1366, src: workStaffLG, height: 535, width: 669}
                                ]}
                                alt="zyski"
                            />
                        </Col>
                        <Col sm={4} md={5}>
                            <Text as="h2" mb="3.2rem" variant="headline_1">
                                Uzbrajamy Cię <br /> w zaawansowaną technologię
                            </Text>
                            <Text css={[pb(4)]} variant="body_copy_0">
                                Współpracując z {BRAND_NAME} czerpiesz z przewag sztucznej inteligencji. Nasza skuteczność i dopasowanie bazują na autorskich
                                algorytmach, stosujących uczenie maszynowe (Machine Learning).
                            </Text>

                            <Button onClick={props.scrollToForm} css={[w100, onDesktop(resetWidth)]} variant="outlined_secondary" size="small">
                                sprzedawaj więcej
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const section = (theme: Theme) => css`
    background-color: ${theme.colors.primary};
    ${p(8, 1)};

    ${onDesktop(p(5, 4))}
`;

const pictureCenter = css`
    & > img {
        margin: 0 auto;
    }
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Col, Container, Row} from "@pg-design/grid-module";
import {flexAlignCenter, mb, mh, onDesktop, p, pb, resetWidth, w100} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

import {BRAND_NAME} from "../../app/constants/company";

const workStaffLG = require("../assets/work_staff/work_staff_936x593.png");
const workStaffMD = require("../assets/work_staff/work_staff_647x410.png");
const workStaffXS = require("../assets/work_staff/work_staff_336_213.png");

interface IProps {
    scrollToForm: () => void;
}

export const ArmedWithTech = (props: IProps) => {
    return (
        <section css={section}>
            <div css={uxContainer}>
                <Container>
                    <Row css={flexAlignCenter}>
                        <Col sm={4} md={7} css={[mb(4), onDesktop(mb(0))]}>
                            <Picture
                                css={pictureCenter}
                                sources={[
                                    {minWidthPX: 0, src: workStaffXS, height: 286, width: 359},
                                    {minWidthPX: 600, src: workStaffMD, height: 454, width: 571},
                                    {minWidthPX: 1366, src: workStaffLG, height: 535, width: 669}
                                ]}
                                alt="zyski"
                            />
                        </Col>
                        <Col sm={4} md={5}>
                            <Text as="h2" mb="3.2rem" variant="headline_1">
                                Uzbrajamy Cię <br /> w zaawansowaną technologię
                            </Text>
                            <Text css={[pb(4)]} variant="body_copy_0">
                                Współpracując z {BRAND_NAME} czerpiesz z przewag sztucznej inteligencji. Nasza skuteczność i dopasowanie bazują na autorskich
                                algorytmach, stosujących uczenie maszynowe (Machine Learning).
                            </Text>

                            <Button onClick={props.scrollToForm} css={[w100, onDesktop(resetWidth)]} variant="outlined_secondary" size="small">
                                sprzedawaj więcej
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

const section = (theme: Theme) => css`
    background-color: ${theme.colors.primary};
    ${p(8, 1)};

    ${onDesktop(p(5, 4))}
`;

const pictureCenter = css`
    & > img {
        margin: 0 auto;
    }
`;

const uxContainer = css`
    max-width: 1600px;
    ${mh("auto")}
`;
