import React from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, flex, flexAbsoluteCenter, flexDirection, mb, mt, position} from "@pg-design/helpers-css-strings";
import {ChevronDownIcon} from "@pg-design/icons-module";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {OurOfferContactForm} from "./OurOfferContactForm";

const headerXL = require("../assets/header/header_2560.png");
const headerSM = require("../assets/header/header_602.png");
const headerLG = require("../assets/header/header_1920.png");
const headerMD = require("../assets/header/header_1366.png");
const headerXS = require("../assets/header/header_360.png");

interface IProps {
    scrollTo: () => void;
}

export const HeaderSection = (props: IProps) => {
    const {isMobile} = useUserDevice();

    const brandColor = useTheme().colors.primary;

    return (
        <section css={[position("relative"), section]}>
            <div css={headerContent}>
                <div css={headerTexts}>
                    <Headline variant="headline_1" fontFamily="secondary">
                        Wprost do nowego klienta.
                    </Headline>
                    <SubHeadline as="h2" variant="headline_0" fontFamily="secondary" mb="3.2rem">
                        Przeprowadzimy Cię.
                    </SubHeadline>
                    <button onClick={props.scrollTo}>
                        <ChevronDownIcon size={isMobile ? "3.7" : "4.7"} wrapperSize={isMobile ? "4.8" : "6.4"} wrapperColor={brandColor} />
                    </button>
                </div>
                <div css={formHolder}>
                    <OurOfferContactForm smallVersion />
                </div>
            </div>
            <Picture
                fit="bottom right"
                sources={[
                    {minWidthPX: 0, src: headerXS, height: 286, width: 360},
                    {minWidthPX: 360, src: headerSM, height: 488, width: 602},
                    {minWidthPX: 602, src: headerMD, height: 589, width: 1366},
                    {minWidthPX: 1366, src: headerLG, height: 826, width: 1920},
                    {minWidthPX: 1920, src: headerXL, height: 1102, width: 2560}
                ]}
                alt="współpraca z deweloperem"
            />
        </section>
    );
};

const section = (theme: Theme) => css`
    height: 488px;
    margin-bottom: 540px;

    @media (min-width: ${theme.breakpoints.xs}) {
        margin-bottom: 500px;
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        margin-bottom: 450px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        height: 588px;
        ${mb(25)};
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        height: 826px;
        ${mb(0)};
    }
`;

const headerContent = (theme: Theme) => css`
    position: absolute;
    top: 6%;
    left: ${calculateRemSize(2)};
    right: ${calculateRemSize(2)};
    z-index: 1;
    ${flex()};
    ${flexDirection("column")};
    text-align: center;

    @media (min-width: ${theme.breakpoints.sm}) {
        left: 10%;
        right: 2%;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        text-align: left;
        ${flex("flex-start", "space-between")};
        ${flexDirection("row")};
        top: 12%;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        top: 20%;
        right: 13%;
    }
`;

const headerTexts = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 632px;
    }
`;

const Headline = styled(Text)`
    font-size: 4.4rem;
    line-height: 5.86rem;
    font-weight: 700;
    ${mb(1)};
    text-align: center;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 5.6rem;
        line-height: 7.92rem;
        text-align: left;
    }
`;

const SubHeadline = styled(Text)`
    font-weight: 400;
    text-align: center;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        text-align: left;
    }
`;

const formHolder = (theme: Theme) => css`
    ${flexAbsoluteCenter};
    width: 100%;
    ${mt(4)};

    @media (min-width: ${theme.breakpoints.md}) {
        width: unset;
        ${mt(0)};
        transform: translate(0, -6%);
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        transform: translate(0, -18%);
    }
`;
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, flex, flexAbsoluteCenter, flexDirection, mb, mt, position} from "@pg-design/helpers-css-strings";
import {ChevronDownIcon} from "@pg-design/icons-module";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {OurOfferContactForm} from "./OurOfferContactForm";

const headerXL = require("../assets/header/header_2560.png");
const headerSM = require("../assets/header/header_602.png");
const headerLG = require("../assets/header/header_1920.png");
const headerMD = require("../assets/header/header_1366.png");
const headerXS = require("../assets/header/header_360.png");

interface IProps {
    scrollTo: () => void;
}

export const HeaderSection = (props: IProps) => {
    const {isMobile} = useUserDevice();

    const brandColor = useTheme().colors.primary;

    return (
        <section css={[position("relative"), section]}>
            <div css={headerContent}>
                <div css={headerTexts}>
                    <Headline variant="headline_1" fontFamily="secondary">
                        Wprost do nowego klienta.
                    </Headline>
                    <SubHeadline as="h2" variant="headline_0" fontFamily="secondary" mb="3.2rem">
                        Przeprowadzimy Cię.
                    </SubHeadline>
                    <button onClick={props.scrollTo}>
                        <ChevronDownIcon size={isMobile ? "3.7" : "4.7"} wrapperSize={isMobile ? "4.8" : "6.4"} wrapperColor={brandColor} />
                    </button>
                </div>
                <div css={formHolder}>
                    <OurOfferContactForm smallVersion />
                </div>
            </div>
            <Picture
                fit="bottom right"
                sources={[
                    {minWidthPX: 0, src: headerXS, height: 286, width: 360},
                    {minWidthPX: 360, src: headerSM, height: 488, width: 602},
                    {minWidthPX: 602, src: headerMD, height: 589, width: 1366},
                    {minWidthPX: 1366, src: headerLG, height: 826, width: 1920},
                    {minWidthPX: 1920, src: headerXL, height: 1102, width: 2560}
                ]}
                alt="współpraca z deweloperem"
            />
        </section>
    );
};

const section = (theme: Theme) => css`
    height: 488px;
    margin-bottom: 540px;

    @media (min-width: ${theme.breakpoints.xs}) {
        margin-bottom: 500px;
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        margin-bottom: 450px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        height: 588px;
        ${mb(25)};
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        height: 826px;
        ${mb(0)};
    }
`;

const headerContent = (theme: Theme) => css`
    position: absolute;
    top: 6%;
    left: ${calculateRemSize(2)};
    right: ${calculateRemSize(2)};
    z-index: 1;
    ${flex()};
    ${flexDirection("column")};
    text-align: center;

    @media (min-width: ${theme.breakpoints.sm}) {
        left: 10%;
        right: 2%;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        text-align: left;
        ${flex("flex-start", "space-between")};
        ${flexDirection("row")};
        top: 12%;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        top: 20%;
        right: 13%;
    }
`;

const headerTexts = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 632px;
    }
`;

const Headline = styled(Text)`
    font-size: 4.4rem;
    line-height: 5.86rem;
    font-weight: 700;
    ${mb(1)};
    text-align: center;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 5.6rem;
        line-height: 7.92rem;
        text-align: left;
    }
`;

const SubHeadline = styled(Text)`
    font-weight: 400;
    text-align: center;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        text-align: left;
    }
`;

const formHolder = (theme: Theme) => css`
    ${flexAbsoluteCenter};
    width: 100%;
    ${mt(4)};

    @media (min-width: ${theme.breakpoints.md}) {
        width: unset;
        ${mt(0)};
        transform: translate(0, -6%);
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        transform: translate(0, -18%);
    }
`;
