import React from "react";
import {useSelector} from "react-redux";

import {Highlight, ITextProps, Text} from "@pg-design/text-module";

import {IRPStore} from "../app/rp_reducer";
import {useCurrentRoute} from "../hooks/use_current_route";

interface IProps extends ITextProps {
    prefixContent?: React.ReactNode;
    suffixContent?: React.ReactNode;
    pageString?: string;
    highlight?: boolean;
}

export const SeoMainHeadline = (props: IProps) => {
    const {children, prefixContent, suffixContent, pageString, highlight, variant = "headline_1", ...restProps} = props;
    const seoHeadline = useSelector((store: IRPStore) => store.metaData.customMetaData?.heading_h1);
    const {query} = useCurrentRoute();
    const fullHeadline = seoHeadline && `${seoHeadline}${pageString ? pageString : ""}`;
    const useCustomHeading = !Object.keys(query).includes("stage") && seoHeadline;
    const headline = useCustomHeading ? fullHeadline : children;

    return (
        <Text variant={variant} {...restProps}>
            {prefixContent && !seoHeadline ? `${prefixContent} ` : null}
            {highlight ? <Highlight>{headline}</Highlight> : headline}
            {suffixContent && !seoHeadline ? (typeof suffixContent === "object" ? suffixContent : ` ${suffixContent}`) : null}
        </Text>
    );
};
import React from "react";
import {useSelector} from "react-redux";

import {Highlight, ITextProps, Text} from "@pg-design/text-module";

import {IRPStore} from "../app/rp_reducer";
import {useCurrentRoute} from "../hooks/use_current_route";

interface IProps extends ITextProps {
    prefixContent?: React.ReactNode;
    suffixContent?: React.ReactNode;
    pageString?: string;
    highlight?: boolean;
}

export const SeoMainHeadline = (props: IProps) => {
    const {children, prefixContent, suffixContent, pageString, highlight, variant = "headline_1", ...restProps} = props;
    const seoHeadline = useSelector((store: IRPStore) => store.metaData.customMetaData?.heading_h1);
    const {query} = useCurrentRoute();
    const fullHeadline = seoHeadline && `${seoHeadline}${pageString ? pageString : ""}`;
    const useCustomHeading = !Object.keys(query).includes("stage") && seoHeadline;
    const headline = useCustomHeading ? fullHeadline : children;

    return (
        <Text variant={variant} {...restProps}>
            {prefixContent && !seoHeadline ? `${prefixContent} ` : null}
            {highlight ? <Highlight>{headline}</Highlight> : headline}
            {suffixContent && !seoHeadline ? (typeof suffixContent === "object" ? suffixContent : ` ${suffixContent}`) : null}
        </Text>
    );
};
