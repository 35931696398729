import {hitGoogleTagManager} from "./init_google_tag_manager";

enum GTMFavouritesListEvent {
    FAVOURITE_EVENTS = "favourite_events"
}

enum GTMFavouritesAction {
    LIST_ACTION = "list"
}

enum GTMFavouritesLabel {
    LIST_VIEW = "list_view",
    YOUR_LIST_RIGHT_TOP = "your_list_right_top",
    YOUR_LIST_LEFT_TOP = "your_list_left_top",
    YOUR_REQUEST_LEFT_TOP = "your_requests_left_top",
    DELETE_FROM_LIST = "delete_from_list",
    GO_TO_MY_REQUESTS = "go_to_my_requests",
    GO_TO_COMPARISON_TOOL = "go_to_comparison_tool"
}

export const gtmClickListViewModal = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.LIST_VIEW
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickMyListNavigation = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.YOUR_LIST_LEFT_TOP
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickMyListApplicationsNavigation = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.YOUR_REQUEST_LEFT_TOP
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickDeleteFromList = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.DELETE_FROM_LIST
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickGoToMyApplications = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.GO_TO_MY_REQUESTS
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickGoToComparisonTool = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.GO_TO_COMPARISON_TOOL
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickMyListRPNavigation = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.YOUR_LIST_RIGHT_TOP
    };

    hitGoogleTagManager(eventData);
};
import {hitGoogleTagManager} from "./init_google_tag_manager";

enum GTMFavouritesListEvent {
    FAVOURITE_EVENTS = "favourite_events"
}

enum GTMFavouritesAction {
    LIST_ACTION = "list"
}

enum GTMFavouritesLabel {
    LIST_VIEW = "list_view",
    YOUR_LIST_RIGHT_TOP = "your_list_right_top",
    YOUR_LIST_LEFT_TOP = "your_list_left_top",
    YOUR_REQUEST_LEFT_TOP = "your_requests_left_top",
    DELETE_FROM_LIST = "delete_from_list",
    GO_TO_MY_REQUESTS = "go_to_my_requests",
    GO_TO_COMPARISON_TOOL = "go_to_comparison_tool"
}

export const gtmClickListViewModal = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.LIST_VIEW
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickMyListNavigation = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.YOUR_LIST_LEFT_TOP
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickMyListApplicationsNavigation = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.YOUR_REQUEST_LEFT_TOP
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickDeleteFromList = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.DELETE_FROM_LIST
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickGoToMyApplications = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.GO_TO_MY_REQUESTS
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickGoToComparisonTool = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.GO_TO_COMPARISON_TOOL
    };

    hitGoogleTagManager(eventData);
};

export const gtmClickMyListRPNavigation = () => {
    const eventData = {
        event: GTMFavouritesListEvent.FAVOURITE_EVENTS,
        action: GTMFavouritesAction.LIST_ACTION,
        label: GTMFavouritesLabel.YOUR_LIST_RIGHT_TOP
    };

    hitGoogleTagManager(eventData);
};
