import {useCallback} from "react";

export const useBoxHandlerWithAnchor = (link: string, target: "_self" | "_blank" | "_parent" | "_top" = "_self") => {
    return useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            const linkElement = event.currentTarget.querySelector("a");

            if (linkElement && linkElement.contains(event.target as Node)) {
                return;
            }
            event.stopPropagation();
            window.open(link, target || "_self");
        },
        [link, target]
    );
};
import {useCallback} from "react";

export const useBoxHandlerWithAnchor = (link: string, target: "_self" | "_blank" | "_parent" | "_top" = "_self") => {
    return useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            const linkElement = event.currentTarget.querySelector("a");

            if (linkElement && linkElement.contains(event.target as Node)) {
                return;
            }
            event.stopPropagation();
            window.open(link, target || "_self");
        },
        [link, target]
    );
};
