import React, {PropsWithChildren} from "react";

import {useTabsColorsSchema} from "../hooks/use_tabs_colors_schema";
import {ITabsCoreProps, TabsAllowedValueTypes, TabsCore} from "./TabsCore";

export type TabsColor = "dark" | "full-dark" | "gray" | "invert-dark" | "warm";

// Tabs interfaces are not pretty, but this notation (together with what sits deeper in `TabsCore`) generally means that props `onChange`, `value` and `tabs` will always use the same value type.
// ie. when prop `value="string"`, it will strictly enforce `string` in onChange callback and tabs definition object.

type IPropsCommonWithTabsCore<ValueType extends TabsAllowedValueTypes> = Pick<
    ITabsCoreProps<ValueType>,
    "fullWidth" | "fullWidthBorder" | "onChange" | "tabs" | "value" | "variant" | "titlesMaxWidth"
>;

interface IProps<ValueType extends TabsAllowedValueTypes> extends IPropsCommonWithTabsCore<ValueType> {
    activeColor?: TabsColor;
    color: TabsColor;
    className?: string;
}

export const Tabs = <ValueType extends TabsAllowedValueTypes>(props: PropsWithChildren<IProps<ValueType>>) => {
    const {getTabsColorsSchema} = useTabsColorsSchema();

    const colorsSchema = getTabsColorsSchema(props.color, props.variant, props.activeColor);

    return (
        <TabsCore colorsSchema={colorsSchema} {...props}>
            {props.children}
        </TabsCore>
    );
};
import React, {PropsWithChildren} from "react";

import {useTabsColorsSchema} from "../hooks/use_tabs_colors_schema";
import {ITabsCoreProps, TabsAllowedValueTypes, TabsCore} from "./TabsCore";

export type TabsColor = "dark" | "full-dark" | "gray" | "invert-dark" | "warm";

// Tabs interfaces are not pretty, but this notation (together with what sits deeper in `TabsCore`) generally means that props `onChange`, `value` and `tabs` will always use the same value type.
// ie. when prop `value="string"`, it will strictly enforce `string` in onChange callback and tabs definition object.

type IPropsCommonWithTabsCore<ValueType extends TabsAllowedValueTypes> = Pick<
    ITabsCoreProps<ValueType>,
    "fullWidth" | "fullWidthBorder" | "onChange" | "tabs" | "value" | "variant" | "titlesMaxWidth"
>;

interface IProps<ValueType extends TabsAllowedValueTypes> extends IPropsCommonWithTabsCore<ValueType> {
    activeColor?: TabsColor;
    color: TabsColor;
    className?: string;
}

export const Tabs = <ValueType extends TabsAllowedValueTypes>(props: PropsWithChildren<IProps<ValueType>>) => {
    const {getTabsColorsSchema} = useTabsColorsSchema();

    const colorsSchema = getTabsColorsSchema(props.color, props.variant, props.activeColor);

    return (
        <TabsCore colorsSchema={colorsSchema} {...props}>
            {props.children}
        </TabsCore>
    );
};
