import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {backgroundColor, flex, flexDirection, mb, pb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import ResponsiveCard from "../../../../atoms/responsive_card/ResponsiveCard";
import {useResponsiveLinkTarget} from "../../../../hooks/useResponsiveLinkTarget";
import {useOfferLink} from "../../../../offer/detail/hooks/use_offer_link";
import {usePromotionStyle} from "../../../../promotion/components/Promotion";
import {getPromotionTypeName} from "../../../../promotion/helpers/PromotionType";
import {IVendorDetailPromotionOffer} from "../../../types/IVendorDetailPromotionOffer";

interface IProps {
    promotion: IVendorDetailPromotion;
    vendorSlug: string;
}

export interface IVendorDetailPromotion {
    name: string | null;
    id: number;
    offer: IVendorDetailPromotionOffer;
    type: number;
}

export const VendorDetailPromotionBox = (props: IProps) => {
    const {promotion} = props;
    const promotionStyle = usePromotionStyle(props.promotion.type);
    const offerLink = useOfferLink({
        ...promotion.offer,
        vendor: {slug: props.vendorSlug}
    });

    const responsiveLinkTarget = useResponsiveLinkTarget();

    return (
        <a href={offerLink} target={responsiveLinkTarget} css={vendorDetailPromotionBox}>
            <ResponsiveCard
                cover={<ResponsiveCard.ImgCover alt={promotion.offer.name} src={promotion.offer.main_image.m_img_375x211 || undefined} />}
                coverPadding={2}
                coverDesktopPosition="left"
            >
                <ResponsiveCard.Body
                    header={
                        <Text as="h3" variant="headline_4" css={[pb(2), flex(), flexDirection("column")]}>
                            <span css={[offerNameStyle]}>{promotion.offer.name}</span>{" "}
                            <Text variant="mini_header" css={[promotionTypeNameStyle, backgroundColor(promotionStyle.color), mb(2)]}>
                                {getPromotionTypeName(promotion.type)}
                            </Text>
                        </Text>
                    }
                    text={promotion.name}
                >
                    <Button size="medium" type="button" variant="filled_primary">
                        ZOBACZ SZCZEGÓŁY
                    </Button>
                </ResponsiveCard.Body>
            </ResponsiveCard>
        </a>
    );
};

const vendorDetailPromotionBox = (theme: Theme) => css`
    display: block;
    position: relative;

    &:hover {
        text-decoration: none;
        color: ${theme.colors.secondary};
    }
`;

export const promotionTypeNameStyle = css`
    text-transform: uppercase;
    display: inline-block;
    width: fit-content;
    order: 0;
`;

const offerNameStyle = css`
    order: 1;
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {backgroundColor, flex, flexDirection, mb, pb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import ResponsiveCard from "../../../../atoms/responsive_card/ResponsiveCard";
import {useResponsiveLinkTarget} from "../../../../hooks/useResponsiveLinkTarget";
import {useOfferLink} from "../../../../offer/detail/hooks/use_offer_link";
import {usePromotionStyle} from "../../../../promotion/components/Promotion";
import {getPromotionTypeName} from "../../../../promotion/helpers/PromotionType";
import {IVendorDetailPromotionOffer} from "../../../types/IVendorDetailPromotionOffer";

interface IProps {
    promotion: IVendorDetailPromotion;
    vendorSlug: string;
}

export interface IVendorDetailPromotion {
    name: string | null;
    id: number;
    offer: IVendorDetailPromotionOffer;
    type: number;
}

export const VendorDetailPromotionBox = (props: IProps) => {
    const {promotion} = props;
    const promotionStyle = usePromotionStyle(props.promotion.type);
    const offerLink = useOfferLink({
        ...promotion.offer,
        vendor: {slug: props.vendorSlug}
    });

    const responsiveLinkTarget = useResponsiveLinkTarget();

    return (
        <a href={offerLink} target={responsiveLinkTarget} css={vendorDetailPromotionBox}>
            <ResponsiveCard
                cover={<ResponsiveCard.ImgCover alt={promotion.offer.name} src={promotion.offer.main_image.m_img_375x211 || undefined} />}
                coverPadding={2}
                coverDesktopPosition="left"
            >
                <ResponsiveCard.Body
                    header={
                        <Text as="h3" variant="headline_4" css={[pb(2), flex(), flexDirection("column")]}>
                            <span css={[offerNameStyle]}>{promotion.offer.name}</span>{" "}
                            <Text variant="mini_header" css={[promotionTypeNameStyle, backgroundColor(promotionStyle.color), mb(2)]}>
                                {getPromotionTypeName(promotion.type)}
                            </Text>
                        </Text>
                    }
                    text={promotion.name}
                >
                    <Button size="medium" type="button" variant="filled_primary">
                        ZOBACZ SZCZEGÓŁY
                    </Button>
                </ResponsiveCard.Body>
            </ResponsiveCard>
        </a>
    );
};

const vendorDetailPromotionBox = (theme: Theme) => css`
    display: block;
    position: relative;

    &:hover {
        text-decoration: none;
        color: ${theme.colors.secondary};
    }
`;

export const promotionTypeNameStyle = css`
    text-transform: uppercase;
    display: inline-block;
    width: fit-content;
    order: 0;
`;

const offerNameStyle = css`
    order: 1;
`;
