import {batch} from "react-redux";
import {Dispatch} from "redux";

import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {fetchMyApplicationsList} from "../api/fetch_my_applications_list";
import {fetchMyOffersOffersList, fetchMyOffersOffersListTypes} from "./fetch_my_offers_offers_list";
import {fetchMyOffersPropertiesList, fetchMyOffersPropertiesListTypes} from "./fetch_my_offers_properties_list";

const FETCH_MY_OFFERS_APPLIED_LIST = "my_offers/FETCH_MY_OFFERS_APPLIED_LIST";
export const fetchMyOffersAppliedListTypes = createRequestActionTypes(FETCH_MY_OFFERS_APPLIED_LIST);

export const fetchMyOffersAppliedList = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    dispatch({type: fetchMyOffersAppliedListTypes.start});

    const isAuthenticated = getState().isAuthenticated;

    if (!isAuthenticated) {
        batch(() => {
            dispatch({type: fetchMyOffersOffersListTypes.success, result: []});
            dispatch({type: fetchMyOffersPropertiesListTypes.success, result: []});
        });

        return;
    }

    fetchMyApplicationsList({})
        .then((response) => {
            return (response.results || []).reduce<{offers: number[]; properties: number[]}>(
                (acc, application) => {
                    if (application.property && !acc.properties.includes(application.property)) {
                        acc.properties.push(application.property);

                        return acc;
                    }

                    if (application.offer && !acc.offers.includes(application.offer)) {
                        acc.offers.push(application.offer);
                    }

                    return acc;
                },
                {offers: [], properties: []}
            );
        })
        .then(({offers, properties}) => {
            batch(() => {
                dispatch(fetchMyOffersOffersList(offers));
                dispatch(fetchMyOffersPropertiesList(properties));
            });
        });
};
import {batch} from "react-redux";
import {Dispatch} from "redux";

import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {fetchMyApplicationsList} from "../api/fetch_my_applications_list";
import {fetchMyOffersOffersList, fetchMyOffersOffersListTypes} from "./fetch_my_offers_offers_list";
import {fetchMyOffersPropertiesList, fetchMyOffersPropertiesListTypes} from "./fetch_my_offers_properties_list";

const FETCH_MY_OFFERS_APPLIED_LIST = "my_offers/FETCH_MY_OFFERS_APPLIED_LIST";
export const fetchMyOffersAppliedListTypes = createRequestActionTypes(FETCH_MY_OFFERS_APPLIED_LIST);

export const fetchMyOffersAppliedList = () => async (dispatch: Dispatch, getState: () => IRPStore) => {
    dispatch({type: fetchMyOffersAppliedListTypes.start});

    const isAuthenticated = getState().isAuthenticated;

    if (!isAuthenticated) {
        batch(() => {
            dispatch({type: fetchMyOffersOffersListTypes.success, result: []});
            dispatch({type: fetchMyOffersPropertiesListTypes.success, result: []});
        });

        return;
    }

    fetchMyApplicationsList({})
        .then((response) => {
            return (response.results || []).reduce<{offers: number[]; properties: number[]}>(
                (acc, application) => {
                    if (application.property && !acc.properties.includes(application.property)) {
                        acc.properties.push(application.property);

                        return acc;
                    }

                    if (application.offer && !acc.offers.includes(application.offer)) {
                        acc.offers.push(application.offer);
                    }

                    return acc;
                },
                {offers: [], properties: []}
            );
        })
        .then(({offers, properties}) => {
            batch(() => {
                dispatch(fetchMyOffersOffersList(offers));
                dispatch(fetchMyOffersPropertiesList(properties));
            });
        });
};
