import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PlaceholderIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.00003 6.59019C5.86635 6.59019 6.56865 5.8856 6.56865 5.01643C6.56865 4.14725 5.86635 3.44266 5.00003 3.44266C4.13369 3.44266 3.4314 4.14725 3.4314 5.01643C3.4314 5.8856 4.13369 6.59019 5.00003 6.59019Z" />
            <path d="M3.52938 0.0983276L2.63232 1.08193H1.07841C0.539191 1.08193 0.0980148 1.52456 0.0980148 2.06554V7.96717C0.0980148 8.50816 0.539191 8.95079 1.07841 8.95079H8.92154C9.46076 8.95079 9.90194 8.50816 9.90194 7.96717V2.06554C9.90194 1.52456 9.46076 1.08193 8.92154 1.08193H7.36762L6.47056 0.0983276H3.52938ZM4.99997 7.47538C3.64703 7.47538 2.549 6.37373 2.549 5.01636C2.549 3.65898 3.64703 2.55735 4.99997 2.55735C6.35291 2.55735 7.45096 3.65898 7.45096 5.01636C7.45096 6.37373 6.35291 7.47538 4.99997 7.47538Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PlaceholderIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.00003 6.59019C5.86635 6.59019 6.56865 5.8856 6.56865 5.01643C6.56865 4.14725 5.86635 3.44266 5.00003 3.44266C4.13369 3.44266 3.4314 4.14725 3.4314 5.01643C3.4314 5.8856 4.13369 6.59019 5.00003 6.59019Z" />
            <path d="M3.52938 0.0983276L2.63232 1.08193H1.07841C0.539191 1.08193 0.0980148 1.52456 0.0980148 2.06554V7.96717C0.0980148 8.50816 0.539191 8.95079 1.07841 8.95079H8.92154C9.46076 8.95079 9.90194 8.50816 9.90194 7.96717V2.06554C9.90194 1.52456 9.46076 1.08193 8.92154 1.08193H7.36762L6.47056 0.0983276H3.52938ZM4.99997 7.47538C3.64703 7.47538 2.549 6.37373 2.549 5.01636C2.549 3.65898 3.64703 2.55735 4.99997 2.55735C6.35291 2.55735 7.45096 3.65898 7.45096 5.01636C7.45096 6.37373 6.35291 7.47538 4.99997 7.47538Z" />
        </SvgIcon>
    );
};
