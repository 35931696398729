import React, {createContext, PropsWithChildren, useContext, useState} from "react";

//  Context
interface IMyOffersContextValue {
    removeButtonsDisabled: boolean;
    setRemoveButtonsDisabled: (areDisabled: boolean) => void;
    selectedNavigationItemValue: number;
    setSelectedNavigationItemValue: (itemValue: number) => void;
}

const MyOffersContext = createContext<IMyOffersContextValue | null>(null);

//  Provider
export const MyOffersContextProvider = (props: PropsWithChildren<{}>) => {
    const {children} = props;

    const [removeButtonsDisabled, setRemoveButtonsDisabled] = useState(false);
    const [selectedNavigationItemValue, setSelectedNavigationItemValue] = useState(0);

    return (
        <MyOffersContext.Provider
            value={{
                removeButtonsDisabled,
                setRemoveButtonsDisabled: (areDisabled) => {
                    setRemoveButtonsDisabled(areDisabled);
                },
                selectedNavigationItemValue,
                setSelectedNavigationItemValue: (itemValue) => {
                    setSelectedNavigationItemValue(itemValue);
                }
            }}
        >
            {children}
        </MyOffersContext.Provider>
    );
};

//  Hook
export const useMyOffersContext = () => {
    const ctx = useContext(MyOffersContext);

    if (!ctx) {
        throw new Error("MyOffersContext was not provided");
    }

    return ctx;
};
import React, {createContext, PropsWithChildren, useContext, useState} from "react";

//  Context
interface IMyOffersContextValue {
    removeButtonsDisabled: boolean;
    setRemoveButtonsDisabled: (areDisabled: boolean) => void;
    selectedNavigationItemValue: number;
    setSelectedNavigationItemValue: (itemValue: number) => void;
}

const MyOffersContext = createContext<IMyOffersContextValue | null>(null);

//  Provider
export const MyOffersContextProvider = (props: PropsWithChildren<{}>) => {
    const {children} = props;

    const [removeButtonsDisabled, setRemoveButtonsDisabled] = useState(false);
    const [selectedNavigationItemValue, setSelectedNavigationItemValue] = useState(0);

    return (
        <MyOffersContext.Provider
            value={{
                removeButtonsDisabled,
                setRemoveButtonsDisabled: (areDisabled) => {
                    setRemoveButtonsDisabled(areDisabled);
                },
                selectedNavigationItemValue,
                setSelectedNavigationItemValue: (itemValue) => {
                    setSelectedNavigationItemValue(itemValue);
                }
            }}
        >
            {children}
        </MyOffersContext.Provider>
    );
};

//  Hook
export const useMyOffersContext = () => {
    const ctx = useContext(MyOffersContext);

    if (!ctx) {
        throw new Error("MyOffersContext was not provided");
    }

    return ctx;
};
