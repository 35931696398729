import * as React from "react";
import {css, Theme} from "@emotion/react";

import {m, mb} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {getTextVariantStyleFor} from "@pg-design/text";

interface ITestimonialSliderItemProps {
    description: string;
    logoSrc: {
        r_img_200x115: string;
    } | null;
}

export const TestimonialSliderItem = (props: ITestimonialSliderItemProps) => {
    const {description, logoSrc} = props;

    return (
        <article>
            {/**
             - replace U+002D into U+00AD. In order to avoid line breaks
             - dangerouslySetInnerHTM - used to allow end user to add <br> tags
             */}
            <blockquote dangerouslySetInnerHTML={{__html: description?.replace(/-/g, "‑")}} css={testimonialDescription}></blockquote>
            {logoSrc && <Image css={mb(8)} alt="logo" src={logoSrc.r_img_200x115} height="115" width="200" />}
        </article>
    );
};

const testimonialDescription = (theme: Theme) => css`
    ${getTextVariantStyleFor("body_copy_0")(theme)};

    ${m(0, 0, 4, 0)}
    font-style: italic;
`;
import * as React from "react";
import {css, Theme} from "@emotion/react";

import {m, mb} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {getTextVariantStyleFor} from "@pg-design/text";

interface ITestimonialSliderItemProps {
    description: string;
    logoSrc: {
        r_img_200x115: string;
    } | null;
}

export const TestimonialSliderItem = (props: ITestimonialSliderItemProps) => {
    const {description, logoSrc} = props;

    return (
        <article>
            {/**
             - replace U+002D into U+00AD. In order to avoid line breaks
             - dangerouslySetInnerHTM - used to allow end user to add <br> tags
             */}
            <blockquote dangerouslySetInnerHTML={{__html: description?.replace(/-/g, "‑")}} css={testimonialDescription}></blockquote>
            {logoSrc && <Image css={mb(8)} alt="logo" src={logoSrc.r_img_200x115} height="115" width="200" />}
        </article>
    );
};

const testimonialDescription = (theme: Theme) => css`
    ${getTextVariantStyleFor("body_copy_0")(theme)};

    ${m(0, 0, 4, 0)}
    font-style: italic;
`;
