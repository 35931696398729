// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import styled from "@emotion/styled";

import {mr, onDesktop} from "@pg-design/helpers-css";

export const FavouriteListWrapper = styled.ul`
    padding: 0;
    overflow: hidden;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;

    ${onDesktop(mr(4))}
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import styled from "@emotion/styled";

import {mr, onDesktop} from "@pg-design/helpers-css";

export const FavouriteListWrapper = styled.ul`
    padding: 0;
    overflow: hidden;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;

    ${onDesktop(mr(4))}
`;
