import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const ourOfferSuccessHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("our_offer", payload), 500);

interface IOurOfferFormValuesTrackedData {
    name: string;
    email: string;
    phone: string;
}

export const ourOfferSuccessHit = (ourOffer: IOurOfferFormValuesTrackedData) => {
    const payload = {
        ...getTrackedSiteData(),
        ...getTrackedOurOfferData(ourOffer)
    };
    ourOfferSuccessHitAlgolytics(payload);
};

/**
 * Helper
 */
function getTrackedOurOfferData(ourOffer: IOurOfferFormValuesTrackedData) {
    return {
        name: ourOffer.name,
        phone: ourOffer.phone,
        email: ourOffer.email
    };
}
import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const ourOfferSuccessHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("our_offer", payload), 500);

interface IOurOfferFormValuesTrackedData {
    name: string;
    email: string;
    phone: string;
}

export const ourOfferSuccessHit = (ourOffer: IOurOfferFormValuesTrackedData) => {
    const payload = {
        ...getTrackedSiteData(),
        ...getTrackedOurOfferData(ourOffer)
    };
    ourOfferSuccessHitAlgolytics(payload);
};

/**
 * Helper
 */
function getTrackedOurOfferData(ourOffer: IOurOfferFormValuesTrackedData) {
    return {
        name: ourOffer.name,
        phone: ourOffer.phone,
        email: ourOffer.email
    };
}
