import React from "react";

interface IProps {
    className?: string;
}
export function BuylandoLogo(props: IProps) {
    const {className} = props;

    return (
        <svg className={className} width="252" height="64" viewBox="0 0 252 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M86.2629 45.8366H82.5007V16.3851H86.2629V29.4971C87.8495 27.3902 90.5011 26.3143 93.3115 26.3143C98.9094 26.3143 102.649 30.7074 102.649 36.2884C102.649 41.9814 98.8867 46.3745 93.3115 46.3745C90.3652 46.3745 87.8945 45.2538 86.2629 43.0349V45.8366ZM92.6317 29.7436C88.8468 29.7436 86.4671 32.5677 86.4671 36.3108C86.4671 40.1435 88.7558 42.8779 92.6317 42.8779C96.3938 42.8779 98.7507 40.0539 98.7507 36.3108C98.7507 32.5677 96.3938 29.7436 92.6317 29.7436Z"
                fill="#020341"
            />
            <path
                d="M120.621 26.8297H124.384V38.1262C124.384 43.0796 120.712 46.352 115.568 46.352C110.423 46.352 106.751 43.0796 106.751 38.1262V26.8297H110.514V37.8348C110.514 40.9503 112.463 42.9899 115.568 42.9899C118.672 42.9899 120.621 40.9279 120.621 37.8348V26.8297Z"
                fill="#020341"
            />
            <path d="M148.136 26.8297L136.826 53.9053H132.951L136.101 46.195L127.375 26.8297H131.5L138.027 41.5554L144.033 26.8297H148.136Z" fill="#020341" />
            <path d="M151.603 16.3851H155.365V45.8365H151.603V16.3851Z" fill="#020341" />
            <path
                d="M169.19 26.3143C172.091 26.3143 174.607 27.435 176.239 29.6539V26.8298H180.001V45.8365H176.239V43.0124C174.562 45.2313 172.114 46.352 169.19 46.352C163.638 46.352 159.853 41.9141 159.853 36.2659C159.853 30.7522 163.638 26.3143 169.19 26.3143ZM169.893 29.7436C167.967 29.7436 166.471 30.3936 165.36 31.6487C164.25 32.8815 163.728 34.4728 163.728 36.3107C163.728 40.0538 166.085 42.8779 169.893 42.8779C173.768 42.8779 176.058 40.1434 176.058 36.3107C176.035 32.5677 173.678 29.7436 169.893 29.7436Z"
                fill="#020341"
            />
            <path
                d="M189.339 26.8298V29.2505C190.653 27.2781 192.852 26.3143 195.503 26.3143C197.906 26.3143 199.787 27.0763 201.169 28.6453C202.597 30.2143 203.299 32.2315 203.299 34.697V45.8365H199.537V35.0332C199.537 31.9177 197.611 29.8332 194.529 29.8332C191.424 29.8332 189.52 31.9401 189.52 35.0332V45.8365H185.758V26.8298H189.339Z"
                fill="#020341"
            />
            <path
                d="M216.762 26.3143C219.663 26.3143 222.315 27.4126 223.81 29.497V16.3851H227.572V45.8365H223.81V43.0124C222.179 45.2313 219.686 46.352 216.762 46.352C211.209 46.352 207.47 41.9589 207.47 36.2659C207.47 30.7073 211.209 26.3143 216.762 26.3143ZM217.465 29.7436C213.702 29.7436 211.345 32.5676 211.345 36.3107C211.345 40.0538 213.702 42.8779 217.465 42.8779C221.34 42.8779 223.674 40.1434 223.674 36.3107C223.652 32.5676 221.34 29.7436 217.465 29.7436Z"
                fill="#020341"
            />
            <path
                d="M242.304 26.3143C248.333 26.3143 252.548 30.7522 252.548 36.2883C252.548 41.9813 248.355 46.3744 242.304 46.3744C236.275 46.3744 232.06 41.9813 232.06 36.2883C232.083 30.7522 236.275 26.3143 242.304 26.3143ZM246.972 31.6039C245.794 30.3487 244.23 29.7436 242.327 29.7436C240.4 29.7436 238.859 30.3487 237.681 31.6039C236.502 32.8591 235.935 34.3832 235.935 36.2883C235.935 38.1935 236.502 39.8072 237.681 41.04C238.859 42.2952 240.423 42.9003 242.327 42.9003C246.248 42.9003 248.763 40.1658 248.763 36.2883C248.763 34.3832 248.151 32.8367 246.972 31.6039Z"
                fill="#020341"
            />
            <path
                d="M37.1688 21.3424V21.5441C36.1943 21.4096 35.1971 21.3424 34.1998 21.3424H18.5844L0 0.0270691H15.6154C27.514 0.0270691 37.1688 9.57525 37.1688 21.3424Z"
                fill="#ED436D"
            />
            <path d="M18.5844 21.3424V42.4559C17.6325 42.568 16.6353 42.6576 15.6154 42.6576H0V0.0270691L18.5844 21.3424Z" fill="#822CEE" />
            <path
                d="M55.7537 42.6576C55.7537 48.5523 53.3511 53.8643 49.4302 57.7419C45.5094 61.6192 40.138 63.9951 34.1774 63.9951H18.5846V42.4783C23.3441 41.8283 27.6275 39.6318 30.8458 36.4266C34.7213 32.6163 37.101 27.3715 37.169 21.5664C47.6624 22.9785 55.7537 31.8766 55.7537 42.6576Z"
                fill="#F69E51"
            />
            <path
                d="M37.169 21.5442C37.101 27.3493 34.7213 32.594 30.8458 36.4044C27.6048 39.6095 23.3441 41.806 18.5846 42.456V21.3424H34.2001C35.1973 21.3424 36.1945 21.4097 37.169 21.5442Z"
                fill="#4927E4"
            />
            <path d="M18.5844 42.4558V63.9729L0 42.6575H15.6154C16.6353 42.6575 17.6325 42.5678 18.5844 42.4558Z" fill="#B82DEE" />
        </svg>
    );
}
import React from "react";

interface IProps {
    className?: string;
}
export function BuylandoLogo(props: IProps) {
    const {className} = props;

    return (
        <svg className={className} width="252" height="64" viewBox="0 0 252 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M86.2629 45.8366H82.5007V16.3851H86.2629V29.4971C87.8495 27.3902 90.5011 26.3143 93.3115 26.3143C98.9094 26.3143 102.649 30.7074 102.649 36.2884C102.649 41.9814 98.8867 46.3745 93.3115 46.3745C90.3652 46.3745 87.8945 45.2538 86.2629 43.0349V45.8366ZM92.6317 29.7436C88.8468 29.7436 86.4671 32.5677 86.4671 36.3108C86.4671 40.1435 88.7558 42.8779 92.6317 42.8779C96.3938 42.8779 98.7507 40.0539 98.7507 36.3108C98.7507 32.5677 96.3938 29.7436 92.6317 29.7436Z"
                fill="#020341"
            />
            <path
                d="M120.621 26.8297H124.384V38.1262C124.384 43.0796 120.712 46.352 115.568 46.352C110.423 46.352 106.751 43.0796 106.751 38.1262V26.8297H110.514V37.8348C110.514 40.9503 112.463 42.9899 115.568 42.9899C118.672 42.9899 120.621 40.9279 120.621 37.8348V26.8297Z"
                fill="#020341"
            />
            <path d="M148.136 26.8297L136.826 53.9053H132.951L136.101 46.195L127.375 26.8297H131.5L138.027 41.5554L144.033 26.8297H148.136Z" fill="#020341" />
            <path d="M151.603 16.3851H155.365V45.8365H151.603V16.3851Z" fill="#020341" />
            <path
                d="M169.19 26.3143C172.091 26.3143 174.607 27.435 176.239 29.6539V26.8298H180.001V45.8365H176.239V43.0124C174.562 45.2313 172.114 46.352 169.19 46.352C163.638 46.352 159.853 41.9141 159.853 36.2659C159.853 30.7522 163.638 26.3143 169.19 26.3143ZM169.893 29.7436C167.967 29.7436 166.471 30.3936 165.36 31.6487C164.25 32.8815 163.728 34.4728 163.728 36.3107C163.728 40.0538 166.085 42.8779 169.893 42.8779C173.768 42.8779 176.058 40.1434 176.058 36.3107C176.035 32.5677 173.678 29.7436 169.893 29.7436Z"
                fill="#020341"
            />
            <path
                d="M189.339 26.8298V29.2505C190.653 27.2781 192.852 26.3143 195.503 26.3143C197.906 26.3143 199.787 27.0763 201.169 28.6453C202.597 30.2143 203.299 32.2315 203.299 34.697V45.8365H199.537V35.0332C199.537 31.9177 197.611 29.8332 194.529 29.8332C191.424 29.8332 189.52 31.9401 189.52 35.0332V45.8365H185.758V26.8298H189.339Z"
                fill="#020341"
            />
            <path
                d="M216.762 26.3143C219.663 26.3143 222.315 27.4126 223.81 29.497V16.3851H227.572V45.8365H223.81V43.0124C222.179 45.2313 219.686 46.352 216.762 46.352C211.209 46.352 207.47 41.9589 207.47 36.2659C207.47 30.7073 211.209 26.3143 216.762 26.3143ZM217.465 29.7436C213.702 29.7436 211.345 32.5676 211.345 36.3107C211.345 40.0538 213.702 42.8779 217.465 42.8779C221.34 42.8779 223.674 40.1434 223.674 36.3107C223.652 32.5676 221.34 29.7436 217.465 29.7436Z"
                fill="#020341"
            />
            <path
                d="M242.304 26.3143C248.333 26.3143 252.548 30.7522 252.548 36.2883C252.548 41.9813 248.355 46.3744 242.304 46.3744C236.275 46.3744 232.06 41.9813 232.06 36.2883C232.083 30.7522 236.275 26.3143 242.304 26.3143ZM246.972 31.6039C245.794 30.3487 244.23 29.7436 242.327 29.7436C240.4 29.7436 238.859 30.3487 237.681 31.6039C236.502 32.8591 235.935 34.3832 235.935 36.2883C235.935 38.1935 236.502 39.8072 237.681 41.04C238.859 42.2952 240.423 42.9003 242.327 42.9003C246.248 42.9003 248.763 40.1658 248.763 36.2883C248.763 34.3832 248.151 32.8367 246.972 31.6039Z"
                fill="#020341"
            />
            <path
                d="M37.1688 21.3424V21.5441C36.1943 21.4096 35.1971 21.3424 34.1998 21.3424H18.5844L0 0.0270691H15.6154C27.514 0.0270691 37.1688 9.57525 37.1688 21.3424Z"
                fill="#ED436D"
            />
            <path d="M18.5844 21.3424V42.4559C17.6325 42.568 16.6353 42.6576 15.6154 42.6576H0V0.0270691L18.5844 21.3424Z" fill="#822CEE" />
            <path
                d="M55.7537 42.6576C55.7537 48.5523 53.3511 53.8643 49.4302 57.7419C45.5094 61.6192 40.138 63.9951 34.1774 63.9951H18.5846V42.4783C23.3441 41.8283 27.6275 39.6318 30.8458 36.4266C34.7213 32.6163 37.101 27.3715 37.169 21.5664C47.6624 22.9785 55.7537 31.8766 55.7537 42.6576Z"
                fill="#F69E51"
            />
            <path
                d="M37.169 21.5442C37.101 27.3493 34.7213 32.594 30.8458 36.4044C27.6048 39.6095 23.3441 41.806 18.5846 42.456V21.3424H34.2001C35.1973 21.3424 36.1945 21.4097 37.169 21.5442Z"
                fill="#4927E4"
            />
            <path d="M18.5844 42.4558V63.9729L0 42.6575H15.6154C16.6353 42.6575 17.6325 42.5678 18.5844 42.4558Z" fill="#B82DEE" />
        </svg>
    );
}
