import {Fragment} from "react";
import {css, useTheme} from "@emotion/react";

import {calculateRemSize as crs} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {ICampaignDetails} from "../../types/ICampaignDetails";

interface IProps {
    campaign: ICampaignDetails;
}

export function SmsCampaignDetails(props: IProps) {
    const {campaign} = props;
    const theme = useTheme();

    return (
        <div css={details}>
            <Text variant="headline_3" align="center" color={theme.colors.primary}>
                {campaign.highlight_title}
            </Text>

            {campaign.highlight_description &&
                campaign.highlight_description
                    .split("\n")
                    .filter((n) => Boolean(n))
                    .map((item, index) => {
                        return (
                            <Fragment key={`smscd_${index}`}>
                                {index !== 0 && <hr css={separator} />}
                                <Text align="center" color="white">
                                    {item}
                                </Text>
                            </Fragment>
                        );
                    })}
        </div>
    );
}

// Styles
const details = css`
    display: flex;
    flex-direction: column;
`;

const separator = css`
    background: white;
    border: 0;
    height: 1px;
    width: ${crs(5)};
`;
import {Fragment} from "react";
import {css, useTheme} from "@emotion/react";

import {calculateRemSize as crs} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {ICampaignDetails} from "../../types/ICampaignDetails";

interface IProps {
    campaign: ICampaignDetails;
}

export function SmsCampaignDetails(props: IProps) {
    const {campaign} = props;
    const theme = useTheme();

    return (
        <div css={details}>
            <Text variant="headline_3" align="center" color={theme.colors.primary}>
                {campaign.highlight_title}
            </Text>

            {campaign.highlight_description &&
                campaign.highlight_description
                    .split("\n")
                    .filter((n) => Boolean(n))
                    .map((item, index) => {
                        return (
                            <Fragment key={`smscd_${index}`}>
                                {index !== 0 && <hr css={separator} />}
                                <Text align="center" color="white">
                                    {item}
                                </Text>
                            </Fragment>
                        );
                    })}
        </div>
    );
}

// Styles
const details = css`
    display: flex;
    flex-direction: column;
`;

const separator = css`
    background: white;
    border: 0;
    height: 1px;
    width: ${crs(5)};
`;
