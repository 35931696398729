import React from "react";
import {css, useTheme} from "@emotion/react";

import {ResponsiveRender} from "@pg-design/grid-module";
import {flexAbsoluteCenter, mb, p} from "@pg-design/helpers-css";
import {BrandListIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

export const EmptyAppliedList = () => {
    const theme = useTheme();

    return (
        <div css={emptyAppliedList}>
            <ResponsiveRender
                desktop={<BrandListIcon css={mb(4)} size="9.6" wrapperColor={theme.colors.primary} wrapperSize="9.6" />}
                mobile={<BrandListIcon css={mb(3)} size="7.2" wrapperColor={theme.colors.primary} wrapperSize="7.2" />}
            />

            <Text align="center" as="div" variant="headline_3">
                Nie masz jeszcze żadnych wysłanych zapytań
            </Text>
        </div>
    );
};

const emptyAppliedList = css`
    ${flexAbsoluteCenter};
    flex-direction: column;
    ${p(10, 0)};
`;
import React from "react";
import {css, useTheme} from "@emotion/react";

import {ResponsiveRender} from "@pg-design/grid-module";
import {flexAbsoluteCenter, mb, p} from "@pg-design/helpers-css";
import {BrandListIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

export const EmptyAppliedList = () => {
    const theme = useTheme();

    return (
        <div css={emptyAppliedList}>
            <ResponsiveRender
                desktop={<BrandListIcon css={mb(4)} size="9.6" wrapperColor={theme.colors.primary} wrapperSize="9.6" />}
                mobile={<BrandListIcon css={mb(3)} size="7.2" wrapperColor={theme.colors.primary} wrapperSize="7.2" />}
            />

            <Text align="center" as="div" variant="headline_3">
                Nie masz jeszcze żadnych wysłanych zapytań
            </Text>
        </div>
    );
};

const emptyAppliedList = css`
    ${flexAbsoluteCenter};
    flex-direction: column;
    ${p(10, 0)};
`;
