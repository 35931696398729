import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CartTimerIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.133 7.396a.8.8 0 1 0 0 1.601.8.8 0 0 0 0-1.601Zm0 1.072a.267.267 0 1 1-.002-.535.267.267 0 0 1 .002.535ZM6.865 1a2.136 2.136 0 1 0-.002 4.271A2.136 2.136 0 0 0 6.865 1Zm0 3.736a1.601 1.601 0 1 1 0-3.203 1.601 1.601 0 0 1 0 3.203Z" />
            <path d="M7.03 2.068h-.534v1.601h1.17v-.534h-.635V2.068ZM7.4 6.337H3.055l-.487-2.935H4.19v-.534H2.48l-.222-1.34-.037.005H1v.535h.806l.79 4.766v.037h.006l.001.006.036-.006h5.294V5.795H7.4v.542ZM3.395 7.405a.8.8 0 1 0 0 1.601.8.8 0 0 0 0-1.601Zm0 1.066a.267.267 0 1 1 .267-.266.267.267 0 0 1-.267.263v.003Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CartTimerIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.133 7.396a.8.8 0 1 0 0 1.601.8.8 0 0 0 0-1.601Zm0 1.072a.267.267 0 1 1-.002-.535.267.267 0 0 1 .002.535ZM6.865 1a2.136 2.136 0 1 0-.002 4.271A2.136 2.136 0 0 0 6.865 1Zm0 3.736a1.601 1.601 0 1 1 0-3.203 1.601 1.601 0 0 1 0 3.203Z" />
            <path d="M7.03 2.068h-.534v1.601h1.17v-.534h-.635V2.068ZM7.4 6.337H3.055l-.487-2.935H4.19v-.534H2.48l-.222-1.34-.037.005H1v.535h.806l.79 4.766v.037h.006l.001.006.036-.006h5.294V5.795H7.4v.542ZM3.395 7.405a.8.8 0 1 0 0 1.601.8.8 0 0 0 0-1.601Zm0 1.066a.267.267 0 1 1 .267-.266.267.267 0 0 1-.267.263v.003Z" />
        </SvgIcon>
    );
};
