import {pluralize} from "@pg-mono/string-utils";

import {offerPlural} from "../../offer/utils/offer_plural";

export enum PromotionType {
    OFFER = 1,
    OPENDAYS = 2,
    DISCOUNT = 3,
    LASTMINUTE = 4,
    PRESALE = 5,
    EXHIBITION = 6,
    NO_CONTRIBUTION = 7
}

export const getPromotionTypeName = (type: PromotionType, offerLongVersion?: boolean): string => {
    switch (type) {
        case PromotionType.OPENDAYS:
            return "Dni Otwarte";
        case PromotionType.DISCOUNT:
            return "Przecena";
        case PromotionType.LASTMINUTE:
            return "Last minute";
        case PromotionType.PRESALE:
            return "Przedsprzedaż";
        case PromotionType.EXHIBITION:
            return "Targi Mieszkaniowe";
        case PromotionType.NO_CONTRIBUTION:
            return `Nieruchomość bez wkładu własnego`;
        default:
            //  Offer
            return offerLongVersion ? "Oferty promocyjne" : "Promocja";
    }
};

export const getPromotionMetaDataTypeName = (type: PromotionType): string => {
    switch (type) {
        case PromotionType.OFFER:
            return "promocje";
        case PromotionType.OPENDAYS:
            return "dni otwarte";
        case PromotionType.DISCOUNT:
            return "przeceny";
        case PromotionType.LASTMINUTE:
            return "oferty last minute";
        case PromotionType.PRESALE:
            return "przedsprzedaże";
        case PromotionType.EXHIBITION:
            return "targi mieszkaniowe";
        case PromotionType.NO_CONTRIBUTION:
            return "bez wkładu własnego";
        default:
            return "";
    }
};

const promotionPlural = pluralize(["specjalną", "specjalne", "specjalnych"]);
const offerTypePlural = pluralize(["promocyjną", "promocyjne", "promocyjnych"]);
const opendaysPlural = pluralize(["dni otwartych", "dni otwartych", "dni otwartych"]);
const discountPlural = pluralize(["przeceny", "przeceny", "przeceny"]);
const lastMinutePlural = pluralize(["last minute", "last minute", "last minute"]);
const presalePlural = pluralize(["przedsprzedaży", "przedsprzedaży", "przedsprzedaży"]);
const exhibitionPlural = pluralize(["targów mieszkaniowych", "targów mieszkaniowych", "targów mieszkaniowych"]);

export const getPromotionTypePlural = (type: PromotionType, count: number): string => {
    const getTypePlural = (typeValue: string) => `${offerPlural(count)} ${typeValue}`;

    switch (type) {
        case PromotionType.OFFER:
            return getTypePlural(offerTypePlural(count));
        case PromotionType.OPENDAYS:
            return getTypePlural(opendaysPlural(count));
        case PromotionType.DISCOUNT:
            return getTypePlural(discountPlural(count));
        case PromotionType.LASTMINUTE:
            return getTypePlural(lastMinutePlural(count));
        case PromotionType.PRESALE:
            return getTypePlural(presalePlural(count));
        case PromotionType.EXHIBITION:
            return getTypePlural(exhibitionPlural(count));
        default:
            return getTypePlural(promotionPlural(count));
    }
};

export const promotionTypes = [
    {label: "Oferty promocyjne", value: PromotionType.OFFER},
    {label: "Dni otwarte", value: PromotionType.OPENDAYS},
    {label: "Przeceny", value: PromotionType.DISCOUNT},
    {label: "Last minute", value: PromotionType.LASTMINUTE},
    {label: "Przedsprzedaż", value: PromotionType.PRESALE},
    {label: "Targi mieszkaniowe", value: PromotionType.EXHIBITION},
    {label: "Bez wkładu własnego", value: PromotionType.NO_CONTRIBUTION}
];
import {pluralize} from "@pg-mono/string-utils";

import {offerPlural} from "../../offer/utils/offer_plural";

export enum PromotionType {
    OFFER = 1,
    OPENDAYS = 2,
    DISCOUNT = 3,
    LASTMINUTE = 4,
    PRESALE = 5,
    EXHIBITION = 6,
    NO_CONTRIBUTION = 7
}

export const getPromotionTypeName = (type: PromotionType, offerLongVersion?: boolean): string => {
    switch (type) {
        case PromotionType.OPENDAYS:
            return "Dni Otwarte";
        case PromotionType.DISCOUNT:
            return "Przecena";
        case PromotionType.LASTMINUTE:
            return "Last minute";
        case PromotionType.PRESALE:
            return "Przedsprzedaż";
        case PromotionType.EXHIBITION:
            return "Targi Mieszkaniowe";
        case PromotionType.NO_CONTRIBUTION:
            return `Nieruchomość bez wkładu własnego`;
        default:
            //  Offer
            return offerLongVersion ? "Oferty promocyjne" : "Promocja";
    }
};

export const getPromotionMetaDataTypeName = (type: PromotionType): string => {
    switch (type) {
        case PromotionType.OFFER:
            return "promocje";
        case PromotionType.OPENDAYS:
            return "dni otwarte";
        case PromotionType.DISCOUNT:
            return "przeceny";
        case PromotionType.LASTMINUTE:
            return "oferty last minute";
        case PromotionType.PRESALE:
            return "przedsprzedaże";
        case PromotionType.EXHIBITION:
            return "targi mieszkaniowe";
        case PromotionType.NO_CONTRIBUTION:
            return "bez wkładu własnego";
        default:
            return "";
    }
};

const promotionPlural = pluralize(["specjalną", "specjalne", "specjalnych"]);
const offerTypePlural = pluralize(["promocyjną", "promocyjne", "promocyjnych"]);
const opendaysPlural = pluralize(["dni otwartych", "dni otwartych", "dni otwartych"]);
const discountPlural = pluralize(["przeceny", "przeceny", "przeceny"]);
const lastMinutePlural = pluralize(["last minute", "last minute", "last minute"]);
const presalePlural = pluralize(["przedsprzedaży", "przedsprzedaży", "przedsprzedaży"]);
const exhibitionPlural = pluralize(["targów mieszkaniowych", "targów mieszkaniowych", "targów mieszkaniowych"]);

export const getPromotionTypePlural = (type: PromotionType, count: number): string => {
    const getTypePlural = (typeValue: string) => `${offerPlural(count)} ${typeValue}`;

    switch (type) {
        case PromotionType.OFFER:
            return getTypePlural(offerTypePlural(count));
        case PromotionType.OPENDAYS:
            return getTypePlural(opendaysPlural(count));
        case PromotionType.DISCOUNT:
            return getTypePlural(discountPlural(count));
        case PromotionType.LASTMINUTE:
            return getTypePlural(lastMinutePlural(count));
        case PromotionType.PRESALE:
            return getTypePlural(presalePlural(count));
        case PromotionType.EXHIBITION:
            return getTypePlural(exhibitionPlural(count));
        default:
            return getTypePlural(promotionPlural(count));
    }
};

export const promotionTypes = [
    {label: "Oferty promocyjne", value: PromotionType.OFFER},
    {label: "Dni otwarte", value: PromotionType.OPENDAYS},
    {label: "Przeceny", value: PromotionType.DISCOUNT},
    {label: "Last minute", value: PromotionType.LASTMINUTE},
    {label: "Przedsprzedaż", value: PromotionType.PRESALE},
    {label: "Targi mieszkaniowe", value: PromotionType.EXHIBITION},
    {label: "Bez wkładu własnego", value: PromotionType.NO_CONTRIBUTION}
];
