import {hitGoogleTagManager} from "./init_google_tag_manager";

export const gtmGaEventOurOfferFormSuccess = () => {
    const gtmData = {
        event: "GAEvent",
        GAEventCategory: "formularz",
        GAEventAction: "widok dla dewelopera",
        GAEventLabel: "vendor lead"
    };
    hitGoogleTagManager(gtmData);
};
import {hitGoogleTagManager} from "./init_google_tag_manager";

export const gtmGaEventOurOfferFormSuccess = () => {
    const gtmData = {
        event: "GAEvent",
        GAEventCategory: "formularz",
        GAEventAction: "widok dla dewelopera",
        GAEventLabel: "vendor lead"
    };
    hitGoogleTagManager(gtmData);
};
