import React from "react";
import {css, Theme} from "@emotion/react";

import {Image} from "@pg-design/image-module";

interface IProps {
    alt: string;
    src?: string;
}

export const ResponsiveCardImgCover: React.FC<IProps> = ({alt, src}) => <Image src={src} alt={alt} width="auto" height="auto" css={[cardBoxImageStyle]} />;

const cardBoxImageStyle = (theme: Theme) => css`
    height: auto;
    width: 100%;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        height: 100%;
        width: 100%;
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Image} from "@pg-design/image-module";

interface IProps {
    alt: string;
    src?: string;
}

export const ResponsiveCardImgCover: React.FC<IProps> = ({alt, src}) => <Image src={src} alt={alt} width="auto" height="auto" css={[cardBoxImageStyle]} />;

const cardBoxImageStyle = (theme: Theme) => css`
    height: auto;
    width: 100%;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        height: 100%;
        width: 100%;
    }
`;
