import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button-module";
import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize as crs, p} from "@pg-design/helpers-css";
import {BrandMapMarkersIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {monthDeclension} from "@pg-mono/string-utils";
import {useUserDevice} from "@pg-mono/user-device";

import {useOfferLink} from "../../../offer/detail/hooks/use_offer_link";
import {ICampaignDetails} from "../../types/ICampaignDetails";
import {getDateFromCampaignDetails} from "../../utils/get_date_from_campaign_details";
import {SmsCampaignDetails} from "./SmsCampaignDetail";
import {SmsCampaignMenu} from "./SmsCampaignMenu";

import mapBg from "../../assets/campaign_map.jpg";

interface IProps {
    campaign: ICampaignDetails;
}

export function SmsCampaign(props: IProps) {
    const {campaign} = props;
    const theme = useTheme();
    const {isMobile} = useUserDevice();

    const offerLink = useOfferLink(campaign.offer);

    const [lng, lat] = campaign.offer.geo_point.coordinates;
    const mapLink = `http://www.google.com/maps/place/${lat},${lng}`;

    const address = campaign.address ? campaign.address : campaign.offer.sales_offices[0].address || campaign.offer.address;

    return (
        <div css={smsCampaign}>
            <InfoWrap backgroundImgUrl={campaign.image ? campaign.image.m_img_720x1280 : ""}>
                <WideContent css={info}>
                    {campaign.offer.vendor && campaign.offer.vendor.logo && campaign.offer.vendor.logo.v_log_120x90 && (
                        <div css={logoWrap}>
                            <Image css={vendorLogo} src={campaign.offer.vendor.logo.v_log_120x90} alt={campaign.offer.vendor.name} width="91px" height="68px" />
                        </div>
                    )}
                    <div css={infoContent}>
                        <div css={borderTl} />
                        <div css={borderTr} />
                        <div css={borderBr} />
                        <div css={borderBl} />
                        <Text color="white" variant="headline_6">
                            {campaign.offer.name}
                        </Text>
                        <Text color={theme.colors.primary} variant="headline_2" css={campaignSlogan} align="center">
                            {campaign.slogan ? campaign.slogan : "Zapraszamy na dni otwarte"}
                        </Text>
                        <hr css={separator} />
                        <Text color="white" variant="headline_6">
                            {getOpenDaysDateTime(campaign)}
                        </Text>
                        <hr css={separator} />
                        <Text color="white" variant="body_copy_0">
                            {address.split("\n").map((item, index) => (
                                <span key={`sca_${index}`}>{item}</span>
                            ))}
                        </Text>
                    </div>
                    <div css={arrowWrap}>
                        <span css={arrow}>{">"}</span>
                    </div>
                </WideContent>
            </InfoWrap>
            <div css={campaignDetailsWrap}>
                <SmsCampaignDetails campaign={campaign} />
            </div>
            <div css={offerImageWrap}>
                {campaign.offer.main_image && (
                    <a css={offerImageLink} href={campaign.landing_page_url ? campaign.landing_page_url : offerLink}>
                        {isMobile ? (
                            <Image css={offerImage} alt={campaign.offer.name} width="604px" height="415px" src={campaign.offer.main_image.m_img_415} />
                        ) : (
                            <Image css={offerImage} alt={campaign.offer.name} width="1135px" height="780px" src={campaign.offer.main_image.m_img_700} />
                        )}
                    </a>
                )}
                <Button
                    css={offerImageButton}
                    variant="filled_secondary"
                    size="x-small"
                    href={campaign.landing_page_url ? campaign.landing_page_url : offerLink}
                >
                    Zobacz stronę inwestycji
                </Button>
            </div>
            <div>
                <a href={mapLink} css={mapLinkStyle}>
                    <Button variant="filled_primary" size="small" iconLeft={BrandMapMarkersIcon} type="submit">
                        Zobacz na mapie
                    </Button>
                </a>
            </div>
            <SmsCampaignMenu campaign={campaign} />
        </div>
    );
}

//  Utils
function getOpenDaysDateTime(campaign: Pick<ICampaignDetails, "opendays_date_range" | "opendays_time_range">) {
    const {fromDate: lowerDate, toDate: upperDate} = getDateFromCampaignDetails(campaign);

    const isSameYear = lowerDate.getFullYear() === upperDate.getFullYear();
    const isSameMonth = lowerDate.getMonth() === upperDate.getMonth();
    const isSameDay = lowerDate.getDate() === upperDate.getDate();

    let outDate = "";

    if (isSameYear && isSameMonth && isSameDay) {
        outDate += `${lowerDate.getDate()} ${monthDeclension(lowerDate.getMonth())}`;
    } else if (isSameYear && isSameMonth) {
        outDate += `${lowerDate.getDate()} - ${upperDate.getDate()} ${monthDeclension(upperDate.getMonth())}`;
    } else {
        outDate += `${lowerDate.getDate()} ${monthDeclension(lowerDate.getMonth())} - ${upperDate.getDate()} ${monthDeclension(upperDate.getMonth())}`;
    }

    if (lowerDate.getHours() === upperDate.getHours() && lowerDate.getMinutes() === upperDate.getMinutes()) {
        outDate += `, godz. ${lowerDate.getHours()}:${lowerDate.getMinutes().toString().padStart(2, "0")}`;
    } else {
        outDate += `, godz. ${lowerDate.getHours()}:${lowerDate.getMinutes().toString().padStart(2, "0")} - ${upperDate.getHours()}:${upperDate
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
    }

    return outDate;
}

//  Styles
const smsCampaign = css`
    width: 100%;
`;

const InfoWrap = styled.div<{backgroundImgUrl: string}>`
    ${({backgroundImgUrl}) => css`
        background: url("${backgroundImgUrl}") center center / cover no-repeat rgba(0, 0, 0, 0.6);
        background-blend-mode: color;
        min-height: ${crs(4)};
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `}
`;

const info = css`
    display: flex;
    flex-direction: column;
`;

const logoWrap = css`
    display: flex;
    justify-content: center;
`;

const vendorLogo = css`
    ${p(1)};
    background: white;
`;

const borderColor = "rgba(255, 255, 255, 0.5)";

const infoContent = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    ${p(2)};
    gap: ${crs(0.5)};
    border: solid ${borderColor};
    border-width: 0 0.1rem;
`;

const fakeBorderStyle = css`
    position: absolute;
    width: ${crs(4)};
    height: 1px;
    background: ${borderColor};
`;

const borderTl = css`
    ${fakeBorderStyle};
    inset: 0 auto auto 0;
`;

const borderTr = css`
    ${fakeBorderStyle};
    inset: 0 0 auto auto;
`;

const borderBl = css`
    ${fakeBorderStyle};
    inset: auto auto 0 0;
`;

const borderBr = css`
    ${fakeBorderStyle};
    inset: auto 0 0 auto;
`;

const separator = css`
    background: white;
    border: 0;
    height: 1px;
    width: ${crs(5)};
`;

const campaignSlogan = css`
    text-transform: uppercase;
`;

const arrowWrap = css`
    display: flex;
    justify-content: center;
    ${p(4)}
`;

const arrow = css`
    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateX(0);
        }
        40% {
            transform: translateX(-30px);
        }
        60% {
            transform: translateX(-15px);
        }
    }

    display: inline-flex;
    rotate: 90deg;
    animation: bounce 2s infinite;
    color: white;
    font-size: ${crs(8)};
`;

const campaignDetailsWrap = (theme: Theme) => css`
    min-height: ${crs(4)};
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.gray[700]};
`;

const offerImageWrap = css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const offerImageButton = css`
    position: absolute;
    inset: auto auto ${crs(2)} auto;
`;

const offerImage = css`
    display: flex;

    & img {
        max-width: 100%;
        height: auto;
        width: 100%;
    }
`;

const offerImageLink = css`
    width: 100%;
`;

const mapLinkStyle = css`
    width: 100%;
    height: 23.2rem;
    background: url(${mapBg}) center center / cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
`;
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button-module";
import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize as crs, p} from "@pg-design/helpers-css";
import {BrandMapMarkersIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {monthDeclension} from "@pg-mono/string-utils";
import {useUserDevice} from "@pg-mono/user-device";

import {useOfferLink} from "../../../offer/detail/hooks/use_offer_link";
import {ICampaignDetails} from "../../types/ICampaignDetails";
import {getDateFromCampaignDetails} from "../../utils/get_date_from_campaign_details";
import {SmsCampaignDetails} from "./SmsCampaignDetail";
import {SmsCampaignMenu} from "./SmsCampaignMenu";

import mapBg from "../../assets/campaign_map.jpg";

interface IProps {
    campaign: ICampaignDetails;
}

export function SmsCampaign(props: IProps) {
    const {campaign} = props;
    const theme = useTheme();
    const {isMobile} = useUserDevice();

    const offerLink = useOfferLink(campaign.offer);

    const [lng, lat] = campaign.offer.geo_point.coordinates;
    const mapLink = `http://www.google.com/maps/place/${lat},${lng}`;

    const address = campaign.address ? campaign.address : campaign.offer.sales_offices[0].address || campaign.offer.address;

    return (
        <div css={smsCampaign}>
            <InfoWrap backgroundImgUrl={campaign.image ? campaign.image.m_img_720x1280 : ""}>
                <WideContent css={info}>
                    {campaign.offer.vendor && campaign.offer.vendor.logo && campaign.offer.vendor.logo.v_log_120x90 && (
                        <div css={logoWrap}>
                            <Image css={vendorLogo} src={campaign.offer.vendor.logo.v_log_120x90} alt={campaign.offer.vendor.name} width="91px" height="68px" />
                        </div>
                    )}
                    <div css={infoContent}>
                        <div css={borderTl} />
                        <div css={borderTr} />
                        <div css={borderBr} />
                        <div css={borderBl} />
                        <Text color="white" variant="headline_6">
                            {campaign.offer.name}
                        </Text>
                        <Text color={theme.colors.primary} variant="headline_2" css={campaignSlogan} align="center">
                            {campaign.slogan ? campaign.slogan : "Zapraszamy na dni otwarte"}
                        </Text>
                        <hr css={separator} />
                        <Text color="white" variant="headline_6">
                            {getOpenDaysDateTime(campaign)}
                        </Text>
                        <hr css={separator} />
                        <Text color="white" variant="body_copy_0">
                            {address.split("\n").map((item, index) => (
                                <span key={`sca_${index}`}>{item}</span>
                            ))}
                        </Text>
                    </div>
                    <div css={arrowWrap}>
                        <span css={arrow}>{">"}</span>
                    </div>
                </WideContent>
            </InfoWrap>
            <div css={campaignDetailsWrap}>
                <SmsCampaignDetails campaign={campaign} />
            </div>
            <div css={offerImageWrap}>
                {campaign.offer.main_image && (
                    <a css={offerImageLink} href={campaign.landing_page_url ? campaign.landing_page_url : offerLink}>
                        {isMobile ? (
                            <Image css={offerImage} alt={campaign.offer.name} width="604px" height="415px" src={campaign.offer.main_image.m_img_415} />
                        ) : (
                            <Image css={offerImage} alt={campaign.offer.name} width="1135px" height="780px" src={campaign.offer.main_image.m_img_700} />
                        )}
                    </a>
                )}
                <Button
                    css={offerImageButton}
                    variant="filled_secondary"
                    size="x-small"
                    href={campaign.landing_page_url ? campaign.landing_page_url : offerLink}
                >
                    Zobacz stronę inwestycji
                </Button>
            </div>
            <div>
                <a href={mapLink} css={mapLinkStyle}>
                    <Button variant="filled_primary" size="small" iconLeft={BrandMapMarkersIcon} type="submit">
                        Zobacz na mapie
                    </Button>
                </a>
            </div>
            <SmsCampaignMenu campaign={campaign} />
        </div>
    );
}

//  Utils
function getOpenDaysDateTime(campaign: Pick<ICampaignDetails, "opendays_date_range" | "opendays_time_range">) {
    const {fromDate: lowerDate, toDate: upperDate} = getDateFromCampaignDetails(campaign);

    const isSameYear = lowerDate.getFullYear() === upperDate.getFullYear();
    const isSameMonth = lowerDate.getMonth() === upperDate.getMonth();
    const isSameDay = lowerDate.getDate() === upperDate.getDate();

    let outDate = "";

    if (isSameYear && isSameMonth && isSameDay) {
        outDate += `${lowerDate.getDate()} ${monthDeclension(lowerDate.getMonth())}`;
    } else if (isSameYear && isSameMonth) {
        outDate += `${lowerDate.getDate()} - ${upperDate.getDate()} ${monthDeclension(upperDate.getMonth())}`;
    } else {
        outDate += `${lowerDate.getDate()} ${monthDeclension(lowerDate.getMonth())} - ${upperDate.getDate()} ${monthDeclension(upperDate.getMonth())}`;
    }

    if (lowerDate.getHours() === upperDate.getHours() && lowerDate.getMinutes() === upperDate.getMinutes()) {
        outDate += `, godz. ${lowerDate.getHours()}:${lowerDate.getMinutes().toString().padStart(2, "0")}`;
    } else {
        outDate += `, godz. ${lowerDate.getHours()}:${lowerDate.getMinutes().toString().padStart(2, "0")} - ${upperDate.getHours()}:${upperDate
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
    }

    return outDate;
}

//  Styles
const smsCampaign = css`
    width: 100%;
`;

const InfoWrap = styled.div<{backgroundImgUrl: string}>`
    ${({backgroundImgUrl}) => css`
        background: url("${backgroundImgUrl}") center center / cover no-repeat rgba(0, 0, 0, 0.6);
        background-blend-mode: color;
        min-height: ${crs(4)};
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `}
`;

const info = css`
    display: flex;
    flex-direction: column;
`;

const logoWrap = css`
    display: flex;
    justify-content: center;
`;

const vendorLogo = css`
    ${p(1)};
    background: white;
`;

const borderColor = "rgba(255, 255, 255, 0.5)";

const infoContent = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    ${p(2)};
    gap: ${crs(0.5)};
    border: solid ${borderColor};
    border-width: 0 0.1rem;
`;

const fakeBorderStyle = css`
    position: absolute;
    width: ${crs(4)};
    height: 1px;
    background: ${borderColor};
`;

const borderTl = css`
    ${fakeBorderStyle};
    inset: 0 auto auto 0;
`;

const borderTr = css`
    ${fakeBorderStyle};
    inset: 0 0 auto auto;
`;

const borderBl = css`
    ${fakeBorderStyle};
    inset: auto auto 0 0;
`;

const borderBr = css`
    ${fakeBorderStyle};
    inset: auto 0 0 auto;
`;

const separator = css`
    background: white;
    border: 0;
    height: 1px;
    width: ${crs(5)};
`;

const campaignSlogan = css`
    text-transform: uppercase;
`;

const arrowWrap = css`
    display: flex;
    justify-content: center;
    ${p(4)}
`;

const arrow = css`
    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateX(0);
        }
        40% {
            transform: translateX(-30px);
        }
        60% {
            transform: translateX(-15px);
        }
    }

    display: inline-flex;
    rotate: 90deg;
    animation: bounce 2s infinite;
    color: white;
    font-size: ${crs(8)};
`;

const campaignDetailsWrap = (theme: Theme) => css`
    min-height: ${crs(4)};
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.gray[700]};
`;

const offerImageWrap = css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const offerImageButton = css`
    position: absolute;
    inset: auto auto ${crs(2)} auto;
`;

const offerImage = css`
    display: flex;

    & img {
        max-width: 100%;
        height: auto;
        width: 100%;
    }
`;

const offerImageLink = css`
    width: 100%;
`;

const mapLinkStyle = css`
    width: 100%;
    height: 23.2rem;
    background: url(${mapBg}) center center / cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
`;
