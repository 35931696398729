import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {m, p} from "@pg-design/helpers-css";
import {Tabs} from "@pg-design/tabs";
import {useIsMounted} from "@pg-mono/hooks";

import {IRPStore} from "../../app/rp_reducer";
import {TwoColumnGridWithStickyAside} from "../../atoms/TwoColumnGrid";
import {useFavourites} from "../../favourite/hooks/use_favourites";
import {
    handleFavouritesListApplicationOpenHit,
    handleFavouritesListNavigationClickHit,
    handleFavouritesListRenderHit
} from "../../tracking/algolytics_hits/favourites_list_hit";
import {gtmClickMyListApplicationsNavigation, gtmClickMyListNavigation} from "../../tracking/google_tag_manager/gtm_favourites_list";
import {AppliedList} from "../components/AppliedList";
import {FavouritesList} from "../components/FavouritesList/FavouritesList";
import {FavouritesListApplication} from "../components/FavouritesList/FavouritesListApplication";
import {MyFavouritesListNavigationItemValue, myFavouritesListNavigationOptions} from "../constants/my_offers_navigation_options";
import {MyOffersContextProvider, useMyOffersContext} from "../contexts/MyOffersContext";

const MyFavouritesListViewNC = () => {
    const isMounted = useIsMounted();
    const isAuthenticated = useSelector((store: IRPStore) => store.isAuthenticated);
    const {selectedNavigationItemValue, setSelectedNavigationItemValue} = useMyOffersContext();
    const {favouriteOfferIds, favouritePropertiesIds, loaded} = useFavourites();

    useEffect(() => {
        if (isMounted && loaded) {
            //  authenticated user data is from server so we need to wait for it
            const hasOnlyOffers = favouritePropertiesIds.length === 0;

            handleFavouritesListApplicationOpenHit(hasOnlyOffers);
            handleFavouritesListRenderHit(favouriteOfferIds, favouritePropertiesIds);
        } else if (isMounted && !isAuthenticated) {
            //  unauthenticated user data is from local storage so it is immediately after render
            handleFavouritesListRenderHit(favouriteOfferIds, favouritePropertiesIds);
        }
    }, [isMounted, loaded]);

    const handleNavigationChange = (value: number) => {
        handleFavouritesListNavigationClickHit(value);
        setSelectedNavigationItemValue(Number(value));

        if (value === MyFavouritesListNavigationItemValue.LIST) {
            gtmClickMyListNavigation();
        } else {
            gtmClickMyListApplicationsNavigation();
        }
    };

    const showAsideForm =
        selectedNavigationItemValue === MyFavouritesListNavigationItemValue.LIST && (favouritePropertiesIds.length > 0 || favouriteOfferIds.length > 0);

    return (
        <div css={myListView}>
            <TwoColumnGridWithStickyAside stickyAside={showAsideForm ? <FavouritesListApplication /> : null}>
                <Tabs
                    css={contentWrapStyle}
                    fullWidth
                    color="gray"
                    tabs={myFavouritesListNavigationOptions.map((navOption) => ({
                        title: navOption.label,
                        value: navOption.value,
                        content: (
                            //TODO create shared/ui list component for fav and fav applied list
                            <div css={listContainerStyle}>
                                {navOption.value === MyFavouritesListNavigationItemValue.LIST ? <FavouritesList /> : <AppliedList />}
                            </div>
                        )
                    }))}
                    variant="contained"
                    value={selectedNavigationItemValue}
                    onChange={(selectedTabValue) => handleNavigationChange(selectedTabValue)}
                />
            </TwoColumnGridWithStickyAside>
        </div>
    );
};

export const MyOffersView = () => (
    <MyOffersContextProvider>
        <MyFavouritesListViewNC />
    </MyOffersContextProvider>
);

const myListView = css`
    ${p(0, 0, 6, 0)}
`;

const contentWrapStyle = (theme: Theme) => css`
    ${p(4, 0, 0, 0)};

    @media (min-width: ${theme.breakpoints.md}) {
        // Ugly hack for setting max width of a tab title
        div[data-name="tab-title"] {
            max-width: 18rem;
        }
    }
`;

const listContainerStyle = (theme: Theme) => css`
    ${p(3, 0, 0, 0)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${m(4, 0, 0, 0)};
    }
`;
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {m, p} from "@pg-design/helpers-css";
import {Tabs} from "@pg-design/tabs";
import {useIsMounted} from "@pg-mono/hooks";

import {IRPStore} from "../../app/rp_reducer";
import {TwoColumnGridWithStickyAside} from "../../atoms/TwoColumnGrid";
import {useFavourites} from "../../favourite/hooks/use_favourites";
import {
    handleFavouritesListApplicationOpenHit,
    handleFavouritesListNavigationClickHit,
    handleFavouritesListRenderHit
} from "../../tracking/algolytics_hits/favourites_list_hit";
import {gtmClickMyListApplicationsNavigation, gtmClickMyListNavigation} from "../../tracking/google_tag_manager/gtm_favourites_list";
import {AppliedList} from "../components/AppliedList";
import {FavouritesList} from "../components/FavouritesList/FavouritesList";
import {FavouritesListApplication} from "../components/FavouritesList/FavouritesListApplication";
import {MyFavouritesListNavigationItemValue, myFavouritesListNavigationOptions} from "../constants/my_offers_navigation_options";
import {MyOffersContextProvider, useMyOffersContext} from "../contexts/MyOffersContext";

const MyFavouritesListViewNC = () => {
    const isMounted = useIsMounted();
    const isAuthenticated = useSelector((store: IRPStore) => store.isAuthenticated);
    const {selectedNavigationItemValue, setSelectedNavigationItemValue} = useMyOffersContext();
    const {favouriteOfferIds, favouritePropertiesIds, loaded} = useFavourites();

    useEffect(() => {
        if (isMounted && loaded) {
            //  authenticated user data is from server so we need to wait for it
            const hasOnlyOffers = favouritePropertiesIds.length === 0;

            handleFavouritesListApplicationOpenHit(hasOnlyOffers);
            handleFavouritesListRenderHit(favouriteOfferIds, favouritePropertiesIds);
        } else if (isMounted && !isAuthenticated) {
            //  unauthenticated user data is from local storage so it is immediately after render
            handleFavouritesListRenderHit(favouriteOfferIds, favouritePropertiesIds);
        }
    }, [isMounted, loaded]);

    const handleNavigationChange = (value: number) => {
        handleFavouritesListNavigationClickHit(value);
        setSelectedNavigationItemValue(Number(value));

        if (value === MyFavouritesListNavigationItemValue.LIST) {
            gtmClickMyListNavigation();
        } else {
            gtmClickMyListApplicationsNavigation();
        }
    };

    const showAsideForm =
        selectedNavigationItemValue === MyFavouritesListNavigationItemValue.LIST && (favouritePropertiesIds.length > 0 || favouriteOfferIds.length > 0);

    return (
        <div css={myListView}>
            <TwoColumnGridWithStickyAside stickyAside={showAsideForm ? <FavouritesListApplication /> : null}>
                <Tabs
                    css={contentWrapStyle}
                    fullWidth
                    color="gray"
                    tabs={myFavouritesListNavigationOptions.map((navOption) => ({
                        title: navOption.label,
                        value: navOption.value,
                        content: (
                            //TODO create shared/ui list component for fav and fav applied list
                            <div css={listContainerStyle}>
                                {navOption.value === MyFavouritesListNavigationItemValue.LIST ? <FavouritesList /> : <AppliedList />}
                            </div>
                        )
                    }))}
                    variant="contained"
                    value={selectedNavigationItemValue}
                    onChange={(selectedTabValue) => handleNavigationChange(selectedTabValue)}
                />
            </TwoColumnGridWithStickyAside>
        </div>
    );
};

export const MyOffersView = () => (
    <MyOffersContextProvider>
        <MyFavouritesListViewNC />
    </MyOffersContextProvider>
);

const myListView = css`
    ${p(0, 0, 6, 0)}
`;

const contentWrapStyle = (theme: Theme) => css`
    ${p(4, 0, 0, 0)};

    @media (min-width: ${theme.breakpoints.md}) {
        // Ugly hack for setting max width of a tab title
        div[data-name="tab-title"] {
            max-width: 18rem;
        }
    }
`;

const listContainerStyle = (theme: Theme) => css`
    ${p(3, 0, 0, 0)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${m(4, 0, 0, 0)};
    }
`;
