import {useParams} from "react-router";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {elevation} from "@pg-design/elevation";
import {calculateRemSize as crs, p} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {Link} from "@pg-design/link-module";
import {ISmsCampaignParams, rpAppLink} from "@pg-mono/rp-routes";
import {useUserDevice} from "@pg-mono/user-device";

import {smsCampaignPhoneNumberClick} from "../../tracking/sms_campaign_phone_number_click";
import {ICampaignDetails} from "../../types/ICampaignDetails";
import {getAddToCalendarLink} from "../../utils/get_add_to_calendar_link";

interface IProps {
    campaign: Pick<ICampaignDetails, "opendays_date_range" | "opendays_time_range" | "slogan" | "offer" | "highlight_description" | "phone">;
}

export function SmsCampaignMenu(props: IProps) {
    const {campaign} = props;
    const {isIOS} = useUserDevice();
    const {subscriberUuid} = useParams<ISmsCampaignParams>();

    const phone = `+${campaign.phone.replace(/ /g, "")}`;

    if (isIOS === null) {
        return null;
    }

    return (
        <div css={smsCampaignMenu}>
            <div css={campaignButtons}>
                <Button
                    variant="filled_primary"
                    size="x-small"
                    iconLeft={PhoneIcon}
                    css={campaignButton}
                    href={`tel:${phone}`}
                    onClick={() => {
                        smsCampaignPhoneNumberClick(campaign);
                    }}
                >
                    <span css={campaignButtonFont}>Zadzwoń</span>
                </Button>
                <Button variant="filled_secondary" size="x-small" css={campaignButton} href={getAddToCalendarLink(campaign, isIOS)} target="_blank">
                    <span css={campaignButtonFont}>Dodaj do kalendarza</span>
                </Button>
            </div>
            {subscriberUuid && (
                <div css={unsubscribeWrap}>
                    <Link size="x-small" href={rpAppLink.newsletter.sms.unsubscribe({subscriberUuid})}>
                        Wypisz się z listy subskrybentów SMS
                    </Link>
                </div>
            )}
        </div>
    );
}

// Styles
const smsCampaignMenu = css`
    display: flex;
    flex-direction: column;
    gap: ${crs(1)};
    inset: auto 0 0 0;
    background: white;
    ${p(1)}
    z-index: 1;
    ${elevation(1)}
    position: fixed;
`;

const campaignButtons = css`
    display: flex;
    gap: ${crs(1)};
`;

const campaignButton = css`
    flex: 1 0 30%;
`;

const campaignButtonFont = css`
    font-size: 12px;
`;

const unsubscribeWrap = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;
import {useParams} from "react-router";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {elevation} from "@pg-design/elevation";
import {calculateRemSize as crs, p} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {Link} from "@pg-design/link-module";
import {ISmsCampaignParams, rpAppLink} from "@pg-mono/rp-routes";
import {useUserDevice} from "@pg-mono/user-device";

import {smsCampaignPhoneNumberClick} from "../../tracking/sms_campaign_phone_number_click";
import {ICampaignDetails} from "../../types/ICampaignDetails";
import {getAddToCalendarLink} from "../../utils/get_add_to_calendar_link";

interface IProps {
    campaign: Pick<ICampaignDetails, "opendays_date_range" | "opendays_time_range" | "slogan" | "offer" | "highlight_description" | "phone">;
}

export function SmsCampaignMenu(props: IProps) {
    const {campaign} = props;
    const {isIOS} = useUserDevice();
    const {subscriberUuid} = useParams<ISmsCampaignParams>();

    const phone = `+${campaign.phone.replace(/ /g, "")}`;

    if (isIOS === null) {
        return null;
    }

    return (
        <div css={smsCampaignMenu}>
            <div css={campaignButtons}>
                <Button
                    variant="filled_primary"
                    size="x-small"
                    iconLeft={PhoneIcon}
                    css={campaignButton}
                    href={`tel:${phone}`}
                    onClick={() => {
                        smsCampaignPhoneNumberClick(campaign);
                    }}
                >
                    <span css={campaignButtonFont}>Zadzwoń</span>
                </Button>
                <Button variant="filled_secondary" size="x-small" css={campaignButton} href={getAddToCalendarLink(campaign, isIOS)} target="_blank">
                    <span css={campaignButtonFont}>Dodaj do kalendarza</span>
                </Button>
            </div>
            {subscriberUuid && (
                <div css={unsubscribeWrap}>
                    <Link size="x-small" href={rpAppLink.newsletter.sms.unsubscribe({subscriberUuid})}>
                        Wypisz się z listy subskrybentów SMS
                    </Link>
                </div>
            )}
        </div>
    );
}

// Styles
const smsCampaignMenu = css`
    display: flex;
    flex-direction: column;
    gap: ${crs(1)};
    inset: auto 0 0 0;
    background: white;
    ${p(1)}
    z-index: 1;
    ${elevation(1)}
    position: fixed;
`;

const campaignButtons = css`
    display: flex;
    gap: ${crs(1)};
`;

const campaignButton = css`
    flex: 1 0 30%;
`;

const campaignButtonFont = css`
    font-size: 12px;
`;

const unsubscribeWrap = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;
