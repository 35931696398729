import React from "react";
import {css} from "@emotion/react";

import {BuylandoBanner} from "../components/BuylandoBanner";
import {BuylandoExpert} from "../components/BuylandoExpert";
import {BuylandoIntro} from "../components/BuylandoIntro";
import {BuylandoKnowledgeCTA} from "../components/BuylandoKnowledgeCTA";
import {BuylandoOffer} from "../components/BuylandoOffer";
import {BuylandoSteps} from "../components/BuylandoSteps";
import {BuylandoWhyRP} from "../components/BuylandoWhyRp";

export const BuylandoLandingView = () => {
    return (
        <div css={viewWrap}>
            <BuylandoBanner />
            <BuylandoIntro />
            <BuylandoWhyRP />
            <BuylandoOffer />
            <BuylandoSteps />
            <BuylandoExpert />
            <BuylandoKnowledgeCTA />
        </div>
    );
};

/*
    Styles
 */
const viewWrap = css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
import React from "react";
import {css} from "@emotion/react";

import {BuylandoBanner} from "../components/BuylandoBanner";
import {BuylandoExpert} from "../components/BuylandoExpert";
import {BuylandoIntro} from "../components/BuylandoIntro";
import {BuylandoKnowledgeCTA} from "../components/BuylandoKnowledgeCTA";
import {BuylandoOffer} from "../components/BuylandoOffer";
import {BuylandoSteps} from "../components/BuylandoSteps";
import {BuylandoWhyRP} from "../components/BuylandoWhyRp";

export const BuylandoLandingView = () => {
    return (
        <div css={viewWrap}>
            <BuylandoBanner />
            <BuylandoIntro />
            <BuylandoWhyRP />
            <BuylandoOffer />
            <BuylandoSteps />
            <BuylandoExpert />
            <BuylandoKnowledgeCTA />
        </div>
    );
};

/*
    Styles
 */
const viewWrap = css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
