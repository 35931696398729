import {SerializedError} from "@reduxjs/toolkit";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

export function isFetchBaseQueryError(rtkqError: FetchBaseQueryError | SerializedError | unknown): rtkqError is FetchBaseQueryError {
    if (typeof rtkqError !== "object" || !rtkqError) {
        return false;
    }

    if (!("status" in rtkqError)) {
        return false;
    }

    return true;
}
import {SerializedError} from "@reduxjs/toolkit";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

export function isFetchBaseQueryError(rtkqError: FetchBaseQueryError | SerializedError | unknown): rtkqError is FetchBaseQueryError {
    if (typeof rtkqError !== "object" || !rtkqError) {
        return false;
    }

    if (!("status" in rtkqError)) {
        return false;
    }

    return true;
}
