import * as React from "react";
import {css} from "@emotion/react";

import {borderRadius, calculateRemSize, onDesktop, p} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {useBoxHandlerWithAnchor} from "../../hooks/use_box_handler_with_anchor";
import {IPartner} from "../actions/fetch_partners_list";

interface IProps {
    partner: IPartner;
}

export const PartnersListItem = (props: IProps) => {
    const {partner} = props;
    const itemHandler = useBoxHandlerWithAnchor(partner.website);

    const partnerListItem = (
        <div css={partnersListItemContent} onClick={itemHandler}>
            <div css={partnerHeader}>
                <Text align="center">
                    <a href={partner.website} target="_blank" rel={partner.follow_link ? undefined : "nofollow"}>
                        {partner.name}
                    </a>
                </Text>
                <Image css={partnerLogo} src={partner.logo?.p_log_160} alt={partner.name} width="160" height="160" />
            </div>

            <Text variant="body_copy_1" css={partnersText}>
                {partner.description}
            </Text>
        </div>
    );

    return <li css={partnersListItem}>{partnerListItem}</li>;
};

const partnersListItem = css`
    flex: 0 0 30%;
`;

const partnersListItemContent = css`
    ${p(4, 2)};
    background: white;
    ${borderRadius(2)};
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(2)};

    ${onDesktop(css`
        flex-direction: row;
        column-gap: ${calculateRemSize(2)};
    `)}
    &:hover {
        cursor: pointer;
    }
`;

const partnerHeader = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

const partnerLogo = css`
    ${p(2)}

    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }
`;

const partnersText = css`
    flex: 4;
`;
import * as React from "react";
import {css} from "@emotion/react";

import {borderRadius, calculateRemSize, onDesktop, p} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {useBoxHandlerWithAnchor} from "../../hooks/use_box_handler_with_anchor";
import {IPartner} from "../actions/fetch_partners_list";

interface IProps {
    partner: IPartner;
}

export const PartnersListItem = (props: IProps) => {
    const {partner} = props;
    const itemHandler = useBoxHandlerWithAnchor(partner.website);

    const partnerListItem = (
        <div css={partnersListItemContent} onClick={itemHandler}>
            <div css={partnerHeader}>
                <Text align="center">
                    <a href={partner.website} target="_blank" rel={partner.follow_link ? undefined : "nofollow"}>
                        {partner.name}
                    </a>
                </Text>
                <Image css={partnerLogo} src={partner.logo?.p_log_160} alt={partner.name} width="160" height="160" />
            </div>

            <Text variant="body_copy_1" css={partnersText}>
                {partner.description}
            </Text>
        </div>
    );

    return <li css={partnersListItem}>{partnerListItem}</li>;
};

const partnersListItem = css`
    flex: 0 0 30%;
`;

const partnersListItemContent = css`
    ${p(4, 2)};
    background: white;
    ${borderRadius(2)};
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(2)};

    ${onDesktop(css`
        flex-direction: row;
        column-gap: ${calculateRemSize(2)};
    `)}
    &:hover {
        cursor: pointer;
    }
`;

const partnerHeader = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

const partnerLogo = css`
    ${p(2)}

    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }
`;

const partnersText = css`
    flex: 4;
`;
