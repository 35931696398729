import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {PromotionType} from "../../promotion/helpers/PromotionType";
import {ViewType} from "../../view_type/ViewType";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const pageViewHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("search", payload), 500);

export const promotionListSearchHit = (
    searchOptions: {
        search_region_name: string | null;
        search_region_id: number | null;
        search_region_type: number | null;
        search_type: PromotionType;
    },
    offersData: {offer_id: number[]; offer_name: string[]; vendor_id: number[]; vendor_name: string[]}
) => {
    const payload = {
        view_type: ViewType.PROMOTION_LIST,
        ...getTrackedSiteData(),
        ...searchOptions,
        ...offersData
    };
    pageViewHitAlgolytics(payload);
};

export const normalizeRegionDataToPayload = (searchRegion?: {name: string; id: number; type: number} | null) => {
    return {
        search_region_name: searchRegion?.name ?? null,
        search_region_id: searchRegion?.id ?? null,
        search_region_type: searchRegion?.type ?? null
    };
};
import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {PromotionType} from "../../promotion/helpers/PromotionType";
import {ViewType} from "../../view_type/ViewType";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const pageViewHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("search", payload), 500);

export const promotionListSearchHit = (
    searchOptions: {
        search_region_name: string | null;
        search_region_id: number | null;
        search_region_type: number | null;
        search_type: PromotionType;
    },
    offersData: {offer_id: number[]; offer_name: string[]; vendor_id: number[]; vendor_name: string[]}
) => {
    const payload = {
        view_type: ViewType.PROMOTION_LIST,
        ...getTrackedSiteData(),
        ...searchOptions,
        ...offersData
    };
    pageViewHitAlgolytics(payload);
};

export const normalizeRegionDataToPayload = (searchRegion?: {name: string; id: number; type: number} | null) => {
    return {
        search_region_name: searchRegion?.name ?? null,
        search_region_id: searchRegion?.id ?? null,
        search_region_type: searchRegion?.type ?? null
    };
};
