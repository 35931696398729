import * as React from "react";
import {css, Theme} from "@emotion/react";

import {centerText, onDesktop} from "@pg-design/helpers-css";
import {useIsMounted} from "@pg-mono/hooks";

import {slickSlider} from "../../../app/styles/slick_slider";
import {ITestimonial} from "../../actions/fetch_testimonials";
import {TestimonialSliderItem} from "./atoms/TestimonialSliderItem";
const LazySlider = React.lazy(() => import(/* webpackChunkName: "lazy_slick_slider_chunk" */ "@pg-mono/slick-slider"));

interface IProps {
    testimonials: ITestimonial[];
}

const TestimonialGalleryPlaceholder = (props: IProps) => {
    const hasTestimonials = props.testimonials.length > 0;

    if (!hasTestimonials) {
        return null;
    }

    const {text, image} = props.testimonials[0];

    return (
        <div>
            <TestimonialSliderItem description={text} logoSrc={image} />
        </div>
    );
};

export const TestimonialSlider = (props: IProps) => {
    const {testimonials} = props;

    const isMounted = useIsMounted();

    const gallerySlickHolderSettings = {
        dots: true,
        arrows: true,
        infinite: true,
        outerEdgeLimit: true,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        autoplay: true,
        autoplaySpeed: 4000
    };

    return (
        <div css={testimonialSliderHolder}>
            {isMounted ? (
                <React.Suspense fallback={<TestimonialGalleryPlaceholder testimonials={testimonials} />}>
                    <LazySlider {...gallerySlickHolderSettings}>
                        {testimonials.map(({id, image, text}) => (
                            <TestimonialSliderItem key={`${id}`} description={text} logoSrc={image} />
                        ))}
                    </LazySlider>
                </React.Suspense>
            ) : (
                <TestimonialGalleryPlaceholder testimonials={testimonials} />
            )}
        </div>
    );
};

const testimonialSliderHolder = (theme: Theme) => css`
    ${slickSlider};
    ${slickDots(theme)};
    ${nextPrevButtons};
    ${centerText};
    overflow: hidden;
    padding-bottom: 1.5rem;

    ${onDesktop(css`
        text-align: left;
    `)}
`;

const slickDots = (theme: Theme) => css`
    .slick-dots {
        text-align: unset;
        z-index: 2;
        > li {
            margin: 0 0 1rem 0;
            width: 2.4rem;
            height: 2.4rem;
            

            &:not(:last-of-type) {
                margin-right: 2.4rem;

                @media (min-width: ${theme.breakpoints.sm}) {
                    margin-right: 0.8rem;
                }
            }

            > button {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                background-color: ${theme.colors.gray[300]} ;
                &::before {
                    color: transparent;
                }
            }

            &.slick-active {
                > button {
                    background: ${theme.colors.primary};
                    &::before {
                        color: ${theme.colors.primary};
                    }
                }
            }
        }
    }
}`;

const nextPrevButtons = css`
    .slick-next,
    .slick-prev {
        &:before {
            background-image: none;
            background-color: transparent;
        }
    }
`;
import * as React from "react";
import {css, Theme} from "@emotion/react";

import {centerText, onDesktop} from "@pg-design/helpers-css";
import {useIsMounted} from "@pg-mono/hooks";

import {slickSlider} from "../../../app/styles/slick_slider";
import {ITestimonial} from "../../actions/fetch_testimonials";
import {TestimonialSliderItem} from "./atoms/TestimonialSliderItem";
const LazySlider = React.lazy(() => import(/* webpackChunkName: "lazy_slick_slider_chunk" */ "@pg-mono/slick-slider"));

interface IProps {
    testimonials: ITestimonial[];
}

const TestimonialGalleryPlaceholder = (props: IProps) => {
    const hasTestimonials = props.testimonials.length > 0;

    if (!hasTestimonials) {
        return null;
    }

    const {text, image} = props.testimonials[0];

    return (
        <div>
            <TestimonialSliderItem description={text} logoSrc={image} />
        </div>
    );
};

export const TestimonialSlider = (props: IProps) => {
    const {testimonials} = props;

    const isMounted = useIsMounted();

    const gallerySlickHolderSettings = {
        dots: true,
        arrows: true,
        infinite: true,
        outerEdgeLimit: true,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        autoplay: true,
        autoplaySpeed: 4000
    };

    return (
        <div css={testimonialSliderHolder}>
            {isMounted ? (
                <React.Suspense fallback={<TestimonialGalleryPlaceholder testimonials={testimonials} />}>
                    <LazySlider {...gallerySlickHolderSettings}>
                        {testimonials.map(({id, image, text}) => (
                            <TestimonialSliderItem key={`${id}`} description={text} logoSrc={image} />
                        ))}
                    </LazySlider>
                </React.Suspense>
            ) : (
                <TestimonialGalleryPlaceholder testimonials={testimonials} />
            )}
        </div>
    );
};

const testimonialSliderHolder = (theme: Theme) => css`
    ${slickSlider};
    ${slickDots(theme)};
    ${nextPrevButtons};
    ${centerText};
    overflow: hidden;
    padding-bottom: 1.5rem;

    ${onDesktop(css`
        text-align: left;
    `)}
`;

const slickDots = (theme: Theme) => css`
    .slick-dots {
        text-align: unset;
        z-index: 2;
        > li {
            margin: 0 0 1rem 0;
            width: 2.4rem;
            height: 2.4rem;
            

            &:not(:last-of-type) {
                margin-right: 2.4rem;

                @media (min-width: ${theme.breakpoints.sm}) {
                    margin-right: 0.8rem;
                }
            }

            > button {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                background-color: ${theme.colors.gray[300]} ;
                &::before {
                    color: transparent;
                }
            }

            &.slick-active {
                > button {
                    background: ${theme.colors.primary};
                    &::before {
                        color: ${theme.colors.primary};
                    }
                }
            }
        }
    }
}`;

const nextPrevButtons = css`
    .slick-next,
    .slick-prev {
        &:before {
            background-image: none;
            background-color: transparent;
        }
    }
`;
